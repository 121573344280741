import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, IDoesFilterPassParams, RowNode } from 'ag-grid-community';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'custom-driving-rating-filter',
    template: `
    <div class="padding-10" fxLayout="column" fxFlex>
        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="poorSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.poor' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="averageSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.average' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="goodSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.good' | translate }}
        </mat-checkbox>
    </div>
    `,
    styles: [`
        :host ::ng-deep label.mat-checkbox-layout {
            width: 100%;
        }
        :host ::ng-deep label .mat-checkbox-inner-container {
            margin-left: 0px;
        }
    `]
})
export class CustomDrivingRatingFilterComponent implements IFilterAngularComp
{
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    poorSelected: boolean = true;
    averageSelected: boolean = true;
    goodSelected: boolean = true;

    constructor(private cService: CommonService) { }

    agInit(params: IFilterParams) {
        this.params = params;
        this.valueGetter = params.valueGetter;
    }

    isFilterActive(): boolean {
        return !this.poorSelected || !this.averageSelected || !this.goodSelected;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        let filterExpression: boolean;

        // Single de-selections
        if (!this.poorSelected) {
            filterExpression = this.valueGetter(params.node) > 
                this.cService.mentorDrivingRatingValues.highRisk.max;
        }

        if (!this.averageSelected) {
            filterExpression = (this.valueGetter(params.node) >
                this.cService.mentorDrivingRatingValues.mediumRisk.max)
                || (this.valueGetter(params.node) <= 
                this.cService.mentorDrivingRatingValues.highRisk.max);
        }

        if (!this.goodSelected) {
            filterExpression = this.valueGetter(params.node) < 
                this.cService.mentorDrivingRatingValues.lowRisk.min;
        }

        // Double de-selections
        if (!this.poorSelected && !this.averageSelected) {
            filterExpression = this.valueGetter(params.node) > 
                this.cService.mentorDrivingRatingValues.mediumRisk.max;
        }
        
        if (!this.poorSelected && !this.goodSelected) {
            filterExpression = (this.valueGetter(params.node) >
                this.cService.mentorDrivingRatingValues.highRisk.max) 
                && (this.valueGetter(params.node) < 
                this.cService.mentorDrivingRatingValues.lowRisk.min);
        }

        if (!this.averageSelected && !this.goodSelected) {
            filterExpression = this.valueGetter(params.node) <=
                this.cService.mentorDrivingRatingValues.highRisk.max;
        }

        // Catch all - nothing selected
        if (!this.poorSelected && !this.averageSelected && !this.goodSelected) {
            filterExpression = this.valueGetter(params.node) == null;
        }

        return filterExpression;
    }

    getModel(): any {
        return;
    }

    setModel(model: any) {
        
    }

    // ----------------------

    selectionChanged() {
        this.params.filterChangedCallback();
    }
}