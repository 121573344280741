import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentIeCompatibleDate'
})
export class IeCompatibleDate implements PipeTransform
{
    transform(dateInput: Date | string | number, momentFormat?: string) {
        /**
         * Problem ref: 
         * https://www.csgpro.com/blog/2016/08/a-bad-date-with-internet-explorer-11-trouble-with-new-unicode-characters-in-javascript-date-strings
         * 
         * NOTE: IE 11's conversion of date to localString includes a character that causes
         * it to fail when rendered on the browser. The traditional Angular Date Pipe uses
         * the toLocalString conversion, hence the problem is triggered on IE.
         * 
         * To avoid this, use moment instead. This means passing in supported moment format
         */

        const toRelativeTime = 'relativeTime';
        let translatedMoment: string;

        if (momentFormat.toLowerCase() === toRelativeTime.toLowerCase()) {
            translatedMoment = moment(dateInput).startOf('hour').fromNow();
        }
        else {
            translatedMoment = moment(dateInput).format(momentFormat);
        }
        
        return translatedMoment;
    }
}