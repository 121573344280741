import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'calendar-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="calendar-header">

            <button mat-icon-button class="calendar-header-buttons" (click)="previousClicked('month')">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <small class="calendar-header-label" style="position: relative; top: -4px">
                {{ periodLabel | date: 'MMM' | translateDateMonth }}
            </small>
            <button mat-icon-button class="calendar-header-buttons" (click)="nextClicked('month')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <button mat-icon-button class="calendar-header-buttons calendar-double-arrow" (click)="previousClicked('year')">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <small class="calendar-header-label" style="position: relative; top: -4px">
                {{ periodLabel | date: 'yyyy' }}
            </small>
            <button mat-icon-button class="calendar-header-buttons calendar-double-arrow" (click)="nextClicked('year')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    `,
    styles: [`
        .calendar-header {
            display: flex;
            align-items: center;
        }

        .calendar-header-label {
            flex: 1;
            height: 1em;
            font-weight: 500;
            text-align: center;
        }

        .calendar-double-arrow .mat-icon {
            margin: -22%;
        }

        button.calendar-header-buttons {
            min-width: 0px;
            border-radius: 0px;
            width: 28px;
            opacity: 0.7
        }
    `]
})
export class CalendarHeaderComponent<D> implements OnDestroy
{
    private _destroyed = new Subject<void>();
    
    constructor(
        private _calendar: MatCalendar<D>, 
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, 
        cdr: ChangeDetectorRef) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe({ next: () => cdr.markForCheck() });
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._calendar.activeDate;
        // this._dateAdapter
        //     .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
        //     .toLocaleUpperCase();
    }
    // get monthLabel() {
    //     return this._dateAdapter
    //         .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
    //         .toLocaleUpperCase();
    // }
    // get yearLabel() {
    //     return this._dateAdapter
    //         .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
    //         .toLocaleUpperCase();
    // }
    
    // EVENTS
    previousClicked(mode: 'month' | 'year') {
        this._calendar.activeDate = mode === 'month' ?
            this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
            this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year') {
        this._calendar.activeDate = mode === 'month' ?
            this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
            this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
}