import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { AuthService, CompanyPasswordPolicy } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityComponent implements OnInit {
  hide: boolean = true;
  changePasswordForm: FormGroup;
  companyPwPolicy: CompanyPasswordPolicy;

  maxTabTextLength: number = 12;

  constructor(
    public cService: CommonService,
    public authService: AuthService,
    private changeDetectionRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    setTimeout(() => {
      const companyName = (cService.userProfile && cService.userProfile.company) ? cService.userProfile.company : '';
      this.cService.setPageHeader(this.translate.instant('SECURITY')['accountAndSecurity']);
    });
  }

  ngOnInit() {
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, Validators.required),
      newPassword: new FormControl(null, Validators.required),
      confirmNewPassword: new FormControl(null, Validators.required)
    });

    this.authService.getPasswordPolicyForAuthenticatedUser().subscribe({
      next: (companyPwPolicy: any) => {
        this.companyPwPolicy = companyPwPolicy;
        this.changeDetectionRef.detectChanges();
        console.log('%c Company Password Policy', 'color: orange', companyPwPolicy);
      }
    });
  }

  // EVENTS
  clearChangePasswordForm(): void {
    this.changePasswordForm.reset();
    this.authService.passwordChangeErrorMessage = '';
  }

  changeAccountPassword(): void {
    if (!this.cService.userProfile) {
      this.cService.userProfile = JSON.parse(localStorage.getItem(this.cService.localStorageKeys.userProfile));
    }

    const credentials = {
      username: this.cService.userProfile.username,
      companyCode: (this.cService.userProfile.isDistributor && this.cService.userProfile.distributorUserCompany) ?
        this.cService.userProfile.distributorUserCompany : this.cService.userProfile.company,
      pin: this.changePasswordForm.get('pin').value,
      firstName: '',
      lastName: '',
      password: ''
    };

    const newPassword = this.changePasswordForm.get('newPassword').value;

    this.authService.changeAccountPassword(credentials, newPassword).subscribe({
      next: (response) => {
        this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.passwordSuccessfullyChanged);
        this.clearChangePasswordForm();
      },
      error: () => {
        if (this.authService.passwordChangeErrorMessage) {
          this.changeDetectionRef.detectChanges();
        }
      }
    });
  }
}
