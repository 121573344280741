import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dark-theme-toggle',
    template: `
    <mat-slide-toggle class="style-as-menu-button force" [checked]="isDarkTheme" fxFlex
        (toggleChange)="toggleDarkTheme()" (dragChange)="toggleDarkTheme()" color="primary">
        {{ 'OPTIONS.darkTheme' | translate }}
    </mat-slide-toggle>
    `,
    styles: []
})
export class DarkThemeToggleComponent
{
    @Input() isDarkTheme: boolean;

    @Output() onToggleChange = new EventEmitter();

    toggleDarkTheme() {
        this.onToggleChange.emit();
    }
}