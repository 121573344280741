import { AfterViewInit, Component, InjectionToken, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
import { ReportMeta } from '../../services/reports.service';
import { OtherMiniReportOptions } from 'src/app/model/reports';
import { DriverHqService } from 'src/app/services/driver-hq.service';
import { CasualDialogTypesEnum } from 'src/app/services/casual-report.service';
import { DriverHqGuideViewerComponent } from 'src/app/dialogs/driver-hq-guide-viewer/driver-hq-guide-viewer.component';
import { Config } from '../../config';
import { DriverHqDocumentViewerComponent } from 'src/app/components/driver-hq-document-viewer/driver-hq-document-viewer.component';
import { PromptWithActionDialogComponent } from 'src/app/dialogs/prompt-with-action/prompt-with-action.component';
@Component({
  selector: 'vrm-driverhq-guide',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})

export class ResourcesComponent implements AfterViewInit {
  @ViewChild(DriverHqDocumentViewerComponent, { static: true })
  viewer: DriverHqDocumentViewerComponent;
  trainingMetadata: ReportMeta;
  trainingModuleData: Array<any>;
  categories:Array<string>;

  reportOptions: OtherMiniReportOptions = {
    reportHeight: '100%'
  };


  public documents: Array<any>;
  public showViewer:boolean=false;
  title: string;
 
 
  constructor(
    private cService: CommonService,
    private dialog: MatDialog,
    private driverHqService: DriverHqService
  
  ) {
    setTimeout(() => {
      const pageTitle = cService.readyTranslations.guideFaq.vrmGuides;
      this.cService.setPageHeader(pageTitle);
    });
    this.getCategories();
    this.driverHqService.getDocumentList().subscribe(r => {
      this.documents = r;
    })
  }
  ngAfterViewInit(): void {
    console.log(this.viewer);
    this.showViewer = false;
  }
  private getCategories() {
    this.categories = [];
    for (let prop in Config.DocumentCategories) {
      this.categories.push(Config.DocumentCategories[prop].text);
    }
  }
  public openDoc(docId, filetype, file_real_name){
    this.title = file_real_name;
    this.viewer.openDoc(docId,filetype)
    this.showViewer = true;
  }
  public closeDoc() {
    this.title='';
    this.viewer.closeDoc();
    this.showViewer = false;
  }
  public downloadDoc(docId, filetype, file_real_name){
    this.viewer.downloadDoc(docId, filetype, file_real_name);
  }
  deleteDoc(docId) {

    this.cService.promptConfig = {
      title: 'DELETE DOCUMENT',
      body: `<div style='margin-top:10%; margin-left:5%;, margin-right:5%'>Would you like to delete this document?</div>`,
      actionButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    };

    this.dialog.open(PromptWithActionDialogComponent).afterClosed().subscribe({
      next: (todelete: boolean) => {
        if (todelete) {
          this.driverHqService
            .deleteDocument(docId)
            .toPromise()
            .then((res) => {
              this.dialog.closeAll();
              setTimeout(() => {
                this.driverHqService.getDocumentList().subscribe(r => {
                  this.documents = r;
                })
              }, 100);

              this.cService.promptConfig = {
                title: 'DELETE DOCUMENT SUCCESS',
                body: `<div style='margin-top:10%; margin-left:5%;, margin-right:5%'>This document has been successfully deleted.</div>`
                ,
                actionButtonText: 'Ok'
              };
              this.dialog.open(PromptWithActionDialogComponent).afterClosed().subscribe({
                next: (ok: boolean) => {


                }
              });
            })
            .catch((err) => {
              console.log(err);

            });


        }
      }
    });

  }
}   
