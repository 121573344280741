import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';

import { ReviewPeriod } from 'vrm-forms';
import { SidepaneItem } from '../../model/dialogs';
import { DriverHqService } from 'src/app/services/driver-hq.service';


@Component({
  selector: 'vrm-driver-performance-mentor',
  templateUrl: './driver-performance-mentor.component.html',
  styleUrls: ['./driver-performance-mentor.component.scss']
})
export class DriverPerformanceMentorComponent implements OnInit {
  showDetailsBufferProgress: boolean = false;
  sidepaneItems: Array<SidepaneItem> = [];
  reportEndpointName: string;

  reviewPeriods: Array<ReviewPeriod> = [];

  selectedCoachFormData: any;
  selectedPeriod: any;
  coachFormIsReadonly: boolean = false;
  dataReady: boolean = false;
  renderMultipleCharts: boolean = false;
  formIsVisible: boolean = false;
  formDataUpdated: boolean = false;

  driverIndexSummaryChart: any;
  mentorCharts: Array<any> = [];




  pageTitle: string = '';
  pageTitleDescription: string = '';
  constructor(
    private translate: TranslateService,
    public cService: CommonService,
    public rptService: ReportsService,
    private driverHqService: DriverHqService
  ) { }

  public data: any = { "selectedRow": { "firstName": "MNT89F", "lastName": "MNT89L", "pin": "MEN237888", "email": "MNT89@edriving.com", "location1": "London", "profileDriver": 0, "defensiveDriving": 0, "rating": 0, "completionDate": null }, "reportEndpointName": "roadrisk/driver_risk_rating" }


  ngOnInit() {
    console.log('DIALOG DATA', this.data);
    this.initialize();
  }

  initialize() {
    this.showDetailsBufferProgress = true;
    this.reportEndpointName = this.data.reportEndpointName;

    this.pageTitle = this.translate.instant('FORM')['driverPerformanceRecord'];
    this.generatePageDescription([
      this.data.selectedRow.firstName + ' ' + this.data.selectedRow.lastName,
      this.data.selectedRow.pin
    ]);
    this.fetchData(this.data.selectedRow.pin);
  }

  getTransformedMonth(): string {
    let month = '';
    const currentMonth = new Date().getMonth() + 1;
    month = (currentMonth < 10) ? month = '0' + currentMonth : currentMonth.toString();

    return month;
  }


  generatePageDescription(texts: Array<string>) {
    let description: string = '';

    texts.forEach((text, key) => {
      const suffix = (key === texts.length - 1) ? '' : ' . ';
      description += text + suffix;
    });

    this.pageTitleDescription = description;
  }

  fetchData(pin: string) {
    this.dataReady = false;
    // Coach Form - ForkJoined Summaries
    this.driverHqService.getCoachSummariesForkJoined(pin).subscribe({
      next: (resp) => {
        const chartSummary = resp[0];
        const coachSummary = resp[1];
        const mentorCharts = resp[2];

        this.showDetailsBufferProgress = false;

        console.log('COACH SUMMARY Data Response', coachSummary);

        this.reviewPeriods = coachSummary.data[0].dates;
        this.driverIndexSummaryChart = chartSummary;

        // Translate mentor chart titles
        if (mentorCharts.length > 0) {
          this.translate.get('CHART').subscribe({
            next: (translation) => {
              mentorCharts.forEach((mentorChart: any) => {
                const translationText = translation[mentorChart.options.title.text];
                if (translationText) {
                  mentorChart.options.title.translatedText = translationText;
                }
              });

              this.mentorCharts = mentorCharts;
            }
          });
        }

        console.log('Review periods', this.reviewPeriods);


        setTimeout(() => {
          this.dataReady = true;
        });

        if (this.mentorCharts.length > 0) {
          setTimeout(() => {
            this.renderMultipleCharts = true;
          },
            2000);
        }

        // console.log('Fork Joined Summary Response', resp);
        console.log('Chart Summary', chartSummary);
        console.log('Mentor charts', mentorCharts);
      }
    });



  }



}
