import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { CommonService, VrmFileIcon } from '../../services/common.service';

@Component({
    selector: 'file-type-icon',
    template: `
    <span [matTooltip]="value">
        <i [class]="fileIcon.icon" [style.color]="fileIcon.color"
            style="font-size: 25px; position: relative; top: 2px">
        </i>
    </span>
    `,
    styles: []
})
export class FileExtensionAsIconComponent implements ICellRendererAngularComp
{
    fileIcon: VrmFileIcon;
    value: string;

    constructor(private cService: CommonService) { }

    agInit(params: { value: any }) { 
        this.value = params.value;
        this.fileIcon = this.cService.getApplicableIconForExtension(this.value);
    }



    refresh(): boolean {
        return false;
    }
}