import { 
  Component, OnInit, OnChanges, SimpleChanges, OnDestroy,
  Input, ViewChild, ElementRef 
} from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
/* =======================================
Ref: https://chartjs-plugin-datalabels.netlify.com/guide/getting-started.html#integration

This plugin registers itself globally, 
meaning that once imported, all charts will display labels. 

In case you want it enabled only for a few charts, 
you first need to unregister it globally:
========================================= */
Chart.plugins.unregister(ChartDataLabels);

@Component({
  selector: 'vrm-chart',

  // NOTE: The canvas tag cannot be in a non-html tag such as ng-content
  // hence why it's wrapped with the div at this level!
  template: `
      <div fxLayoutAlign="center" style="position: relative; height: 40vh">
          <canvas #chartCanvas></canvas>
      </div>
  `,
  styles: [`
      canvas {
          margin-top: 10px;
          margin-bottom: 20px;
          width: 100% !important; /* <-- necessary for canvas to be responsive */
      }
  `]
})
export class VrmChartJsComponent implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('chartCanvas', { static: true })
  chartElement: ElementRef;

  @Input() chartId: string | number;
  @Input() chartData: any;

  constructor() { 
    Chart.plugins.register(ChartDataLabels);
  }

  // LIFECYCLE
  ngOnInit() {
    this.renderCharts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']) {
      this.renderCharts();
    }
  }

  // FUNCTIONS
  renderCharts(): void {
    let renderChart: any ;
    const targets: Array<any> = ['Acceleration', 'Braking', 'Cornering', 'Distraction', 'Speeding'];
    const options = this.chartData.options;
    delete this.chartData.plugins;

      if (options.title.translatedText) {
        options.title.text = options.title.translatedText;
      }

      this.chartData.options = options;

      if (targets.includes(options.title.text)) {
        options.scales.yAxes[0].ticks = {
          min: 0,
          userCallback: (value: number, index: number, values: number) => {
              return value.toFixed(2);
            }
        };
        this.chartData.options = options;

        renderChart = new Chart(this.chartElement.nativeElement, {plugins: [ChartDataLabels], ...this.chartData});
      }
      else{
        renderChart = new Chart(this.chartElement.nativeElement, {plugins: [], ...this.chartData});
        Chart.plugins.unregister(ChartDataLabels);
      }
  }

  ngOnDestroy() {
    Chart.plugins.unregister(ChartDataLabels);
  }
}
