// Angular
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// Services
import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';
import { ProvisioningService } from '../../services/provisioning.service';
import { TranslateService } from '@ngx-translate/core';
// Subscription
import { Subscription } from 'rxjs';
// Dialog
import { UserIndexComponent } from '../../dialogs/user-index/user-index.component';
import { UploadUsersComponent } from 'src/app/dialogs/upload-users/upload-users.component';
// Model
import { ReportOption } from 'src/app/model/reports';
import { ApiResponse, DriversListItem } from 'src/app/model/provisioning';

@Component({
  selector: 'app-self-provisioning',
  templateUrl: './self-provisioning.component.html',
  styleUrls: ['./self-provisioning.component.css']
})

export class SelfProvisioningComponent implements OnInit {

  reportTitle: string = '';
  primaryKey: string = 'userId';
  userProvisioningReady: boolean = false;
  /**
   * @todo remove this if multiselection never will an option
   */
  multiRowSelection: boolean = false;

  // Dialogs component
  private userIndexComponent = UserIndexComponent;
  private uploadUsersComponent = UploadUsersComponent;

  // Report option
  selfProvisioningOptions: ReportOption = {
    reportEndpointName: 'provisioning',
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        },
        {
          id: 'customDetails',
          labelDefault: 'VRM Mentor',
          labelKey: 'customDetails',
          iconKey: 'details',
          toolPanel: 'customDetailsToolPaneComponent',
          toolPanelParams: {
            showTimeFramePanel: false
          }
        }
      ],
      defaultToolPanel: 'customDetails',
      hiddenByDefault: false
    }
  };

  // Report Structure
  selfProvisioningMeta: any = {
    name: 'Provisioning',
    structure: {
      firstName: {
        name: 'First name',
        type: 'text',
        master: true
      },
      lastName: {
        name: 'Last name',
        type: 'text',
      },
      email: {
        name: 'Email',
        type: 'email'
      },
      station: {
        name: 'Station',
        type: 'number'
      },
      manages: {
        name: 'Manages',
        type: 'number'
      },
      firstLogin: {
        name: 'APP First Login',
        cellRenderer: 'sendActivationMail'
      }  
    }
  };


  rawSelfProvisioningData: Array<DriversListItem>;
  lang: string;
  isBillingAdmin: boolean = false;
  private _provisioningDataChange$Subscription: Subscription;
  private _deleteUserSubscription: Subscription;
  private _fetchDataTableSubscription: Subscription;
  private _isBillingAdminSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private rptService: ReportsService,
    private router: Router,
    private cService: CommonService,
    private prService: ProvisioningService,
    private dialog: MatDialog
  ){
    this.isBillingAdmin = this.cService.userProfile.userPermissions.isBillingAdmin;
    this.rptService.hasTimeFrameFilter = false;
    this._provisioningDataChange$Subscription = this.prService.provisioningDataChange$.subscribe({
      next: () => this._fetchDataTable()
    });
  }

  ngOnInit(): void {
    this.reportTitle = `:: ${this.translate.instant('MESSAGESANDWARNINGS').working}`; 
    this.selfProvisioningOptions.mobileColumns = this.prService.provisioningOptions.mobileColumns;

    // TODO: Why is this being called twice? Once in the constructor and then here?
    this._fetchDataTable();
  }

  // Event listener
  /**
   * Add element event listener
   * @param $event the data from children compoent
   */
  openUserForm($event: any): void {
    this.dialog.open(this.userIndexComponent, $event);
  }
  uploadUsers($event: any): void {
    this.dialog.open(this.uploadUsersComponent, $event);
  }
  /**
   * Delete element event listener
   * @param $event the data from children component
   * @todo: IMPLEMENT METHOD!
   */
  deleteUser(data: DriversListItem): void {
    console.log(data);
  this._deleteUserSubscription = this.prService.deleteUser(data.id, data.idProvisioning).subscribe({
      next: ({ statusCode, bodyResponse }: ApiResponse): void => {
        if (statusCode == 200) {
          this.prService.triggerBehaviourprovisioningDataChange();
          this.cService.showToast(this.translate.instant('USER').deletedUser, null, 'close');
          this._fetchDataTable();
        } else {
          this.cService.showToast(this.translate.instant('MESSAGESANDWARNINGS').genericError, null, 'close');
        }
      },
      error: (err) => this.cService.showToast(this.translate.instant('MESSAGESANDWARNINGS').genericError, null, 'close')
    });
  }

  _fetchDataTable(): void {
   this._fetchDataTableSubscription = this.prService.getDriversData({'vrmCustom_manuallyHideProgress': 'true'}).subscribe({
      next: (response: DriversListItem[]) => {
        console.log('USERS', JSON.stringify(response));
        this.rawSelfProvisioningData = response;
      },
      error: (error) => {
        this.reportTitle = `:: ${this.translate.instant('SELFPROVISIONING').requestedDriversFailed}`;
        this.cService.showToast(this.translate.instant('SELFPROVISIONING').requestedDriversFailed, null, 'refresh now');
        this.cService.hideIndeterminateProgress = true;
      },
      complete: () => {
        this.reportTitle = this.translate.instant('SELFPROVISIONING').selfProvisioningTitle;
        this.userProvisioningReady = true;
        this.cService.hideIndeterminateProgress = true;
      }
    }); 
  }

  ngOnDestroy() {
    if (this._provisioningDataChange$Subscription) { this._provisioningDataChange$Subscription.unsubscribe(); }
    if (this._deleteUserSubscription) { this._deleteUserSubscription.unsubscribe(); }
    if (this._fetchDataTableSubscription) { this._fetchDataTableSubscription.unsubscribe(); }
    if (this._isBillingAdminSubscription) {this._isBillingAdminSubscription.unsubscribe(); } 
  }
  
}