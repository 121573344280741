import { Component } from '@angular/core';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'ag-cell-num-to-decimal-place',
    template: `
    <span>{{ value }}</span>
    `
})
export class NumberToDecimalPlaceComponent
{
    value: string;

    constructor(private cService: CommonService) { }

    agInit(params: any): void {
        if (params.colDef.colId === 'driveTimeLastSeven'
            || params.colDef.colId === 'totalDriveTime') {
            this.value = this.cService.numberToDecimalPlace(params.value, 1);
        }
        else {
            this.value = this.cService.numberToDecimalPlace(params.value);
        }
    }
}