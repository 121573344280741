import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'settings-button',
    template: `
    <button type="button" mat-icon-button aria-label="settings" matTooltip="Settings"
        (click)="toggleSettingsSidenav()" *ngIf="iconOnly; else menuItemButton">
        <mat-icon>settings</mat-icon>
    </button>

    <ng-template #menuItemButton>
        <button type="button" mat-menu-item (click)="toggleSettingsSidenav()">
            <mat-icon>settings</mat-icon>
            <span>{{ 'OPTIONS.settings' | translate }}</span>
        </button>
    </ng-template>
    `,
    styles: []
})
export class SettingsButtonComponent
{
    @Input() iconOnly: boolean = false;

    @Output() triggerSidenav = new EventEmitter();

    toggleSettingsSidenav() {
        this.triggerSidenav.emit();
    }
}