import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonService } from '../../services/common.service';

@Component({
  selector: 'vrm-summary-card',
  templateUrl: './vrm-summary-card.component.html',
  styleUrls: ['./vrm-summary-card.component.scss']
})
export class VrmSummaryCardComponent {

  constructor(
    public cService: CommonService,
    public translate: TranslateService
    ) { }

  @Input() cardTitle: string;
  @Input() summaryData: [] = [];
  @Input() context: string;
}
