import { Component } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { ColDef } from 'ag-grid-community';

@Component({
    selector: 'ag-plus-sign-prefix',
    template: `
    <span>{{ value }}</span>
    `
})
export class PrefixPlusSignToPositiveNumComponent
{
    value: number | string;

    constructor(private cService: CommonService) { }

    agInit(params: { value: any; colDef: ColDef }): void {
        const hasDecimal = (params.colDef.colId.includes('indexingDiffLast')) ? true : false;
        this.value = this.cService.prefixNumberWithPlusSign(params.value, hasDecimal);
    }
}