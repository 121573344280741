import { Component, Input } from '@angular/core';

import { SummaryItem } from '../../model/dashboards';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'vrm-summary-item',
    template: `
    <mat-card class="margin-b-10 card-appearance" fxLayout="row">
        <vrm-percentage-arc [percentage]="summaryItem.completionPercentage" ieHeightForSvg="40"
            [arcColor]="summaryItem.completionPercentage > 50 ? 'green' : 'red'"></vrm-percentage-arc>
        
        <div class="vrm-dark-grey-text text-align-c" fxFlex [style.font-size]="cService.isPcViewport ? '0.90vw' : '14px'">
            <div class="margin-5">
                <div class="bold">{{ summaryItem.activity }}</div>
                <div><small fxFlex>{{ summaryItem.driversAssigned }} Drivers Assigned</small></div>
            </div>

            <div class="padding-b-5 vrm-off-white-border-top" fxLayout="row" fxLayoutAlign="space-around">
                <div matTooltip="Completed">
                    <!--<mat-icon class="icon-position-t-5">done</mat-icon>-->
                    <small [style.color]="summaryItem.completionPercentage > 50 ? 'green' : 'red'">{{ summaryItem.completed }}</small>
                </div>

                <div matTooltip="Not Completed">
                    <small>{{ summaryItem.notCompleted }}</small>
                </div>
            </div>
        </div>
    </mat-card>
    `,
    styles: [`
    .vertical-label {
        width: 15px; 
        border-bottom-left-radius: 4px; 
        border-top-left-radius: 4px;
        border-right: 2px solid white;
    }
    .card-appearance:hover {
        opacity: 1;
        box-shadow: 1;
    }
    `]
  })
  export class VrmSummaryItemComponent 
  {
    constructor(public cService: CommonService) {}

    @Input() summaryItem: SummaryItem;
  }