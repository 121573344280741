import { Directive, OnInit, Renderer2, RendererStyleFlags2, ElementRef } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';

@Directive({
    selector: '[vrm-accent]'
})
export class VrmAccentDirective implements OnInit
{
    constructor(
        private cService: CommonService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        this.renderer.setStyle(
            this.elementRef.nativeElement, 'color', `${ this.cService.accentColor }`, RendererStyleFlags2.Important
        );
    }
}