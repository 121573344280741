// NOTE: Not in use yet as it isn't clear how to pass the calendar alias or object through

import { Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';

import { Moment } from 'moment';
import * as moment from 'moment-timezone';

import { CalendarHeaderComponent } from '../xs-components/calendar-header.component';
import { DaysOfTheWeekEnum } from 'src/app/services/common.service';

@Component({
    selector: 'multi-highlight-calendar',
    template: `
    <mat-calendar #multiDatesHighlightCalendar fxFlex [(selected)]="selectedDate"
        [headerComponent]="calendarHeader" [dateClass]="dateClass"
        (selectedChange)="selectedCalendarDateChanged($event)"
        (monthSelected)="selectedCalendarMonthChanged($event)"
        [dateFilter]="calendarFilter">
    </mat-calendar>
    `,
    styles: [],
    encapsulation: ViewEncapsulation.None
})
export class MultiDatesHighlightCalendarComponent
{
    selectedDate: Moment;
    @Output() selectedDateValueChange = new EventEmitter<Moment>();
    @Input() set selectedDateValue(value: Moment) {
        this.selectedDate = value;
    }

    @ViewChild('multiDatesHighlightCalendar', { static: false })
    multiDatesHighlightCalendar: MatCalendar<Moment>;
    @Output() calendarPropertyChange = new EventEmitter<any>();
    @Input() set calendarProperty(value: any) {
        this.multiDatesHighlightCalendar = value;
    }

    calendarFilter?: (date: Moment) => void;
    @Output() calendarFilterValueChange = new EventEmitter<(date: Moment) => void>();
    @Input() set calendarFilterValue(value: (date: Moment) => void) {
        this.calendarFilter = value;
    }

    dateClass?: any;
    @Output() dateClassValueChange = new EventEmitter<Moment>();
    @Input() set dateClassValue(value: any) {
        this.dateClass = value;
    }

    @Output() selectedDateChanged = new EventEmitter<Moment>();
    @Output() selectedMonthChanged = new EventEmitter<Moment>();

    calendarHeader = CalendarHeaderComponent;

    constructor() { }

    selectedCalendarDateChanged(event: Moment) {
        this.selectedDateChanged.emit(event);
    }
    selectedCalendarMonthChanged(event: Moment) {
        this.selectedMonthChanged.emit(event);
    }
}
