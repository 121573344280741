import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DrilldownFormsService, DynamicFormsStruct } from '../../services/drilldown-forms.service';
import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';

import { ReviewPeriod } from 'vrm-forms';
import { SidepaneItem } from '../../model/dialogs';

interface DialogData { 
    selectedRow: any; 
    reportEndpointName: string;
    autoExpandCoachForm: boolean;
}

@Component({
    selector: 'driver-index-classic-dialog',
    templateUrl: './driver-index-classic.component.html',
    styleUrls: ['./driver-index-classic.component.scss']
})
export class DriverIndexClassicComponent implements OnInit
{
    showDetailsBufferProgress: boolean = false;
  sidepaneItems: Array<SidepaneItem> = [];
  reportEndpointName: string;

  reviewPeriods: Array<ReviewPeriod> = [];
  outstandingReviews: number;
  outstandingPccReviews: number;
  outstandingOneToOnePlusReviews: number;
  selectedCoachFormData: any;
  selectedOneToOneFormData: any;
  selectedPeriod: any;
  coachFormIsReadonly: boolean = false;
  dialogDataReady: boolean = false;
  renderMultipleCharts: boolean = false;
  coachFormIsVisible: boolean = false;
  oneToOneFormIsVisible: boolean = false;
  formDataUpdated: boolean = false;

  driverIndexSummaryChart: any;
  mentorCharts: Array<any> = [];
  
  // TODO: Remove as just for PCC Demo
  pccData: any;
  pccEvent: any;

  // TODO: Should come from the API . Currently not used
  // reviews: Array<ReviewListItem> = [{
  //     createdDate: new Date('2019-01-11'),
  //     completedBy: 'Tobi Tepede',
  //     completedDate: new Date('2019-01-11'),
  //     status: 'Complete',
  //     passed: true,
  //     selected: false
  //   },
  //   {
  //     createdDate: new Date('2019-02-14'),
  //     completedBy: '',
  //     completedDate: null,
  //     status: 'Required',
  //     passed: false,
  //     selected: false
  //   }
  // ];

  dialogTitle: string = '';
  dialogTitleDescription: string = '';

  oneToOnePlusFormStruct: DynamicFormsStruct;

  constructor(
    private dialogRef: MatDialogRef<DriverIndexClassicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
    private dfService: DrilldownFormsService,
    public cService: CommonService,
    public rptService: ReportsService
  ) { }

  ngOnInit() {
    console.log('DIALOG DATA', this.data);
    this.initialize();
  }

  initialize() {
    this.showDetailsBufferProgress = true;
    this.reportEndpointName = this.data.reportEndpointName;

    this.dialogTitle = this.translate.instant('FORM')['driverPerformanceRecord'];
    this.generateDialogDescription([
      this.data.selectedRow.firstName + ' ' + this.data.selectedRow.lastName, 
      this.data.selectedRow.pin
    ]);

    /**
     * NOTE: The vrm-mentor-reports component already ensured that
     * selectedRow defaults to the first array item in case of multiple edit.
     */
    if (this.rptService.selectedRows.length > 0) {
      // Collate sidepane items
      this.rptService.selectedRows.forEach(selectedData => {
        this.sidepaneItems.push({
          title: `${selectedData.firstName + ' ' + selectedData.lastName}`,
          pin: selectedData.pin,
          description: selectedData.pin,
          data: selectedData
        });
      });

      // Identify any single item put in-view (but not added to selected batch / list).
      // This is supposed to be a graceful way of handling an instance where
      // an item outside collated list, was clicked by mistake.
      const itemNotFoundInBatch: boolean = this.rptService.selectedRows.find(item => item.pin == this.data.selectedRow.pin) ? false : true;
      if (itemNotFoundInBatch) {
        this.sidepaneItems.push({
          title: `${this.data.selectedRow.firstName + ' ' + this.data.selectedRow.lastName}`,
          pin: this.data.selectedRow.pin,
          description: this.data.selectedRow.pin,
          data: this.data.selectedRow,
          inViewButNotInBatch: true
        });
      }
    }

    this.fetchData(this.data.selectedRow.pin);

    this.dialogRef.beforeClosed().subscribe({
      next: () => {
        if (this.formDataUpdated) {
          this.rptService.triggerReportEndpointFilterChangedObservable(this.rptService.endpointFilterValue);
          this.formDataUpdated = false;
        }
      }
    });
  }

  // EVENTS
  coachExpansionPanelToggled(opened: boolean) {
    setTimeout(() => {
      this.coachFormIsVisible = opened;
    });
  }
  oneToOnePlusExpansionPanelToggled(opened: boolean) {
   if (opened) {
      this.getExistingOneToOneFormDataAndStructure();
   }
   else {
      this.oneToOneFormIsVisible = false;
   }
  }

  periodChanged(event: {selectedPeriod: any}) 
  {
    this.selectedCoachFormData = null;

    this.coachFormIsReadonly = true;
    this.selectedPeriod = event.selectedPeriod;
    const currentMonthIdentifier = new Date().getFullYear() + '-' + this.getTransformedMonth();

    this.showDetailsBufferProgress = true;

    this.dfService.getDriverIndexCoachForm(
      this.data.selectedRow.pin, this.selectedPeriod.month.coachId).subscribe({
        next: (formData) => {
            this.showDetailsBufferProgress = false;
            this.selectedCoachFormData = formData.data[0];
    
            if (this.selectedPeriod.month.coachId === currentMonthIdentifier) {
              this.coachFormIsReadonly = false;
            }
        },

        error: error => {
          this.showDetailsBufferProgress = false;
          this.cService.showToast(`:: ${this.translate.instant('FORM').failedToFetchCoachFormData}`);
          console.error(':: ERROR', error);
        }
      });
  }

  submitCoachForm(event: { formData: any }) {
    this.showDetailsBufferProgress = true;
    this.coachFormIsReadonly = true;
    console.log('FILLED Coach Form Data', event);

    this.dfService.saveDriverIndexCoachForm(this.data.selectedRow.pin, this.selectedPeriod.month.coachId,
      event.formData).subscribe({
        next: (resp) => {
          this.showDetailsBufferProgress = false;
          this.formDataUpdated = true;

          this.coachFormIsReadonly = false;
          this.completeCoachingReviewPeriod();
          this.cService.showToast('Saved', 3000);
          console.log('SUCCESS', resp);
        },
        error: () => {
          this.showDetailsBufferProgress = false;
          this.coachFormIsReadonly = false;
          this.cService.showToast(':: Failed to save Form Data');
          console.error(`:: ${this.translate.instant('FORM').failedToSaveFormData}`);
        }
      });
  }

  // METHODS
  getTransformedMonth(): string {
    let month = '';
    const currentMonth = new Date().getMonth() + 1;
    month = (currentMonth < 10) ? month = '0' + currentMonth : currentMonth.toString();

    return month;
  }

  paneItemSelected(event: { pin: any; inViewButNotInBatch: any; data: any }) 
  {
      console.log(':: DIAGNOSTIC DATA', { event: event, dialogData: this.data });
      const selectedRowPin = (this.data.selectedRow) ? this.data.selectedRow.pin : null;

      if (event && event.pin) {
         if ((event.pin !== selectedRowPin)) {
            if (event.inViewButNotInBatch) {
               this.data.selectedRow = event.data;
            }
            else {
               this.data.selectedRow = this.rptService.selectedRows.find(itemData => itemData.pin == event.pin);
            }
   
            if (this.data.selectedRow) {
               this.generateDialogDescription([
                  this.data.selectedRow.firstName + ' ' + this.data.selectedRow.lastName, 
                  this.data.selectedRow.pin
               ]);
            }
   
            this.fetchData(event.pin);
         }
      }
      else {
         console.error('VRM ERROR: pin or event.pin not defined, hence no request made to the API');
      }
  }

  generateDialogDescription(texts: Array<string>) {
    let description: string = '';

    texts.forEach((text, key) => {
      const suffix = (key === texts.length - 1) ? '' : ' . ';
      description += text + suffix;
    });

    this.dialogTitleDescription = description;
  }

  getExistingOneToOneFormDataAndStructure() {
   const formProvisioningId = 'OneToOneFormTest'; // <-- TODO: 
   const formName = 'OneToOnePlus';
   const employeePin = this.data.selectedRow.pin;

   this.dfService.getDynamicFormsStructAndDataForkJoined(formName, employeePin, formProvisioningId)
   .subscribe({
      next: (response: Array<any>) => {
         this.selectedOneToOneFormData = response[0];
         this.oneToOnePlusFormStruct = response[1].form_structure;
         this.oneToOneFormIsVisible = true;
      },

      error: (error) => {
         console.error('OneToOnePlus ERROR:', error);
      },

      complete: () => {
         console.log('%c Dynamic FORK-JOIN Done!', 'color: limegreen');
      }
   });
  }

  fetchData(pin: string) 
  {
    this.dialogDataReady = false;

    this.dfService.getEventSummaryForkJoined(pin).subscribe({
      next: (response) => {
        this.showDetailsBufferProgress = false;

        const activeEventSummaryData = response[0];
        const historicEventSummaryData = response[1];

        this.data.selectedRow.activeEventSummary = activeEventSummaryData;
        this.data.selectedRow.historicEventSummary = historicEventSummaryData;
      },

      error: () => {
        this.showDetailsBufferProgress = false;
      }
    });

    // Coach Form - ForkJoined Summaries
    this.dfService.getCoachSummariesForkJoined(pin).subscribe({
      next: (resp) => {
        const chartSummary = resp[0];
        const coachSummary = resp[1];
        const mentorCharts = resp[2];

        this.showDetailsBufferProgress = false;
        
        console.log('COACH SUMMARY Data Response', coachSummary);

        this.reviewPeriods = coachSummary.data[0].dates;
        this.driverIndexSummaryChart = chartSummary;

        // Translate mentor chart titles
        if (mentorCharts.length > 0) {
          this.translate.get('CHART').subscribe({
            next: (translation) => {
              mentorCharts.forEach((mentorChart: any) => {
                const translationText = translation[mentorChart.options.title.text];
                if (translationText) {
                  mentorChart.options.title.translatedText = translationText;
                }
              });

              this.mentorCharts = mentorCharts;
            }
          });
        }

        console.log('Review periods', this.reviewPeriods);

        this.outstandingReviews = coachSummary.data[0].outstandingReviews;
        console.log('Outstanding reviews', this.outstandingReviews);
        setTimeout(() => {
          this.dialogDataReady = true;
        });

        if (this.mentorCharts.length > 0) {
          setTimeout(() => {
            this.renderMultipleCharts = true;
          },
          2000);
        }

        // console.log('Fork Joined Summary Response', resp);
        console.log('Chart Summary', chartSummary);
        console.log('Mentor charts', mentorCharts);
      }
    });

    // TODO: Remove as just for PCC Demo
    this.pccData = {
      userType: '',
      isCausationAnalysisComplete: false,
      isReviewComplete: false
    };

    this.pccEvent = {
      date: '2020-04-06',
      code: 'Other Party: Lost Control of Vehicle',
      description: 'VEHICLE # 1, VEHICLE # 2, AND VEHICLE # 3 WERE TRAVELING NORTH BOUND ON INTERSTATE 270 IN COLUMBUS, OH. VEHICLE # 2 WAS TRAVELING BEHIND VEHICLE # 3. VEHICLE # 3 WAS BEHIND VEHICLE # 1. BOTH VEHICLE # 3 AND VEHICLE # 1 WERE STOPPING FOR TRAFFIC.',
      value: '2.97'
   }

    this.outstandingPccReviews = 1;

    this.outstandingOneToOnePlusReviews = 1;

  }

  // TODO: These two endpoint calls are yet to work correctly!
  saveOneToOneForm(event: { formData: any }) {
   console.log('%c OneToOnePlus Form Data', 'color: lightblue', event.formData);

   if (event && event.formData) {
      const formName = this.oneToOnePlusFormStruct.metadata.name;
      const employeePin = this.data.selectedRow.pin;

      this.dfService.saveDynamicForm(formName, employeePin, event).subscribe({
         next: () => {
            this.cService.showToast(`OneToOnePlus: 
               ${this.cService.readyTranslations.form.formSaved}`);
         },

         error: () => {
            this.cService.showToast(`OneToOnePlus: 
               ${this.cService.readyTranslations.form.failedToSaveFormData}`);
         }
      });
   }
  }

  // TODO: Update after PCC Demo
  savePcc(event: { formData: any }) {
    // this.showDetailsBufferProgress = true;
    // this.coachFormIsReadonly = true;
    console.log('PCC DATA', event);

    // TODO: Remove after demo - hard-coded submission name will need to be set on the front-end
    const submissionName: string = 'DemoHardCoded';

    this.dfService.savePccReview(this.data.selectedRow.pin, submissionName, {data: event.formData}).subscribe({
        next: (resp) => {
          this.showDetailsBufferProgress = false;
          // this.coachFormIsReadonly = false;
          this.cService.showToast('Saved', 3000);
          console.log('SUCCESS', resp);
        },
        error: () => {
          this.showDetailsBufferProgress = false;
          // this.coachFormIsReadonly = false;
          this.cService.showToast(':: Failed to save Form Data');
          console.error(`:: ${this.translate.instant('FORM').failedToSaveFormData}`);
        }
      });
  }

  // Sets the current coaching period to complete upon save
  completeCoachingReviewPeriod() {

    for (const reviewPeriod of this.reviewPeriods) {
      
      // First find the selected reviewPeriod by year 
      if (reviewPeriod.year == this.selectedPeriod.year) {

        // Now find the coaching period by using the selected coachId
        const selectedReviewPeriod = reviewPeriod.months.find(period => period.coachId == this.selectedPeriod.month.coachId);
        if (selectedReviewPeriod) {

          // If the current status is required then this review can be removed from the outstandingReviews count
          if ((selectedReviewPeriod.status == 'required') && (this.outstandingReviews > 0)) {
            this.outstandingReviews--;
          }
          
          // When found then set the status of this review period to complete
          selectedReviewPeriod.status = 'complete';
          break;
        }
      }
    }

  }
}