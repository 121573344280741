// TODO: This is a service. It shouldn't have been placed in a model folder

// Angular
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Subsctiption
import { Subscription } from 'rxjs';

export class UserForm {

    public userForm: FormGroup;   
    private _setIsDriverOnChangeValueSubscription: Subscription;
    private _setIsManagerOnChangeValueSubscription: Subscription;

    constructor() {
        this.userForm = new FormGroup({
            firstName: new FormControl(null, [Validators.required]),
            middleName: new FormControl(),
            lastName: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            phoneNumber: new FormControl(),
            isDriver: new FormControl({value: true, disabled: false}),
            isManager: new FormControl(),
            driverGroupId: new FormControl({ value: null, disabled: false }),
            managerGroupIds: new FormControl({ value: null, disabled: true }),
        });
        this._setIsDriverOnChangeValue();
        this._setIsManagerOnChangeValue();

    }

    get getUserForm(): FormGroup {
        return this.userForm;
    }

    /**
     * Inizialize Form with data
     * @param initialValues: Object
     */
    inizializeForm(initialValues: object): void {
        if (typeof initialValues == 'object') {
            Object.keys(this.userForm.controls).forEach(key => {
                if (initialValues.hasOwnProperty(key)) {
                    this.userForm.controls[key].setValue(initialValues[key]);
                }
            });
            this.userForm.markAllAsTouched();
        }
    }

    /**
     * Set driversGroupsId Validators at isDrivers values change
     */
    _setIsDriverOnChangeValue() {
     this._setIsDriverOnChangeValueSubscription = this.userForm.get('isDriver').valueChanges.subscribe({
            next: (value) => {
                const driverGroupId = this.userForm.get('driverGroupId');
                if (value) {
                    driverGroupId.enable();
                }
                if (!value) {
                    driverGroupId.reset();
                    driverGroupId.disable();
                    driverGroupId.setValidators(null);
                }
            }
        });
    }

    /**
     * Set managerGroupsIds Validators at isManager values change
     */
    _setIsManagerOnChangeValue() {
      this._setIsManagerOnChangeValueSubscription = this.userForm.get('isManager').valueChanges.subscribe({
            next: (value) => {
                const managerGroupIds = this.userForm.get('managerGroupIds');
                if (value) {
                    managerGroupIds.enable();
                }
                if (!value) {
                    managerGroupIds.reset();
                    managerGroupIds.disable();
                    managerGroupIds.setValidators(null);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this._setIsDriverOnChangeValueSubscription) { this._setIsDriverOnChangeValueSubscription.unsubscribe(); }

        if (this._setIsManagerOnChangeValueSubscription) { this._setIsManagerOnChangeValueSubscription.unsubscribe(); }
      }
}

export interface Combo {
    value: string;
    label: string;
}