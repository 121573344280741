import { Component, Input, ViewChild } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';

import { ReportOption, OtherMiniReportOptions } from '../../model/reports';
import { AgGridAngular } from 'ag-grid-angular';
import { NumberToPercentageComponent } from '../ag-grid-cell-renderers/number-to-percentage.component';
import { NumberToDecimalPlaceComponent } from '../ag-grid-cell-renderers/number-to-decimal-place.component';
import { ColDef } from 'ag-grid-community';

@Component({
    selector: 'vrm-mini-report',
    templateUrl: './vrm-mini-reports.component.html',
    styleUrls: ['./vrm-mini-reports.component.scss']
})
export class VrmMiniReportComponent
{
    @Input() metadata: any;
    @Input() rawData: any;
    @Input() reportTitle: string;
    @Input() reportOptions: ReportOption;
    @Input() otherOptions?: OtherMiniReportOptions;

    agMobileColumns: ColDef[] = [];

    columnsToDisplay: ColDef[];
    agGridPcColumns: ColDef[];
  
    initialLoad: boolean = true;
    initialView: string;
  
    frameworkComponents: any;
    sideBar: any;
    statusBar: any;
    gridOptions: any;
    isRtlLanguage: boolean;
    private icons: any;
  
    @ViewChild('agGridMiniReport', { static: true }) agGrid: AgGridAngular;

    constructor(
        private cService: CommonService,
        private rptService: ReportsService
    ) {
        // TODO: Looks like isRtl needs to be watched. Use RxJs!
        this.isRtlLanguage = cService.selectedLanguage.isRtl;
        setTimeout(() => { 
            if (this.otherOptions.hasHeader) {
                this.cService.setPageHeader(this.reportTitle, true); 
            }
        });

        this.initializeAgGridProperties();
        this.initialView = (cService.isPcViewport) ? 'pc' : 'mobile';
    }

    /* AG-GRID LIFECYCLE HOOKS
    ------------------------ */
    initializeAgGridProperties() {
        // Used to specify Ag-Grid specific sub components
        this.frameworkComponents = {
            numberToPercentageComponent: NumberToPercentageComponent,
            numberToDecimalPlaceComponent: NumberToDecimalPlaceComponent,
            // customDetailsToolPaneComponent: CustomDetailsToolPaneComponent
        };

        this.icons = { 
            'details': '<span class="ag-icon ag-icon-details"></span>'
        };

        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel'
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                }
            ],

            defaultToolPanel: 'columns',
            hiddenByDefault: true
        };

        this.statusBar = {
            statusPanels: this.rptService.agGridConfig.statusPanels
        };

        // Should come last mostly
        this.gridOptions = {
            defaultColDef: this.rptService.agGridConfig.defaultColumnConfig,
            rowGroupPanelShow: 'always',
            rowSelection: 'multiple',
            suppressRowClickSelection: false,
            statusBar: this.statusBar,
            excelStyles: this.rptService.agGridConfig.excelStyles,
            icons: this.icons,
            rowClassRules: this.rptService.agGridConfig.rowClassRules,
            ...this.rptService.agGridConfig.common
        };
    }

    agGridReady(event: AgGridAngular) {
        this.displayColumnsByViewportSize();

        setTimeout(() => {
        this.initialLoad = false;
        }, 500);
    }

    /* METHODS
    ----------- */
    private displayColumnsByViewportSize() 
    {
        if (this.agGrid.columnApi.isPivotMode) {
            this.agGrid.columnApi.setPivotMode(false);
        }

        if (this.metadata.structure) {
            this.rptService.dataStructure = this.metadata.structure;
            this.agGridPcColumns = this.rptService.convertToAgColumnDefinition(this.metadata.structure);
        }
        else {
            // Assume we have groupStructure instead
            this.agGridPcColumns = this.rptService.convertToAgGroupedColumnDefinition(this.metadata.groupStructure);
        }
  
        this.columnsToDisplay = this.agGridPcColumns;
        this.commonGridConfig();
    
        if (this.cService.isMobileViewport) {
            this.agGrid.api.closeToolPanel();
        }
    }

    private commonGridConfig() {    
        // Run Async - Ensure grid is attached to the DOM
        setTimeout(() => {
            if (this.columnsToDisplay.length < 10 && !this.cService.isMobileViewport && !this.metadata.groupStructure) {
                this.agGrid.api.sizeColumnsToFit();
            }
            else {
                this.agGrid.columnApi.autoSizeAllColumns();
            }
        });
    }
}