import { Component, Input } from '@angular/core';

import { CommonService } from '../../services/common.service';

@Component({
  selector: 'security-footer',
  templateUrl: './security-footer.component.html',
  styles: []
})
export class SecurityFooterComponent 
{
  @Input() demarcate: boolean = true;
  constructor(public cService: CommonService) { }
}
