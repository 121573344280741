import { Component, OnInit, Input } from '@angular/core';

import { Score } from '../../model/dashboards';

@Component({
    selector: 'good-average-poor-card',
    templateUrl: './good-average-poor-card.component.html',
    styleUrls: ['./good-average-poor-card.component.scss']
})
export class GoodAveragePoorCardComponent 
{
    @Input() scores: Array<Score> = [];
    @Input() cardTitle: string;
    @Input() titleIconName?: string = '';
}