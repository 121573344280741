import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FilterType, FilterColumn } from '../../model/filter';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'vrm-multi-select',
  templateUrl: './vrm-multi-select.component.html',
  styleUrls: ['./vrm-multi-select.component.scss']
})
export class VrmMultiSelectComponent implements OnInit, AfterViewInit
{
  @Input() columnFilter: FilterColumn;
  @Input() placeholderText = '';
  @Input() canSetDefault?: boolean;
  @Input() persistedFilters: Array<FilterColumn> = [];
  @Input() persistedColumn: FilterColumn;

  @Output() menuClosed = new EventEmitter();

  filterText: string;
  filterItems: Array<FilterType> = [];
  cachedFilterItems: Array<FilterType> = [];
  selectedItems?: Array<FilterType> = [];

  columnViewName: string = 'columnsSelected';
  columnsVisibleOnLoad: Array<FilterType>;
  displayedColumnsChanged: boolean = false;
  allIsSelected?: boolean;

  selectAllLimit: number = 30;
  maxFilterListItemsOnLoad: number = 100;
  indeterminate: boolean = false;
  multiselectOpened: boolean;

  public multiselectInput: FormControl = new FormControl();
  public filterTextInput: FormControl = new FormControl();

  constructor(public cService: CommonService) {}

  ngOnInit() {
    if (this.columnFilter.filters.length <= this.maxFilterListItemsOnLoad) {
      this.filterItems = this.columnFilter.filters;
    }
    else {
      this.cachedFilterItems = this.columnFilter.filters;
    }
    this.placeholderText = (this.columnFilter.icon) ? '' : this.columnFilter.text;

    if (this.columnFilter.colName.toLowerCase() == this.columnViewName.toLowerCase()) {
      this.columnsVisibleOnLoad = this.columnFilter.filters;
    }

    this.watchValueChanges();
    this.persistSelections();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.columnFilter.colName.toLowerCase() == this.columnViewName.toLowerCase()) { 
        if (!this.persistedColumn) {
          this.selectAll();
        }
      }
    });
  }

  watchValueChanges() {
    this.filterTextInput.valueChanges.subscribe({
      next: (filterText) => {
        this.filterItems = this.columnFilter.filters.filter(filter => filter.text.toLowerCase().includes(filterText.toLowerCase()));
        this.filterText = filterText;
      }
    });

    this.multiselectInput.valueChanges.subscribe({
      next: (selectedItems) => {
        this.selectedItems = selectedItems;
  
        // Determine indeterminate selection
        if (selectedItems.length > 0 && selectedItems.length < this.filterItems.length) {
          this.indeterminate = true;
          this.allIsSelected = false;
        }
        else {
          this.indeterminate = false;
        }
      }
    });
  }

  loadCachedItems() {
    if (this.filterItems.length <= 0 && this.cachedFilterItems.length > 0) {
      this.filterItems = this.cachedFilterItems;
      this.cachedFilterItems = [];
    }
  }
  multiSelectOpenChange(multiselectOpened: boolean): void 
  {
    this.multiselectOpened = multiselectOpened;

    if (!multiselectOpened) {
      this.menuClosed.emit({
        colName: this.columnFilter.colName,
        text: this.columnFilter.text,
        filters: this.selectedItems
      });
    }

    this.columnChangedIndicator(this.selectedItems);
  }
  selectionChanged() {
    this.columnChangedIndicator(this.selectedItems);
  }

  persistSelections() {
    // Persist existing selections
    if (this.persistedFilters.length > 0) 
    {
        this.persistedFilters.forEach((persistedFilter) => {
          if (persistedFilter.colName === this.columnFilter.colName) {
            this.selectedItems = persistedFilter.filters;
            this.setCurrentSelections();
          }
        });
    }
    else {
      this.deselectAll();
    }

    // Persist existing column selections
    if (this.persistedColumn) {
      this.selectedItems = this.persistedColumn.filters;
      this.setCurrentSelections();
    }
  }

  columnChangedIndicator(currentlyVisibleColumns: Array<any>) 
  {
    if (this.columnFilter.colName.toLowerCase() == this.columnViewName.toLowerCase()) {
      const isTheSame = Object.is(this.columnsVisibleOnLoad, currentlyVisibleColumns);

      if (isTheSame) {
        this.displayedColumnsChanged = false;
      }
      else {
        this.displayedColumnsChanged = true;
      }

      if (this.columnFilter.filters.length == this.selectedItems.length) {
        this.selectAll();
      }
    }
  }

  setCurrentSelections() {
    this.multiselectInput.setValue(this.selectedItems);
  }
  // NOTE: When persisting data on-load, this compare function is needed for material to map to the newly set values above
  compareFn(filterItem: FilterType, filterItemToSet: FilterType): boolean {
    return filterItem && filterItemToSet ? filterItem.value === filterItemToSet.value : filterItem === filterItemToSet;
  }

  selectAll() {
    if (this.filterItems.length <= this.selectAllLimit) {
      this.multiselectInput.setValue(this.filterItems);
      this.selectedItems = this.filterItems;

      this.allIsSelected = true;
      this.indeterminate = false;
    }
  }

  deselectAll() {
    this.allIsSelected = false;
    this.indeterminate = false;

    this.multiselectInput.setValue([]);
    this.selectedItems = [];
  }

  toggleCheckAll(event: Event): void {
    event.preventDefault();
    if (this.allIsSelected) { this.deselectAll(); } else { this.selectAll(); }
  }

  trackByFn(filterItem: FilterType) {
    return filterItem.value;
  }
}
