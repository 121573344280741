import { Component, ViewChild, Type, ComponentFactoryResolver } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

import { Moment } from 'moment';
import * as moment from 'moment-timezone';

import { IToolPanel } from 'ag-grid-community';

import { RenderComponentDirective } from '../../../directives/render-component.directive';
import { MultiDatesHighlightCalendarComponent } from '../../../components/calendar/multi-dates-highlight-calendar.component';

import { CalendarHeaderComponent } from '../../xs-components/calendar-header.component';
import { ReportsService, TransformedSingleData, PeriodFrequency, TimeFrameEnums, TimeFrame, TimeFrameValueEnums } from '../../../services/reports.service';
import { CommonService, DaysOfTheWeekEnum } from 'src/app/services/common.service';

// Model
import { CustomToolPanelParams, GenericComponent } from '../../../model/reports';


@Component({
    selector: 'custom-details-tool-pane',
    templateUrl: './custom-details-tool-pane.component.html',
    styleUrls: ['./custom-details-tool-pane.component.scss']
})
export class CustomDetailsToolPaneComponent implements IToolPanel {

    selectedRowItems: Array<TransformedSingleData> = [];
    moreDetailsComponent: Type<any>;
    selectedDate: Date | Moment = moment().startOf('day');
    calendarHeader = CalendarHeaderComponent;
    selectedTimeSpan: Date | Moment | number | null = null;
    calendarFilter: (date: Moment) => void;
    dateHighlighterClass: (dateToHighlight: Date) => any;

    // NOTE: Please do not separate the ViewChild decorator from its timeFrameCalendar variable!
    @ViewChild('timeFrameCalendar', { static: false })
    timeFrameCalendar: MatCalendar<Moment>;
    // @ViewChild(MultiDatesHighlightCalendarComponent, { static: false })
    // timeFrameCalendar: MatCalendar<Moment>;
    
    @ViewChild(RenderComponentDirective, { static: false }) renderDirective: RenderComponentDirective;

    timeFramePanelExpanded: boolean = false;

    private currentTimeZone: string;
    private datesToHighlight: Array<Date> = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public rptService: ReportsService,
        public cService: CommonService,
        private dateAdapter: DateAdapter<any>
    ) { 
        this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('%c Current TimeZone', 'color: yellow', this.currentTimeZone);
        // dateAdapter.setLocale('fr');
    }
 
    agInit(params: CustomToolPanelParams): void {
        
        params.api.addEventListener('rowClicked', this.refresh.bind(this));
        params.api.addEventListener('selectionChanged', this.selectionChanged.bind(this));
        params.api.addEventListener('rowDataChanged', this.gridDataChanged.bind(this));
        if (params.moreDetails) { this.moreDetailsComponent = params.moreDetails; } 
    }

    refresh() {
        setTimeout(() => {
            if (this.rptService.selectedRows.length === 1) {
                
                // TODO: Need to understand why this depends on a component just to hide / disable
                if (this.moreDetailsComponent) {
                    this._loadMoreDetails(this.rptService.selectedRows[0].moreDetails);
                }

                const selectedData = this.rptService.selectedRows[0];
                const selectedItemKeys = Object.keys(selectedData);

                this.selectedRowItems = [];

                selectedItemKeys.forEach((key) => {
                    const columnName = key;

                    if (this.rptService.dataStructure[columnName]) {
                        const value = selectedData[columnName];

                        if (value || !isNaN(value)) {
                            const transformedData: TransformedSingleData
                            = this.rptService.transformSingleDataForDetailsPane(
                                columnName, value);

                            this.selectedRowItems.push(transformedData);
                        }
                    }
                });             
            }
        });
    }

    // EVENTS
    selectionChanged() {
        if (this.renderDirective) {
            this.renderDirective.viewContainer.clear();
        }

        if (this.selectedRowItems.length !== 1) {
            this.selectedRowItems = [];
        }
    }

    gridDataChanged() {
        this.resetSelectedRowItem();
        console.log('Time Frame on Load', this.rptService.selectedTimeFrame.timeFrame);

        if (!this.rptService.selectedTimeFrame.timeFrame) {
            this.clearTimeSpanFilter(true);
        }
        else {
            this.persistDefaultTimeFrame();
        }
    }

    selectedCalendarDateChanged(selectedDate: Date, preventRefresh?: boolean) {
        this.selectedTimeSpan = moment.utc(selectedDate);
        console.log('Selected date', this.selectedTimeSpan);

        if (!preventRefresh) {
            this.collateTimeFrameSelectionAndTriggerReportRefresh();
        }
    }
    selectedCalendarMonthChanged(selectedDate: Date, preventRefresh?: boolean) {
        // NOTE: This offset to 7th day on month selection ensures that week is able to find a default
        // first week in the currently selected month without defaulting to last week in previous month
        const selectedDayOffset = new Date(moment(selectedDate).year(), moment(selectedDate).month(), 7);

        this.selectedDate = 
        (this.currentTimeZone.toLowerCase().includes('europe')) ? 
        moment(selectedDayOffset) 
        : moment.utc(selectedDayOffset);

        this.selectedTimeSpan = this.selectedDate;
        console.log('Selected month:', this.selectedDate);

        if (!preventRefresh) {
            this.collateTimeFrameSelectionAndTriggerReportRefresh();
        }

        // Retain current Time Frame in use
        this.timeFrameFrequencyChanged(this.rptService.selectedTimeFrame.frequency as PeriodFrequency, true);
    }
    clearTimeSpanFilter(preventRefresh?: boolean) {
        this.selectedTimeSpan = null;
        this.selectedDate = moment().startOf('day');
        this.rptService.selectedTimeFrame.timeFrame = null;

        if (!preventRefresh) {
            this.collateTimeFrameSelectionAndTriggerReportRefresh();
        }
    }
    timeFrameFrequencyChanged(frequency: PeriodFrequency, preventRefresh?: boolean) {
        this.rptService.selectedTimeFrame.frequency = frequency;
        const selectedDate = new Date(this.rptService.selectedTimeFrame.timeFrame.date);

        setTimeout(() => {
            if (frequency.value === TimeFrameValueEnums.month) {
                this.selectedDate = moment.utc(selectedDate);
                this.timeFrameCalendar.currentView = 'year';

                if (!preventRefresh) {
                    this.collateTimeFrameSelectionAndTriggerReportRefresh();
                }
            }
            else if (frequency.value === TimeFrameValueEnums.week) {
                this.timeFrameCalendar.currentView = 'month';

                // Allow Sunday dates selection only!
                this.calendarFilter = (date: Moment) => {
                    const day = date.day();
                    return day == DaysOfTheWeekEnum.sun;
                };

                // Default to Sunday when in weekly view
                const selectedDayOfWeek: number = new Date(selectedDate).getUTCDay();
                selectedDate.setDate(selectedDate.getDate() - selectedDayOfWeek);
                this.selectedDate = moment.utc(selectedDate);
                // this.datesToHighlight = this.generateDatesToHighlight(this.selectedDate, 7);

                this.selectedCalendarDateChanged(selectedDate);
            }
            else {
                // Assume daily 
                this.timeFrameCalendar.currentView = 'month';
                this.calendarFilter = null;
                this.selectedCalendarDateChanged(selectedDate);
            }
        });
    }
    collateTimeFrameSelectionAndTriggerReportRefresh() {
        const dateString: string = moment(this.selectedDate).format('YYYY-MM-DD');

        this.rptService.selectedTimeFrame.timeFrame = {
            period: this.rptService.selectedTimeFrame.frequency.value,
            date: dateString
        };

        console.log('Selected Time Frame', this.rptService.selectedTimeFrame.timeFrame);
        const isCommonReport = (this.cService.navigatedReport.commonReportPath) ? true : false;
        this.cService.triggerDateFilterChangedObservable(isCommonReport);
    }
    persistDefaultTimeFrame() {
        if (this.rptService.hasTimeFrameFilter) {
            if (this.rptService.selectedTimeFrame.timeFrame) {
                const selectedDate = new Date(this.rptService.selectedTimeFrame.timeFrame.date);

                if (this.rptService.selectedTimeFrame.frequency.value === TimeFrameValueEnums.month) {
                    this.selectedCalendarMonthChanged(selectedDate, true);
                    this.timeFramePanelExpanded = true;
                }
                else {
                    this.selectedCalendarDateChanged(selectedDate, true);
                    this.timeFramePanelExpanded = true;
                }
            }
            else {
                this.timeFramePanelExpanded = false;
                this.timeFrameFrequencyChanged(this.rptService.selectedTimeFrame.frequency as PeriodFrequency);
            }
        }
    }

    // METHODS
    resetSelectedRowItem() {
        if (this.selectedRowItems.length !== 1) {
            this.selectedRowItems = [];
        }
    }

    private generateDatesToHighlight(dateFrom: Date | any, dateSpan: number): Array<Date> {
        const datesToHighlight: Array<Date> = [];

        for (let i = 0; i < dateSpan; i++) {
            const dateHolder = dateFrom;
            dateHolder.setDate(dateFrom.getDate() + i);

            datesToHighlight.push(dateHolder);
        }

        return [new Date('2020/01/20'), new Date('11/01/2020'), new Date('12/01/2020')]; // datesToHighlight;
    }

    /**
     * If a moreDetailsComponent is set, this method add a more Detailed tool panel
     * @param moreDetails: Column that are not in ag grid structure 
     */
    // TODO: Need to understand the purpose of this change 
    _loadMoreDetails(moreDetails: any) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.moreDetailsComponent);
        this.renderDirective.viewContainer.clear();
        const componentRef = this.renderDirective.viewContainer.createComponent(componentFactory);
        (<GenericComponent>componentRef.instance).moreDetails = moreDetails;
    }

}
