import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonService } from '../../services/common.service';
import { CompanyPasswordPolicy } from 'src/app/services/auth.service';

@Component({
    selector: 'password-policy-info',
    template: `
    <div class="password-policy-info" *ngIf="passwordPolicy">
        <small>
            <p><strong>{{ 'SECURITY.passwordRequirements' | translate }}</strong></p>

            <p *ngIf="lengthPolicies.length > 0">
                <span *ngFor="let policy of lengthPolicies">{{ policy }}<br></span>
            </p>

            <div *ngIf="mustContainPolicies.length > 0">
                {{ 'SECURITY.mustContainA' | translate }}
                <ul>
                    <li *ngFor="let mustPolicy of mustContainPolicies">
                        <span *ngIf="mustPolicy.toLowerCase() !== 'special character'; else specialCharsPolicy">
                            {{ mustPolicy }}
                        </span>

                        <ng-template #specialCharsPolicy>
                            {{ mustPolicy }}: <br><span>{{ acceptableSpecialChars }}</span>
                        </ng-template>
                    </li>
                </ul>
            </div>

            <p *ngIf="otherPolicies.length > 0">
                <span *ngFor="let policy of otherPolicies">{{ policy }}</span>
            </p>
        </small>
    </div>
    `,
    styles: []
})
export class PasswordPolicyComponent implements OnInit
{
    @Input() passwordPolicy: CompanyPasswordPolicy;

    acceptableSpecialChars: string;
    lengthPolicies: Array<string> = [];
    mustContainPolicies: Array<string> = [];
    otherPolicies: Array<string> = [];

    constructor(
        public cService: CommonService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        if (this.passwordPolicy) {
            if (this.passwordPolicy.passwordAllowedSpecialChars) {
                const replacementChar = new RegExp('', 'g');
                this.acceptableSpecialChars = this.passwordPolicy.passwordAllowedSpecialChars.replace(replacementChar, ' ');
            }

            const policyKeys = Object.keys(this.passwordPolicy);

            // TODO: Get all possible policy properties and include in the following logic
            this.translate.get('SECURITY').subscribe(translation => 
            {
                policyKeys.forEach((policyKey: string) => {
                    if (policyKey.toLowerCase().includes('length')) {
                        if (policyKey.toLowerCase().includes('minlength')) {
                            this.lengthPolicies.push(translation.minimumLength + ': ' + this.passwordPolicy[policyKey]);
                        }

                        if (policyKey.toLowerCase().includes('maxlength')) {
                            this.lengthPolicies.push(translation.maximumLength + ': ' + this.passwordPolicy[policyKey]);
                        }
                    }
                    else if (policyKey.toLowerCase().includes('require')) {
                        if (policyKey.toLowerCase().includes('numeric')) {
                            this.mustContainPolicies.push(translation.number);
                        }

                        if (policyKey.toLowerCase().includes('lowercase')) {
                            this.mustContainPolicies.push(translation.lowercaseCharacter);
                        }

                        if (policyKey.toLowerCase().includes('uppercase')) {
                            this.mustContainPolicies.push(translation.uppercaseCharacter);
                        }

                        if (policyKey.toLowerCase().includes('specialchars')) {
                            this.mustContainPolicies.push(translation.specialCharacter);
                        }
                    }
                    else {
                        if (policyKey.toLowerCase().includes('username')) {
                            this.otherPolicies.push(translation.mustNotContainUsername);
                        }

                        if (policyKey.toLowerCase().includes('notuselastnumpasswords')) {
                            let lastPwReuseLimit = this.passwordPolicy[policyKey];

                            if (lastPwReuseLimit) {
                                lastPwReuseLimit = Number(lastPwReuseLimit);

                                if (lastPwReuseLimit && lastPwReuseLimit > 0) {
                                    if (lastPwReuseLimit === 1) {
                                        this.otherPolicies.push(translation.mustNotBeLastPassword);
                                    }
                                    else {
                                        this.otherPolicies.push(`
                                        ${translation.mustNotBeOneOfYourLast} 
                                        ${lastPwReuseLimit} ${translation.passwords}`);
                                    }
                                }
                            }
                        }

                        if (policyKey.toLowerCase().includes('minhoursbetweenchange')) {
                            let minHoursBeforeChange = this.passwordPolicy[policyKey];

                            if (minHoursBeforeChange) {
                                minHoursBeforeChange = Number(minHoursBeforeChange);

                                if (minHoursBeforeChange && minHoursBeforeChange > 0) {
                                    if (minHoursBeforeChange === 1) {
                                        this.otherPolicies.push(translation.cannotBeChangedUntilOneHourLater);
                                    }
                                    else {
                                        this.otherPolicies.push(`
                                        ${translation.cannotBeResetUntil} ${minHoursBeforeChange} 
                                        ${translation.hoursAfterLastChange.toLowerCase()}`);
                                    }
                                }
                            }
                        }
                    }
                });
            });
            
        }
    }
}