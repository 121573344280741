import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'vrm-percentage-arc',
    templateUrl: './vrm-percentage-arc.component.html',
    styleUrls: ['./vrm-percentage-arc.component.scss']
})
export class VrmPercentageArcComponent implements OnInit
{
    @Input() percentage: number;
    @Input() arcSizePx?: string = '60px';
    @Input() useFatCircle?: boolean = false;
    @Input() textColor?: string = '#666666';
    @Input() arcColor?: string = 'black';
    @Input() circleFillColor?: string = '#eee';
    @Input() circleBgWidth?: number = 3.8;
    @Input() arcWidth?: number = 2.8;
    @Input() ieHeightForSvg?: string;

    ngOnInit() {
        // NOTE: This ensures no arc is visible when percentage is zero
        if (this.percentage < 1) {
            this.arcColor = this.circleFillColor;
        }

        this.ieHeightForSvg = (this.ieHeightForSvg) ? 'ie-svg-height-' + this.ieHeightForSvg : '';
    }
}