// Angular
import { Component, OnDestroy } from '@angular/core';
// Services
import { ProvisioningService } from '../../services/provisioning.service';
import { CommonService } from 'src/app/services/common.service';
// Subscription
import { Subscription } from 'rxjs';
// Madel
import { ApiResponse } from '../../model/provisioning';
// Third Part
import { AgRendererComponent } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mentor-activation-lnk',
    template: `
        {{ firstLogin }}
        <button *ngIf="!firstLogin" (click)="sendActivationMail($event)" >{{ 'SELFPROVISIONING.activationLnk' | translate }}</button>
    `,
    styles: [
        `button {
            border: 0px;
            padding: 4px;
            border-radius: 3px;
            cursor: pointer;
        }`
    ]
})
export class SendActivationMailComponent implements OnDestroy, AgRendererComponent  {

    private userId: number;
    firstLogin: string = null;
    private _sendActivationSubscription: Subscription;

    constructor(
        private spService: ProvisioningService,
        public cService: CommonService,
        private translate: TranslateService 
    ) { }

    agInit(params: any): void {
        this.userId = params.data.id;
        this.firstLogin = params.data.firstLogin;
    }

    sendActivationMail($event) {
        console.log(this.userId);
      this._sendActivationSubscription =  this.spService.sendActivationMail(this.userId).subscribe({
            next: (response: ApiResponse) => {
                if (response.statusCode == 200) { this.cService.showToast(this.translate.instant('MESSAGESANDWARNINGS').activationMailSuccess, null, 'close'); }
                // @todo error message
            },
            error: (err) => {

            }
        });
    }

    ngOnDestroy() {
        if (this._sendActivationSubscription) { this._sendActivationSubscription.unsubscribe(); }
      }

    refresh(params: any): boolean {
        console.log('REFRESH -', params);
        this.userId = params.data.id;
        return true;
    }

}