import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';

import { ChartType } from 'ag-grid-community';

// Local imports
import { SummaryItem } from '../../model/dashboards';
import { ReportOption, OtherMiniReportOptions } from '../../model/reports';

import { Charts1 } from '../../data/charts';
import { Charts2 } from '../../data/charts';
import { ActivitySummary } from '../../data/charts';
import { FloatStyle } from 'src/app/model/filter';

enum TabEnums {
  overview = 0,
  driverIndex = 1,
  mentor = 2,
  roadRisk = 3
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DashboardComponent implements OnInit
{
  charts1 = Charts1;
  charts2 = Charts2;
  activitySummary: Array<SummaryItem> = ActivitySummary;

  rowHeight: string = '400';
  gutterSize: number = 20;

  pagedActivitySummaries: Array<SummaryItem> = [];
  pagedActivityCanGoNext: boolean = false;
  pagedActivityCanGoPrevious: boolean = false;
  requestedActivityPage: number = 1;
  activityItemsPerPage: number = 4;

  buttonStyles: FloatStyle = {
    position: 'absolute',
    top: '36px',
    topPosOnOpen: '40px',
    right: '8px',
    left: '8px',
    zIndex: '10'
  };

  maxTabTextLength: number = 12;

  @ViewChild(MatTabGroup, { static: true }) dashItemsTabGroup: MatTabGroup;

  // Activity Summary
  activitySummaryRptOptions: ReportOption;
  activitySummaryReportMeta: any;
  activitySummaryRawReportsData: Array<any>;

  // Population chart
  populationRptOptions: ReportOption;
  populationRawReportsData: Array<any>;
  populationReportMeta: any;

  // Activity summary overview chart
  activitySumChartRptOptions: ReportOption;
  activitySumChartRawReportsData: Array<any>;
  activitySumChartReportMeta: any;

  // RoadRISK Summary Chart
  roadRiskSummaryChartRptOptions: ReportOption;
  roadRiskSummaryChartRawReportsData: Array<any>;
  roadRiskSummaryChartReportMeta: any;

  otherOptions: OtherMiniReportOptions = {
    hasHeader: false,
    hideBookmark: true,
    reportHeight: '330px',
    sizeColumnsToFit: true
  };

  constructor(
    public cService: CommonService, 
    private rptService: ReportsService, 
    private route: ActivatedRoute,
    private translate: TranslateService
    ) { 
      this.activitySummaryRptOptions = {
        reportEndpointName: rptService.reportEndpoint.dashboardActivitySummary,
        mobileColumns: ['courseName']
      };

      setTimeout(() => {
        const companyName = (cService.userProfile && cService.userProfile.company) ? cService.userProfile.company : '';
        const pageTitle = 'STATUS REPORT - VRM ' + companyName.toUpperCase(); // <-- TODO: Translate
        this.cService.setPageHeader(pageTitle, false, false, true);
      });
      

      // Prepare meta data
      this.translate.get('REPORTCOLUMNS').subscribe(translation => 
      {
        this.populationReportMeta = {
          title: translation.totalPopulation,
          structure: {
            vrm_status: {
              name: `VRM ${translation.status}`,
              type: 'text',
              chartDataType: 'category'
            },
            count: {
              name: translation.count,
              type: 'text',
              chartDataType: 'series'
            }
          },
          chartType: ChartType.Pie
        };

        this.activitySumChartReportMeta = {
          title: translation.activitySummaryAll,
          structure: {
            status: {
              name: `${translation.status}`,
              type: 'text',
              chartDataType: 'category'
            },
            count: {
              name: translation.count,
              type: 'text',
              chartDataType: 'series'
            }
          },
          chartType: ChartType.Pie
        };
      });
    }

  ngOnInit(): void 
  {
    this.cService.carouselDriver(this.charts1.length);
    this.pagedArray();

    this.cService.mainSidenavToggled$.subscribe(resp => {
      this.dashItemsTabGroup.realignInkBar();
    });

    // OVERVIEW Tab - Resolve API Calls on Load
    this.route.data.subscribe(data => {
      const responses = data.resolvedResponse;

      console.log(':: DASHBOARD - OVERVIEW', responses);

      // Activity summary table
      this.activitySummaryReportMeta = responses[0][0];
      this.activitySummaryRawReportsData = responses[0][1].data;
      
      // Population
      this.populationRawReportsData = responses[1].data;

      // Activity summary overview
      this.activitySumChartRawReportsData = responses[2].data;
    });
  }

  selectedTabChanged() {
    console.log('....Selected Tab Changed', this.dashItemsTabGroup.selectedIndex);

    this.translate.get('REPORTCOLUMNS').subscribe({
      next: (translation) => 
      {
        // RoadRISK Tab
        if (this.dashItemsTabGroup.selectedIndex === TabEnums.roadRisk) 
        {
          // RoadRISK Summary
          this.roadRiskSummaryChartReportMeta = {
            title: translation.summary,
            structure: {
              RoadRiskName: {
                name: translation.name,
                type: 'text',
                chartDataType: 'category'
              },
              Low: {
                name: translation.low,
                type: 'number',
                chartDataType: 'series'
              },
              Medium: {
                name: translation.medium,
                type: 'number',
                chartDataType: 'series'
              },
              High: {
                name: translation.high,
                type: 'number',
                chartDataType: 'series'
              },
              VeryHigh: {
                name: translation.veryHigh,
                type: 'number',
                chartDataType: 'series'
              }
            },
            chartType: ChartType.GroupedColumn
          };
  
          this.rptService.commonReport(this.rptService.reportEndpoint.roadRiskSummary).subscribe({
            next: (summary) => {
              this.roadRiskSummaryChartRawReportsData = summary.data;
              console.log('RoadRISK Summary', summary);
            }
          });
        }
      }
    });
  }

  pagedArray(): void
  {
    this.pagedActivitySummaries = [];
    
    const itemsToPage: Array<SummaryItem> = this.activitySummary;
    const maxPage = Math.round(itemsToPage.length / this.activityItemsPerPage);
    const pages: Array<{ itemStart: number, itemEnd: number }> = [];

    // Generate page / array slicing valuesS
    for (let i = 0; i < maxPage; i++) 
    {
      if (i === 0) {
        pages.push({
          itemStart: 0,
          itemEnd: this.activityItemsPerPage
        });
      }
      else {
        const previousIndex = i - 1;
        const sliceStart = pages[previousIndex].itemEnd;
        const sliceEnd = sliceStart + this.activityItemsPerPage;

        pages.push({
          itemStart: sliceStart,
          itemEnd: (sliceEnd > itemsToPage.length) ? itemsToPage.length : sliceEnd
        });
      }
    }

    const zeroBasedPageQuery = pages[this.requestedActivityPage - 1];
    if (zeroBasedPageQuery) {
      this.pagedActivitySummaries = itemsToPage.slice(zeroBasedPageQuery.itemStart, zeroBasedPageQuery.itemEnd);
    }
    else {
      this.pagedActivitySummaries = itemsToPage.slice(0, this.activityItemsPerPage);
      this.requestedActivityPage = 1;
      console.error(':: VRM ERROR: Requested Array Page DOES NOT EXIST');
    }

    // Determine navigation boundaries
    this.pagedActivityCanGoNext = (this.requestedActivityPage >= maxPage) ? false : true;
    this.pagedActivityCanGoPrevious = (this.requestedActivityPage <= 1) ? false : true;
  }
  moveToPreviousActivity() {
    this.requestedActivityPage--;
    this.pagedArray();
  }
  moveToNextActivity() {
    this.requestedActivityPage++;
    this.pagedArray();
  }

  realignOnAnimationDone() {
    setTimeout(() => {
      this.dashItemsTabGroup.realignInkBar();
    });
  }
}
