
export const constant = {
  
  'PREDEFINED_COMMENTS_HIGHPRIORITY' : {

    'BODY-HOLE': 1,

    'BODY-ITEM_HANGING_DOWN': 1,

    'BODY-SCRATCH_LARGER_THAN_BILL': 3,

    'BRAKE-PARKING-LOOSE': 1,

    'BRAKE-SERVICE-GRINDING': 2,

    'BRAKE-SERVICE-NOT_STOP': 2,

    'BRAKE-SERVICE-SINK_TO_FLOORMAT': 1,

    'BRAKE-SERVICE-SQUEAKING': 3,

    'BRANDING-BADGE': 3,

    'BRANDING-DIRTY': 3,

    'CAMERA-BACKUP_MISSING': 2,

    'CAMERA-DISPLAY': 3,

    'CAMERA-NOT_TURN_ON': 2,

    'DASHBOARD-DEF_ADBLUE': 3,

    'DASHBOARD-ENGINE': 2,

    'DASHBOARD-OTHER': 2,

    'DELIVERY': 2,

    'DOORS': 1,

    'HORN': 1,

    'LICENSE-PLATE_DAMAGED': 2,

    'LICENSE-PLATE_DIRTY': 3,

    'LICENSE-PLATE_EXPIRED': 1,

    'LIGHTS-BRAKE_LIGHT': 1,

    'LIGHTS-COVER': 1,

    'LIGHTS-HEADLIGHTS': 1,

    'LIGHTS-INTERNAL': 3,

    'LIGHTS-TURN_LIGHT': 1,

    'SEATBELT': 1,

    'SIDE_MIRROR-NOT_OBSTRUCTED': 3,

    'SIDE_MIRROR-OBSTRUCTED': 1,

    'TIRE-FLAT': 1,

    'TIRE-HAS_OBJECTS': 1,

    'TIRE-TREAD_BELOW_INDICATOR': 1,

    'WINDSHIELD-CRACKS': 2,

    'WINDSHIELD-NOT_OBSTRUCTED': 3,

    'WINDSHIELD-OBSTRUCTED': 1,

    'WIPER-DAMAGE': 1,

    'WIPER-DEFROSTER': 1,

    'WIPER-WASHER': 1,

    // AUDIT COMMENTS, all set to severity 1
    'WINDSHIELD-CRACKS_OVER'          : 1,
    'WIPER-NOT_PRESENT'               : 1,
    'SIDE_MIRROR-NOT_PRESENT'      : 1,
    'SIDE_MIRROR-CRACKS'          : 1,
    'BODY-ITEMS_LOOSE'   : 1,
    'BODY-LARGE_DENTS'          : 1,
    'LIGHT_C&R-BROKEN'          : 1,
    'LIGHTS-HEADLIGHTS_NO'               : 1,
    'LIGHTS-FLASHERS_NO'               : 1,
    'TIRE-TREAD'                       : 1,
    'TIRE-OBJECTS'                : 1,
    'TIRE-VISIBILY_FLAT'      : 1,
    'PACKAGES-MISPLACED'      : 1,
    'UNIFORMS-MISSING'      : 1,
    'CREDENTIALS-ID'      : 1,
    'UNIFORMS-TOP' : 1,
    'UNIFORMS-BOT' : 1,
    'CREDENTIALS-FACE_ID' : 1,
    'CREDENTIALS-FACE_BADGE' : 1,
    'CREDENTIALS-FACE_RABBIT' : 1,
    'CREDENTIALS-ID_RABBIT' : 1,
    'CREDENTIALS-ID_BADGE' : 1,
    'CREDENTIALS-BADGE_RABBIT' : 1,
    'CREDENTIALS-ALL' : 1,
    'AC_HEATING-AC' : 1,
    'AC_HEATING-HEATING'	: 1,

  },

  'PREDEFINED_COMMENTS_TEXT': {

    'BODY-HOLE': 'Hole/puncture are present',

    'BODY-ITEM_HANGING_DOWN': 'Items attached to the body of the vehicle are loose or missing.',

    'BODY-SCRATCH_LARGER_THAN_BILL': 'Scratch/Dent/Chips are present and larger than a dollar bill or credit card.',

    'BRAKE-PARKING-LOOSE': 'Parking brake is loose or no braking occurs.',

    'BRAKE-SERVICE-GRINDING': 'There is grinding.',

    'BRAKE-SERVICE-NOT_STOP': 'Vehicle does not stop properly.',

    'BRAKE-SERVICE-SINK_TO_FLOORMAT': ' It sinks all the way to the floor mat.',

    'BRAKE-SERVICE-SQUEAKING': 'There is squeaking.',

    'BRANDING-BADGE': 'Amazon Prime Badge is not visible or not in good condition.',

    'BRANDING-DIRTY': 'Amazon Van is dirty.',

    'CAMERA-BACKUP_MISSING': 'The external back-up camera is missing.',

    'CAMERA-DISPLAY': 'The rear-view display monitor is mirror imaged.',

    'CAMERA-NOT_TURN_ON': 'The rear-view display monitor does not come on.',

    'DASHBOARD-DEF_ADBLUE': 'DEF AdBlue indicator light stays on.',

    'DASHBOARD-ENGINE': 'Check engine light stays on.',

    'DASHBOARD-OTHER': 'Other warning lights stay on.',

    'DELIVERY': 'Cradle is damaged or missing.',

    'DOORS': 'Any doors can\'t open/close/lock from the outside.',

    'HORN': 'Horn not functional.',

    'LICENSE-PLATE_DAMAGED': 'License plates/temp tags are damaged or missing.',

    'LICENSE-PLATE_DIRTY': 'License plates/tmp tags are dirty',

    'LICENSE-PLATE_EXPIRED': 'License plates/temp tags have expired.',

    'LIGHTS-BRAKE_LIGHT': 'Some or no Brake lights come on.',

    'LIGHTS-COVER': 'Reflectors/light covers are broken.',

    'LIGHTS-HEADLIGHTS': 'One or any headlights are out.',

    'LIGHTS-INTERNAL': 'Internal or rear compartment lights do not come on.',

    'LIGHTS-TURN_LIGHT': ' One or any turn/indicator signals are not working.',

    'SEATBELT': 'Seat belt does not buckle or is missing.',

    'SIDE_MIRROR-NOT_OBSTRUCTED': 'Damage does not obstruct the driver’s line of sight.',

    'SIDE_MIRROR-OBSTRUCTED': 'Missing mirror, distortion, or damage obstructs the driver’s line of sight.',

    'TIRE-FLAT': 'Tire is losing air or visibly flat.',

    'TIRE-HAS_OBJECTS': 'Tire has objects, sidewall bulges, or cuts to tire tread/surface.',

    'TIRE-TREAD_BELOW_INDICATOR': 'Tread is below the tread indicator.',

    'WINDSHIELD-CRACKS': '3 or more cracks/chips are present',

    'WINDSHIELD-NOT_OBSTRUCTED': 'Damage does not obstruct the driver’s line of sight.',

    'WINDSHIELD-OBSTRUCTED': 'Damage obstructs the driver’s line of sight.',

    'WIPER-DAMAGE': 'Wipers are damaged, missing or do not turn on.',

    'WIPER-DEFROSTER': 'Defroster is not functional.',

    'WIPER-WASHER': 'Windshield Wiper/Washer System is not functional.',

  },
  'CATEGORY_NAME_20190312': {

    'BODY-FRONT_DRIVER_SIDE': 'Body Damage, Front Driver Side',

    'BODY-REAR_DRIVER_SIDE': 'Body Damage, Rear Driver Side',

    'BODY-FRONT_PASSENGER_SIDE': 'Body Damage, Front Passenger Side',

    'BODY-REAR_PASSENGER_SIDE': 'Body Damage, Rear Passenger Side',

    'BODY-FRONT_SIDE': 'Body Damage, Front Side',

    'BODY-REAR_SIDE': 'Body Damage, Rear Side',

    'BODY-UNDERCARRIAGE': 'Body Damage, Under Carriage',

    'BRAKE-SERVICE': 'Brakes, Service Brake',

    'BRAKE-PARKING': 'Brakes, Parking Brake,',

    'BRANDING-EXTERIOR': 'Branding, Exterior',

    'CAMERA-DISPLAY': 'Camera, Display Monitor',

    'CAMERA-EXTERIOR_CAMERA': 'Camera, Exterior Camera',

    'DASHBOARD': 'Dashboard Indicator Lights, Dashboard',

    'DELIVERY-INTERIOR': ' Delivery Device Cradle, Interior',

    'DOORS-DRIVER_SIDE': 'Doors, Driver Side',

    'DOORS-PASSENGER_SIDE': 'Doors, Passenger Side',

    'DOORS-REAR_SIDE': 'Doors, Rear Side',

    'DOORS-SLIDING_DOOR': 'Doors, Sibling Side',

    'HORN': 'Horn, Horn',

    'LICENSE-FRONT_BUMPER': 'License Plates/Temporary Tags,  Front Bumper',

    'LICENSE-REAR_BUMPER': 'License Plates/Temporary Tags,  Rear Bumper',

    'LIGHTS-FRONT_DRIVER_SIDE': 'Lights, Front Driver Side',

    'LIGHTS-REAR_DRIVER_SIDE': 'Lights, Rear Driver Side',

    'LIGHTS-FRONT_PASSENGER_SIDE': 'Lights, Front Passenger Side',

    'LIGHTS-REAR_PASSENGER_SIDE': 'Lights, Rear Passenger Side',

    'LIGHTS-INTERIOR': 'Lights, Interior',

    'SEATBELT-DRIVER_SIDE': 'Safety/Seat Belts, Driver Side',

    'SEATBELT-PASSENGER_SIDE': 'Safety/Seat Belts, Passenger Side',

    'SIDE_MIRROR-DRIVER_SIDE': 'Side Mirror,  Driver Side',

    'SIDE_MIRROR-PASSENGER_SIDE': 'Side Mirror,  Passenger Side',

    'TIRE-FRONT_DRIVER_SIDE': 'Tire,  Front Driver Side',

    'TIRE-REAR_DRIVER_SIDE': 'Tire,  Rear Driver Side',

    'TIRE-FRONT_PASSENGER_SIDE': 'Tire, Front Passenger Side',

    'TIRE-REAR_PASSENGER_SIDE': 'Tire,  Front Passenger Side',

    'WINDSHIELD-DRIVER_SIDE': 'Windshield,  Driver Side',

    'WINDSHIELD-PASSENGER_SIDE': 'Windshield,  Passenger Side',

    'WIPER-FRONT_SIDE': 'Wiper, Front Side',

    'WIPER-DRIVER_SIDE': 'Wiper, Driver Side',

    'WIPER-REAR_SIDE': 'Wiper, Rear Side',


  },
  
  'PREDEFINED_COMMENTS_TEXT_AUDIT': {
    'WINDSHIELD-CRACKS_OVER'          : 'Cracks over the steering wheels.',
    'WIPER-NOT_PRESENT'               : 'Wipers are not present.',
    'SIDE_MIRROR-NOT_PRESENT'      : 'Side mirrors are not present.',
    'SIDE_MIRROR-CRACKS'          : 'Side mirrors have cracks.',
    'BODY-ITEMS_LOOSE'   : 'Items attached to the body (such as Side Mirrors, Steps, Bumpers etc.) of the vehicle are loose hanging or held with a zip tie or tape.',
    'BODY-LARGE_DENTS'          : 'Large dents in the hood, fender or door.',
    'LIGHT_C&R-BROKEN'          : 'Light Covers or Reflectors are broken.',
    'LIGHTS-HEADLIGHTS_NO'               : 'Headlights are not operational.',
    'LIGHTS-FLASHERS_NO'               : 'Emergency Flashers are not operational.',
    'TIRE-TREAD'                       : 'Tread depth is less than 2/32',
    'TIRE-OBJECTS'                : 'Objects/cuts/buldge to the surface or Tread.',
    'TIRE-VISIBILY_FLAT'      : 'Tire is visibly flat.',
    'PACKAGES-MISPLACED'      : 'Packages on seat or dashboard.',
    'UNIFORMS-MISSING'      : 'The DA does not have on a branded Top and Bottom.', // Shireen is checking if we should keep
    'UNIFORMS-TOP' : 'The DA does not have a branded Top.',
    'UNIFORMS-BOT' : 'The DA does not have a branded Bottom.',
    'CREDENTIALS-ID'      : 'Driver\'s face, ID and Rabbit Device login all matches.',
    'CREDENTIALS-FACE_ID' : 'Face does not match ID.',
    'CREDENTIALS-FACE_BADGE' : 'Face does not match badge.',
    'CREDENTIALS-FACE_RABBIT' : 'Face does not match Rabbit app.',
    'CREDENTIALS-ID_RABBIT' : 'ID does not match Rabbit app.',
    'CREDENTIALS-ID_BADGE' : 'ID does not match badge.',
    'CREDENTIALS-BADGE_RABBIT' : 'Badge does not match Rabbit app.',
    'CREDENTIALS-ALL' : 'Face, ID, badge and Rabbit app do not match.',
    'AC_HEATING-AC' : 'AC is not working properly.',
    'AC_HEATING-HEATING'	: 'Heating is not working properly.',
    'OTHER_AUDIT': ''

  },

  'CATEGORY_NAME_AUDIT' : {

    'BODY-FRONT_DRIVER_SIDE': 'Body Damage, Front Driver Side',

    'BODY-REAR_DRIVER_SIDE': 'Body Damage, Rear Driver Side',

    'BODY-FRONT_PASSENGER_SIDE': 'Body Damage, Front Passenger Side',

    'BODY-REAR_PASSENGER_SIDE': 'Body Damage, Rear Passenger Side',

    'BODY-FRONT_SIDE': 'Body Damage, Front Side',

    'BODY-REAR_SIDE': 'Body Damage, Rear Side',

    'BODY-UNDERCARRIAGE': 'Body Damage, Under Carriage',

    'BRAKE-SERVICE': 'Brakes, Service Brake',

    'BRAKE-PARKING': 'Brakes, Parking Brake,',

    'BRANDING-EXTERIOR': 'Branding, Exterior',

    'CAMERA-DISPLAY': 'Camera, Display Monitor',

    'CAMERA-EXTERIOR_CAMERA': 'Camera, Exterior Camera',

    'DASHBOARD': 'Dashboard Indicator Lights, Dashboard',

    'DELIVERY-INTERIOR': ' Delivery Device Cradle, Interior',

    'DOORS-DRIVER_SIDE': 'Doors, Driver Side',

    'DOORS-PASSENGER_SIDE': 'Doors, Passenger Side',

    'DOORS-REAR_SIDE': 'Doors, Rear Side',

    'DOORS-SLIDING_DOOR': 'Doors, Sibling Side',

    'HORN': 'Horn, Horn',

    'LICENSE-FRONT_BUMPER': 'License Plates/Temporary Tags,  Front Bumper',

    'LICENSE-REAR_BUMPER': 'License Plates/Temporary Tags,  Rear Bumper',

    'LIGHTS-FRONT_DRIVER_SIDE': 'Lights, Front Driver Side',

    'LIGHTS-REAR_DRIVER_SIDE': 'Lights, Rear Driver Side',

    'LIGHTS-FRONT_PASSENGER_SIDE': 'Lights, Front Passenger Side',

    'LIGHTS-REAR_PASSENGER_SIDE': 'Lights, Rear Passenger Side',

    'LIGHTS-INTERIOR': 'Lights, Interior',

    'SEATBELT-DRIVER_SIDE': 'Safety/Seat Belts, Driver Side',

    'SEATBELT-PASSENGER_SIDE': 'Safety/Seat Belts, Passenger Side',

    'SIDE_MIRROR-DRIVER_SIDE': 'Side Mirror,  Driver Side',

    'SIDE_MIRROR-PASSENGER_SIDE': 'Side Mirror,  Passenger Side',

    'TIRE-FRONT_DRIVER_SIDE': 'Tire/Tyre,  Front Driver Side',

    'TIRE-REAR_DRIVER_SIDE': 'Tire/Tyre,  Rear Driver Side',

    'TIRE-FRONT_PASSENGER_SIDE': 'Tire/Tyre, Front Passenger Side',

    'TIRE-REAR_PASSENGER_SIDE': 'Tire/Tyre,  Front Passenger Side',

    'WINDSHIELD-DRIVER_SIDE': 'Windshield,  Driver Side',

    'WINDSHIELD-PASSENGER_SIDE': 'Windshield,  Passenger Side',

    'WIPER-FRONT_SIDE': 'Wiper, Front Side',

    'WIPER-DRIVER_SIDE': 'Wiper, Driver Side',

    'WIPER-REAR_SIDE': 'Wiper, Rear Side',


  // CHANGED categories for audits
  'TIRE-DRIVER_SIDE': 'Tire, Driver Side',
  'TIRE-PASSENGER_SIDE': 'Tire, Passenger Side',

  // NEW categories for audits
  'LIGHT_C&R-FRONT_DRIVER_SIDE': 'Light covers and reflectors, Front Driver Side',
  'LIGHT_C&R-REAR_DRIVER_SIDE': 'Light covers and reflectors, Rear Driver Side',
  'LIGHT_C&R-FRONT_PASSENGER_SIDE': 'Light covers and reflectors, Front Passenger Side',
  'LIGHT_C&R-REAR_PASSENGER_SIDE': 'Light covers and reflectors, Rear Passenger Side',
  'LIGHT_C&R-INTERIOR': 'Light covers and reflectors, Interior',
  
  'PACKAGES': 'Packages',
  'UNIFORMS': 'Uniforms',
  'CREDENTIALS': 'Credentials',

  'CREDENTIALS-ALL_MATCH': 'Credentials, Does the face/badge/ID and Rabbit app all match?',
  'CREDENTIALS-DRIVERS_LICENSE': 'Credentials, Is the driver\'s license valid?',
  
  'AC_HEATING': 'AC/Heating'

  }
 };

