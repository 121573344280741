import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getColumnNamesFromMeta'
})
export class ColumnNamesTransform implements PipeTransform {
    transform(columnName: string, metaStructure: { columnName: any }) 
    {
        const column = metaStructure.columnName;
        let colName = '';

        if (column) {
            colName = column.name;
        }
        else {
            colName = columnName;
        }

        return colName;
    }
}