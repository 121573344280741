import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MustMatch } from '../../error-handlers/form-validators';
import { FleetReportDvcrService } from 'src/app/services/fleet-report-dvcr.service';
import { ApiResponse } from 'src/app/model/provisioning';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

// Subscription
import { Subscription } from 'rxjs';

@Component({
  selector: 'fleet-activation',
  templateUrl: './fleet-activation.component.html',
  styleUrls: ['./fleet-activation-component.scss']
})

export class FleetActivationComponent implements OnDestroy
{
  isSecond: boolean = false;
  isFirst: boolean = true;
  passwordForm: FormGroup;
  token: string;
  isSubmit: boolean = false;
  private _routeSubscription: Subscription;
  private _onSubmitSubscription: Subscription;
  user: any;
  isExpired: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private dvcrService: FleetReportDvcrService,
    private cService: CommonService,
    private authService: AuthService,
    private translate: TranslateService,
    private jwt: JwtHelperService
  ) {
  this._routeSubscription = this.route.params.subscribe({
      next: (urlResponse: {token: string}) => {
        this.token = urlResponse.token; 
      }
    });
    this.user = this.jwt.decodeToken(this.token);
    this.isExpired = this.jwt.isTokenExpired(this.token);
    this.passwordForm = this.formBuilder.group({
      password: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/))
      ])),
      confirmPassword: new FormControl(null, Validators.required)
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });
  } 

    changePage() {
      this.isFirst = false;
      this.isSecond = true;
    }

    onSubmit() {
     this._onSubmitSubscription = this.dvcrService.setPassword(this.passwordForm.get('password'), this.token).subscribe({
        next: ({ statusCode, bodyResponse }: ApiResponse) => {
          if (statusCode == 200) { this.changePage(); }
          if (statusCode == 401) {
            /**
             *@todo: refresh message
             */
            this.cService.showToast(``, null, 'refresh');
          }
        },
        error: (err) => {
          this.cService.showToast(this.translate.instant('MESSAGESANDWARNINGS').genericError, null, 'close');
        }
      });
    }

    ngOnDestroy() {
      if (this._routeSubscription) { this._routeSubscription.unsubscribe(); }

      if (this._onSubmitSubscription) { this._onSubmitSubscription.unsubscribe(); }
    }

    navigate() {
      this.authService.navigateToLoginPage();
    }

}