// Angulat
import { Component, Input, OnInit } from '@angular/core';

// Services
import { FleetReportDvcrService } from '../../services/fleet-report-dvcr.service';
import { CommonService } from '../../services/common.service';
import { TranslateService } from '@ngx-translate/core';

// Subscription
import { Subscription } from 'rxjs';

// Model
import { SeverityComponentInput } from '../../model/provisioning';
import { GenericComponent } from '../../model/reports';

@Component({
    selector: 'mentor-severtity-details',
    template: `
    <div *ngIf="mustShow">
        <button mat-raised-button (click)="GetZipFile()">{{ 'DVCR.downloadImage' | translate }}</button>
        <div class="margin-b-15" *ngFor="let more of moreDetails.moreDetailsData">
            <div >
                <hr>
                <h4>{{more.title | titlecase}}</h4> 
                <p [ngStyle]="more.style">{{more.content | titlecase}}</p>
            </div>
        </div>
    </div>`,
    styles: [
        `.button-details {
            font-size: 17px;
            cursor: pointer;
            color: black!important;
            text-decoration: none;
        }`
    ]
})
export class SeverityDetailsComponent implements GenericComponent, OnInit {

    @Input() moreDetails: SeverityComponentInput;
    mustShow: boolean = false;
    private _getZipFileSubscription: Subscription;


    constructor(
        private translate: TranslateService,
        private dvcrService: FleetReportDvcrService,
        private cService: CommonService

    ) { }

    ngOnInit() {
        if ( this.moreDetails.hasOwnProperty('moreDetailsData') && this.moreDetails.moreDetailsData.length) { this.mustShow = true; }
    }

    /**
     * @TODO Change method name
     */
   GetZipFile() {
      this._getZipFileSubscription = this.dvcrService.getZip(this.moreDetails.zipCode).subscribe({
            next: (response) => {
                const link = document.createElement('a');
                link.href = `data:application/zip;base64,${response}`;
                link.download = 'images.zip';
                link.click();
                link.remove();
            },
            error: (error) => {
                console.log('ERROR', error);
                this.cService.showToast(this.translate.instant('MESSAGESANDWARNINGS').genericError, null, 'close');
            },
            complete: () => {
            }
        });
    }

    ngOnDestroy() {
        if (this._getZipFileSubscription) { this._getZipFileSubscription.unsubscribe(); }
      }

}