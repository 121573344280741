import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ReportsService, TransformedSingleData } from '../../services/reports.service';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'grid-see-mvr-button',
    template: `
    <span>
        <button mat-button fxFlex class="sharp-corners bold grid-text" [style.color]="transformedValue?.color"
            style="text-decoration: underline" (click)="triggerLinkButtonAction()">
            {{ value }}
        </button>
    </span>
    `,
    styles: []
})
export class GridSeeMvrLinkButtonComponent implements ICellRendererAngularComp 
{
    public value: string;
    public transformedValue: TransformedSingleData;

    constructor(
        private router: Router,
        private cService: CommonService,
        public rptService: ReportsService
    ) { }

    agInit(params: { value: any}) {
        this.transformedValue = 
            this.rptService.transformSingleDataForDetailsPane('indexingLicenceStatus', params.value);

        this.value = (this.transformedValue) ? this.transformedValue.uiValue : params.value;
    }

    triggerLinkButtonAction() {
        if (this.cService.mvrPageHidden()) {
            this.cService.showToastWithoutDuration(
                this.cService.readyTranslations.messagesAndWarnings.insufficientPermissionToViewRequestedPage,
                'close');
        }
        else {
            setTimeout(() => {
                this.rptService.gridSearch.text = this.rptService.selectedRows[0]['pin'];
                this.rptService.gridSearch.activated = true;
    
                this.router.navigate(['commonreports/mvr-main']);
            }, 250);
        }
    }

    refresh() {
        return false;
    }
}