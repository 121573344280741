import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GuideViewerComponent } from '../../dialogs/guide-viewer/guide-viewer.component';

import { GuideAndBucketContentService } from '../../services/guide-and-bucket-content.services';
import { CommonService } from '../../services/common.service';
import { ReportMeta } from '../../services/reports.service';
import { OtherMiniReportOptions } from 'src/app/model/reports';


@Component({
    selector: 'company-guide-component',
    templateUrl: './company-guide.component.html',
    styleUrls: ['./company-guide.component.scss']
})
export class CompanyGuideComponent
{
  trainingMetadata: ReportMeta;
  trainingModuleData: Array<any>;

  reportOptions: OtherMiniReportOptions = {
    reportHeight: '100%'
  };

  private guideViewerDialogComponent = GuideViewerComponent;

  constructor(
    private gBucketService: GuideAndBucketContentService,
    private cService: CommonService,
    private dialog: MatDialog
  ) {
    setTimeout(() => {
      const pageTitle = cService.readyTranslations.guideFaq.companyGuides;
      this.cService.setPageHeader(pageTitle);
    });

    gBucketService.currentFaqEndpointName = 'company';

    this.gBucketService.getAllTrainingModulesMetaForkJoined(gBucketService.currentFaqEndpointName).subscribe({
      next: (response: Array<any>) => {
        this.trainingMetadata = response[0];
        this.trainingModuleData = response[1].data;

        console.log('%c All Training modules + Meta', 'color: lightblue', response);
      }
    });
  }

  viewContentInDialog(dialogData: any) {
    console.log('DIALOG DATA', dialogData);
    this.dialog.open(this.guideViewerDialogComponent, dialogData);
  }
}