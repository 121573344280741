import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { Router, NavigationError, NavigationEnd } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '../environments/environment';

import * as rg4js from 'raygun4js';

import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './services/common.service';
import { AuthService } from './services/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { first } from 'rxjs/operators';

import { Config } from '../app/config';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
  title: string = 'VRM - eDriving';
  inFullscreenMode: boolean = false;

  @ViewChild('mainSidenav', { static: false })
  mainSidenav: MatSidenav;

  @ViewChild('settingsSidenav', { static: false })
  settingsSidenav: MatSidenav;

  constructor(
    public cService: CommonService,
    public authService: AuthService,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private appUpdate: SwUpdate,
    private router: Router
    ) {
      this.constructorInit();
  }

  constructorInit(): void {
    // Orientation based Breakpoint
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait
      ]).subscribe(result => {
        this.cService.applyBreakpoint();
    });

    // ngx - Translation - NOTE: Lang to use is also set on Login
    const vrmLanguages: Array<string> = [];
    this.cService.vrmLanguages.map(vrmLang => {
      vrmLanguages.push(vrmLang.code);
    });
    this.translate.addLangs(vrmLanguages);

    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|fr|ru|de|ar/) ? browserLang : 'en');

    if (this.authService.isAuthenticated()) 
    {
      this.authService.determineDefaultStartPage(this.cService.userProfile.startPage);
      this.cService.triggerUserProfileReadyObservable(this.cService.userProfile);

      this.cService.appStableSinceInitLoad$.pipe(
      first(stable => stable)).subscribe({
        next: (appStatus) => 
        {
          console.log('%c Application stable', 'color: salmon', appStatus);
  
          // Refresh token but allow time for all initial API calls to finish
          setTimeout(() => {
            // this.authService.refreshToken().subscribe({
            //   next: () => {
            //     this.authService.triggerTokenExpirationAndKeepaliveWatch();
            //   }
            // });
          },
          this.cService.appSettings.waitInMsForInitialApiCalls);
  
          // Check updates via SW
          if (this.appUpdate.isEnabled) {
            this.appUpdate.checkForUpdate().then(() => {
              this.appUpdate.available.subscribe({
                next: () => {
                  this.cService.showToastWithoutDuration('Updates available', 'refresh now');
                }
              });
            });
          }
  
          // Warn if using live-data in development
          if (!environment.production && Config.apiRoot.env.toLowerCase() === 'production') {
            this.cService.prodDataInUseInDev = true;
          }
        }
      });
    }
    else {
      console.log('%c WARNING: Not Authenticated!', 'color: yellow');
    }
  }

  // Lifecycle Hooks
  ngOnInit(): void 
  {
    // Apply User Profile if not already applied
    if (this.authService.isAuthenticated()) {
      if (!this.authService.userProfileSet) {
        this.cService.userProfile = JSON.parse(localStorage.getItem(this.cService.localStorageKeys.userProfile));
        this.cService.applyUserProfile();

        console.log('User Profile', this.cService.userProfile);
      }
      else {
        this.cService.processMainNavTranslations();
      }
    }
    else {
      if (!location.href.includes(`/sso`) && !location.href.includes(`/reset-password`) && !location.href.includes(`/fleet_activation`)) {
       // this.authService.navigateToLoginPage();
      }
    }

    if (localStorage.getItem(this.cService.localStorageKeys.isDarkTheme)) {
      this.cService.isDarkTheme = true;
    }

    const savedTheme = localStorage.getItem(this.cService.localStorageKeys.currentTheme);
    if (savedTheme) {
      this.cService.currentTheme = savedTheme;
    }

    // RayGun RealTime Tracking & Google analytics page tracking
    const rayGunProduction: string = 'UaXyObv1gExWyEiI9PMg';
    const rayGunDevelopment: string = 'AKWhZ0ZYgh7gTx020qxb6Q'; // <-- For testing RayGun
    const rayGunKey = (environment.production) ? rayGunProduction : rayGunDevelopment;
    
    if (environment.production || environment.staging) {
      rg4js('apiKey', rayGunKey);
      rg4js('setVersion', Config.appVersion);
    }
    
    if (environment.production) {
      rg4js('enablePulse', true); // <-- Needed for RealTime

      this.router.events.subscribe({
        next: (event: any) => {
          if (event instanceof NavigationEnd) {
            rg4js('trackEvent', {
              type: 'pageView',
              path: event.url
            });

            // Google analytics page tracking
            if (this.cService.userProfile && this.cService.userProfile.userId && event.urlAfterRedirects) {
              gtag('config', 'UA-158782147-3',
              {
                'user_id'   : this.cService.userProfile.userId,
                'page_path' : event.urlAfterRedirects
              });
            }
          }
          else if (event instanceof NavigationError) {
            rg4js('send', { error: event.error });
          }
        }
      });
    }
  }

  // EVENTS
  @HostListener('window:offline') 
  onOffline() {
    this.cService.isOnline = false;
  }
  @HostListener('window:online') 
  onOnline() {
    this.cService.isOnline = true;
  }

  toggleFullScreen() {
    if (!this.inFullscreenMode) {
      const elem: any = document.documentElement;
    
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        this.inFullscreenMode = true;
      } 
      else if (elem.mozRequestFullScreen) { 
        /* Firefox */
        elem.mozRequestFullScreen();
        this.inFullscreenMode = true;
      } 
      else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
        this.inFullscreenMode = true;
      } 
      else if (elem.msRequestFullscreen) { 
        /* IE/Edge */
        elem.msRequestFullscreen();
        this.inFullscreenMode = true;
      }
    }
    else {
      this.exitFullScreen();
    }
  }

  exitFullScreen() {
    const mainDocument: any = document;

    if (mainDocument.exitFullscreen) {
      mainDocument.exitFullscreen();
      this.inFullscreenMode = false;
    } 
    else if (mainDocument.mozCancelFullScreen) {
      /* Firefox */
      mainDocument.mozCancelFullScreen();
      this.inFullscreenMode = false;
    } 
    else if (mainDocument.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      mainDocument.webkitExitFullscreen();
      this.inFullscreenMode = false;
    } 
    else if (mainDocument.msExitFullscreen) {
      /* IE/Edge */
      mainDocument.msExitFullscreen();
      this.inFullscreenMode = false;
    }
  }

  // ---- METHODS ----
  manuallyCheckUpdates() {
    if (this.appUpdate.isEnabled) {
      this.cService.showToast(`${this.translate.instant('MESSAGESANDWARNINGS').checkingUpdates}...`);

      this.appUpdate.checkForUpdate().then(() => {
        location.reload();
      });
    }
    else {
      this.cService.showToast(':: Feature not available . Clear cache and refresh instead');
    }
  }

  reloadApp() {
    const translatedMessage = (this.cService.readyTranslations.generic.reloading) 
    ? this.cService.readyTranslations.generic.reloading : 'Reloading';

    this.cService.showToast(translatedMessage + '...');
    location.reload();
  }
  
  closeMainSidenav(): void {
    this.mainSidenav.close();
  }
  toggleSettingsSidenav(accAndSecTab?: number): void {
    if (accAndSecTab) {
      this.cService.selectedAccountAndSecTab = accAndSecTab;
    }

    this.settingsSidenav.toggle();

    if (!this.cService.isPcViewport) {
      this.cService.mainSidenavOpened = false;
    }
  }
  closeSettingSidenav(): void {
    this.settingsSidenav.close();
  }
  logout() {
    this.authService.logout();
    this.authService.navigateToLoginPage();
  }
  toggleDarkTheme(): void {
    this.cService.isDarkTheme = !this.cService.isDarkTheme;

    // Save to cookie!
    if (this.cService.isDarkTheme) {
      localStorage.setItem(this.cService.localStorageKeys.isDarkTheme, 'true');
    }
    else {
      localStorage.removeItem(this.cService.localStorageKeys.isDarkTheme);
    }
  }

  identifySelectedNav(selectedNav?: any): void {
    this.cService.commonReportTitle = selectedNav.translatedName;
    this.cService.identifySelectedNavAndParent();

    if (this.cService.isMobileViewport) {
      this.cService.toggleMainSidenav();
    }
  }

  onlyBadge(event) {
    event.preventDefault();
  }
}
