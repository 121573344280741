import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { AuthService, CompanyPasswordPolicy } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit
{
    resetPasswordForm: FormGroup;
    validatedPwResetToken: string;
    companyPwPolicy: CompanyPasswordPolicy;
    hide: boolean = true;

    constructor(
        public authService: AuthService,
        public cService: CommonService,
        private router: Router,
        private activeRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.resetPasswordForm = new FormGroup({
            password: new FormControl(null, Validators.required),
            confirmPassword: new FormControl(null, Validators.required)
        });

        this.activeRoute.params.subscribe({
            next: (urlResponse: { token: string }) => {
                const token = urlResponse.token;
                console.log('URL Token', token);

                if (token) {
                    
                    this.authService.validatePasswordResetTokenAndGetPolicy(token).subscribe({
                        next: (response: { username: string; company: string; companyPasswordPolicy: CompanyPasswordPolicy }) => {
                            this.validatedPwResetToken = token;
                            this.companyPwPolicy = response.companyPasswordPolicy;
                            console.log('Company PW Policy', this.companyPwPolicy);
                        },
                        error: (errResponse: HttpErrorResponse) => {
                            if (errResponse.status === this.cService.responseTypes.error.unauthorized) {
                                this.authService.navigateToLoginPage();
                            }
                            else {
                                this.unableToValidateRequest();
                            }
                        }
                    });

                }
                else {
                    this.unableToValidateRequest();
                }
            }
        });
    }

    clearPasswordResetForm() {
        this.resetPasswordForm.reset();
        this.authService.passwordChangeErrorMessage = '';
    }

    unableToValidateRequest() {
        this.cService.showToast(
            this.cService.readyTranslations.messagesAndWarnings.unableToValidateSecurityRequest);
        
        this.authService.passwordChangeErrorMessage = 
            this.cService.readyTranslations.messagesAndWarnings.unableToValidateSecurityRequest;
            
        console.error('VRM ERROR: Unable to validate security request');
    }

    resetAccountPassword() {
        const password = this.resetPasswordForm.get('password').value;
        this.authService.resetAccountPassword(this.validatedPwResetToken, password).subscribe({
            next: (response: any) => {
                this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.passwordSuccessfullyChanged);
                this.authService.navigateToLoginPage();
            },
            error: (errResp: HttpErrorResponse) => {
                this.authService.passwordChangeErrorMessage = 
                    this.cService.readyTranslations.messagesAndWarnings.failedToProcessRequest;
            }
        });
    }
}