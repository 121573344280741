export const navData = {
    // Status report
    statusReport: { idName: 'Status Report' },
    dashboard: { 
        idName: 'Dashboard' 
    },
    driverActivitySummary: { 
        idName: 'Driver Activity Summary', 
        endpoint: 'status_report/driver_activity_summary' 
    },

    // Culture
    culture: { idName: 'Culture' },
    privacy: { 
        idName: 'Privacy', 
        endpoint: 'culture/privacy' 
    },
    altPrivacy: { 
        idName: 'Alt Privacy', 
        endpoint: 'culture/alt_privacy' 
    },
    policy: { 
        idName: 'Policy', 
        endpoint: 'culture/policy' 
    },
    pledge: { 
        idName: 'Pledge', 
        endpoint: 'culture/pledge' 
    },
    managerPledge: { 
        idName: 'Manager Pledge', 
        endpoint: 'culture/manager_pledge' 
    },
    mentorConsent: {
        idName: 'Mentor Consent', 
        endpoint: 'culture/mentor_consent' 
    },
    authorizationToDrive: { 
        idName: 'Authorization To Drive', 
        endpoint: 'culture/authorisation_to_drive' 
    },
    travellingForWork: { 
        idName: 'Travelling For Work', 
        endpoint: 'culture/travel_for_work' 
    },
    tutorialVideos: { 
        idName: 'Tutorial Videos', 
        endpoint: 'culture/tutorial_videos' 
    },
    riskFoundation: { 
        idName: 'Risk Foundation', 
        endpoint: 'culture/risk_foundation' 
    },

    // RoadRISK
    roadRisk: { idName: 'RoadRISK' },
    profileSummary: { 
        idName: 'Profile Summary', 
        endpoint: 'roadrisk/profile_summary' 
    },
    defensiveDriving: { 
        idName: 'Defensive Driving', 
        endpoint: 'roadrisk/defensive_driving' 
    },
    feedback: { 
        idName: 'Feedback', 
        endpoint: 'roadrisk/feedback' 
    },
    driverRiskRating: { 
        idName: 'Driver Risk Rating', 
        endpoint: 'roadrisk/driver_risk_rating' 
    },

    // eLearning
    eLearning: { idName: 'eLearning' },
    oneMoreSecond: { 
        idName: 'One More Second', 
        endpoint: 'training/oms' 
    },

    // RiskCOACH
    riskCoach: { idName: 'RiskCOACH', endpoint: 'riskcoach' },

    // DriverINDEX
    driverIndex: { idName: 'DriverINDEX' },
    scorecard: { 
        idName: 'Scorecard', 
        endpoint: 'driverindex/scorecard' 
    },
    mentorByEdriving: { 
        idName: 'MENTOR by eDriving', 
        endpoint: 'driverindex/mentor' 
    },
    incidents: { 
        idName: 'Incidents', 
        endpoint: 'incidents' 
    },
    eventsTable: { 
        idName: 'Events Table', 
        endpoint: 'driverindex/events_table' 
    },
    riskLevelChanges: { 
        idName: 'Risk Level Changes', 
        endpoint: 'driverindex/risk_level_changes' 
    },

    // Licence Checking
    licenceChecking: {
        idName: 'Licence Checking',
        endpoint: 'licence/results'
    },

    // MVR+
    mvrPlus: { idName: 'MVR+' },
    main: { 
        idName: 'Main', 
        endpoint: 'mvr/main' 
    },
    drm: { 
        idName: 'DRM', 
        endpoint: 'mvr/drm' 
    },
    administration: { 
        idName: 'Administration', 
        endpoint: 'mvr/administration' 
    },

    // Driver Summary Report
    driverSummaryReport: { idName: 'Driver Summary Report', endpoint: 'mentor/driver_summary' },

    // DSP Performance
    dspPerformance: { idName: 'DSP Performance', endpoint: 'mentor/distributor_dsp_performance' },

    // Driver Performance
    driverPerformance: { idName: 'Driver Performance' },
    driverPerformanceReport: { 
        idName: 'Report', 
        endpoint: 'mentor/driver_performance' 
    },
    driverPerformanceComparison: { 
        idName: 'Comparison', 
        endpoint: 'mentor/driver_performance_delta' 
    },

    // Station report
    stationReport: { idName: 'Station Report' },
    stationReportReport: { 
        idName: 'Report', 
        endpoint: 'mentor/dsp_performance' 
    },
    stationReportComparison: { 
        idName: 'Comparison', 
        endpoint: 'mentor/dsp_performance_delta' 
    },

    // DVCR
    dvcr: { idName: 'DVCR' },

    // DVIR
    dvir: { idName: 'DVIR' },

    // Self Provisioning
    users: { idName: 'Users' },

    // GUIDES / FAQ
    guidesFaq: { idName: 'GUIDES / FAQ' },
    managerGuides: { idName: 'Manager Guides' },
    vrmGuides: { idName: 'VRM Guides' },

    // Mentor TSP Summary
    mentorTspSummary: { idName: 'Mentor TSP Summary', endpoint: 'mentor/tsp_summary' },
    vrmAlerts: { idName: 'VRM Alerts', endpoint: 'alerts' },

    // Contact Us
    contactUs: { idName: 'Contact Us' },
    threeSixty: { idName: '360 Magazine' }
};