import { Directive, OnInit, Renderer2, RendererStyleFlags2, ElementRef } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';

@Directive({
    selector: '[vrm-bg-color]'
})
export class VrmBackgroundColorDirective implements OnInit
{
    // TODO: Look into all directives similar to this
    // and ensure it updates the interface when colour changes!

    constructor(
        private cService: CommonService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    companyColor = (this.cService.userProfile && this.cService.userProfile.HqColour) ? 
        '#' + this.cService.userProfile.HqColour : '';

    ngOnInit() {
        const companyColor = (this.cService.userProfile && this.cService.userProfile.HqColour) ? 
            '#' + this.cService.userProfile.HqColour : 'black';

        this.renderer.setStyle(
            this.elementRef.nativeElement, 'background-color', `${ companyColor }`, RendererStyleFlags2.Important
        );

        this.renderer.setStyle(
            this.elementRef.nativeElement, 'color', 'white', RendererStyleFlags2.Important
        );
    }
}