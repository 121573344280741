import { Component, Input, ViewChild, ElementRef  } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';

import { AgGridAngular } from 'ag-grid-angular';
import { ChartRef, ChartType, ChartRangeParams, ChartOptions, ProcessChartOptionsParams, GridOptions, ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

interface InternalChartType { 
    agType: string; 
    name: string;
}

@Component({
    selector: 'vrm-ag-chart',
    templateUrl: './vrm-ag-chart.component.html',
    styleUrls: ['./vrm-ag-chart.component.scss']
})
export class VrmAgChartComponent
{
    @Input() metadata: any;
    @Input() rawData: any;
    @Input() reportTitle: string;

    columnsToDisplay: ColDef[];
    agGridPcColumns: ColDef[];

    gridOptions: GridOptions;
    isRtlLanguage: boolean;

    expPanel = {
        headerHeight: '48px'
    };
    chartTypeList: Array<any> = [];
    selectedChartType: InternalChartType;

    chartReference: ChartRef;
    @ViewChild('agGridCharts', { static: false }) agGrid: AgGridAngular;
    @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef<HTMLDivElement>;

    constructor(
        public cService: CommonService,
        public rptService: ReportsService,
        private translate: TranslateService
    ) {
        // TODO: Looks like isRtl needs to be watched. Use RxJs!
        this.isRtlLanguage = cService.selectedLanguage.isRtl;
        this.initializeAgGridProperties();
    }

    initializeAgGridProperties() {
        this.gridOptions = {
            defaultColDef: { sortable: true, resizable: true },
            ...this.rptService.agGridConfig.common
        };

        this.getAvailableChartTypes();
    }

    agGridReady(event: AgGridAngular) {
        this.displayColumnsByViewportSize();
    }

    private getAvailableChartTypes() {
        const chartTypeNodes = Object.keys(ChartType);

        this.translate.get('CHARTTYPES').subscribe({
            next: (translation) => {
                chartTypeNodes.forEach((node) => {
                    const chartType = ChartType[node];
    
                    this.chartTypeList.push({
                        agType: node,
                        name: translation[chartType]
                    });
                });
            }
        });
    }

    private displayColumnsByViewportSize() 
    {
        this.drawIntegratedChart();

        if (this.metadata.structure) {
            this.rptService.dataStructure = this.metadata.structure;
            this.agGridPcColumns = this.rptService.convertToAgColumnDefinition(this.metadata.structure);
            this.columnsToDisplay = this.agGridPcColumns;
            
            this.commonGridConfig();
        }
        else {
            console.error('VRM ERROR: Unsupported or missing VRM column structure');
        }
    }
    chartTypeChanged(event: Event, chartType: InternalChartType) {
        event.stopPropagation();

        this.selectedChartType = chartType;
        this.drawIntegratedChart();
    }
    refreshChart(event: Event) {
        event.stopPropagation();
        this.drawIntegratedChart();
    }

    private commonGridConfig() {
        // Run Async - Ensure grid is attached to the DOM
        setTimeout(() => {
            if (this.columnsToDisplay.length < 10 && !this.cService.isMobileViewport) {
                this.agGrid.api.sizeColumnsToFit(); 
            }
            else {
                this.agGrid.columnApi.autoSizeAllColumns();
            }
        });
    }

    drawIntegratedChart() {
        const cellRange = {
            rowStartIndex: 0,
            columns: this.rptService.getColumnNames(this.metadata.structure)
        };

        const chartRangeParams: ChartRangeParams = {
            cellRange: cellRange,
            chartType: (this.selectedChartType) ? ChartType[this.selectedChartType.agType] : this.metadata.chartType,
            chartContainer: this.chartContainer.nativeElement,

            processChartOptions: (params: ProcessChartOptionsParams) => {
                const options = params.options;

                options.title = {
                    text: this.reportTitle
                };
                
                options.legendPosition = 'bottom';

                return options;
            }
        };

        setTimeout(() => {
            if (this.chartReference) {
                this.chartReference.destroyChart();
            }
            this.chartReference = this.agGrid.api.chartRange(chartRangeParams);
        });
    }
}