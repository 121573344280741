import { NgModule } from '@angular/core';

// Material
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_HAMMER_OPTIONS } from '@angular/material/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MatTabGroup } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';

// Material CDK
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatNativeDateModule } from '@angular/material';

@NgModule({
  imports: [
    // Material
    BrowserAnimationsModule,
    MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatDividerModule,
    MatSidenavModule, MatTooltipModule, MatCardModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatExpansionModule,
    MatListModule, MatSlideToggleModule, MatSnackBarModule, MatButtonToggleModule, MatSelectModule, MatGridListModule,
    MatAutocompleteModule, MatTableModule, MatPaginatorModule, MatSortModule, MatChipsModule, MatTabsModule,
    MatProgressBarModule, MatDialogModule, MatBadgeModule, ScrollingModule, MatProgressSpinnerModule, MatSliderModule,
    
    // Datepicker / Calendar
    MatMomentDateModule, MatDatepickerModule, MatNativeDateModule,

    // Material SDK
    DragDropModule
  ],

  exports: [
    // Material
    BrowserAnimationsModule,
    MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatDividerModule,
    MatSidenavModule, MatTooltipModule, MatCardModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatExpansionModule,
    MatListModule, MatSlideToggleModule, MatSnackBarModule, MatButtonToggleModule, MatSelectModule, MatGridListModule,
    MatAutocompleteModule, MatTableModule, MatPaginatorModule, MatSortModule, MatChipsModule, MatTabsModule,
    MatProgressBarModule, MatDialogModule, MatBadgeModule, ScrollingModule, MatProgressSpinnerModule, MatSliderModule,
    
    // Datepicker / Calendar
    MatMomentDateModule, MatDatepickerModule, MatNativeDateModule,

    // Material SDK
    DragDropModule
  ],

  providers: [
    { provide: MatTabGroup, useValue: {} },
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
    { 
      provide: DateAdapter, useClass: MomentDateAdapter, 
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] 
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { 
      provide: MAT_HAMMER_OPTIONS, useValue: {
        cssProps: { userSelect: true }
      } 
    }
  ]
})
export class MaterialLibModule { }
