// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Third part
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'user-index-dialog',
  templateUrl: './user-index.component.html',
  styleUrls: [
    './user-index.component.scss'
  ]
})

export class UserIndexComponent implements OnInit {

  dialogTitle: string = '';
  dialogTitleDescription: string = '';
  showDetailsBufferProgress: boolean = false;
  isUpdate: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<UserIndexComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    
    ngOnInit() { 
      console.log('DIALOG DATA', this.data);
      this.initialize();
    }

    /**
     * @TODO translate
     */
    initialize() {
      if (this.data.hasOwnProperty('selectedRow') && this.data.selectedRow.id) {
        this.dialogTitle = 'Update User';
        this.isUpdate = true;
      } else {
        this.dialogTitle = 'Create User';
        this.isUpdate = false;
      }
    }
}