// Angular
import { Component, Input, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'mentor-upload-outcome',
    template: `<div> 
        <vrm-dialog-header [dialogTitle]="title" hideDivider="true"></vrm-dialog-header>
         <p style="color: #008000a6;padding: 10px">{{successfully}} {{subject}} successfully uploaded</p>
         <p style="color: #ff0000b6;padding: 10px">{{failed}} {{subject}} failed to upload</p>    
    </div>`
})
export class UploadOutcomeComponent implements OnInit {

    @Input() title: string;
    @Input() failed: number;
    @Input() successfully: number;
    @Input() subject: string;

    constructor(
        public dialogRef: MatDialogRef<UploadOutcomeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() { 
        console.log('DIALOG DATA', this.data);
        this.failed = this.data.failed;
        this.successfully = this.data.successfully;
        this.subject = this.data.subject;
        this.title = this.data.title;
    }

}