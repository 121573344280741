import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

import { VrmMultiSelectComponent } from '../vrm-multiselect/vrm-multi-select.component';

import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';

import { FilterChip, FilterColumn, FilterType, FloatStyle } from '../../model/filter';
import { LocationColsFilter } from '../../model/reports';

@Component({
    selector: 'vrm-filter-panel-component',
    templateUrl: 'vrm-filter-panel.component.html',
    styleUrls: ['vrm-filter-panel.component.scss'],

    animations: [
        trigger('expandFilters', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})

export class VrmFilterPanelComponent implements OnInit 
{
    @Input() metadata: any;
    @Input() isDashboard: boolean = false;
    @Input() floatedButtonStyle: FloatStyle = null;
    floatedButtonTop: string;

    @Output() filterChange = new EventEmitter();
    @Output() clearAll = new EventEmitter();
    @Output() columnsChange = new EventEmitter();

    @ViewChildren('filterMultiSelect') filterMultiSelect: QueryList<VrmMultiSelectComponent>;

    showFilters: boolean = false;
    persistedFilters: Array<FilterColumn> = [];
    persistedDynamicColumn: FilterColumn;

    appliedFilterChips: Array<FilterChip> = [];
    filtersApplied: FilterChip[] = [];

    locationColumns: LocationColsFilter[] = [];
    dynamicLocationColumns: FilterColumn[] = [];
    genericColumns: LocationColsFilter[] = [];

    dataTableSearchForm: FormGroup;

    constructor(
        private translate: TranslateService,
        public cService: CommonService, 
        public rptService: ReportsService,
    ) { }

    ngOnInit() {
        this.persistedFilters = (this.rptService.currentFilterToPersist.length > 0) ? this.rptService.currentFilterToPersist : [];
        if (this.rptService.currentDynamicColumn) { this.persistedDynamicColumn = this.rptService.currentDynamicColumn; }
        
        this.dataTableSearchForm = new FormGroup({
            searchDriver: new FormControl()
        });

        this.dataTableSearchForm.get('searchDriver').valueChanges.subscribe({
            next: (searchText) => {
                // this.rptService.searchOrderPageData.search = searchText;
                if (searchText !== undefined || searchText !== null) {
                    if (searchText === '') { this.triggerSearch(); }
                }
            }
        });

        if (this.metadata) {
            this.locationColumns = this.metadata.locFilters;
            this.genericColumns = this.metadata.genFilters;
            this.dynamicLocationColumns = this.generateLocationAsFilterType();
        }

        this.processFilterChips(true);

        if (this.floatedButtonStyle) {
            this.floatedButtonTop = this.floatedButtonStyle.top;
        }
    }

    triggerSearch(): void {
        this.filterChange.emit();

        // this.rptService.searchOrderPageData.searchApplied = false;
        // if (this.rptService.searchOrderPageData.search) {
        //     this.rptService.searchOrderPageData.searchApplied = true;
        // }

        this.processFilterChips();
    }

    clearSearch() {
        this.dataTableSearchForm.reset();
        // this.rptService.searchOrderPageData.searchApplied = false;
        this.triggerSearch();
    }

    toggleFilterPanel() {
        this.showFilters = !this.showFilters;

        if (this.showFilters) {
            this.processFilterChips(true);

            if (this.floatedButtonStyle) {
                this.floatedButtonTop = (this.floatedButtonStyle.topPosOnOpen) ? 
                    this.floatedButtonStyle.topPosOnOpen : this.floatedButtonStyle.top;
            }
        }
        else {
            if (this.floatedButtonStyle) { this.floatedButtonTop = this.floatedButtonStyle.top; }
        }
    }

    changeColumnsDisplayed(selectedColumns: FilterColumn) {
        this.columnsChange.emit(selectedColumns);
    }

    triggerFilterChanged(filter: FilterColumn): void {
        this.filterChange.emit(filter);
        this.processFilterChips();
    }

    processFilterChips(onFilterLoad?: boolean)
    {
        this.appliedFilterChips = [];

        // Search
        // const search: FilterChip;
        // if (this.rptService.searchOrderPageData.search && this.rptService.searchOrderPageData.searchApplied) {
        //     search = {
        //         colName: 'search',
        //         text: `${this.cService.readyTranslations.generic.search.toUpperCase()} "${this.rptService.searchOrderPageData.search}"`,
        //         search: this.rptService.searchOrderPageData.search
        //     }

        //     this.appliedFilterChips.unshift(search);
        // }

        // Filters
        if (this.rptService.currentFilterToPersist.length > 0) 
        {
            this.rptService.currentFilterToPersist.forEach((filter) => {
                const filterChip: FilterChip = {
                    colName: filter.colName,
                    filters: filter.filters,
                    text: filter.text.toUpperCase(),
                    tooltip: this.getFilterTooltip(filter.filters),
                    groupCount: filter.filters.length
                };

                const colGroupExists = this.appliedFilterChips.find(
                    col => col.colName.toLowerCase() == filterChip.colName.toLowerCase());

                if (colGroupExists) {
                    // Remove and replace below
                    this.appliedFilterChips = this.appliedFilterChips.filter(
                        chip => chip.colName.toLowerCase() != filterChip.colName.toLowerCase());
                }

                this.appliedFilterChips.unshift(filterChip);
            });

            if (onFilterLoad) {
                setTimeout(() => {
                    this.appliedFilterChips.forEach((filterChip) => {
                        const activeMultiselect: VrmMultiSelectComponent = this.filterMultiSelect.find(
                            item => item.columnFilter.colName.toLowerCase() == filterChip.colName.toLowerCase());
    
                        if (activeMultiselect) { 
                            if (activeMultiselect.columnFilter.colName.toLowerCase() == filterChip.colName.toLowerCase()) {
                                activeMultiselect.selectedItems = filterChip.filters;
                                activeMultiselect.setCurrentSelections();
                            }
                        }
                    });
                });
            }
        }
    }

    removeFilterChips(chip?: FilterChip) {
        if (chip) {
            if (!chip.search) {
                this.appliedFilterChips = this.appliedFilterChips.filter(chipItem => chipItem.colName != chip.colName);
                this.rptService.currentFilterToPersist = this.rptService.currentFilterToPersist.filter(filter => filter.colName != chip.colName);
                
                console.log('Filters to persist', this.rptService.currentFilterToPersist);
                
                if (this.rptService.currentFilterToPersist.length > 0) {
                    // this.rptService.unsetMissingFilters();
                }
                else {
                    // this.rptService.clearFilters(true);
                }
    
                const activeMultiselect: VrmMultiSelectComponent = this.filterMultiSelect.find(item => item.columnFilter.colName === chip.colName);
                activeMultiselect.deselectAll();
                
                this.filterChange.emit();
            }
            else {
                this.appliedFilterChips = this.appliedFilterChips.filter(chipItem => chipItem.colName != chip.colName);
                // this.rptService.clearSearch();
                this.filterChange.emit();
            }
        }
        else {
            // this.rptService.availableFilterKeys.forEach((filterKey) => {
            //     const activeMultiselect: VrmMultiSelectComponent = this.filterMultiSelect.find(
            //         item => item.columnFilter.colName.toLowerCase() === filterKey.toLowerCase());

            //     if (activeMultiselect) {
            //         activeMultiselect.deselectAll();
            //     }
            // });

            this.appliedFilterChips = [];
            // this.rptService.clearFilters(false);
            this.filterChange.emit();
        }
    }

    getFilterTooltip(filters: Array<FilterType>): string {
        let tooltip = '';

        if (filters.length > 0) {
            filters.forEach((filter) => {
                if (filter.text) {
                    tooltip += `${filter.text} \n`;
                }
            });
        }

        return tooltip.trim();
    }

    generateLocationAsFilterType(): Array<FilterColumn> {
        let locationList = [];
        const locationFilters: Array<FilterColumn> = this.metadata.locFilters;

        locationList = locationFilters.map(col => ({ text: col.text, value: col.colName }));
        return locationList;
    }

    trackByColNameFn(filter: FilterColumn) {
        return filter.colName;
    }
}
