import { Pipe, PipeTransform } from '@angular/core';

import { Config } from '../config';

import * as moment from 'moment';

@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
    transform(value: Date | number | string, momentFormat?: string) {
        const dateFormat: string = (momentFormat) ? momentFormat : Config.momentDateFormatUs;
        return moment(value).format(dateFormat);
    }
}