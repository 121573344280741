// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Third part
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'upload-users-dialog',
  templateUrl: './upload-users.component.html',
  styleUrls: [
    './upload-users.component.scss'
  ]
})

export class UploadUsersComponent implements OnInit {

  dialogTitle: string = this.translate.instant('GENERIC').uploadElements;
  dialogTitleDescription: string = '';
  showDetailsBufferProgress: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<UploadUsersComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    
    ngOnInit() { 
    }

}