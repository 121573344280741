import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface Direction {
    direction: 'next' | 'previous';
}

@Component({
    selector: 'simple-client-paginator',
    template: `
        <div fxLayout="row" fxLayoutAlign="space-between" class="simple-paginator">

            <button [disabled]="selectedPage === 1"
                mat-button type="button" color="primary" class="sharp-corners"
                (click)="changeToSelectedPage(null, 'previous')" *ngIf="!showNumberSelectorOnly">
                <mat-icon class="align-icon-to-text">chevron_left</mat-icon>
                {{ 'PAGING.previous' | translate }} 
            </button>

            <div fxFlex>
                <button mat-button type="button" fxFlex class="sharp-corners"
                    [matMenuTriggerFor]="availablePages">
                    {{ selectedPage }} {{ 'PAGING.of' | translate }} {{ pages.length }} 
                    <mat-icon class="align-icon-to-text">arrow_drop_down</mat-icon>
                </button>
                <mat-menu #availablePages [overlapTrigger]="false">
                    <button [disabled]="(i + 1) === selectedPage"
                        type="button" mat-menu-item style="text-align: center"
                        *ngFor="let page of pages; let i = index"
                        (click)="changeToSelectedPage(i + 1)">
                        {{ i + 1 }}
                    </button>
                </mat-menu>
            </div>

            <button [disabled]="selectedPage === pages.length"
                mat-button type="button" color="primary" class="sharp-corners"
                *ngIf="!showNumberSelectorOnly"
                (click)="changeToSelectedPage(null, 'next')">
                {{ 'PAGING.next' | translate }}
                <mat-icon class="align-icon-to-text">chevron_right</mat-icon> 
            </button>

        </div>
    `,
    styles: [`
        .align-icon-to-text {
            position: relative;
            bottom: 2px;
        }
        :host ::ng-deep button[disabled] {
            background: none !important;
            border: none !important;
            color: rgba(0, 0, 0, 0.26) !important;
        }
    `]
})
export class SimpleClientPaginatorComponent implements OnInit
{
    @Input() pages: Array<any> = [];
    @Input() showNumberSelectorOnly: boolean = false;
    @Input() selectedPage: number = 1;

    @Output() goToNextPage = new EventEmitter<number>();
    @Output() goToPrevPage = new EventEmitter<number>();
    @Output() switchPageToSelectedNumber = new EventEmitter<number>();

    ngOnInit(): void {
        this.pages = Array(this.pages);
    }

    changeToSelectedPage(page?: number, direction?: Direction | string) {
        if (page) {
            this.selectedPage = page;
            this.switchPageToSelectedNumber.emit(this.selectedPage);
        }
        else {
            if (direction && direction === 'next') {
                if (this.selectedPage !== this.pages.length) {
                    this.selectedPage++;
                    this.goToNextPage.emit(this.selectedPage);
                }
            }
            else {
                // Assume previous
                if (this.selectedPage !== 1) {
                    this.selectedPage--;
                    this.goToPrevPage.emit(this.selectedPage);
                }
            }
        }
    }
}