import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'concatenate'
})
export class ConcatenateText implements PipeTransform {
    transform(text: string, quantityAllowed: number)
    {
        let concatenatedText = text;

        if (Number(quantityAllowed) && text) {
            if (text.split('').length > quantityAllowed) {
                concatenatedText = text.trim().substring(0, quantityAllowed) + ' . .';
            }
        }

        return concatenatedText;
    }
}