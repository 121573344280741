import { Pipe, PipeTransform } from '@angular/core';

import { CommonService } from '../services/common.service';

@Pipe({
    name: 'determineElementProps'
})
export class DetermineElementProps implements PipeTransform 
{
    constructor(private cService: CommonService) { }

    transform(value: string, propertyRequested: string)
    {
        let newElementValues: { colorName: string, riskLevel: string, className: string };
        let requestedProperty: any;

        const colorCodes = {
            black: { 
                colorName: 'Black', 
                riskLevel: this.cService.readyTranslations.reportData.veryHigh, 
                className: 'black-background' 
            },
            red: { 
                colorName: 'Red', 
                riskLevel: this.cService.readyTranslations.reportData.high, 
                className: 'red-background' 
            },
            yellow: { 
                colorName: 'Yellow', 
                riskLevel: this.cService.readyTranslations.reportData.medium, 
                className: 'amber-background' 
            },
            green: { 
                colorName: 'Green', 
                riskLevel: this.cService.readyTranslations.reportData.low, 
                className: 'green-background' 
            }
        };

        if (value) {
            if (value.toLowerCase().trim() === 'black' || value.toLowerCase().trim() === 'very high') {
                newElementValues = colorCodes.black;
            }

            if (value.toLowerCase().trim() === 'red' || value.toLowerCase().trim() === 'high') {
                newElementValues = colorCodes.red;
            }

            if (value.toLowerCase().trim() === 'yellow' || value.toLowerCase().trim() === 'amber' 
                || value.toLowerCase().trim() === 'medium' || value.toLowerCase().trim() === 'orange') {
                newElementValues = colorCodes.yellow;
            }

            if (value.toLowerCase().trim() === 'green' || value.toLowerCase().trim() === 'low') {
                newElementValues = colorCodes.green;
            }
        }

        if (propertyRequested.toLowerCase() === 'classname') {
            requestedProperty = newElementValues.className;
        }
        else if (propertyRequested.toLowerCase() === 'risklevel') {
            requestedProperty = newElementValues.riskLevel;
        }
        else if (requestedProperty.toLowerCase() === 'colorname' || requestedProperty.toLowerCase() === 'colourname') {
            requestedProperty = newElementValues.colorName;
        }
        else {
            requestedProperty = newElementValues;
        }

        return requestedProperty;
    }
}
