import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'logout-button',
    template: `
    <button type="button" mat-icon-button (click)="logout()" *ngIf="iconOnly; else matMenuItem" 
        aria-label="logout" matTooltip="Logout">
        <mat-icon>logout</mat-icon>
    </button>

    <ng-template #matMenuItem>
        <button type="button" mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>{{ 'OPTIONS.logout' | translate }}</span>
        </button>
    </ng-template>
    `,
    styles: []
})
export class LogoutButtonComponent
{
    @Input() iconOnly: boolean = false;

    @Output() onLogout = new EventEmitter();

    logout() {
        this.onLogout.emit();
    }
}