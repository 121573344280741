import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellClassParams } from 'ag-grid-community';

import { DrilldownFormsService } from '../../services/drilldown-forms.service';
import { CasualReportService } from '../../services/casual-report.service';

@Component({
    selector: 'action-option-button',
    template: `
    <span fxFlex>
        <button type="button" mat-button fxFlex class="bold grid-text" style="text-decoration: underline" vrm-color
            (click)="triggerLinkButtonAction()" *ngIf="value">
            {{ value }}
        </button>

        <!--
        <button type="button" mat-icon-button style="height: 30px; position: relative; bottom: 6px" [matMenuTriggerFor]="moreOptions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #moreOptions [overlapTrigger]="false">
            <button type="button" mat-menu-item (click)="triggerLinkButtonAction('options-print')">
                <mat-icon>print</mat-icon>
                {{ 'GENERIC.print' | translate }}
            </button>
            <button type="button" mat-menu-item (click)="triggerLinkButtonAction('options-email')">
                <mat-icon>email</mat-icon>
                {{ 'GENERIC.sendViaEmail' | translate }}
            </button>
            <button type="button" mat-menu-item (click)="triggerLinkButtonAction('options-delete')">
                <mat-icon style="color: red">delete</mat-icon>
                {{ 'GENERIC.deleteSelected' | translate }}
            </button>
        </mat-menu>
        -->
    </span>
    `,
    styles: []
})
export class ActionOptionButtonCasualReportComponent implements ICellRendererAngularComp
{
    public value: string;
    
    constructor(
        private dfService: DrilldownFormsService,
        private crService: CasualReportService
    ) {}

    agInit(params: CellClassParams) {
        this.value = params.value;
    }

    triggerLinkButtonAction(triggeredButton?: string) {
        setTimeout(() => {
            if (triggeredButton === 'options-print') {
                console.log('%c PRINT', 'color: salmon');
            }
            else if (triggeredButton === 'options-email') {
                console.log('%c SEND AS EMAIL', 'color: salmon');
            }
            else if (triggeredButton === 'options-delete') {
                console.log('%c DELETE SELECTED', 'color: salmon');
            }
            else {
                // Assume main action was triggered
                if (this.crService.selectedRows.length === 1) {
                    this.dfService.launchSelectedIntervention(
                        this.crService.selectedRows[0]);
                }
            }
        }, 100); // <-- Wait for selectedRow to be up-to-date
    }

    refresh(): boolean {
        return false;
    }
}