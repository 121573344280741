import { Component } from '@angular/core';

import { CommonService, PromptDialogConfig } from '../../services/common.service';

@Component({
    selector: 'prompt-dialog',
    templateUrl: './prompt-with-action.component.html',
    styleUrls: ['./prompt-with-action.component.scss']
})
export class PromptWithActionDialogComponent
{
    promptConfig: PromptDialogConfig;

    constructor(private cService: CommonService) {
        this.promptConfig = this.cService.promptConfig;
    }
}