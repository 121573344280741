import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { CommonService, NumericRatingToTextEnum } from '../../services/common.service';

@Component({
    selector: 'mask-complete-incomplete-notstarted',
    template: `
    <span>{{ value }}</span>
    `,
    styles: []
})
export class MaskCompleteIncompleteNotstartedRatingComponent implements ICellRendererAngularComp
{
    value: string;

    constructor(private cService: CommonService) { }

    agInit(params: { value: any }) {
        this.value = this.cService.mapNumericRatingToText(
            NumericRatingToTextEnum.completeIncompleteNotStartedNa, params.value);
    }

    refresh() { return false; }
}