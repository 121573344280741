import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'check-updates-button',
    template: `
    <button type="button" mat-menu-item (click)="manuallyCheckUpdates()">
        <div>
            <!-- <mat-icon>update</mat-icon> {{ 'OPTIONS.checkUpdates' | translate }} -->
            {{ 'GENERIC.version' | translate }}: {{ appVersion }}
        </div>
        <!--
        <small style="position: relative; bottom: 32px; left: 58px;">
            {{ 'GENERIC.version' | translate }}: {{ appVersion }}
        </small>
        -->
    </button>
    `,
    styles: []
})
export class CheckUpdatesButtonComponent
{
    @Input() appVersion: string;
    @Output() onCheckUpdates = new EventEmitter();

    manuallyCheckUpdates() {
        this.onCheckUpdates.emit();
    }
}