import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DrilldownFormsService, PccCrash } from '../../services/drilldown-forms.service';
import { CommonService } from '../../services/common.service';
import { ReportsService, ReportMeta } from '../../services/reports.service';

import { ReviewPeriod } from 'vrm-forms';
import { SidepaneItem } from '../../model/dialogs';
import { OtherMiniReportOptions } from 'src/app/model/reports';

interface DialogData { 
  selectedRow: any; 
  reportEndpointName: string;
  autoExpandCoachForm: boolean;
}

interface DynamicFormsEvent {
  formData: any; 
  submissionId?: string; 
  formComplete?: boolean;
  triggeredByAutosave: boolean;
}

interface Intervention { 
  name: string; 
  value: string; 
  allowUserCreation?: boolean;
}

@Component({
  selector: 'driver-index-dialog',
  templateUrl: './driver-index.component.html',
  styleUrls: ['./driver-index.component.scss']
})
export class DriverIndexComponent implements OnInit
{
  firstLastNames: string;
  nameContainerWidth: string;
  historyTabDataFailedOnLoad: boolean = false;

  showDetailsBufferProgress: boolean = false;
  sidepaneItems: Array<SidepaneItem> = [];
  reportEndpointName: string;

  applicableCoachingInterventions: Array<Intervention> = [];
  pccReviewCrashes: Array<PccCrash> = [];
  canCreateAnIntervention: boolean = false;

  coachingHistoryMeta: ReportMeta;
  coachingHistory: Array<any>;
  reportOptions: OtherMiniReportOptions = {
    reportHeight: '100%'
  };

  reviewPeriods: Array<ReviewPeriod> = [];
  outstandingReviews: number;
  outstandingPccReviews: number;
  outstandingOneToOnePlusReviews: number;
  selectedCoachFormData: any;
  selectedPeriod: any;
  coachFormIsReadonly: boolean = false;
  dialogDataReady: boolean = false;
  renderMultipleCharts: boolean = false;
  formDataUpdated: boolean = false;

  driverIndexSummaryChart: any;
  mentorCharts: Array<any> = [];
  tableauChartsUrl: string;

  @ViewChild('tableauDashboardIframe', { static: false })
  tableauDashboardIframe: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<DriverIndexComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService,
    public dfService: DrilldownFormsService,
    public cService: CommonService,
    public rptService: ReportsService
  ) { }

  ngOnInit() {
    console.log('DIALOG DATA', this.data);
    this.dfService.selectedDriver = this.data.selectedRow;

    this.initialize();
  }

  initialize() {
    this.showDetailsBufferProgress = true;
    this.reportEndpointName = this.data.reportEndpointName;
    this.dfService.currentTabIndex = this.dfService.drilldownTabs.insight;

    this.updateFirstLastNames();
    this.nameContainerWidth = this.cService.predictMinWidthInPixelByWord(this.firstLastNames) + 'px';

    this.collateSidepaneItems();

    this.fetchData(this.dfService.selectedDriver.pin);

    this.dialogRef.beforeClosed().subscribe({
      next: () => {
        if (this.formDataUpdated) {
          this.rptService.triggerReportEndpointFilterChangedObservable(this.rptService.endpointFilterValue);
          this.formDataUpdated = false;
        }

        this.dfService.closeFormPane();
      }
    });

    if (!this.cService.isPcViewport) {
      this.toggleFormPaneWidth();
    }
  }

  // EVENTS . METHODS
  toggleFormPaneWidth() {
    if (this.dfService.showFormPane) {
      this.dfService.widenForm = !this.dfService.widenForm;
    }
    else {
      this.dfService.widenForm = false;
    }
  }

  selectedTabChanged(selectedTab: { index: number }) {
    if ((this.historyTabDataFailedOnLoad || this.dfService.refreshHistoryOnTabSwitch) && selectedTab.index == 1) {
      this.getCoachingHistoryTabData();
    }

    // Tableau test disabled for now
    // this.getTableauCharts();
  }

  newItemAddedToPanel(newItem: any) {
    this.collateSidepaneItems(newItem);
  }

  updateFirstLastNames() {
    this.firstLastNames = this.dfService.selectedDriver.firstName + ' ' + this.dfService.selectedDriver.lastName;
  }

  // METHODS
  private getTransformedMonth(): string {
    let month = '';
    const currentMonth = new Date().getMonth() + 1;
    month = (currentMonth < 10) ? month = '0' + currentMonth : currentMonth.toString();

    return month;
  }

  private pushSidepaneItem(sidepaneItem: any) {
    this.sidepaneItems.unshift({
      title: `${sidepaneItem.firstName + ' ' + sidepaneItem.lastName}`,
      pin: sidepaneItem.pin,
      description: sidepaneItem.pin,
      data: sidepaneItem
    });
  }
  private collateSidepaneItems(newItem?: any) {
    /**
     * NOTE: The vrm-mentor-reports component already ensured that
     * selectedRow defaults to the first array item in case of multiple edit.
     */
    if (this.rptService.selectedRows.length > 0) {
      // Collate sidepane items
      if (newItem) {
        const alreadySelectedInGrid = this.rptService.selectedRows.find(item => item.pin === newItem.pin);
        if (!alreadySelectedInGrid) {
          this.rptService.selectedRows.push(newItem);
          this.rptService.addNewlySelectedRowAndTriggerObservable(newItem);
        }

        this.dfService.fetchOutstandingInterventionsPerDriver([newItem.pin]).subscribe({
          next: (response) => {
            newItem.outstanding = response.data[0].outstanding;
            this.pushSidepaneItem(newItem);
          },

          error: () => {
            this.pushSidepaneItem(newItem);
            this.cService.showToast(`:: ${this.cService.readyTranslations.messagesAndWarnings.failedToFetchData}`);
          }
        });
      }
      else {
        const batchPins: Array<any> = this.rptService.selectedRows.map(row => row.pin);

        this.dfService.fetchOutstandingInterventionsPerDriver(batchPins).subscribe({
          next: (response: { data: Array<any> }) => {
            this.rptService.selectedRows.forEach(selectedData => {
              selectedData.outstanding = response.data.find(item => item.pin == selectedData.pin).outstanding;
              this.pushSidepaneItem(selectedData);
            });
          },

          error: () => {
            this.rptService.selectedRows.forEach(selectedData => {
              this.pushSidepaneItem(selectedData);
            });
            this.cService.showToast(`:: ${this.cService.readyTranslations.messagesAndWarnings.failedToFetchData}`);
          }
        });
      }
    }
  }
  private updateSelectedInterventionOnComplete() {
    const pin: any = this.dfService.selectedDriver.pin;

    this.dfService.fetchOutstandingInterventionsPerDriver([pin]).subscribe({
      next: (response) => {
        this.sidepaneItems.find(item => item.pin == pin).data.outstanding = response.data[0].outstanding;
      }
    });
  }

  paneItemSelected(event: { pin: any; data: any }) 
  {
      console.log(':: DIAGNOSTIC DATA', { event: event, dialogData: this.data });
      const selectedRowPin = (this.dfService.selectedDriver) ? this.dfService.selectedDriver.pin : null;

      if (event && event.pin) {
         if ((event.pin !== selectedRowPin)) {
            this.dfService.closeFormPane();

            this.dfService.selectedDriver = this.rptService.selectedRows.find(itemData => itemData.pin == event.pin);
            this.updateFirstLastNames();
   
            this.fetchData(event.pin);
         }
      }
      else {
         console.error('VRM ERROR: pin or event.pin not defined, hence no request made to the API');
      }
  }

  // TODO: It is not currently possible to interact with the iFrame due to cross-origin issue.
  // This is because the host of the iFrame's URL does not match
  tableauIframeLoaded() {
    // console.log('%c **** Iframe CONTENT HEIGHT ****', 'color: lime', this.tableauDashboardIframe.nativeElement);

      // console.log('%c **** Iframe CONTENT HEIGHT ****', 'color: lime', 
      //   this.tableauDashboardIframe.nativeElement.contentWindow.document.body.scrollHeight);
  }
  private getTableauCharts() {
    if (!this.cService.productionInUse) {
      this.tableauChartsUrl = '';

      // Tableau Test
      this.dfService.getNewlyAuthenticatedTableauCharts().subscribe({
        next: (response: { token: any }) => {
          if (response.token && response.token != -1) {
            this.tableauChartsUrl = `https://tableau-staging.vrm-mentor.com/trusted/${response.token}/views/VRMDashboards/StatusReport-VRMDashboard?:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n&:embed=y`;
          }
          else {
            this.cService.showToast(':: Tableau Not Found');
          }
        },
  
        error: () => {
          this.cService.showToast(':: Failed to Fetch Tableau');
        }
      });
    }
  }

  private openProvisionedFormByDefault() {
    if (this.data.autoExpandCoachForm) {
        if (this.coachingHistory.length > 0) {

          const interventionToOpen = this.coachingHistory.find(
            intervention => intervention.status.toLowerCase() === 'new' &&
            intervention.interventionValue === this.dfService.driverImprovementInterventionValue);
  
            this.dfService.launchSelectedIntervention(interventionToOpen);
        }
    }
  }

  getCoachingHistoryTabData() {
    this.dfService.getCoachingHistoryTabDataForkJoined(this.dfService.selectedDriver.pin)
      .subscribe({
        next: (response) => {
          this.historyTabDataFailedOnLoad = false;

          this.applicableCoachingInterventions = response[0].data;
          this.pccReviewCrashes = response[1].data;
          
          this.coachingHistoryMeta = response[2];
          this.coachingHistory = response[3].data;

          const canCreateChildIntervention = 
            this.applicableCoachingInterventions.find(intervention => intervention.allowUserCreation == true);
          if (canCreateChildIntervention) {
            this.canCreateAnIntervention = true;
          }

          this.openProvisionedFormByDefault();

          this.dfService.refreshHistoryOnTabSwitch = false;
        },

        error: () => {
          this.historyTabDataFailedOnLoad = true;
        }
      });
  }

  fetchData(pin: string) 
  {
    this.dialogDataReady = false;

    this.dfService.getEventSummaryForkJoined(pin).subscribe({
      next: (response) => {
        this.showDetailsBufferProgress = false;

        const activeEventSummaryData = response[0];
        const historicEventSummaryData = response[1];

        this.dfService.selectedDriver.activeEventSummary = activeEventSummaryData;
        this.dfService.selectedDriver.historicEventSummary = historicEventSummaryData;
      },

      error: () => {
        this.showDetailsBufferProgress = false;
      }
    });

    // Tableau Test
    // this.getTableauCharts();

    // Coach Form - ForkJoined Summaries
    this.dfService.getCoachSummariesForkJoined(pin).subscribe({
      next: (resp) => {
        const chartSummary = resp[0];
        const coachSummary = resp[1];
        const mentorCharts = resp[2];

        this.showDetailsBufferProgress = false;
        
        console.log('COACH SUMMARY Data Response', coachSummary);

        this.reviewPeriods = coachSummary.data[0].dates;
        this.driverIndexSummaryChart = chartSummary;

        // Translate mentor chart titles
        if (mentorCharts.length > 0) {
          this.translate.get('CHART').subscribe({
            next: (translation) => {
              mentorCharts.forEach((mentorChart: any) => {
                const translationText = translation[mentorChart.options.title.text];
                if (translationText) {
                  mentorChart.options.title.translatedText = translationText;
                }
              });

              this.mentorCharts = mentorCharts;
            }
          });
        }

        console.log('Review periods', this.reviewPeriods);

        this.outstandingReviews = coachSummary.data[0].outstandingReviews;
        console.log('Outstanding reviews', this.outstandingReviews);
        setTimeout(() => {
          this.dialogDataReady = true;
        });

        if (this.mentorCharts.length > 0) {
          setTimeout(() => {
            this.renderMultipleCharts = true;
          },
          2000);
        }

        // console.log('Fork Joined Summary Response', resp);
        console.log('Chart Summary', chartSummary);
        console.log('Mentor charts', mentorCharts);
      }
    });

    this.getCoachingHistoryTabData();
  }

  submitDynamicForm(event: DynamicFormsEvent) {
   console.log('%c On-Save Form Event', 'color: lightblue', event);

    if (event && event.formData) {
        const formName = this.dfService.currentFormStruct.metadata.name;
        const employeePin = this.dfService.selectedDriver.pin;

        delete event.formComplete;
        event.submissionId = (this.dfService.currentFormSubmissionId) 
        ? this.dfService.currentFormSubmissionId : '';

        this.saveDynamicForm(formName, employeePin, event);
    }
  }
  private saveDynamicForm(formName: string, employeePin: string, event: any) {
    this.dfService.saveDynamicForm(formName, employeePin, event).subscribe({
      next: (response: { submissionId: string }) => {
        console.log('%c Saved Dynamic Form RESPONSE', 'color: salmon', response);

        if (!this.dfService.currentFormSubmissionId && response.submissionId) {
          this.dfService.currentFormSubmissionId = response.submissionId;
        }

        this.dfService.refreshHistoryOnTabSwitch = true;

        this.cService.showToast(`
          ${this.dfService.launchedIntervention}: ${this.cService.readyTranslations.form.formSaved}`);
      },

      error: () => {
          this.cService.showToast(`${this.dfService.launchedIntervention}: 
            ${this.cService.readyTranslations.form.failedToSaveFormData}`);
      }
    });
  }

  formMayNowBeMarkedAsComplete(event: DynamicFormsEvent) {
    // TODO: If DriverPerformanceReview form, then mark as complete without prompt. Otherwise, prompt
    // TODO: For now though, mark all forms as complete in the background. This is now only triggered when the user clicks "Save"
    console.log('%c Form Complete Triggered', 'color: yellow', event);

    if (event && event.formData) {
      const formName = this.dfService.currentFormStruct.metadata.name;
      const employeePin = this.dfService.selectedDriver.pin;

      event.submissionId = (this.dfService.currentFormSubmissionId) ? this.dfService.currentFormSubmissionId : '';
      event.formComplete = true;

      this.dfService.saveDynamicForm(formName, employeePin, event).subscribe({
        next: (response) => {
          console.log('%c Form Complete RESPONSE', 'color: salmon', response);

          if (response.submissionId) {
            this.dfService.currentFormSubmissionId = response.submissionId;
          }

          this.dfService.refreshHistoryOnTabSwitch = true;
          this.updateSelectedInterventionOnComplete();
        },

        error: () => {
            this.cService.showToast(`${this.dfService.launchedIntervention}: 
              ${this.cService.readyTranslations.form.failedToSaveFormData}`);
        }
      });
    }
  }
}
