import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ 
    name: 'trustAsSafeHtml'
 })
 export class TrustAsSafeHtmlPipe implements PipeTransform
 {
    constructor(private sanitizer: DomSanitizer) {}

    transform(unTrustedHtml: string) {
        return this.sanitizer.bypassSecurityTrustHtml(unTrustedHtml);
    }
 }