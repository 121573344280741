import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HttpHeaders, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Config } from '../config';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class DriverHqService {

  constructor(private common: CommonService, private http: HttpClient, public jwtHelper: JwtHelperService, private domSanitizer: DomSanitizer) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': "en_US'",
    });
    this.options = { headers: this.headers };
  
  
  }
  headers: HttpHeaders;
  options: any;

  dummy_data:any;
  getTrainingList(): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': "en_US'",
    });
    let options = { headers: headers };
    return this.http.get(Config.apiRoot.uri + `api/v2/users/${this.common.userProfile.userId}/modules?includeResult=TRUE&includeStart=TRUE`,
      options).pipe(
        map(response => {
          console.log(response)
          return response;
        }));
  }
  getModuleUrl(productCode, returnUrl): Observable<any> {
    return this.http.post(Config.apiRoot.uri + `api/v2/users/${this.common.userProfile.userId}/modules/${encodeURIComponent(productCode)}/url`+ (returnUrl?`?returnUrl=${encodeURIComponent(returnUrl)}`:''),
      this.options).pipe(
        map(response => {
          console.log(response)
          return response;
        }));
  }

  getActiveEventSummary(pin?: string): Observable<any> {
    ///driverhq/api/v1/driverindex/active_event_summary/:pin
    return this.http.get(`${Config.apiRoot.uri}api/v1/driverindex/active_event_summary/${pin}`, this.options)
      .pipe(
        map(response => {
          console.log('Active Event Summary response', response);
          return response;
        })
      );
  }
  //https://mis-api-staging.vrm-mentor.com/forms/data/DriverImprovementReview/MEN396941/2020-07-21
  getHistoricEventSummary(pin?: string): Observable<any> {
    return this.http.get(`${Config.apiRoot.uri}api/v1/driverindex/historic_event_summary/${pin}`, this.options)
      .pipe(
        map(response => {
          console.log('Historic Event Summary response', response);
          return response;
        })
      );
  }

  getEventSummaryForkJoined(pin: string): Observable<any> {
    pin = '';
    return forkJoin([
      this.getActiveEventSummary(pin),
      this.getHistoricEventSummary(pin)
    ]);
  }

  getDriverIndexSummary(pin: string): Observable<any> {
    return this.http.get(`${Config.apiRoot.uri}api/v1/charts/driverindex/summary/${pin}`, this.options)
      .pipe(
        map(response => {
          console.log('DriverINDEX Summary response', response);
          return response;
        })
      );
  }
  getDriverIndexCoachSummary(pin: string): Observable<any> {
    pin = '';
    return this.http.get(`${Config.apiRoot.uri}api/v1/driverindex/coach_summary/${pin}`, this.options)
      .pipe(
        map(response => {
          console.log('Driver Index Coach Summary!', response);
          return response;
        })
      );
  }
  getMentorCharts(pin: string): Observable<any> {
    pin = '';
    return this.http.get(`${Config.apiRoot.uri}api/v1/charts/driverindex/mentor/${pin}`, this.options)
      .pipe(
        map(response => {
          console.log('Mentor Charts');
          return response;
        })
      );
  }
  getCoachSummariesForkJoined(pin: string): Observable<any> {
    pin = '';
    return forkJoin([
      this.getDriverIndexSummary(pin),
      this.getDriverIndexCoachSummary(pin),
      this.getMentorCharts(pin)
    ]);
  }





  getCoachingHistoryTabDataForkJoined(employeePin: any): Observable<any> {

    return forkJoin([
      this.getCoachingHistoryMeta(),
      this.getCoachingHistory(employeePin)
    ]);
  }


  getCoachingHistoryMeta(): Observable<any> {

    return this.http.get(Config.apiRoot.uri + `api/v1/coaching_interventions/history_meta`,
      this.options).pipe(
        map(response => {
          console.log(response)
          return response;
        }));
  }
  getCoachingHistory(pin: string): Observable<any> {
    pin = '';
    return this.http.get(Config.apiRoot.uri + `api/v1/coaching_interventions/history/${pin}`,
      this.options).pipe(
        map(response => {
          console.log(response)
          return response;
        }));
  }
  parseJwt(token) {
    return this.jwtHelper.decodeToken(token)
  }

  getGuideList(): Observable<any> {
    var parsed = this.parseJwt(this.common.userProfile.token);
    return this.http.get(Config.apiRoot.uri + `api/v1/companies/${parsed.vrmUser.companyId}/faq/generic`,
      this.options).pipe(
        map(response => {
          console.log(response)
          return response;
        }));
  }


  // getTrainingModuleById(moduleName: string, moduleId: number, extension: string): Observable<any> {
  //   return this.http.get(`${Config.apiRoot.uri}faq/${moduleName}/${moduleId}`, {
  //     headers: {
  //       vrmCustom_requestName: 'Training Module',
  //     },
  //     responseType: 'blob' as 'json'
  //   }).pipe(
  //     map(response => {
  //       console.log('%c Requested Training Module - ArrayBuffer', 'color: salmon', response);

  //       const binaryData = [];
  //       let streamLink = '';

  //       const contentType: string = this.matchExtensionToMimetype[extension];
  //       if (!contentType) {
  //         this.cService.showToast(
  //           ':: ' + this.cService.readyTranslations.messagesAndWarnings.fileMayNotWorkCorrectly);
  //         console.warn('VRM WARNING: This file may not DOWNLOAD or RENDER correctly due to missing ContentType');
  //       }

  //       binaryData.push(response);
  //       streamLink = window.URL.createObjectURL(new Blob(binaryData, {
  //         type: contentType
  //       }));

  //       return streamLink;
  //     })
  //   );
  // }
  //
  matchExtensionToMimetype = {
    doc: 'application/msword',
    docx: 'application/msword',
    dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    pdf: 'application/pdf'
  };
  prepareDummyData() {

   
    let options = { responseType: 'blob' as 'json'};
   
    this.http.get("../../assets/sampledata/samplePdf.pdf",options).subscribe(
      data => {
        this.dummy_data = data;
        console.log('success', data);
      },
      error => console.log('oops', error)
    );
 
  }

  getGuideData(faqId: number, extension: string):Observable<any>{
    var parsed = this.parseJwt(this.common.userProfile.token);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': "en_US'",
    });
    let options = { headers: headers, responseType: 'blob' as 'json' };
  
    return this.http.get(Config.apiRoot.uri + `api/v1/companies/${parsed.vrmUser.companyId}/faq/generic/${faqId}`,
      options).pipe(
        map(response => {
        
          console.log('%c Requested Training Module - ArrayBuffer', 'color: salmon', response);

          const binaryData = [];
          let streamLink = '';

          const contentType: string = this.matchExtensionToMimetype[extension];
          if (!contentType) {
            this.common.showToast(
              ':: ' + this.common.readyTranslations.messagesAndWarnings.fileMayNotWorkCorrectly);
            console.warn('VRM WARNING: This file may not DOWNLOAD or RENDER correctly due to missing ContentType');
          }
          binaryData.push(response);
          streamLink = streamLink = window.URL.createObjectURL(new Blob(binaryData, {
            type: contentType
          }));

          return streamLink;
        })
      );
  }
  getDocumentList(): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': "en_US'",
    });
    let options = { headers: headers };
    let url = Config.apiRoot.uri + `api/v1/documents/company/${this.common.userProfile.companyCode}/employee/${this.common.userProfile.pin}`;
    return this.http.get(url,
      options).pipe(
        map(response => {
          console.log(response)
          return response;
        }));
  }
  getDocument(docId, fileType): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': "en_US'",
    });
    let url = Config.apiRoot.uri + `api/v1/documents/download/${docId}`;
    if (fileType == 'application/pdf') {
    return this.http.get(url,
      {
        headers: headers, responseType: 'blob' as 'json'
      }).pipe(
        map(response => {
          const binaryData = [];
          let streamLink = '';
          binaryData.push(response);
          streamLink = window.URL.createObjectURL(new Blob(binaryData, {
            type: fileType
          }));
          return streamLink;
        }
        )
      );
  }else{
      return this.http.get(url,
        {
          headers: headers, responseType: 'arraybuffer' 
        }).pipe(
          map( response => {
            let streamLink = '' as any;
            console.log(response)
            let TYPED_ARRAY = new Uint8Array(response);
           // console.log(TYPED_ARRAY);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '');

           // console.log(STRING_CHAR);
            let base64String = btoa(STRING_CHAR);
            //console.log(base64String);
           // in case if we want to display the image as is use below 
           // streamLink = this.domSanitizer.bypassSecurityTrustUrl('data: image / jpg; base64, ' + base64String);
//https://www.ngdevelop.tech/insert-image-from-url-in-pdf-using-pdfmake/
            streamLink = 'data:image/jpg;base64,' + base64String;
            return streamLink;
          }
          )
        );
  }
}
  downloadDocument(docId, fileType, fileName): Observable<any>  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': "en_US'",
    });
    let url = Config.apiRoot.uri + `api/v1/documents/download/${docId}`;
  
    return this.http.get(url,
        {
          headers: headers, responseType: 'blob' 
        }).pipe(
          map(response => {
            saveAs(response, fileName);
          }
          ));
        
    } 
          
  public deleteDocument(docId: number): Observable<HttpEvent<any>> {
    const url = Config.apiRoot.uri + `api/v1/documents/delete/${docId}`;
    const options = { headers: this.headers };
    const req = new HttpRequest('DELETE', url, {}, options);
    return this.http.request(req);
  }
 
    
  
}
