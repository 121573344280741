import { Component } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ReportsService } from 'src/app/services/reports.service';

export interface Company {
    value: string;
    viewValue: string;
  }

export interface Station {
    value: string;
    viewValue: string;
 }

@Component({
    selector: 'station-dsp-filter',
    templateUrl: './station-dsp-filter.component.html',
    styleUrls: ['./station-dsp-filter.component.scss']
})
export class StationDspFilterComponent {

    companies: Company[] = [
        {value: 'company1', viewValue: 'Company1'},
        {value: 'company2', viewValue: 'Company2'},
        {value: 'company3', viewValue: 'Company3'}
      ];

      stations: Station[] = [
        {value: 'station1', viewValue: 'Station1'},
        {value: 'station2', viewValue: 'Station2'},
        {value: 'station3', viewValue: 'Station3'}
      ];

    constructor(
        public rptService: ReportsService,
        private cService: CommonService,
    ) { }
      
    agInit(): void {
    }

}
