

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DrilldownFormsService } from '../../services/drilldown-forms.service';
import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';

import { ReviewPeriod } from 'vrm-forms';
import { SidepaneItem } from '../../model/dialogs';
import { DriverHqService } from 'src/app/services/driver-hq.service';


@Component({
  selector: 'vrm-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  showDetailsBufferProgress: boolean = false;
  sidepaneItems: Array<SidepaneItem> = [];
  reportEndpointName: string;

  reviewPeriods: Array<ReviewPeriod> = [];
  outstandingReviews: number;
  outstandingPccReviews: number;
  outstandingOneToOnePlusReviews: number;
  selectedCoachFormData: any;
  selectedPeriod: any;
  coachFormIsReadonly: boolean = false;
  dataReady: boolean = false;
  renderMultipleCharts: boolean = false;
  formIsVisible: boolean = false;
  formDataUpdated: boolean = false;



  dialogTitle: string = '';
  dialogTitleDescription: string = '';


  constructor(
    private translate: TranslateService,
    private dfService: DrilldownFormsService,
    public cService: CommonService,
    public rptService: ReportsService,
    private driverHqService: DriverHqService
  ) { }

  public data: any = { "selectedRow": { "firstName": "MNT89F", "lastName": "MNT89L", "pin": "MEN237888", "email": "MNT89@edriving.com", "location1": "London", "profileDriver": 0, "defensiveDriving": 0, "rating": 0, "completionDate": null }, "reportEndpointName": "roadrisk/driver_risk_rating" }


  ngOnInit() {
    console.log('DIALOG DATA', this.data);
    this.initialize();
  }

  initialize() {
    this.showDetailsBufferProgress = true;
    this.reportEndpointName = this.data.reportEndpointName;

    this.dialogTitle = this.translate.instant('FORM')['driverPerformanceRecord'];
    this.generateDialogDescription([
      this.data.selectedRow.firstName + ' ' + this.data.selectedRow.lastName,
      this.data.selectedRow.pin
    ]);



    this.fetchData(this.data.selectedRow.pin);


  }

  // EVENTS
  expansionPanelToggled(opened: boolean) {
    setTimeout(() => {
      this.formIsVisible = opened;
    });
  }




  // METHODS
  getTransformedMonth(): string {
    let month = '';
    const currentMonth = new Date().getMonth() + 1;
    month = (currentMonth < 10) ? month = '0' + currentMonth : currentMonth.toString();

    return month;
  }



  generateDialogDescription(texts: Array<string>) {
    let description: string = '';

    texts.forEach((text, key) => {
      const suffix = (key === texts.length - 1) ? '' : ' . ';
      description += text + suffix;
    });

    this.dialogTitleDescription = description;
  }

  fetchData(pin: string) {
    this.dataReady = false;

    this.driverHqService.getEventSummaryForkJoined(pin).subscribe({
      next: (response) => {
        this.showDetailsBufferProgress = false;

        const activeEventSummaryData = response[0];
        const historicEventSummaryData = response[1];

        this.data.selectedRow.activeEventSummary = activeEventSummaryData;
        this.data.selectedRow.historicEventSummary = historicEventSummaryData;
        setTimeout(() => {
          this.dataReady = true;
        });

      },

      error: () => {
        this.showDetailsBufferProgress = false;
      }
    });



  }


}
