import { Component } from '@angular/core';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'ag-cell-num-to-perc',
    template: `
    <span>{{ value }}</span>
    `
})
export class NumberToPercentageComponent
{
    value: string | number;

    constructor(private cService: CommonService) {}

    agInit(params: { value: any }): void {
        this.value = this.cService.displayNumberAsPercentage(params.value);
    }
}