import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { Config } from '../config';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class GuideAndBucketContentService 
{
    matchExtensionToMimetype = {
        doc: 'application/msword',
        docx: 'application/msword',
        dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        pdf: 'application/pdf'
    };

    currentFaqEndpointName: string;

    constructor(
        private http: HttpClient,
        private cService: CommonService
    ) { }

    private getTrainingModuleMeta(moduleName: string) {
        return this.http.get(`${Config.apiRoot.uri}faq/${moduleName}/meta`, { 
            headers: { vrmCustom_requestName: 'Training Module' } 
        })
    .pipe(
        map(response => {
            console.log('Training module meta - API', response);
            return response;
        })
        );
    }
    
    private getAllTrainingModules(moduleName: string): Observable<any> {
        return this.http.get(`${Config.apiRoot.uri}faq/${moduleName}`, { 
            headers: { vrmCustom_requestName: 'Training Module' } 
        })
    .pipe(
        map(response => {
            console.log('All Training Module', response);
            return response;
        })
        );
    }

    getTrainingModuleById(moduleName: string, moduleId: number, extension: string): Observable<any> {
        return this.http.get(`${Config.apiRoot.uri}faq/${moduleName}/${moduleId}`, { 
            headers: { 
                vrmCustom_requestName: 'Training Module',
            },
            responseType: 'blob' as 'json'
        }).pipe(
            map(response => {
                console.log('%c Requested Training Module - ArrayBuffer', 'color: salmon', response);
                
                const binaryData = [];
                let streamLink = '';

                const contentType: string = this.matchExtensionToMimetype[extension];
                if (!contentType) {
                    this.cService.showToast(
                        ':: ' + this.cService.readyTranslations.messagesAndWarnings.fileMayNotWorkCorrectly);
                    console.warn('VRM WARNING: This file may not DOWNLOAD or RENDER correctly due to missing ContentType');
                }

                binaryData.push(response);
                streamLink = window.URL.createObjectURL(new Blob(binaryData, {
                    type: contentType
                }));
                
                return streamLink;
            })
        );
    }

    getReportDocumentByColumnId(reportName: string, employeeNumber: string, extension: string): Observable<any> {
        return this.http.get(`${Config.apiRoot.uri}pdf-service/${reportName}/${employeeNumber}`, {
            headers: { 
                vrmCustom_requestName: 'Report File',
            },
            responseType: 'blob' as 'json'
        }).pipe(
            map(response => {
                console.log('%c Requested Report File - ArrayBuffer', 'color: salmon', response);

                const binaryData = [];
                let streamLink = '';
                const contentType: string = this.matchExtensionToMimetype[extension];

                if (!contentType) {
                    this.cService.showToast(
                        ':: ' + this.cService.readyTranslations.messagesAndWarnings.fileMayNotWorkCorrectly);
                    console.warn('VRM WARNING: This file may not DOWNLOAD or RENDER correctly due to missing ContentType');
                }

                binaryData.push(response);
                streamLink = window.URL.createObjectURL(new Blob(binaryData, {
                    type: contentType
                }));

                return streamLink;
            })
        );
    }

    // ---- JOINS ----
    getAllTrainingModulesMetaForkJoined(moduleName: string): Observable<any> {
        return forkJoin([
            this.getTrainingModuleMeta(moduleName),
            this.getAllTrainingModules(moduleName)
        ]);
    }
}