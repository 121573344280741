import { Injectable } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { CommonService } from '../services/common.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private cService: CommonService, private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        setTimeout(() => {
            this.cService.hideIndeterminateProgress = (request.headers.get('vrmCustom_hideProgress')) ? true : false;
            this.authService.messageHasCountdown = false;
        });

        // Add Authorization header with jwt token if available
        const accessToken = localStorage.getItem(this.cService.localStorageKeys.accessToken);
        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }

        // Tap into response
        return next.handle(request)
            .pipe(
                catchError((errResp: HttpErrorResponse) => {
                    const errorData = {
                        errorType: 'Client-side error',
                        endpoint: request.url,
                        requestBody: request.body,
                        endpointWithParams: request.urlWithParams,
                        userSignedIn: this.authService.isAuthenticated(),
                        responseMessage: (errResp.error && errResp.error.message) ? errResp.error.message : '',
                        responseStatus: `${errResp.status}: ${errResp.statusText}`,
                        responseUrl: errResp.url
                    };

                    console.error(':: Detailed Error Data', errorData);

                    let errorMessage: any;

                    if (errResp.error instanceof ErrorEvent) {
                        // This is a client-side error
                        errorMessage = errorData;
                    }
                    else {
                        errorData.errorType = ':: Server error';
                        errorMessage = errorData;

                        if (errResp.status == this.cService.responseTypes.error.unprocessableEntity) {
                            this.authService.passwordChangeErrorMessage = this.cService.readyTranslations.messagesAndWarnings.invalidPassword;
                        }
                        else if (errResp.status == this.cService.responseTypes.error.unauthorized) {
                            this.authService.passwordChangeErrorMessage = this.cService.readyTranslations.messagesAndWarnings.checkCredentials;

                            const currentLoc = location.href.toLowerCase();

                            if (!currentLoc.includes('login') && !currentLoc.includes('security') && !currentLoc.includes('reset-password')) {
                                this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.unauthorized);
                            }

                            if (currentLoc.includes('login')) {
                                this.authService.logout(true, true);
                            }
                        }
                        else if (errResp.status == this.cService.responseTypes.error.tooManyRequests) {
                            setTimeout(() => {
                                this.authService.loginErrorMessage = this.cService.readyTranslations.messagesAndWarnings.tryAgainIn;
                                this.authService.messageHasCountdown = true;
                            });
                        }
                        else {
                            let requestName = request.headers.get('vrmCustom_requestName');
                            if (requestName) {
                                requestName = requestName.replace(/\//g, ' ').replace(/-/g, ' ').replace(/_/g, ' ').toUpperCase();

                                const requestNames = requestName.split(' ');

                                if (requestNames.length > 2) {
                                    requestName = requestNames[0] + ' ' + requestNames[1];
                                }
                            }

                            if (request.method.toLowerCase() === 'get') {
                                if (requestName) {
                                    this.cService.showToast(`:: ${requestName} - 
                                    ${this.cService.readyTranslations.messagesAndWarnings.failedToFetchData}`);
                                }
                                else {
                                    this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.failedToFetchData);
                                }
                            }
                            else if (
                                request.method.toLowerCase() === 'post'
                                || request.method.toLowerCase() === 'put'
                                || request.method.toLowerCase() === 'patch'
                            ) {
                                if (
                                    request.headers.get('vrmCustom_requestType')
                                    && request.headers.get('vrmCustom_requestType').toLowerCase() === 'tokenrefresh'
                                ) {
                                    /**
                                     * NOTE: Setting client only & prevent reload to true
                                     * ensures we do not hit the server again at this point.
                                     * Else, it'll result in a loop of 401 error
                                     */
                                    this.authService.logout(true, true);
                                }
                                else if (request.headers.get('vrmCustom_requestType')
                                    && request.headers.get('vrmCustom_requestType').toLowerCase() === 'distributor-company-switch') {
                                    this.cService.showToast(
                                        ':: ' + this.cService.readyTranslations.messagesAndWarnings.failedToSwitchToTheSelectedCompany
                                    );
                                }
                                else {
                                    if (requestName) {
                                        this.cService.showToast(
                                            `:: ${requestName} - 
                                        ${this.cService.readyTranslations.messagesAndWarnings.failedToProcessData}`);
                                    }
                                    else {
                                        this.cService.showToast(
                                            ':: ' + this.cService.readyTranslations.messagesAndWarnings.failedToProcessData);
                                    }
                                }
                            }
                            else if (request.method.toLowerCase() === 'delete') {
                                this.cService.showToast(
                                    ':: ' + this.cService.readyTranslations.messagesAndWarnings.deletionFailed + ' '
                                    + this.cService.readyTranslations.messagesAndWarnings.refreshAndTryAgain);
                            }
                            else {
                                this.cService.showToast(
                                    ':: ' + this.cService.readyTranslations.messagesAndWarnings.failedToProcessRequest);
                            }
                        }

                        if (errResp.url.endsWith(`/users/logout`) || errResp.url.endsWith(`/users/logout/`)) {
                            setTimeout(() => {
                                this.authService.serverTokenReleaseFailedOnLogout = true;
                            });
                        }

                        console.error('HTTP Request Error - VRM', {
                            method: request.method,
                            params: request.urlWithParams
                        });
                    }

                    return throwError(errorMessage);
                }),

                finalize(() => {
                    setTimeout(() => {
                        if (!request.headers.get('vrmCustom_manuallyHideProgress')) {
                            this.cService.hideIndeterminateProgress = true;
                        }
                    });
                })
            );
    }
}


// tslint:disable-next-line
export const HttpRequestInterceptorProvider = [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true
}];