// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// Subscription
import { Subscription } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
import { ProvisioningService } from '../../services/provisioning.service';
import { CommonService } from '../../services/common.service';
// Component
import { UploadOutcomeComponent } from '../../dialogs/upload-user-outcome/upload-outcome.component';
// Third part
import { Observable, forkJoin } from 'rxjs';
// Model
import { GroupsApi, ApiResponse } from '../../model/provisioning';
import { UserForm, Combo } from '../../model/UserForm';
import { AppComponent } from 'src/app/app.component';
import { UploadUsersComponent } from 'src/app/dialogs/upload-users/upload-users.component';


/**
 * @TODO export in model if need it
 */
interface NgCsvOptions {
    fieldSeparator: string;
    quoteStrings?: string;
    decimalseparator?: string;
    headers: string[];
    showLabels?: boolean;
    showTitle?: boolean;	
    title?: string;
    useBom?: boolean;
    removeNewLines?: boolean;
    keys?: string[];
}

interface UploadOutcomeInfo {
    successfully: number;
    failed: number;
    lost: number;
    uploaded: number;
    created: number;
    notCreated: number;
}

@Component({
    selector: 'vrm-upload-users-form',
    templateUrl: './vrm-upload-users-form.component.html',
    styleUrls: [
        'vrm-upload-users-form.component.scss'
    ]
})

export class VrmUploadUsersFormComponent implements OnInit {

    private csvTemplate: object[] = [
        {
            FirstName: 'Name1',
            MiddleName: 'MiddleName1',
            LastName: 'LastName1',
            Email: 'email1@email.com',
            PhoneNumber: 'phoneNumber1',
            driverGroupId: 'ST1',
            managerGroupIds: 'ST1 ST2 ST3',
        }, {
            FirstName: 'Name2',
            MiddleName: 'MiddleName2',
            LastName: 'LastName2',
            Email: 'email2@email.com',
            PhoneNumber: 'phoneNumber2',
            driverGroupId: '',
            managerGroupIds: '',
        }

    ];
    private csvOption: NgCsvOptions = {
        fieldSeparator: ',',
        keys: ['FirstName', 'MiddleName', 'LastName', 'Email', 'PhoneNumber', 'driverGroupId', 'managerGroupIds'],
        headers: ['First Name', ' Middle Name', ' Last Name', ' Email', ' Phone Number', ' Stations', ' Manages'],
    };

    userForms: UserForm[] = [];
    comboGroups: Combo[] = [];
    groupsEnum: object = {}; 
    onError: boolean = false;

    uploadOutcome: UploadOutcomeInfo = {
        successfully: 0,
        failed: 0,
        lost: 0,
        uploaded: 0,
        created: 0,
        notCreated: 0
    };
    private uploadOutcomeCompoent = UploadOutcomeComponent;

    showTableForm: boolean = false;
    private _getGroupsSubscription: Subscription;
    private _uploadValidUsersSubscription: Subscription;

    constructor( 
        public dialogRef: MatDialogRef<UploadUsersComponent>,
        private translate: TranslateService,
        private dialog: MatDialog,
        public cService: CommonService,
        private spService: ProvisioningService
    ) 
    { }

    ngOnInit() {
        if (this.spService.groups) {
            this.comboGroups = this._createComboGroups(this.spService.groups);
            this._createGroupsEnum(this.spService.groups);
        } else {
            /**
             * @todo Change method
             */
         this._getGroupsSubscription = this.spService.getGroups().subscribe((groups: GroupsApi[]): void => {
                this.comboGroups = this._createComboGroups(groups);
                console.log('COMBO GROUPS -', this.comboGroups);
                this._createGroupsEnum(groups);
                console.log('GROUPS ENUM -', this.groupsEnum);
            });
        }
    }

    /**
     * Open the dialog to show upload result
     * @TODO Maybe we can use showRichToast instead dialod for this porpuse
     * @param failed number of failed uploads
     * @param successfully number of successfully
     */
    openOutcomeDialog(failed: number, successfully: number) {
        this.dialog.open(this.uploadOutcomeCompoent, {
            maxHeight: (this.cService.isMobileViewport) ? '90%' : '20%',
            maxWidth: (this.cService.isMobileViewport) ? '90%' : '20%',
            height: '20%',
            width: (this.cService.isMobileViewport) ? '90%' : '20%',

            data: {
                failed,
                successfully,
                subject: 'User/s',
                title: 'Upload users result'
            }
        });
    }

    fileUploadListener($event: any) {
        this.cService.hideIndeterminateProgress = false;
        const files = $event.srcElement.files;
        this.onError = false;
        if (this._isCSVFile(files[0])) {
            const input = $event.target;
            const reader = new FileReader();
            reader.readAsText(input.files[0]);
            reader.onload = (data) => {
                try {
                    const csvData: string = String(reader.result);
                    const csvRecordsArray = csvData.trim().split(/\r\n|\n/);
                    const headersRow: string[] = this._getHeaderArray(csvRecordsArray);
                    if (headersRow.length == this.csvOption.headers.length) {
                        this._getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
                    } else {
                        this._onError();
                    }
                } catch (err) {
                    console.log('ERROR IN ONLOAD -', err);
                    this._onError();
                } finally {
                    this.cService.hideIndeterminateProgress = true;
                }   
            };
            reader.onerror = this._onError;
        } else {
            this._onError();
            this.cService.hideIndeterminateProgress = true;
        }
    }


    uploadValidUsers() {
      this._uploadValidUsersSubscription = this.spService.forkCreateUserCalls(this.userForms, {'vrmCustom_manuallyHideProgress': 'true'}).subscribe({
            next: (subscribes: Array<ApiResponse>) => {
                subscribes.forEach((subscribe: ApiResponse ) => {
                    console.log(subscribe);
                    (subscribe.statusCode == 201) ? this.uploadOutcome.created ++ : this.uploadOutcome.notCreated ++;
                });
            },
            /**
             * @todo what here ?
             */
            error: (err) => {
                console.log('ERROR IN UPLOAD USER -', err); 
                this.cService.hideIndeterminateProgress = true;
            },
            complete: () => {
                this.openOutcomeDialog(this.uploadOutcome.notCreated, this.uploadOutcome.created);
                this.cService.hideIndeterminateProgress = true;
                this.spService.triggerBehaviourprovisioningDataChange();
                this.statusReset();
                this.dialogRef.close();
            }
        });
    }



    _isCSVFile(file: any) {
        return file.name.endsWith('.csv');
    }

    _onError(): void {
        this.onError = true;
        this.statusReset();
    }

    _getHeaderArray(csvRecordsArr: any): string[] {
        const headers = csvRecordsArr[0].split(',');
        const headerArray = [];

        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    } 

    _getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) { 
        for (let i = 1; i < csvRecordsArray.length; i++) {
            const data = csvRecordsArray[i].split(',');
            // FOR EACH ROW IN CSV FILE IF THE NUMBER OF COLUMNS         
            // ARE SAME AS NUMBER OF HEADER COLUMNS THEN PARSE THE DATA 
            if (data.length == headerLength) {
                const form = new UserForm();
                form.inizializeForm({
                    firstName: data[0].trim(),
                    middleName: data[1].trim(),
                    lastName: data[2].trim(),
                    email: data[3].trim(),
                    phoneNumber: data[4].trim(),
                    isDriver: (data[5].trim()) ? true : false,
                    driverGroupId: (data[5].trim()) ? this.groupsEnum[data[5].trim()] : null,
                    isManager: (data[6].trim()) ? true : false,
                    managerGroupIds: (data[6].trim()) ?
                        data[6].trim().split(' ').map((groupName: string) => this.groupsEnum[groupName]) :
                        [],
                });
                (form.getUserForm.invalid) ? this.uploadOutcome.failed++ : this.uploadOutcome.successfully++;
                this.userForms.push(form);  
            } else {
                this.uploadOutcome.lost++;
            }
            this.showTableForm = true;
        }
    }
    statusReset() {
        console.log('STATUS RESET -', this.userForms, this.uploadOutcome);
        this.showTableForm = false;
        this.userForms = [];
        this.uploadOutcome = {
            successfully: 0,
            failed: 0,
            lost: 0,
            uploaded: 0,
            created: 0,
            notCreated: 0
        };
    }

    /**
     * This method create a Groups enumeratio used for track groups names
     * in uploaded csv
     * @param groups Array of GroupsApi
     */
    _createGroupsEnum(groups: GroupsApi[]): void {
        groups.map((group: GroupsApi) => this.groupsEnum[group.name] = `${group.id}`);
    }

    /**
     * This method create a combo for userForms
     * @param groups Array of GroupsApi 
     */
    _createComboGroups(groups: GroupsApi[]): Combo[] {
        return groups.map((group) => ({ label: group.name, value: `${group.id}` }));
    }

    ngOnDestroy() {
        if (this._getGroupsSubscription) { this._getGroupsSubscription.unsubscribe(); }
        if (this._uploadValidUsersSubscription) { this._uploadValidUsersSubscription.unsubscribe(); }
      }

}

