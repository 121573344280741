import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformReportCell'
})
export class TransformReportCell implements PipeTransform {
    transform(value: string)
    {
        let newCellValue = (value == null) ? '' : value;

        if (value && typeof value == 'string') {
            if (value.toLowerCase().trim() === 'red' 
            || value.toLowerCase().trim() === 'red [out_of_date]' 
            || value.toLowerCase().trim() === 'red [new_event]') 
            {
                newCellValue = value.replace('red', 'High');
            }
            else if (value.toLowerCase().trim() === 'green' 
            || value.toLowerCase().trim() === 'green [out_of_date]' 
            || value.toLowerCase().trim() === 'green [new_event]') 
            {
                newCellValue = value.replace('green', 'Low');
            }
            else if (value.toLowerCase().trim() === 'yellow' 
            || value.toLowerCase().trim() === 'yellow [out_of_date]' 
            || value.toLowerCase().trim() === 'yellow [new_event]') 
            {
                newCellValue = value.replace('yellow', 'Medium');
            }
            else if (value.toLowerCase().trim() === 'black' 
            || value.toLowerCase().trim() === 'black [out_of_date]' 
            || value.toLowerCase().trim() === 'black [new_event]') 
            {
                newCellValue = value.replace('black', 'Very High');
            }

            if (value.toLowerCase().includes('[out_of_date]') || value.toLowerCase().includes('[new_event]'))
            {
                if (value.toLowerCase().includes('[out_of_date]')) {
                    newCellValue = newCellValue.replace('[out_of_date]', ' ') + `<span class="material-icons row-icon amber-text">warning</span>`;
                }

                if (value.toLowerCase().includes('[new_event]')) {
                    newCellValue = newCellValue.replace('[new_event]', ' ') + `<span class="material-icons row-icon red-text">warning</span>`;
                }
            }

            if (value.toLowerCase().trim() == 'required') {
                newCellValue = `<span class="material-icons row-icon amber-text">stop</span> Required`;
            }

            if (value.toLowerCase().trim() == 'agree') {
                newCellValue = `<span class="material-icons row-icon amber-text">done</span> Agree`;
            }
            else if (value.toLowerCase().trim() == 'disagree') {
                newCellValue = `<span class="material-icons row-icon amber-text">block</span> Disagree`;
            }
        }

        return newCellValue;
    }
}
