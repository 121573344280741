import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Config } from 'src/app/config';
import { BillingService } from 'src/app/services/billing.service';
import { CommonService } from 'src/app/services/common.service';
import { PromptWithActionDialogComponent } from 'src/app/dialogs/prompt-with-action/prompt-with-action.component';
declare var Stripe: any;
@Component({
  selector: "vrm-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent implements OnInit {
  private stripe: any;
  sessionId: string;
  errorMessage: string;
  hasPaymentMethod: boolean = false;
  paymentMethods: any;
  chargeHistory: any;
  constructor(
    private billingService: BillingService,
    private cService: CommonService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}
  completeSetup() {
    this.billingService
      .completeBillingSession(this.cService.userProfile.userId, this.sessionId)
      .toPromise()
      .then((settings) => {
        this.retrievePaymentInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get("session_id")) {
        this.sessionId = queryParams.get("session_id");
        this.completeSetup();
      } else {
        this.retrievePaymentInfo();
      }
      this.retrieveChargeHistory();
    });
  }

  private retrievePaymentInfo() {
    this.billingService
      .getBillingSetup(this.cService.userProfile.userId)
      .toPromise()
      .then((res) => {
        const obj = res as any;
        console.log(obj);

        if (
          !obj.body.stripe_payment_methods ||
          obj.body.stripe_payment_methods.length == 0
        ) {
          this.errorMessage = "There is no registered credit card.";
        } else {
          this.hasPaymentMethod = true;
          this.paymentMethods = obj.body.stripe_payment_methods;
          this.paymentMethods[0].checked = true;
        }
      })
      .catch((err) => {
        console.log(err.responseStatus);
        if (err.responseStatus) {
          let code = err.responseStatus.split(":")[0];
          code = parseInt(code);
          if (code === 404) {
            this.errorMessage = "There is no registered credit card.";
          }
        }
      });
  }
   deletePaymentMethod(paymentId) {
  
     this.cService.promptConfig = {
       title: 'DELETE CREDIT CARD',
       body: `<div style='margin-top:10%; margin-left:5%;, margin-right:5%'>Would you like to delete the credit card?</div>`
       ,
       actionButtonText:'Yes',
       cancelButtonText: 'Cancel'
     };

     this.dialog.open(PromptWithActionDialogComponent).afterClosed().subscribe({
       next: (todelete: boolean) => {
         if (todelete) {
           this.billingService
             .detachPaymentMethod(this.cService.userProfile.userId, paymentId)
             .toPromise()
             .then((res) => {
               this.dialog.closeAll();
               setTimeout(() => {
                 this.retrievePaymentInfo();
               }, 100);
           
               this.cService.promptConfig = {
                 title: 'DELETE CREDIT CARD SUCCESS',
                 body: `<div style='margin-top:10%; margin-left:5%;, margin-right:5%'>The credit card has been successfully removed.</div>`
                 ,
                 actionButtonText: 'Ok'
               };
               this.dialog.open(PromptWithActionDialogComponent).afterClosed().subscribe({
                 next: (ok: boolean) => {
                   
                  
                 }
               });
             })
             .catch((err) => {
               console.log(err);

             });
         

         }
       }
     });
    
  }

  private retrieveChargeHistory() {
    this.billingService
      .getChargeHistory(this.cService.userProfile.userId)
      .toPromise()
      .then((res) => {
        const obj = res as any;
        console.log(obj);

        this.chargeHistory = obj.body;
      })
      .catch((err) => {
        console.log(err.responseStatus);
        if (err.responseStatus) {
        }
      });
  }

  createSession() {
    const publishableKey = Config.apiRoot.publishableKey;
    this.stripe = Stripe(publishableKey);

    //  (owner_type, owner_id, req.body.success_url, req.body.cancel_url)
    this.billingService
      .createBillingSession(this.cService.userProfile.userId, {
        success_url:
          Config.apiRoot.returnUrlBilling + "?session_id={CHECKOUT_SESSION_ID}",
        cancel_url: Config.apiRoot.returnUrlBilling + "?cancel=true",
      })
      .toPromise()
      .then((res) => {
        const obj = res as any;
        this.sessionId = obj.body.id;
        this.stripe
          .redirectToCheckout({
            sessionId: this.sessionId,
          })
          .then(function (result) {
            if (result.error) {
              this.errorMessage = result.error.message;
            }
          });
      })
      .catch((err) => console.log(err));
  }
}
