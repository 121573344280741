import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridOptions, CellClassParams } from 'ag-grid-community';

import { agGridLocaleText } from '../../assets/i18n/ag-grid-locale-text';
import { CommonService } from './common.service';

interface SpecificColWidthSetting {
    columnId: string; 
    width?: number;
    flex?: number;
}
interface AgGridConfigInternal {
    common: GridOptions; 
    statusPanels: Array<any>; 
    defaultColumnConfig: any; 
    specificColWidth: Array<SpecificColWidthSetting>;
}

enum VrmColumnTypes {
    text = 'text',
    number = 'number',
    percentage = 'percentage',
    date = 'date',
    email = 'email'
}

enum ColIdEnum {
  id = 'id',
  title = 'title',
  asset = 'asset',
  category = 'category',
  company = 'company',
  lang = 'lang',
  extension = 'extension',
  createdAt = 'created_at',

  status = 'status',
  lastUpdated = 'lastUpdated',
  interventionType = 'interventionType',
  createdDate = 'createdDate',
  createdBy = 'createdBy',
  action = 'action'
}

export enum CasualDialogTypesEnum {
  guideViewer = 'guide-viewer'
}

@Injectable({ providedIn: 'root' })
export class CasualReportService
{
  selectedRows: Array<any> = [];

  agGridConfig: AgGridConfigInternal = {
      common: {
        rowDragManaged: true,
        groupIncludeFooter: true,
        animateRows: true,
        multiSortKey: 'ctrl',
        headerHeight: 35, // <-- default
        rowHeight: 33,
        enableRangeSelection: true,
        localeText: null,
        autoSizePadding: 30, // <-- allows space for the sort icon
        enableRtl: false // <-- This is set in the constructor when rtl is in use
      },
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left'
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' }
      ],
  
      defaultColumnConfig: {
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
  
      specificColWidth: [
          { columnId: ColIdEnum.extension, width: 100 }
      ]
  };

  constructor (
      private cService: CommonService,
      private translation: TranslateService
  ) {
      this.initialize();
  }

  initialize() {
      // AgGrid Local translations
      this.translation.get('AGGRIDLOCALE').subscribe({
          next: (translate) => {
          const localeTextKeys = Object.keys(agGridLocaleText);
  
          localeTextKeys.forEach((localeKey) => {
              if (translate[localeKey]) {
              agGridLocaleText[localeKey] = translate[localeKey];
              }
          });
  
          this.agGridConfig.common.localeText = agGridLocaleText;
          this.agGridConfig.common.enableRtl = this.cService.selectedLanguage.isRtl;
          }
      });
  }

  convertToAgColumnDefinition(structure: any): Array<ColDef> {
      const columnNames = Object.keys(structure);
      const agGridColumnDef: Array<ColDef> = [];
  
      this.translation.get('REPORTCOLUMNS').subscribe({
        next: (translate) => {
          columnNames.forEach((col: string) => 
          {
            const colHeaderName = structure[col].name;
            const translatedColName: string = (translate[colHeaderName]) ? translate[colHeaderName] : colHeaderName;
            
            const column: ColDef = {
              headerName: translatedColName,
              field: col,
              colId: col,
              filter: true,
              filterParams: { clearButton: true },
              headerComponentParams: {
                template: `
                <div class="ag-cell-label-container" role="presentation">
                  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
        
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
  
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
        
                    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                  </div>
                </div>
                `
              }
            };
      
            // Set master column defaults
            if (structure[col].master || col === ColIdEnum.title || col === ColIdEnum.asset) {
              column.pinned = 'left';

              // Render Title as Link-button
              column.cellRenderer = 'titleLinkButtonCasualReportComponent';
            }
            if (structure[col].pinned) {
              column.pinned = 'left';
            }
            if (col === ColIdEnum.extension) {
                column.pinned = 'right';
                column.cellRenderer = 'fileExtensionAsIconComponent';
            }
            if (column.pinned) {
              column.lockPinned = true;
              column.cellClass = 'lock-pinned';
            }
  
            // Hide / persist hidden columns
            const columnHidden = (col === ColIdEnum.id || col === ColIdEnum.company) ? true : false;
            if (columnHidden) {
              column.hide = true;
            }
      
            // Apply case-insensitive comparator for sorting.
            if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.text) {
              column.comparator = this.caseInsensitiveSort;
            }
      
            // Apply percentage transformations
            if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.percentage) {
              column.cellRenderer = 'numberToPercentageComponent';
              
              // NOTE: Tagging this as numericColumn helps agGrid to align it right.
              // column.type = 'numericColumn';
  
              // Be graceful to reports with width set independently
              if (!structure[col].width) {
                column.width = 140;
              }
            }
      
            // Apply number type transformations
            if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.number) {
              column.cellRenderer = 'numberToDecimalPlaceComponent';
              column.aggFunc = 'vrmSum';
              column.enableValue = true;
              column.sortingOrder = ['desc', 'asc', null];
  
              // NOTE: Tagging this as numericColumn helps agGrid to align it right.
              // column.type = 'numericColumn';
              column.filter = 'agNumberColumnFilter';
  
              // Be graceful to reports with width set independently
              if (!structure[col].width) {
                column.width = 140;
              }
            }
            // Process specific width recommendations
            // NOTE: This must come after setting generic width for all numeric / percentage columns
            this.agGridConfig.specificColWidth.forEach((columnSetting: SpecificColWidthSetting) => {
              if (col === columnSetting.columnId) {
                column.width = columnSetting.width;
              }
            });
      
            // Apply date type transformations
            if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.date) {
              column.filter = 'agDateColumnFilter';
              column.filterParams = {
                comparator: (filterLocalDateAtMidnight: number | any, cellValue: any) => {
                  const dateAsString = cellValue;
                  if (dateAsString == null) { return -1; }
              
                  const dateParts = dateAsString.split('/');
                  const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
                  if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0; }
                  if (cellDate < filterLocalDateAtMidnight) { return -1; }
                  if (cellDate > filterLocalDateAtMidnight) {return 1; }
                },
      
                browserDatePicker: true,
                clearButton: true
              };
            }

            // Coaching Toolkit Grid
            if (col.toLowerCase() === ColIdEnum.status.toLowerCase()) {
              column.cellClass = (params: CellClassParams) => {
                if (typeof params.value === 'string') {
                  if (params.value.toLowerCase().includes('new')) {
                    return 'agGridRedTexts';
                  }
                  else if (params.value.toLowerCase().includes('progress')) {
                    return 'agGridAmberTexts';
                  }
                  else if (params.value.toLowerCase().includes('completed')) {
                    return 'agGridGreenTexts';
                  }
                }
              };
            }

            column.cellClassRules = {
              agGridAttentionRequired: (params: CellClassParams) => {
                if (params.data && typeof params.data.status === 'string') {
                  return params.data.status.toLowerCase().includes('new');
                }
              }
            };

            if (col.toLowerCase() === ColIdEnum.action) {
              column.cellRenderer = 'actionOptionButtonCasualReportComponent';
            }
      
            agGridColumnDef.push(column);
          });
        }
      });
  
      console.log('Transformed column structure', agGridColumnDef);
      return agGridColumnDef;
  }

  caseInsensitiveSort(valueA: any, valueB: any): number {
      if (!valueA) { valueA = ''; }
      if (!valueB) { valueB = ''; }
  
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }
}