import { Pipe, PipeTransform } from '@angular/core';

import { CommonService } from '../services/common.service';

@Pipe({
    name: 'numberToDecimalPlaces'
})
export class NumberToDecimalPlacesPipe implements PipeTransform
{
    constructor(private cService: CommonService) { }

    transform(value: any, decimalPlaces: number = 2) {
        return this.cService.numberToDecimalPlace(value, decimalPlaces);
    }
}