import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'determineCellColor'
})
export class DetermineReportCellColor implements PipeTransform {
  transform(valuePipe: string, targetContentPipe: string = '') {
    let applyColour: boolean = false;

    const signs = {
      minus: {name: 'minussign', sign: '-'},
      plus: {name: 'plussign', sign: '+'},
    };

    let value: string;
    let targetContent: string;

    if (valuePipe && typeof valuePipe === 'string') {
      value = valuePipe.toLowerCase().trim();
      targetContent = targetContentPipe.toLowerCase().trim();
    } else {
      return;
    }

    switch (targetContent) {
      case signs.minus.name:
        applyColour = (valuePipe.toLowerCase().startsWith(signs.minus.sign));
        break;
      case signs.plus.name:
        applyColour = (value.startsWith(signs.plus.sign));
        break;
      case 'redtext':
        applyColour = (
          value === 'poor' ||
          value === 'red' ||
          value === 'red [out_of_date]' ||
          value === 'red [new_event]' ||
          value === 'not started');
        break;

      case 'greentext':
        applyColour = (
          value === 'complete' ||
          value === 'good' ||
          value === 'green' ||
          value === 'green [out_of_date]' ||
          value === 'green [new_event]');
        break;

      case 'ambertext':
        applyColour = (
          value === 'incomplete' ||
          value === 'in progress' ||
          value === 'inprogress' ||
          value === 'average' ||
          value === 'partial' ||
          value === 'yellow' ||
          value === 'yellow [out_of_date]' ||
          value === 'yellow [new_event]');
        break;
    }

    return applyColour;
  }
}