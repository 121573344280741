// Angular
import { Directive, ViewContainerRef } from '@angular/core';
// export class GenericComponent {
//     constructor(public component: Type<any>, public data: any) { }
// } 

@Directive({
  selector: '[mentor-render-component]'
})
export class RenderComponentDirective {

    constructor(public viewContainer: ViewContainerRef) { }

}

// TODO: Need to understand why this directive seem to be used in the DOM just for truthy-flag purpose. 
// It's doing nothing as a directive.