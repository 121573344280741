import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { DriverIndexComponent } from '../../dialogs/driver-index/driver-index.component';
import { ReportsService, TransformedSingleData } from '../../services/reports.service';
import { CommonService } from '../../services/common.service';
import { DriverIndexClassicComponent } from 'src/app/dialogs/driver-index-classic/driver-index-classic.component';

@Component({
    selector: 'grid-indexing-coach-link-button',
    template: `
    <span>
        <button mat-button fxFlex class="sharp-corners bold grid-text" 
            [style.color]="transformedValue?.color"
            style="text-decoration: underline"
            (click)="triggerLinkButtonAction()" *ngIf="value; else displayValueOnly">
            {{ value }}
        </button>
        <ng-template #displayValueOnly>{{ value }}</ng-template>
    </span>
    `,
    styles: []
})
export class GridIndexingCoachLinkButtonComponent implements ICellRendererAngularComp
{
    public value: string;
    public transformedValue: TransformedSingleData;
    private classicDriverIndexDialog = DriverIndexClassicComponent;
    private driverIndexDialog = DriverIndexComponent;
    private parameters: { value?: any; data?: any };

    constructor(
        private dialog: MatDialog,
        private rptService: ReportsService,
        private cService: CommonService
    ) { }

    agInit(params: { value: any }) {
        this.parameters = params;
        this.transformedValue = this.rptService.transformSingleDataForDetailsPane('indexingCoach', params.value);
        if (this.transformedValue) {
            this.value = this.transformedValue.uiValue;
        }
    }

    triggerLinkButtonAction() {
        setTimeout(() => {
            const defaultSelection = this.parameters.data;

            if (this.rptService.selectedRows.length === 0) {
                if (this.parameters.data) {
                    this.rptService.selectedRows.push(this.parameters.data);
                }
            }

            if (this.cService.userProfile.manages && this.cService.userProfile.manages.length > 0) {
                this.rptService.autoPopulateManagedDriversForManagement();
            }

            console.log('%c Clicked Link data', 'color: yellowgreen', this.parameters);
            console.log('%c Link Button Action Triggered: default selection', 'color: green', defaultSelection);

            if (defaultSelection) {
                const renderClassicOrModernDialog: any = (this.cService.userProfile.companySettings 
                && this.cService.userProfile.companySettings.driverPerformanceClassic === 'TRUE') ?
                this.classicDriverIndexDialog : this.driverIndexDialog;

                this.dialog.open(renderClassicOrModernDialog, {
                    maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
                    maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
                    height: '100%',
                    width: (this.cService.isMobileViewport) ? '100%' : '95%',
            
                    data: {
                        selectedRow: defaultSelection,
                        reportEndpointName: this.rptService.currentReportOptions.reportEndpointName,
                        autoExpandCoachForm: true
                    },
            
                    disableClose: true
                });
            }
        }, 100); // <-- Wait for selectedRow to be up-to-date
    }

    refresh(): boolean {
        return false;
    }
}