import { Directive, OnInit, Renderer2, RendererStyleFlags2, ElementRef } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';

@Directive({
    selector: '[vrm-bg-accent]'
})
export class VrmBackgroundAccentDirective implements OnInit
{
    constructor(
        private cService: CommonService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        const companyAccent = (this.cService.accentColor) ? this.cService.accentColor : 'brown';

        this.renderer.setStyle(
            this.elementRef.nativeElement, 'background-color', `${ companyAccent }`, RendererStyleFlags2.Important
        );

        this.renderer.setStyle(
            this.elementRef.nativeElement, 'color', 'white', RendererStyleFlags2.Important
        );
    }
}