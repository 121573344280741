import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';

import { ReportsService } from '../../services/reports.service';
import { CommonService } from '../../services/common.service';

import { SidepaneItem } from '../../model/dialogs';

interface PaneSearchResult {
    fullName: string; 
    firstName: string; 
    lastName: string; 
    pin: string;
    selected: boolean;
}

@Component({
    selector: 'dialog-sidepane-classic',
    templateUrl: './dialog-sidepane-classic.component.html',
    styleUrls: ['./dialog-sidepane-classic.component.scss']
})
export class DialogSidepaneClassicComponent implements OnInit
{
    @Input() paneItems: Array<SidepaneItem> = [];
    @Input() reportEndpointName: string;
    @Input() selectedRowPin?: string;
    @Output() onPaneItemSelected = new EventEmitter();

    @ViewChild('drawer', { static: false }) sidepaneDrawer: MatDrawer;

    paneDoneList: Array<SidepaneItem> = [];

    minSearchCharTrigger: number = 2; // <-- Change to 3 if hitting an API
    selectedSearchResult: any;

    newItemSearchInput = new FormControl();
    filteredItems: Array<PaneSearchResult> = [];

    showSidepane: boolean = true;
    paneOpened: boolean = true;

    constructor(private rptService: ReportsService, public cService: CommonService) 
    {
        this.newItemSearchInput.valueChanges.subscribe(filterValue => 
        {
            if (filterValue && filterValue.split('').length >= this.minSearchCharTrigger) {
                if (this.rptService.currentReportData 
                    && this.rptService.currentReportData.length > 0
                    && this.rptService.currentReportData[0].fullName) {
                    this.filteredItems = this.rptService.currentReportData.filter(
                        data => data.fullName.toLowerCase().includes(filterValue.toLowerCase()));
                }
                else {
                    this.cService.showToast('::' + this.cService.readyTranslations.messagesAndWarnings.noResultsFound);
                    console.warn('VRM WARNING: If this report has data, ensure it returns fullName in the data');
                }
            }
        });
    }

    ngOnInit() {
        if (this.selectedRowPin) {
            this.paneItems.find(item => item.pin === this.selectedRowPin).active = true;
            this.paneItems.sort( // <-- Sort by lastName
                (item: { data: any }, compareItem: { data: any }) => item.data.lastName.localeCompare(compareItem.data.lastName));
        }
    }

    // EVENTS
    toggleDrawer() {
        this.sidepaneDrawer.toggle().then((paneState: MatDrawerToggleResult) => {
            this.paneOpened = (paneState == 'open') ? true : false;
            console.log('Pane opened', this.paneOpened);
        });
    }

    itemDropped(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data,
                event.previousIndex, event.currentIndex);
        }
    }

    viewSelectedPaneItem(selectedItem: SidepaneItem) {
        this.onPaneItemSelected.emit(selectedItem);
        console.log('Selected Pane Item', selectedItem);

        this.paneItems.map(item => item.active = false);
        this.paneDoneList.map(item => item.active = false);
        selectedItem.active = true;
    }

    searchResultSelected(selectedResult: PaneSearchResult) 
    {
        const alreadyAdded = this.rptService.selectedRows.find(item => item.pin === selectedResult.pin);

        if (!alreadyAdded) {
            if (this.rptService.selectedRows.length < this.rptService.settings.selectAllLimit) {
                selectedResult.selected = true;
                this.rptService.addNewlySelectedRowAndTriggerObservable(selectedResult);
                console.log('selected', selectedResult);
                const alreadyInPaneItems = this.paneItems.find(item => item.pin === selectedResult.pin);
                if (!alreadyInPaneItems) {
                    this.paneItems.unshift({
                        title: selectedResult.fullName,
                        pin: selectedResult.pin,
                        description: selectedResult.pin,
                        data: selectedResult,
                        newToList: true
                    });
                }
            } else {
                this.cService.showToast(`Maximum of ${this.rptService.settings.selectAllLimit} reached`, 5000);
            }
        }
    }

    mergeStandaloneItemToManagedList(event: Event, item: SidepaneItem) {
        event.stopPropagation();

        item.inViewButNotInBatch = false;
        this.searchResultSelected(item.data);
    }
}