const transparency = '0.6';

export const Charts1 = 
[
    // ---- CHARTS JS ---- Convert Hex to RGB http://www.hex2rgba.devoth.com/ use 0.2 Transparency for RGBA
    // ---------
    {
        type: 'pie',
        data: {
            datasets: [{
                data: [16, 1, 277],
                backgroundColor: [
                    `rgba(249, 187, 26, ${transparency})`,
                    `rgba(215, 30, 23, ${transparency})`,
                    `rgb(96, 189, 96, ${transparency})`
                ]
            }],
            labels: [
                'Test Account: 16',
                'Inactive: 1',
                'Active: 277'
            ]
        },
        options: {
            title: {
                display: false,
                text: 'VRM (Total Population)'
            },
            layout: {
                padding: {
                    left: 20,
                    right: 30
                }
            }
        },
        customOptions: { 
            colspan: 1
        }
    },

    // ---------
    {
        type: 'pie',
        data: {
            datasets: [{
                data: [6, 11, 250],
                backgroundColor: [
                    `rgba(96, 189, 96, ${transparency})`,
                    `rgba(249, 187, 26, ${transparency})`,
                    `rgba(215, 30, 23, ${transparency})`
                ]
            }],
            labels: [
                'Complete: 6',
                'Not started: 11',
                'Incomplete: 250'
            ]
        },
        options: {
            title: {
                display: false,
                text: 'Activity Summary (ALL)'
            },
            layout: {
                padding: {
                    left: 20,
                    right: 30
                }
            }
        },
        customOptions: {
            colspan: 1
         }
    },
]

export const Charts2 = 
[
    // ------
    {
        type: 'bar',
        data: {
            datasets: [
                { 
                    label: 'Medium',
                    data: [20, 10],
                    backgroundColor: [
                        `rgba(249, 187, 26, ${transparency})`
                    ]
                },
                { 
                    label: 'High',
                    data: [50, 30, 40, 10, 20],
                    backgroundColor: [
                        `rgba(215, 30, 23, ${transparency})`
                    ]
                },
                { 
                    label: 'Medium',
                    data: [20, 10, 5],
                    backgroundColor: [
                        `rgba(249, 187, 26, ${transparency})`
                    ]
                },
                { 
                    label: 'High',
                    data: [50, 30, 40, 10, 20],
                    backgroundColor: [
                        `rgba(215, 30, 23, ${transparency})`
                    ]
                }
            ]
        },
        options: {
            title: {
                display: false,
                text: 'RoadRISK Outcomes'
            },
            layout: {
                padding: {
                    left: 20,
                    right: 30
                }
            }
        },
        customOptions: {
            colspan: 1
         }
    },

    // ------
    {
        type: 'line',
        data: {
            datasets: [
                { 
                    label: 'Overall DriverINDEX Value',
                    data: [50, 30, 40, 10, 20],
                    backgroundColor: [
                        `rgba(249, 187, 26, ${transparency})`
                    ]
                }
            ],
        },
        options: {
            title: {
                display: false,
                text: 'DriverINDEX (12 Month View)'
            },
            layout: {
                padding: {
                    left: 20,
                    right: 30
                }
            }
        },
        customOptions: { 
            colspan: 1
        }
    },

    // ------
    {
        type: 'horizontalBar',
        data: {
            datasets: [
                { 
                    label: 'Medium',
                    data: [20, 10],
                    backgroundColor: [
                        `rgba(249, 187, 26, ${transparency})`
                    ]
                },
                { 
                    label: 'High',
                    data: [50, 30, 40, 10, 20],
                    backgroundColor: [
                        `rgba(215, 30, 23, ${transparency})`
                    ]
                },
                { 
                    label: 'Medium',
                    data: [20, 10, 5],
                    backgroundColor: [
                        `rgba(249, 187, 26, ${transparency})`
                    ]
                },
                { 
                    label: 'High',
                    data: [50, 30, 40, 10, 20],
                    backgroundColor: [
                        `rgba(215, 30, 23, ${transparency})`
                    ]
                }
            ]
        },
        options: {
            title: {
                display: false,
                text: 'DriverINDEX (Top 5 Events)'
            },
            layout: {
                padding: {
                    left: 20,
                    right: 30
                }
            }
        },
        customOptions: {
            colspan: 2
        }
    }
]

export const ActivitySummary = [
    { activity: 'Privacy Notice',               driversAssigned: 250,   completed: 250, notCompleted: 0, completionPercentage: 100 },
    { activity: 'Driver Details',               driversAssigned: 250,   completed: 249, notCompleted: 1, completionPercentage: 100 },
    { activity: 'Pledge',                       driversAssigned: 250,   completed: 250, notCompleted: 0, completionPercentage: 100 },
    { activity: 'Risk Foundation',              driversAssigned: 251,   completed: 250, notCompleted: 1, completionPercentage: 100 },
    { activity: 'RoadRISK: Profile',            driversAssigned: 250,   completed: 250, notCompleted: 0, completionPercentage: 100 },
    { activity: 'RoadRISK: Defensive Driving',  driversAssigned: 250,   completed: 250, notCompleted: 0, completionPercentage: 100 },
    { activity: 'Attitude',                     driversAssigned: 1,     completed: 0,   notCompleted: 1, completionPercentage: 0 },
    { activity: 'Avoid Rear End Collisions',    driversAssigned: 2,     completed: 0,   notCompleted: 2, completionPercentage: 0 },
    { activity: 'Driving While Distracted',     driversAssigned: 1,     completed: 0,   notCompleted: 1, completionPercentage: 0 },
    { activity: 'Driver Fatigue',               driversAssigned: 2,     completed: 0,   notCompleted: 2, completionPercentage: 0 },
    { activity: 'Driving Under the Influence',  driversAssigned: 1,     completed: 0,   notCompleted: 1, completionPercentage: 0 },
]