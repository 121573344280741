import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ignoreElements } from 'rxjs/operators';
import pdfMake from 'pdfmake/build/pdfmake';
import { CommonService } from 'src/app/services/common.service';
import { DriverHqService } from 'src/app/services/driver-hq.service';
@Component({
  selector: 'driver-hq-document-viewer',
  templateUrl: './driver-hq-document-viewer.component.html',
  styleUrls: ['./driver-hq-document-viewer.component.scss']
})
export class DriverHqDocumentViewerComponent implements OnInit {
  fileExtension:string='';
  streamSource: any;
  showViewer:boolean;
  constructor(
    private cService: CommonService,
    private driverHqService: DriverHqService, private sanitizer: DomSanitizer  ) {
    setTimeout(() => {
      const pageTitle = cService.readyTranslations.guideFaq.vrmGuides;
      this.cService.setPageHeader(pageTitle);
    });
    
  } 
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }  
  pdfDocumentLoaded(){
    //scroll document header to top
   var pageContaner= document.querySelector('vrm-page-container') as HTMLElement;
    console.log(pageContaner.parentElement)
   setTimeout(() => {
     pageContaner.parentElement.parentElement.scrollTo(0, 0);
   }, 200);
  }
  openDoc(id, fileType){
    this.showViewer=true;
    if (id) {
      this.fileExtension = fileType;
      this.driverHqService.getDocument(id, fileType).subscribe(async s => {
      
        this.streamSource = s;
        if (fileType!='application/pdf'){

          var pageWidth = window.screen.width<720?window.screen.width-120:700;//TODO: use container width
          console.log(pageWidth)

          var docDefinition = {
            pageSize: {
              width: pageWidth,
              height: 'auto'
            },
            pageMargins: [10, 10, 10, 10],
            content: [{
              image: this.streamSource,
              width: pageWidth-20
            }
            ]
          }

          
          let pdf = pdfMake.createPdf(docDefinition);
          let blob = await this.getBlob(pdf);
        
          const binaryData = [];

          binaryData.push(blob);
          this.streamSource  =  window.URL.createObjectURL(blob);
       
        }
      });
    }
  }
  downloadDoc(id, fileType, fileName) {
    this.driverHqService.downloadDocument(id, fileType, fileName).toPromise().then(()=>{
      
         console.log('downloaded')
    });
  }
  getBlob(pdf) {
    return new Promise((resolve, reject) => {
      pdf.getBlob((blob) => {
        return resolve(blob);
      }, (err) => {
        return reject(err);
      });

    })

  }

  closeDoc(){
    this.fileExtension = '';
    this.streamSource = '';
    this.showViewer = false;
  }

  ngOnInit() {
  }

}
