import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'mask-company-level-points',
    template: `
    <span>{{ value }}</span>
    `,
    styles: []
})
export class MaskCompanyRiskLevelPointsComponent implements ICellRendererAngularComp
{
    value: string;

    constructor(
        private cService: CommonService,
        private translate: TranslateService
    ) { }

    agInit(params: { value: any }) 
    {
        const companyRiskLevelValues = this.cService.userProfile.companyRiskLevelValues;

        this.translate.get('REPORTDATA').subscribe({
            next: (translation: any) => {
                if (!isNaN(params.value) && companyRiskLevelValues) {
                    if (params.value >= companyRiskLevelValues.high) {
                        this.value = translation.high + ` (${params.value})`;
                    }
                    else if (params.value >= companyRiskLevelValues.medium 
                        && params.value < companyRiskLevelValues.high) {
                        this.value = translation.medium + ` (${params.value})`;
                    }
                    else if (params.value >= companyRiskLevelValues.low
                        && params.value < companyRiskLevelValues.medium) {
                        this.value = translation.low + ` (${params.value})`;
                    }
                    else {
                        // Assume Safe Driver (< low)
                        this.value = translation.safeDriver + ` (${params.value})`;
                    }
                }
                else {
                    this.value = translation.na;
                }
            }
        });
    }

    refresh() {
        return false;
    }
}