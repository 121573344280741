// Angular
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

// Subscription
import { Subscription } from 'rxjs';

// Third Part
import { TranslateService } from '@ngx-translate/core';

// Component
import { UserIndexComponent } from '../../dialogs/user-index/user-index.component';

// Service
import { ProvisioningService } from '../../services/provisioning.service';
import { CommonService } from '../../services/common.service';

// Model
import { UserForm, Combo } from '../../model/UserForm';
import { ApiResponse, GroupsApi, DriversListItem } from '../../model/provisioning';

@Component({
  selector: 'vrm-user-form',
  templateUrl: './vrm-user-form.component.html',
  styleUrls: ['./vrm-user-form.component.scss']
})

export class VrmUserFormComponent implements OnInit {

  comboGroups: Combo[];
  @Input() isUpdate: boolean;
  @Input() userData: DriversListItem; 

  form: UserForm = new UserForm();
  isLoading = true;
  allowedDomains = [];
  domainsDisable = true;
  private _fetchCoachDataSubscription: Subscription;
  private _createUserSubscription: Subscription;
  private _updateUserSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<UserIndexComponent>,
    public cService: CommonService,
    private spService: ProvisioningService,
    private translate: TranslateService

  ) {
  }

  ngOnInit() {
    console.log('value', this.form.getUserForm);
    if (!this.spService.groups) {
       this.comboGroups = this.spService.groups.map((group) => ({ label: group.name, value: `${group.id}` }));
    } else { 
      this._fetchCoachData();
      this._isAllowedDomains();
    }
    if (this.isUpdate) { this.form.inizializeForm(this.userData); }
  }

  // TODO: Try to add action to the showToast Function
  _fetchCoachData() {
  this._fetchCoachDataSubscription = this.spService.getGroups().subscribe({
      next: (managerGroupIds: GroupsApi[]) => { 
        this.comboGroups = managerGroupIds.map(group => ({ label: group.name, value: `${group.id}` }));
       this.isLoading = false;
      },
      error: (err) => {
        console.log('ERROR IN FETCH GROUPS', err);
        this.cService.showToast(this.translate.instant('FORM').failedToFetchCoachFormData, null, 'refresh now');
        this.isLoading = false;
      }
    });
  }

  _isAllowedDomains() {
    this.spService.allowedDomains().subscribe({
      next: (response) => {
        this.allowedDomains = response.bodyResponse;
      },
      error: (err) => {
        console.log('ERROR', err);
      }
    });
  }

  domainContol(value) {
    const domain = value.substring(value.lastIndexOf('@') + 1);
    this.domainsDisable = (this.allowedDomains.indexOf(domain) > -1) ? false : true;
  }

  // Submit Form
  onSubmit() {
    try {
      const { value } = this.form.getUserForm;
      if (!this.isUpdate) {
        this._createUser(value);
        console.log('req', value);
      } else {
        this._updateUser(value);
      }
    } catch (err) {
      console.log('ERROR ON SUBMIT -', err);
    }
  }

  // Create User Form
  _createUser(value: any) {
  this._createUserSubscription = this.spService.saveUser({
      isDriver: value.isDriver,
      isManager: value.isManager,
      driverGroupId: value.driverGroupId,
      managerGroupIds: value.managerGroupIds,
      user: {
        FirstName: value.firstName,
        MiddleName: value.middleName,
        LastName: value.lastName,
        Email: value.email,
        UserName: value.email,
        PhoneNumber: value.phoneNumber,
        Products: [
          { ProductCode: 'MENTOR_AMZL' }, 
          { ProductCode: 'VRM-OMS-US-EN-0001' }
        ]
      }
    }).subscribe({
      next: (response: ApiResponse) => {
        if (response.statusCode == 201) {
          this.spService.triggerBehaviourprovisioningDataChange();
          this.cService.showToast(this.translate.instant('USER').successMessage, null, 'close');
          this.dialogRef.close();
        }
        if (response.statusCode == 400) {
          this.cService.showToast(this.translate.instant('USER').userExisist, null, 'close');
        }
      },
      error: (err) => {
        console.log('ERROR IN CREATE USER - ', err);
        this.cService.showToast(err.message, null, 'close');
      }
    });
  }

  // Update User
  _updateUser(value: any) {
   this._updateUserSubscription = this.spService.updateUser({
      id: this.userData.idProvisioning,
      isDriver: value.isDriver,
      isManager: value.isManager,
      driverGroupId: value.driverGroupId,
      managerGroupIds: value.managerGroupIds,
      user: {
        idUser: this.userData.id,
        FirstName: value.firstName,
        MiddleName: value.middleName,
        LastName: value.lastName,
        Email: value.email,
        UserName: value.email,
        PhoneNumber: value.phoneNumber
      }
    }, {'vrmCustom_manuallyHideProgress': 'true'}).subscribe({
      next: ({user, groups}) => {
        if (user.statusCode == 200 && groups.statusCode == 200) {          
          this.spService.triggerBehaviourprovisioningDataChange();
          this.cService.showToast(this.translate.instant('USER').successUpdateMessage, null, 'close');
          this.dialogRef.close();
        } else {
          if (user.statusCode != 200 && user.statusCode != 400) {
            this.cService.showToast(this.translate.instant('USER').UDatepdateFailed, null, 'close');
          }
          if (user.statusCode == 400) {
            this.cService.showToast(this.translate.instant('USER').userExisist, null, 'close');
          }
          if (groups.statusCode != 200 ) {
            this.cService.showToast(this.translate.instant('USER').GDatepdateFailed, null, 'close');
          }
        }
      },
      error: (err) => {
        console.log('ERROR IN UPDATE USER - ', err);
        this.cService.showToast(this.translate.instant('MESSAGESANDWARNINGS').genericError, null, 'close');
        this.cService.hideIndeterminateProgress = true;
      },
      complete: () => this.cService.hideIndeterminateProgress = true
    });
  }

  ngOnDestroy() {
    if (this._fetchCoachDataSubscription) {
        this._fetchCoachDataSubscription.unsubscribe();
    }
    if (this._createUserSubscription) {
        this._createUserSubscription.unsubscribe();
    }
    if (this._updateUserSubscription) {
      this._updateUserSubscription.unsubscribe();
  }
}

}