import { Component } from '@angular/core';

import {IToolPanel, IToolPanelParams} from "ag-grid-community";

@Component({
    selector: 'settings-tool-panel',
    templateUrl: './custom-settings-tool-panel.component.html',
    styleUrls: ['./custom-settings-tool-panel.component.scss']
})
export class CustomSettingsToolPanelComponent implements IToolPanel
{
    private params: IToolPanelParams;

    agInit(params: IToolPanelParams): void {
        this.params = params;
        this.params.api.addEventListener('modelUpdated', this.refresh.bind(this));
    }

    refresh() {
        // Most work might need to be carried out here
    }
}