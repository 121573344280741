import { Component } from '@angular/core';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'vrm-page-container',
    templateUrl: './vrm-page-container.component.html',
    styleUrls: ['./vrm-page-container.component.scss']
})
export class VrmPageContainerComponent
{
    constructor(public cService: CommonService) { }

    toggleMainSidenav() {
        this.cService.toggleMainSidenav(() => {
            this.cService.triggerMainSidenavToggledAsObservable(this.cService.mainSidenavOpened);
        });
    }
}