import { Component, InjectionToken } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
import { ReportMeta } from '../../services/reports.service';
import { OtherMiniReportOptions } from 'src/app/model/reports';
import { DriverHqService } from 'src/app/services/driver-hq.service';
import { CasualDialogTypesEnum } from 'src/app/services/casual-report.service';
import { DriverHqGuideViewerComponent } from 'src/app/dialogs/driver-hq-guide-viewer/driver-hq-guide-viewer.component';

@Component({
  selector: 'vrm-driverhq-guide',
  templateUrl: './driverhq-guide.component.html',
  styleUrls: ['./driverhq-guide.component.scss']
})

export class DriverhqGuideComponent {
  trainingMetadata: ReportMeta;
  trainingModuleData: Array<any>;

  reportOptions: OtherMiniReportOptions = {
    reportHeight: '100%'
  };


  guideList: Array<any>;
  constructor(
    private cService: CommonService,
    private dialog: MatDialog,
    private driverHqService: DriverHqService
  ) {
    setTimeout(() => {
      const pageTitle = cService.readyTranslations.guideFaq.vrmGuides;
      this.cService.setPageHeader(pageTitle);
    });

  
   
    this.driverHqService.getGuideList().subscribe(r=>{
      console.log(r)
      this.guideList=r.data;
    })
  }

  openModule(evnt, row: any) {

   this.dialog.open(DriverHqGuideViewerComponent, {data:{ selectedRow: row, casualDialogType: CasualDialogTypesEnum.guideViewer}});
   
  }
}   