import { environment } from '../environments/environment';
import { ApiEnv, ApiEnvironments } from './model/user';

export class Config {
    static commonIteration: string = '19.26'; // <-- Current sprint . release iteration of sprint
    static appVersion: string = `3.${Config.commonIteration}`;
    static devVersion: string = `8.5.${Config.commonIteration}`; // <-- Dev only - Angular ver . release increment

    // NOTE: This is fetched from the API on Login and on Load
    static defaultPage: string = 'training';

    // Date format    
    static momentDateFormatUs: string = 'YYYY-MM-DD';
    static loginRedirection:any={
        motus: "https://token.motus.com/tokenservice/edriving/sso?return_to={0}&timestamp={1}"
    }

    // static apiEnvironments: ApiEnvironments = {
    //     staging: { uri: 'https://mis-api-staging.vrm-mentor.com/', env: 'staging' },
    //     production: { uri: 'https://mis-api.vrm-mentor.com/', env: 'production' }
    // };

    //curl --location --request POST 'https://dev-apigateway.mentor.edriving.com/driverhq/api/v1/auth' --header 'Content-Type: application/json' --header 'ed_app: DriverHQ' --header 'ed_locale: en_US' --data - raw '{"firstName":"tom","lastName":"tang","companyCode":"STG_edriving","pin":"STG851004"}'

    static apiEnvironments: ApiEnvironments = {
        staging: { uri: 'https://staging-apigateway.mentor.edriving.com/driverhq/', env: 'staging', publishableKey: 'pk_test_WMqzTWoKVwz3Taqzl8422v7m', returnUrlBilling: 'http://localhost:4200/#/billing' },
        production: { uri: 'https://apigateway.mentor.edriving.com/driverhq/', env: 'production', publishableKey: 'pk_test_WMqzTWoKVwz3Taqzl8422v7m', returnUrlBilling: 'http://localhost:4200/#/billing'   }
    };
// if companyCode is motus, redirect to https://token.motus.com/tokenservice/edriving/sso?return_to=encodeURIComponent('https://driverhq.edriving.com/login_sso?companyCode=MOTUS')&timestamp=1600255793

// if companyCode is not in config, show error
    static DocumentCategories = {
        All: { text: "All", value: 0 },
        Crash_dd: { text: "Crash_dd", value: 1 },
        DrivingLicence: { text: "Driving Licence ", value: 2 },
        InsurancePolicyDocument: { text: "Insurance Policy Document ", value: 3 },
        DriveTrainingCertificate: { text: "Driver Training Certificate ", value: 4 },
        VehicleInformation: { text: " Vehicle Information", value: 5 },
        Other: { text: "Other", value: 6 },
        InsurancePolicyBusiness: { text: "Insurance Policy Business", value: 7 },
        MOTCertificate: { text: "MOT Certificate", value: 8 },
        PoliceReport: { text: "MOT Certificate", value: 9 }
    }
    static apiRoot: ApiEnv =
        (environment.production)
            ? Config.apiEnvironments.production
            : Config.apiEnvironments.staging;
} 