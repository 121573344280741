import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { agGridLocaleText } from '../../assets/i18n/ag-grid-locale-text';

import { CommonService, NumericRatingToTextEnum } from './common.service';

import { Config } from '../config';
import { VrmTableSort, ReportOption } from '../model/reports';
import { FilterColumn } from '../model/filter';
import { ColDef, ColGroupDef, GridOptions, CellClassParams } from 'ag-grid-community';
import { User } from '../model/user';

interface PotentialFilters {
  // Generic Filters
  driverFilter?: Array<string>;
  showFilter?: Array<string>;

  // Location Filters
  location1?: Array<string>;
  location2?: Array<string>;
  location3?: Array<string>;
  location4?: Array<string>;
  location5?: Array<string>;
  location6?: Array<string>;
  location7?: Array<string>;
  location8?: Array<string>;
  location9?: Array<string>;
  location10?: Array<string>;
}
interface SearchOrderPage {
  search: string;
  searchApplied: boolean;
  order: VrmTableSort;
  page: number;
  per_page: number;
  total?: number;
}

interface ReportBody {
  filter?: PotentialFilters;
  search?: any;
  order?: any;
  page?: number;
  per_page?: number;
  periodFilter?: PeriodFilter | string;
  course?: string;
}

enum VrmColumnTypes {
  text = 'text',
  number = 'number',
  decimal = 'decimal',
  percentage = 'percentage',
  date = 'date',
  email = 'email'
}

export enum ReportNameEnum {
  scorecard = 'Scorecard Index report',
  mentor = 'Mentor Index report',
  station = 'Station Performance report',
  stationDelta = 'Station Performance Delta report'
}

enum ColIdEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  pin = 'pin',
  email = 'email',

  location = 'location',
  locationRating = 'locationRating',
  location1 = 'location1',
  location2 = 'location2',
  location3 = 'location3',
  location4 = 'location4',
  location5 = 'location5',
  location6 = 'location6',
  location7 = 'location7',
  location8 = 'location8',
  location9 = 'location9',
  location10 = 'location10',
  
  indexingRating = 'indexingRating',
  indexingCoach = 'indexingCoach',
  indexingCoachCount = 'indexingCoachCount',
  indexingRiskCoach = 'indexingRiskcoach',
  indexingIndexValue = 'indexingIndexValue',
  indexingRiskCoachPlus = 'indexingRiskcoachPlus',
  indexingPcc = 'indexingPcc',
  indexingDiffLastMonth = 'indexingDiffLastMonth',
  indexingDiffLast3Month = 'indexingDiffLast3Month',
  indexingDiffLast6Month = 'indexingDiffLast6Month',
  indexingDiffLast12Month = 'indexingDiffLast12Month',
  indexingViolationsActive = 'indexingViolationsActive',
  indexingLicenceStatus = 'indexingLicenceStatus',
  indexingViolations = 'indexingViolations',

  rating = 'rating',
  profileDriver = 'profileDriver',
  defensiveDriving = 'defensiveDriving',

  courseProgress = 'courseProgress',
  picStatusDt = 'picStatusDt',
  orderStatus = 'orderStatus',
  allowMvr = 'allowMvr',
  requireDocs = 'requireDocs',
  isActive = 'isActive',
  vrmStatus = 'vrmStatus',
  mvrRating = 'mvrRating',
  mvrLicenseClass = 'mvrLicenseClass',
  orderType = 'orderType',
  mvrError = 'mvrError',
  mvrWarning = 'mvrWarning',
  mvrCompanyRating = 'mvrCompanyRating',
  consentStatus = 'consentStatus',
  mentorConsent = 'mentor_consent',
  requiredDoc = 'requiredDoc',
  licenceState = 'licenceState',

  engineOff = 'engineOff',
  accelerationRating = 'accelerationRating',
  brakingRating = 'brakingRating',
  corneringRating = 'corneringRating',
  distractionRating = 'distractionRating',
  speedRating = 'speedRating',
  hardAcceleration = 'hardAcceleration',
  hardBraking = 'hardBraking',
  hardCornering = 'hardCornering',
  distraction = 'distraction',
  speeding = 'speeding',
  seatBeltOff = 'seatBeltOff',
  reverse = 'reverse',
  idling = 'idling',
  mpg = 'mpg',

  overallRating = 'overallRating',
  overallScore = 'overallScore',
  playlist = 'playlist',

  hireDate = 'hireDate',
  status = 'status',
  privacy = 'privacy',
  altPrivacy = 'alt_privacy',
  pledge = 'pledge',
  altPledge = 'alt_pledge',

  sevenDayDiffPerc = 'sevenDayDiffPerc',
  driveTimeLastSevenPercChange = 'driveTimeLastSevenPercChange',
  distanceLastSeven = 'distanceLastSeven',
  businessTrips = 'businessTrips',
  totalDistance = 'totalDistance',
  totalDriverTime = 'totalDriverTime',
  percTotalBusinessPassenger = 'percTotalBusinessPassenger',
  percWeekBusinessPassenger = 'percWeekBusinessPassenger',
  percTotalPersonal = 'percTotalPersonal',
  percWeekPersonal = 'percWeekPersonal',

  driverRating = 'driverRating',
  journeyRating = 'journeyRating',
  vehicleRating = 'vehicleRating',
  attitudeRating = 'attitudeRating',
  knowledgeRating = 'knowledgeRating',
  behaviourRating = 'behaviourRating',
  hazardRating = 'hazardRating',

  companyRiskLevel = 'companyRiskLevel',

  completeStatus = 'completeStatus',
  completionDate = 'completionDate',
  feedbackDate = 'feedbackDate',

  ficoDelta = 'overallScoreDelta',
  distanceDelta = 'distanceDelta',
  brakingDelta = 'hardBrakingDelta',
  speedingDelta = 'speedingDelta',
  distractionDelta = 'distractionDelta',
  seatBeltOffDelta = 'seatBeltOffDelta',
  reverseDelta = 'reverseDelta',
  mpgDelta = 'mpgDelta',

  station = 'station',

  omsStatus = 'omsStatus',
  omsSect1  = 'omsSect1',
  omsSect2  = 'omsSect2',
  omsSect3  = 'omsSect3',
  omsSect4  = 'omsSect4',
  omsSect5  = 'omsSect5',
  omsSect6  = 'omsSect6',
  omsSect7  = 'omsSect7',
  omsSect8  = 'omsSect8',

  outcome   = 'outcome'

}

enum PercentageColEnums {
  collisionPercIndexScore = 'collision_perc_index_score',
  companyPercIndexScore = 'company_perc_index_score',
  incidentPercIndexScore = 'incident_perc_index_score',
  licencePercIndexScore = 'licence_perc_index_score',
  vrmPercIndexScore = 'vrm_perc_index_score'
}

export type FilterPeriod = 'day' | 'week' | 'month';
export type TimeFrame = 'Monthly' | 'Weekly' | 'Daily';

/**
 * @date format: YYYY-MM-DD
 */
interface PeriodFilter {
  period: FilterPeriod;
  date: string;
}
export interface PeriodFrequency {
  label: TimeFrame;
  value: FilterPeriod;
}
export enum TimeFrameEnums {
  monthly = 'Monthly',
  weekly = 'Weekly',
  daily = 'daily'
}
export enum TimeFrameValueEnums {
  month = 'month',
  week = 'week',
  day = 'day'
}
interface SelectedTimeFrame { 
  frequency?: PeriodFrequency; 
  timeFrame?: PeriodFilter; 
}

interface SpecificColWidthSetting {
  columnId: string; 
  width?: number;
  flex?: number;
  prioritize?: boolean;
  applicableReports?: Array<string>;
}
interface AgGridConfigInternal {
  common: GridOptions; 
  excelStyles: Array<any>; 
  statusPanels: Array<any>; 
  defaultColumnConfig: any; 
  rowClassRules: any;
  specificColWidth: Array<SpecificColWidthSetting>;
}

export interface EndpointFilterValue { 
  filterName: string;
  value: string; 
  text: string;
  translatedText?: string;
  selected?: boolean;
}
export interface EndpointFilters {
  default: string | null;
  name: string;
  translatedName?: string;
  values: Array<EndpointFilterValue>;
}

export interface ReportMeta {
  defaultPeriod?: PeriodFilter;
  endpointFilters?: Array<EndpointFilters>;
  name: string;
  structure: any;
  information?: { notification: string };
}

export interface VrmColumnStatesForAgGrid {
  reportName: string;
  columnState: any;
}

export interface ColumnSortModel {
  colId: string; 
  sort: string;
}

export interface TransformedSingleData {
  uiValue: string;
  uiColName: string;
  color?: string;
}

@Injectable({ providedIn: 'root' })
export class ReportsService {
  dataStructure: {};
  persistedStateChanged: boolean = false;
  selectedRows: Array<any> = [];
  currentReportData: Array<any> = [];
  currentReportMeta: ReportMeta;
  private _selectedRowsSubject = new BehaviorSubject(null);
  newlySelectedRowAdded$ = this._selectedRowsSubject.asObservable();

  private _commonReportChangeSubject = new BehaviorSubject(null);
  commonReportPageChanged$ = this._commonReportChangeSubject.asObservable();

  private _reportEndpointFilterChangeSubject = new BehaviorSubject(null);
  reportEndpointFilterChanged$ = this._reportEndpointFilterChangeSubject.asObservable();

  settings = {
    selectAllLimit: 100
  };
  hasTimeFrameFilter: boolean = false;
  hasStationOrDspFilter: boolean = false;
  singleEndpointFilters: EndpointFilters;
  multipleEndpointFilters: Array<EndpointFilters> = [];

  currentReportOptions: ReportOption;
  reportEndpoint = {
    dashboardActivitySummary: 'dashboard/activity_summary',
    population: 'dashboard/population',
    activitySummaryOverview: 'dashboard/activity_summary_overview',
    roadRiskSummary: 'roadrisk/summary'
  };
  commonReportOptions = {
    mobileColumns: ['name', 'pin']
  };
  agGridConfig: AgGridConfigInternal = {
    common: {
      rowDragManaged: true,
      groupIncludeFooter: true,
      animateRows: true,
      multiSortKey: 'ctrl',
      headerHeight: 35, // <-- default . See setColumnHeaderHeight() in report-component
      rowHeight: 33,
      enableRangeSelection: true,
      enableCharts: true,
      localeText: null,
      autoSizePadding: 30, // <-- allows space for the sort icon
      copyHeadersToClipboard: true,
      enableRtl: false, // <-- This is set in the constructor when rtl is in use
      
      aggFuncs: {
        vrmSum: this.customAgColumnSum
      }
    },
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'left'
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' }
    ],

    defaultColumnConfig: {
      sortable: true,
      resizable: true,
      enableRowGroup: true
    },

    excelStyles: [
      {
        id: 'agGridBlueTexts',
        font: {
          color: '#0000ff',
          bold: true
        }
      },
      {
        id: 'agGridLimeGreenTexts',
        font: {
          color: '#32cd32',
          bold: true
        }
      },
      {
        id: 'agGridGreenTexts',
        font: {
          color: '#32a852',
          bold: true
        }
      },
      {
        id: 'agGridAmberTexts',
        font: {
          color: '#ff9900',
          bold: true
        }
      },
      {
        id: 'agGridRedTexts',
        font: {
          color: '#ff0000',
          bold: true
        }
      },
      {
        id: 'agGridBlackTexts',
        font: {
          color: '#000000',
          bold: true
        }
      },
      {
        id: 'agGridGreyTexts',
        font: {
          color: '#808080',
          bold: true
        }
      },
      {
        id: 'agGridBenchmarkBackground',
        interior: {
          color: '#efefef',
          pattern: 'solid'
        }
      }
    ],

    rowClassRules: {
      'agGridBenchmarkBackground': (params: any) => {
        if (params.data && params.data.location) {
          return params.data.location === 'BENCHMARK';
        }
      }
    },

    specificColWidth: [
      { columnId: ColIdEnum.pin, width: 80, prioritize: true },
      { columnId: ColIdEnum.email, width: 125, applicableReports: [ReportNameEnum.scorecard] },

      // { columnId: ColIdEnum.location1, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location2, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location3, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location4, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location5, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location6, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location7, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location8, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location9, width: 140, applicableReports: [ReportNameEnum.scorecard] },
      // { columnId: ColIdEnum.location10, width: 140, applicableReports: [ReportNameEnum.scorecard] },

      { columnId: ColIdEnum.indexingViolationsActive, width: 80,  prioritize: true },
      { columnId: ColIdEnum.indexingViolations,       width: 80,  prioritize: true },
      { columnId: ColIdEnum.indexingIndexValue,       width: 116, prioritize: true },
      { columnId: ColIdEnum.indexingCoachCount,       width: 115, prioritize: true },
      { columnId: ColIdEnum.indexingRating,           width: 80,  prioritize: true },
      { columnId: ColIdEnum.locationRating,           width: 100, prioritize: true },
      { columnId: ColIdEnum.courseProgress,           width: 90,  prioritize: true },
      { columnId: ColIdEnum.indexingCoach,            width: 119, prioritize: true },
      { columnId: ColIdEnum.indexingLicenceStatus,    width: 90,  prioritize: true },
      { columnId: ColIdEnum.companyRiskLevel,         width: 108, prioritize: true },

      { columnId: ColIdEnum.indexingDiffLastMonth,    width: 84, prioritize: true },
      { columnId: ColIdEnum.indexingDiffLast3Month,   width: 86, prioritize: true },
      { columnId: ColIdEnum.indexingDiffLast6Month,   width: 86, prioritize: true },
      { columnId: ColIdEnum.indexingDiffLast12Month,  width: 86, prioritize: true },
      
      { columnId: ColIdEnum.hireDate, width: 87, prioritize: true },
      { columnId: ColIdEnum.status, width: 80 },
      { columnId: ColIdEnum.vrmStatus, width: 80 },
      { columnId: ColIdEnum.isActive, width: 80 },
      { columnId: ColIdEnum.completionDate, width: 100 },
      { columnId: ColIdEnum.feedbackDate, width: 100 },
      { columnId: ColIdEnum.mvrRating, width: 100 },
      { columnId: ColIdEnum.mvrLicenseClass, width: 100 },
      { columnId: ColIdEnum.orderType, width: 100 },
      { columnId: ColIdEnum.orderStatus, width: 100 },
      { columnId: ColIdEnum.mvrError, width: 100 },
      { columnId: ColIdEnum.mvrWarning, width: 100 },
      { columnId: ColIdEnum.mvrCompanyRating, width: 100 },
      { columnId: ColIdEnum.consentStatus, width: 100 },
      { columnId: ColIdEnum.requireDocs, width: 100 },
      { columnId: ColIdEnum.licenceState, width: 100 },
      { columnId: ColIdEnum.allowMvr, width: 100 },
      { columnId: ColIdEnum.pledge, width: 100 },
      { columnId: ColIdEnum.altPledge, width: 100 },
      { columnId: ColIdEnum.mentorConsent, width: 100 },
      { columnId: ColIdEnum.overallRating, width: 100 },

      { columnId: ColIdEnum.sevenDayDiffPerc, width: 80 },
      { columnId: ColIdEnum.driveTimeLastSevenPercChange, width: 100 },
      { columnId: ColIdEnum.distanceLastSeven, width: 100 },
      { columnId: ColIdEnum.businessTrips, width: 80 },
      { columnId: ColIdEnum.totalDistance, width: 80 },
      { columnId: ColIdEnum.totalDriverTime, width: 80 },
      { columnId: ColIdEnum.percTotalBusinessPassenger, width: 80 },
      { columnId: ColIdEnum.percWeekBusinessPassenger, width: 80 },
      { columnId: ColIdEnum.percTotalPersonal, width: 80 },
      { columnId: ColIdEnum.percWeekPersonal, width: 80 },
      { columnId: ColIdEnum.accelerationRating, width: 100 },
      { columnId: ColIdEnum.hardAcceleration, width: 160 },
      { columnId: ColIdEnum.engineOff, width: 160 },
      { columnId: ColIdEnum.brakingRating, width: 120 },
      { columnId: ColIdEnum.hardBraking, width: 160 },
      { columnId: ColIdEnum.corneringRating, width: 120 },
      { columnId: ColIdEnum.hardCornering, width: 160 },
      { columnId: ColIdEnum.distractionRating, width: 120 },
      { columnId: ColIdEnum.distraction, width: 160 },
      { columnId: ColIdEnum.speedRating, width: 120 },
      { columnId: ColIdEnum.speeding, width: 160 },
      { columnId: ColIdEnum.seatBeltOff, width: 160 },
      { columnId: ColIdEnum.reverse, width: 160 },
      { columnId: ColIdEnum.idling, width: 160 },
      { columnId: ColIdEnum.mpg, width: 160 },
      { columnId: ColIdEnum.driverRating, width: 120 },
      { columnId: ColIdEnum.journeyRating, width: 120 },
      { columnId: ColIdEnum.vehicleRating, width: 120 },
      { columnId: ColIdEnum.playlist, width: 80 }
    ]
  };

  iconHeaderColumns = [
    { id: 'engineOff', iconPath: '../../assets/images/mentor/engine-off-icon.png' },
    { id: 'hardAcceleration', iconPath: '../../assets/images/mentor/acceleration-icon.png' },
    { id: 'hardBraking', iconPath: '../../assets/images/mentor/braking-icon.png' },
    { id: 'hardCornering', iconPath: '../../assets/images/mentor/cornering-icon.png' },
    { id: 'distraction', iconPath: '../../assets/images/mentor/distraction-icon.png' },
    { id: 'speeding', iconPath: '../../assets/images/mentor/speed-icon.png' },
    { id: 'seatBeltOff', iconPath: '../../assets/images/mentor/seat-belt-icon.png' },
    { id: 'reverse', iconPath: '../../assets/images/mentor/reversing-icon.png' },
    { id: 'idling', iconPath: '../../assets/images/mentor/idling-icon.png' },
    { id: 'mpg', iconPath: '../../assets/images/mentor/fuel-icon.png' }
  ];

  private currentFilter: PotentialFilters = {};
  currentFilterToPersist: Array<FilterColumn> = [];
  currentDynamicColumn: FilterColumn;

  reportTextMappings: Array<{ rawValue: string; convertedValue: string; color: string }> = [];

  gridSearch = {
    text: '',
    activated: false
  };
  defaultFilters = {
    status: { filterType: 'set', values: ['Active'] }
  };
  gridState = {
    sortModel: [],
    filterModel: { ...this.defaultFilters },
    displayedColumns: [],
    hiddenColumns: [],
    columnGroups: []
  };
  availableFrequencies: Array<PeriodFrequency> = [];
  selectedTimeFrame: SelectedTimeFrame = {};

  searchOrderPageData: SearchOrderPage = {
    order: { column: 'name', direction: '' },
    page: 1,
    per_page: 200000, // To allow large data test until paging is removed
    search: '',
    searchApplied: false
  };
  endpointFilterValue: EndpointFilterValue;

  private filterData: ReportBody = {};

  constructor(
    private http: HttpClient, 
    private cService: CommonService,
    private translation: TranslateService
  ) {
    this.initialize();
  }

  initialize() {
    this.cService.userProfileReady$.subscribe({
      next: (userProfile: User) => {
        if (userProfile) {
          if (userProfile.preferences && userProfile.preferences.gridstate) {
            this.gridState = userProfile.preferences.gridstate;
            console.log('%c Server Persisted Grid State', 'color: yellow', userProfile.preferences);
          }
        }
      }
    });

    // AgGrid Local translations
    this.translation.get('AGGRIDLOCALE').subscribe({
      next: (translate) => {
        const localeTextKeys = Object.keys(agGridLocaleText);

        localeTextKeys.forEach((localeKey) => {
          if (translate[localeKey]) {
            agGridLocaleText[localeKey] = translate[localeKey];
          }
        });

        this.agGridConfig.common.localeText = agGridLocaleText;
        this.agGridConfig.common.enableRtl = this.cService.selectedLanguage.isRtl;
      }
    });

    this.translation.get('GENERIC').subscribe({
      next: (translate) => {
        this.availableFrequencies = [
          { label: <TimeFrame>translate['monthly'], value: 'month' },
          { label: <TimeFrame>translate['weekly'], value: 'week' },
          { label: <TimeFrame>translate['daily'], value: 'day' }
        ];
        this.selectedTimeFrame = {
          frequency: this.availableFrequencies[0],
          timeFrame: null
        };
      }
    });
  }

  // RxJs EVENTS
  addNewlySelectedRowAndTriggerObservable(selectedRow: any) {
    this._selectedRowsSubject.next(selectedRow);
  }

  triggerBehaviourSubjectOnNewCommonReportsPage(reportMeta: ReportMeta) {
    this._commonReportChangeSubject.next(reportMeta);
  }

  triggerReportEndpointFilterChangedObservable(filterValue: EndpointFilterValue) {
    this._reportEndpointFilterChangeSubject.next(filterValue);
  }

  clearSearch() {
    this.searchOrderPageData.search = '';
    this.searchOrderPageData.searchApplied = false;
  }

  // ---- Grid / Column Helpers ----
  public getColumnNames(structure: {}): string[] {
    const columnNames = [];

    for (const nodeName in structure) {
      if (structure.hasOwnProperty(nodeName)) {
        columnNames.push(nodeName);
      }
    }

    return columnNames;
  }
  public transformSingleDataForDetailsPane(columnName: string, value: any) 
  {
    this.reportTextMappings = [
      { rawValue: 'green',        convertedValue: this.cService.readyTranslations.reportData.low,         color: 'green' },
      { rawValue: 'yellow',       convertedValue: this.cService.readyTranslations.reportData.medium,      color: 'orange' },
      { rawValue: 'red',          convertedValue: this.cService.readyTranslations.reportData.high,        color: 'red' },

      { rawValue: 'Safe Driver',  convertedValue: this.cService.readyTranslations.reportData.safeDriver,  color: 'blue' },
      { rawValue: 'Low',          convertedValue: this.cService.readyTranslations.reportData.low,         color: 'green' },
      { rawValue: 'Medium',       convertedValue: this.cService.readyTranslations.reportData.medium,      color: 'orange' },
      { rawValue: 'High',         convertedValue: this.cService.readyTranslations.reportData.high,        color: 'red' },
      { rawValue: 'Very High',    convertedValue: this.cService.readyTranslations.reportData.veryHigh,    color: 'red' },
      { rawValue: 'very high',    convertedValue: this.cService.readyTranslations.reportData.veryHigh,    color: 'red' },
      { rawValue: 'Very high',    convertedValue: this.cService.readyTranslations.reportData.veryHigh,    color: 'red' },

      { rawValue: 'Great',    convertedValue: this.cService.readyTranslations.reportData.great,   color: 'green' },
      { rawValue: 'Good',     convertedValue: this.cService.readyTranslations.reportData.good,    color: 'green' },
      { rawValue: 'Average',  convertedValue: this.cService.readyTranslations.reportData.average, color: 'orange' },
      { rawValue: 'Poor',     convertedValue: this.cService.readyTranslations.reportData.poor,    color: 'red' },
      { rawValue: 'Risky',    convertedValue: this.cService.readyTranslations.reportData.risky,   color: 'black' },

      { rawValue: 'Active',       convertedValue: this.cService.readyTranslations.reportData.active,      color: 'green' },
      { rawValue: 'Inactive',     convertedValue: this.cService.readyTranslations.reportData.inactive,    color: 'grey' },
      { rawValue: 'Test Account', convertedValue: this.cService.readyTranslations.reportData.testAccount, color: 'grey' },
      { rawValue: 'Other',        convertedValue: this.cService.readyTranslations.reportData.other,       color: '' },
      { rawValue: 'Pre Hire',     convertedValue: this.cService.readyTranslations.reportData.preHire,     color: '' },

      { rawValue: 'Agree',    convertedValue: this.cService.readyTranslations.reportData.agree,     color: 'green' },
      { rawValue: 'Disagree', convertedValue: this.cService.readyTranslations.reportData.disagree,  color: 'orange' },
      { rawValue: 'Hold',     convertedValue: this.cService.readyTranslations.reportData.hold,      color: 'orange' },
      { rawValue: 'Yes',      convertedValue: this.cService.readyTranslations.reportData.yes,       color: 'green' },
      { rawValue: 'No',       convertedValue: this.cService.readyTranslations.reportData.no,        color: 'red' },

      { rawValue: 'Complete',     convertedValue: this.cService.readyTranslations.reportData.complete,    color: 'green' },
      { rawValue: 'Incomplete',   convertedValue: this.cService.readyTranslations.reportData.incomplete,  color: 'orange' },
      { rawValue: 'In Progress',  convertedValue: this.cService.readyTranslations.reportData.inProgress,  color: 'orange' },
      { rawValue: 'Partial',      convertedValue: this.cService.readyTranslations.reportData.partial,     color: 'orange' },
      { rawValue: 'Not Started',  convertedValue: this.cService.readyTranslations.reportData.notStarted,  color: 'red' },
      { rawValue: 'required',     convertedValue: this.cService.readyTranslations.reportData.required,    color: 'red' },
      { rawValue: 'Optional',     convertedValue: this.cService.readyTranslations.reportData.optional,    color: 'green' },
      { rawValue: 'Current',      convertedValue: this.cService.readyTranslations.reportData.current,     color: '' },

      { rawValue: 'Valid',        convertedValue: this.cService.readyTranslations.reportData.valid,       color: 'green' },
      { rawValue: 'Not Valid',    convertedValue: this.cService.readyTranslations.reportData.notValid,    color: 'red' },
      { rawValue: 'Invalid',    convertedValue: this.cService.readyTranslations.reportData.notValid,      color: 'red' },
      { rawValue: 'invalid',    convertedValue: this.cService.readyTranslations.reportData.notValid,      color: 'red' },
      { rawValue: 'See MVR',      convertedValue: this.cService.readyTranslations.reportData.seeMvr,      color: 'orange' }
    ];

    const valueStructure: { name: string; type: string } = this.dataStructure[columnName];

    if (valueStructure) {
      const transformedData: TransformedSingleData = {
        uiValue: value,
        uiColName: (valueStructure.name.toLowerCase() === 'pin') 
          ? valueStructure.name.toUpperCase() : valueStructure.name
      };
      const translatedColName = this.translation.instant('REPORTCOLUMNS')[transformedData.uiColName];
  
      if (translatedColName) {
        transformedData.uiColName = translatedColName;
      } 
  
      if (valueStructure.type === VrmColumnTypes.percentage) {
        transformedData.uiValue = this.cService.displayNumberAsPercentage(value);
      }
      else if (valueStructure.type === VrmColumnTypes.decimal) {
        if (ReportNameEnum.mentor && columnName.toLowerCase().includes('time')) {
          transformedData.uiValue = this.cService.numberToDecimalPlace(value, 1);
        }
        else if (columnName.includes('indexingDiffLast')) {
          transformedData.uiValue = this.cService.prefixNumberWithPlusSign(value, true);
          if (transformedData.uiValue.toString().startsWith('+')) {
            transformedData.color = 'red';
          }
          else {
            transformedData.color = 'green';
          }
        }
        else {
          transformedData.uiValue = this.cService.numberToDecimalPlace(value);
        }
      }
      else if (valueStructure.type === VrmColumnTypes.number) {
        if (columnName.toLowerCase() === ColIdEnum.indexingRating.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.locationRating.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.profileDriver.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.defensiveDriving.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.rating.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.driverRating.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.journeyRating.toLowerCase()
        || columnName.toLowerCase() === ColIdEnum.vehicleRating.toLowerCase()) {
          transformedData.uiValue = this.cService.mapNumericRatingToText(
            NumericRatingToTextEnum.highMediumLowNa, value);
        }
        else if (columnName.toLowerCase() === ColIdEnum.overallScore.toLowerCase()) {
          if (typeof value === 'number') {
            // Great and Good
            if (value >= this.cService.ficoScoreValues.lowRisk.from
              && value <= this.cService.ficoScoreValues.veryLowRisk.to) {
                transformedData.color = 'green';
            }
            
            // Average
            if (value >= this.cService.ficoScoreValues.mediumRisk.from
              && value <= this.cService.ficoScoreValues.mediumRisk.to) {
                transformedData.color = 'orange';
            }

            // Poor and Risky
            if (value >= 1 && value <= this.cService.ficoScoreValues.highRisk.to) {
              transformedData.color = 'red';
            }
          }
        }
        else if (columnName.toLowerCase() === ColIdEnum.companyRiskLevel.toLowerCase()) {
          if (typeof value === 'number') {
            const companyRiskLevelValues = this.cService.userProfile.companyRiskLevelValues;

            if (value < companyRiskLevelValues.low) {
              transformedData.uiValue = 
                `${this.cService.readyTranslations.reportData.safeDriver} (${value})`;
              transformedData.color = 'blue';
            }

            if (value >= companyRiskLevelValues.low && value < companyRiskLevelValues.medium) {
              transformedData.uiValue = 
                `${this.cService.readyTranslations.reportData.low} (${value})`;
              transformedData.color = 'green';
            }

            if (value >= companyRiskLevelValues.medium && value < companyRiskLevelValues.high) {
              transformedData.uiValue =
                `${this.cService.readyTranslations.reportData.medium} (${value})`;
              transformedData.color = 'orange';
            }

            if (value >= companyRiskLevelValues.high) {
              transformedData.uiValue =
                `${this.cService.readyTranslations.reportData.high} (${value})`;
              transformedData.color = 'red';
            }
          }
        }
        else if (columnName.toLowerCase() === ColIdEnum.accelerationRating.toLowerCase()
          || columnName.toLowerCase() === ColIdEnum.brakingRating.toLowerCase()
          || columnName.toLowerCase() === ColIdEnum.corneringRating.toLowerCase()
          || columnName.toLowerCase() === ColIdEnum.distractionRating.toLowerCase()
          || columnName.toLowerCase() === ColIdEnum.speedRating.toLowerCase()) {
            transformedData.uiValue = this.cService.mapNumericRatingToText(
              NumericRatingToTextEnum.poorAverageGoodNa, value);
        }
        else if (columnName.toLowerCase() === ColIdEnum.playlist.toLowerCase()) {
          transformedData.uiValue = this.cService.mapNumericRatingToText(
            NumericRatingToTextEnum.completeIncompleteNotStartedNa, value);
        }
      }
      else if (valueStructure.type === VrmColumnTypes.text && value) {
        for (let i = 0; i < this.reportTextMappings.length; i++) {
          const mapping = this.reportTextMappings[i];
  
          if (value && typeof value === 'string') {
            if (value.trim().toLowerCase().startsWith(mapping.rawValue.toLowerCase())) {
              transformedData.color = mapping.color;
              transformedData.uiValue = value.replace(mapping.rawValue, mapping.convertedValue);

              if (!transformedData.uiValue.includes('@')) {
                transformedData.uiValue = this.cService.toTitleCase(transformedData.uiValue);
              }
    
              break;
            }
          }
        }
      }

      // Apply colours based on matched uiValue
      for (let i = 0; i < this.reportTextMappings.length; i++) {
        const mapping = this.reportTextMappings[i];

        if (transformedData.uiValue && typeof transformedData.uiValue === 'string') {
          if (transformedData.uiValue.trim().toLowerCase().startsWith(mapping.rawValue.toLowerCase())) {
            transformedData.color = mapping.color;
            break;
          }
        }
      }
  
      return transformedData;
    }
  }

  convertToAgColumnDefinition(structure: any, reportName?: string): Array<ColDef> {
    const columnNames = Object.keys(structure);
    const agGridColumnDef: Array<ColDef> = [];

    const ratingsDataMapping = {
      'red': this.cService.readyTranslations.reportData.high,
      'Red': this.cService.readyTranslations.reportData.high,
      'yellow': this.cService.readyTranslations.reportData.medium,
      'Yellow': this.cService.readyTranslations.reportData.medium,
      'green': this.cService.readyTranslations.reportData.low,
      'Green': this.cService.readyTranslations.reportData.low,

      'low': this.cService.readyTranslations.reportData.low,
      'medium': this.cService.readyTranslations.reportData.medium,
      'high': this.cService.readyTranslations.reportData.high,
      'Low': this.cService.readyTranslations.reportData.low,
      'Medium': this.cService.readyTranslations.reportData.medium,
      'High': this.cService.readyTranslations.reportData.high,
      'very high': this.cService.readyTranslations.reportData.veryHigh,
      'Very high': this.cService.readyTranslations.reportData.veryHigh,
      'Very High': this.cService.readyTranslations.reportData.veryHigh
    };
    const progressDataMapping = {
      'Complete': this.cService.readyTranslations.reportData.complete,
      'complete': this.cService.readyTranslations.reportData.complete,
      'incomplete': this.cService.readyTranslations.reportData.incomplete,
      'Incomplete': this.cService.readyTranslations.reportData.incomplete,
      'Not Started': this.cService.readyTranslations.reportData.notStarted,
      'Not started': this.cService.readyTranslations.reportData.notStarted,
      'not started': this.cService.readyTranslations.reportData.notStarted,
      'Required': this.cService.readyTranslations.reportData.required,
      'required': this.cService.readyTranslations.reportData.required,
      'Optional': this.cService.readyTranslations.reportData.optional,
      'optional': this.cService.readyTranslations.reportData.optional,
      'In Progress': this.cService.readyTranslations.reportData.inProgress,
      'In progress': this.cService.readyTranslations.reportData.inProgress,
      'in progress': this.cService.readyTranslations.reportData.inProgress,
      'NA': this.cService.readyTranslations.reportData.na,
      'Na': this.cService.readyTranslations.reportData.na,
      'Current': this.cService.readyTranslations.reportData.current,
      'current': this.cService.readyTranslations.reportData.current,
      'Archive': this.cService.readyTranslations.reportData.archive,
      'archive': this.cService.readyTranslations.reportData.archive,
      'Other': this.cService.readyTranslations.reportData.other,
      'other': this.cService.readyTranslations.reportData.other,
      'Pre Hire': this.cService.readyTranslations.reportData.preHire,
      'Pre hire': this.cService.readyTranslations.reportData.preHire
    };
    const statusDataMapping = {
      'active': this.cService.readyTranslations.reportData.active,
      'Active': this.cService.readyTranslations.reportData.active,
      'inactive': this.cService.readyTranslations.reportData.inactive,
      'Inactive': this.cService.readyTranslations.reportData.inactive,
      'Hold': this.cService.readyTranslations.reportData.hold,
      'hold': this.cService.readyTranslations.reportData.hold,
      'Test Account': this.cService.readyTranslations.reportData.testAccount,
      'Test account': this.cService.readyTranslations.reportData.hold,
      'test account': this.cService.readyTranslations.reportData.hold,

      'agree': this.cService.readyTranslations.reportData.agree,
      'Agree': this.cService.readyTranslations.reportData.agree,
      'disagree': this.cService.readyTranslations.reportData.disagree,
      'Disagree': this.cService.readyTranslations.reportData.disagree,

      'Yes': this.cService.readyTranslations.reportData.yes,
      'yes': this.cService.readyTranslations.reportData.yes,
      'No': this.cService.readyTranslations.reportData.no,
      'no': this.cService.readyTranslations.reportData.no,
      'TRUE': this.cService.readyTranslations.reportData.yes,
      'True': this.cService.readyTranslations.reportData.yes,
      'true': this.cService.readyTranslations.reportData.yes,
      'FALSE': this.cService.readyTranslations.reportData.no,
      'False': this.cService.readyTranslations.reportData.no,
      'false': this.cService.readyTranslations.reportData.no,
      'Valid': this.cService.readyTranslations.reportData.valid,
      'valid': this.cService.readyTranslations.reportData.valid,
      'Not Valid': this.cService.readyTranslations.reportData.notValid,
      'Invalid': this.cService.readyTranslations.reportData.notValid,
      'invalid': this.cService.readyTranslations.reportData.notValid,
      'See MVR': this.cService.readyTranslations.reportData.seeMvr,
      'see MVR': this.cService.readyTranslations.reportData.seeMvr,
      'see Mvr': this.cService.readyTranslations.reportData.seeMvr,
      'see mvr': this.cService.readyTranslations.reportData.seeMvr
    };

    this.translation.get('REPORTCOLUMNS').subscribe({
      next: (translate) => {
        columnNames.forEach((col: string) => 
        {
          const defaultColumnWidth = 80;
          const colHeaderName: string = structure[col].name;
          const colHeaderTooltipTag = (structure[col].headerTooltip) 
            ? structure[col].headerTooltip : `${col}Tooltip`;

          const translatedColName: string = (translate[colHeaderName]) ? translate[colHeaderName] : colHeaderName;
          const translatedColHeaderTooltip: string = 
            (colHeaderTooltipTag && translate[colHeaderTooltipTag]) ? translate[colHeaderTooltipTag] : translatedColName;

          const colHeaderIcon = this.iconHeaderColumns.find(
            (headerIconCol: { id: string; iconPath: string }) => headerIconCol.id === col);
          const headerColImage = (colHeaderIcon) ?
            `<img src="${colHeaderIcon.iconPath}" class="report-header-icon" />` : '';
          
          const calculatedMinColWidth = this.cService.predictMinWidthByColName(translatedColName);

          const noPersistedSort = this.gridState.sortModel.length === 0;
          let synthBackendSortIndicator = '';
          let synthSortApplicableColId = '';

          // Show sort icon against back-end's default sort
          if (noPersistedSort && structure[col].defaultSort) {
            let sortDirectionIconClass: string = (structure[col].defaultSort.direction == 'desc') ?
              'ag-desc-sort-icon' : 'ag-asc-sort-icon';

            const columnHasLimitedWidth: SpecificColWidthSetting = 
              this.agGridConfig.specificColWidth.find(
                colWidth => colWidth.columnId.toLowerCase() === col.toLowerCase());

            if (col !== ColIdEnum.firstName && col !== ColIdEnum.lastName && columnNames.length > 10) {
              const potentialColWidth = (defaultColumnWidth > calculatedMinColWidth) ? defaultColumnWidth : calculatedMinColWidth;

              if ((columnHasLimitedWidth && columnHasLimitedWidth.width < 120) || potentialColWidth < 120) {
                sortDirectionIconClass = `${sortDirectionIconClass} apply-less-space`;
              }
            }

            synthBackendSortIndicator = `<i id="dummySortIndicator" class="${sortDirectionIconClass}"></i>`;
            synthSortApplicableColId = col;
          }

          const column: ColDef = {
            headerName: (colHeaderName && colHeaderName.toLowerCase() === 'pin') ? translatedColName.toUpperCase() : translatedColName,
            field: col,
            colId: col,
            filter: true,
            filterParams: { clearButton: true },
            headerComponentParams: {
              template: `
              <div class="ag-cell-label-container" role="presentation" title="${ translatedColHeaderTooltip }">
                <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
      
                <div ref="eLabel" class="ag-header-cell-label" role="presentation">

                  ${ headerColImage }
                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
      
                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                  ${ (col === synthSortApplicableColId) ? synthBackendSortIndicator : '' }
                  <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
      
                  <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                </div>
              </div>
              `
            }
          };

          column.pinnedRowCellRenderer = 'pinnedRowStyleComponent';
    
          if (structure[col].chartDataType) {
            column.chartDataType = structure[col].chartDataType;
          }
    
          // Set master column defaults
          if (structure[col].master) {
            if (!this.cService.isMobileViewport) {
              column.pinned = 'left';
              column.cellClass = 'lock-pinned';
            }
            
            column.checkboxSelection = true;
            column.lockPosition = true;
          }
          if (structure[col].pinned) {
            if (!this.cService.isMobileViewport) {
              column.pinned = 'left';
              column.cellClass = 'lock-pinned';  
            }
            
            column.lockPosition = true;
          }
          if (col.toLowerCase() === ColIdEnum.pin) {
            column.lockPosition = true;
          }
          if (structure[col].type && structure[col].type.toLowerCase() === 'file') {
            column.pinned = 'right';
            column.cellClass = 'lock-pinned';
            column.cellRenderer = 'fileExtToIconAndLinkButtonComponent';
          }

          // Hide / persist hidden columns
          const columnHidden = this.gridState.hiddenColumns.find(hiddenCol => hiddenCol === col);
          if (columnHidden) {
            column.hide = true;
          }

          // Persist existing sort
          const existingColumnSort: ColumnSortModel = this.gridState.sortModel.find(
            (sortModel: ColumnSortModel) => sortModel.colId === col);
          if (existingColumnSort) {
            column.sort = existingColumnSort.sort;
          }
    
          // Apply case-insensitive comparator for sorting.
          if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.text) {
            column.comparator = this.caseInsensitiveSort;
          }
    
          // Apply percentage transformations
          if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.percentage) {
            column.cellRenderer = 'numberToPercentageComponent';
            
            // NOTE: Tagging this as numericColumn helps agGrid to align it right.
            // column.type = 'numericColumn';

            // Be graceful to reports with width set independently
            if (!structure[col].width) {
              const colWidth = (defaultColumnWidth > calculatedMinColWidth) ? defaultColumnWidth : calculatedMinColWidth;

              column.width = colWidth;
            }
          }
    
          // Apply number type transformations
          if (structure[col].type && (structure[col].type.toLowerCase() === VrmColumnTypes.number
            || structure[col].type.toLowerCase() === VrmColumnTypes.decimal)) {

            if (structure[col].type.toLowerCase() === VrmColumnTypes.decimal) {
              column.cellRenderer = 'numberToDecimalPlaceComponent';
            }

            column.aggFunc = 'vrmSum';
            column.enableValue = true;
            column.sortingOrder = ['desc', 'asc', null];

            // NOTE: Tagging this as numericColumn helps agGrid to align it right.
            // column.type = 'numericColumn';
            column.filter = 'agNumberColumnFilter';

            // Be graceful to reports with width set independently
            if (!structure[col].width) {
              const colWidth = (defaultColumnWidth > calculatedMinColWidth) ? defaultColumnWidth : calculatedMinColWidth;

              column.width = colWidth;
            }
          }

          if (structure[col].name && structure[col].name.toLowerCase().includes('change last') &&
          structure[col].name.toLowerCase().includes('month')) {
            column.cellRenderer = 'prefixPlusSignToPositiveNumComponent';
          }

          if (col.toLowerCase() === ColIdEnum.accelerationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.brakingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.corneringRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.distractionRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.speedRating.toLowerCase()) {
            if (structure[col].type.toLowerCase() === VrmColumnTypes.number) {
              column.sortingOrder = ['asc', 'desc', null];
              column.cellRenderer = 'maskPoorAverageGoodRatingComponent';
              column.filter = 'customDrivingRatingFilterComponent';
            }
          }

          if (col.toLowerCase() === ColIdEnum.companyRiskLevel.toLowerCase()) {
            if (structure[col].type.toLowerCase() === VrmColumnTypes.number) {
              column.sortingOrder = ['desc', 'asc', null];
              column.cellRenderer = 'maskCompanyRiskLevelPointsComponent';
              column.filter = 'customCompanyRiskFilterComponent';
            }
          }

          if (col.toLowerCase() === ColIdEnum.indexingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.locationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.rating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.profileDriver.toLowerCase()
          || col.toLowerCase() === ColIdEnum.defensiveDriving.toLowerCase()
          || col.toLowerCase() === ColIdEnum.attitudeRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.knowledgeRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.behaviourRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.hazardRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.driverRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.journeyRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.vehicleRating.toLowerCase()) {
            if (structure[col].type.toLowerCase() === VrmColumnTypes.number) {
              column.cellRenderer = 'maskStandardHighMedLowRatingsComponent';
              column.filter = 'customStandardHighMedLowRatingComponent';
            }
          }

          if (col.toLowerCase() === ColIdEnum.playlist.toLowerCase()) {
            if (structure[col].type.toLowerCase() === VrmColumnTypes.number) {
              column.cellRenderer = 'maskCompleteIncompleteNotstartedRatingComponent';
              column.filter = 'customCompleteIncompleteNotstartedRatingFilterComponent';
            }
          }

          if (col.toLowerCase() === ColIdEnum.indexingCoach.toLowerCase()) {
            column.cellRenderer = 'gridIndexingCoachLinkButtonComponent';
          }
          else if (col.toLowerCase() === ColIdEnum.indexingLicenceStatus.toLowerCase()) {
            column.cellRenderer = 'gridSeeMvrLinkButtonComponent';
          }

          // Apply and overwrite cellRenderer 
          if (structure[col].cellRenderer) {
            column.cellRenderer = structure[col].cellRenderer;
          }
    
          // Apply date type transformations
          if (structure[col].type && structure[col].type.toLowerCase() === VrmColumnTypes.date) {
            // Be graceful to reports with width set independently
            if (!structure[col].width) {
              const colWidth = (defaultColumnWidth + 20 > calculatedMinColWidth) ? defaultColumnWidth + 20 : calculatedMinColWidth;

              column.width = colWidth;
            }

            column.filter = 'agDateColumnFilter';
            column.filterParams = {
              comparator: (filterLocalDateAtMidnight: number | any, cellValue: any) => {
                const dateAsString = cellValue;
                if (dateAsString == null) { return -1; }
            
                const dateParts = dateAsString.split('/');
                const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
                if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0; }
                if (cellDate < filterLocalDateAtMidnight) { return -1; }
                if (cellDate > filterLocalDateAtMidnight) {return 1; }
              },
    
              browserDatePicker: true,
              clearButton: true
            };
          }

          // Process specific width recommendations
          // NOTE: This must come after setting generic width for all numeric / percentage columns
          this.agGridConfig.specificColWidth.forEach((columnSetting: SpecificColWidthSetting) => {
            if (col.toLowerCase() === columnSetting.columnId.toLowerCase()) {
              let colWidth = (columnSetting.width > calculatedMinColWidth) ? columnSetting.width : calculatedMinColWidth;

              // Assign width based on priority and report where applicable
              if (columnSetting.prioritize) {
                colWidth = columnSetting.width;
              }
              else if (columnSetting.applicableReports && reportName && columnSetting.applicableReports.includes(reportName)) {
                colWidth = columnSetting.width;
              }
              else {
                if (columnSetting.columnId && columnSetting.columnId.toLowerCase() === 'email') {
                  colWidth = 0; // unset
                }
              }

              column.width = colWidth;
            }
          });

          // Add cellClass if there is one
          if (structure[col].cellClassRules) { column.cellClassRules = structure[col].cellClassRules; }

          // Add hide attribute if there is in structure
          if (structure[col].hide) { column.hide = structure[col].hide; }

          // Add width attribute if there is in structure
          if (structure[col].width) { column.width = structure[col].width; }

          // Finally, if there's no width, flex it
          // NOTE: This doesn't seem to be working even when not applying autoSizeColum via component
          if (!column.width) {
            column['flex'] = 1;
          }

          // Transform cell data
          if (col.toLowerCase() === ColIdEnum.indexingRiskCoach.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.indexingRiskCoachPlus.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.indexingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.locationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.driverRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.journeyRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.vehicleRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.mvrRating.toLowerCase()) {
              column.refData = ratingsDataMapping;
          }
    
          if (col.toLowerCase() === ColIdEnum.courseProgress.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingCoach.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingPcc.toLowerCase()
          || col.toLowerCase() === ColIdEnum.picStatusDt.toLowerCase()
          || col.toLowerCase() === ColIdEnum.orderStatus.toLowerCase()
          || col.toLowerCase() === ColIdEnum.consentStatus.toLowerCase()) {
            column.refData = progressDataMapping;
          }

          if (col.toLowerCase() === ColIdEnum.status.toLowerCase()
          || col.toLowerCase() === ColIdEnum.privacy.toLowerCase()
          || col.toLowerCase() === ColIdEnum.altPrivacy.toLowerCase()
          || col.toLowerCase() === ColIdEnum.pledge.toLowerCase()
          || col.toLowerCase() === ColIdEnum.altPledge.toLowerCase()
          || col.toLowerCase() === ColIdEnum.mentorConsent.toLowerCase()
          || col.toLowerCase() === ColIdEnum.allowMvr.toLowerCase()
          || col.toLowerCase() === ColIdEnum.requireDocs.toLowerCase()
          || col.toLowerCase() === ColIdEnum.isActive.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.indexingLicenceStatus.toLowerCase()) {
            column.refData = statusDataMapping;
          }
    
          // Cell data color configuration
          // NOTE: Data export also maps to suggested texts.With cellClass, the
          // assigned text colours are retained when exported
          if (col.toLowerCase() === ColIdEnum.accelerationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.brakingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.corneringRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.distractionRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.speedRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.playlist.toLowerCase()
          || col.toLowerCase() === ColIdEnum.status.toLowerCase()
          || col.toLowerCase() === ColIdEnum.overallRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.privacy.toLowerCase()
          || col.toLowerCase() === ColIdEnum.altPrivacy.toLowerCase()
          || col.toLowerCase() === ColIdEnum.pledge.toLowerCase()
          || col.toLowerCase() === ColIdEnum.altPledge.toLowerCase()
          || col.toLowerCase() === ColIdEnum.mentorConsent.toLowerCase()
          || col.toLowerCase() === ColIdEnum.allowMvr.toLowerCase()
          || col.toLowerCase() === ColIdEnum.requireDocs.toLowerCase()
          || col.toLowerCase() === ColIdEnum.isActive.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingLicenceStatus.toLowerCase()
          || col.toLowerCase() === ColIdEnum.completeStatus.toLowerCase()
          || col.toLowerCase() === ColIdEnum.consentStatus.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.omsStatus.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect1.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect2.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect3.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect4.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect5.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect6.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect7.toLowerCase()
          || col.toLowerCase() === ColIdEnum.omsSect8.toLowerCase()
          || col.toLowerCase() === ColIdEnum.outcome.toLowerCase()) {
            column.cellClass = (params: { value: any }) => {
              if (typeof params.value === 'string') {
                if (params.value.toLowerCase() === 'good' || params.value.toLowerCase() === 'great'
                || params.value.toLowerCase() === 'complete' || params.value.toLowerCase() === 'valid'
                || params.value.toLowerCase() === 'active' || params.value.toLowerCase() === 'agree' 
                || params.value.toLowerCase() === 'yes' || params.value.toLowerCase() === 'optional') {
                    return 'agGridGreenTexts';
                }
                else if (params.value.toLowerCase() === 'average' || params.value.toLowerCase() === 'partial'
                || params.value.toLowerCase() === 'incomplete' || params.value.toLowerCase() === 'hold'
                || params.value.toLowerCase() === 'see mvr') {
                  return 'agGridAmberTexts';
                }
                else if (params.value.toLowerCase() === 'poor' || params.value.toLowerCase() === 'disagree'
                || params.value.toLowerCase() === 'no' || params.value.toLowerCase() === 'not valid'
                || params.value.toLowerCase() === 'invalid'
                || params.value.toLowerCase() === 'not started') {
                  return 'agGridRedTexts';
                }
                else if (params.value.toLowerCase() === 'risky') {
                  return 'agGridBlackTexts';
                }
                else if (params.value.toLowerCase() === 'inactive' || params.value.toLowerCase() === 'test account') {
                  return 'agGridGreyTexts';
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.courseProgress.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingRiskCoach.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.locationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingCoach.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingRiskCoachPlus.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingPcc.toLowerCase()
          || col.toLowerCase() === ColIdEnum.orderStatus.toLowerCase()
          || col.toLowerCase() === ColIdEnum.mvrRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.companyRiskLevel.toLowerCase()
          || col.toLowerCase() === ColIdEnum.rating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.profileDriver.toLowerCase()
          || col.toLowerCase() === ColIdEnum.defensiveDriving.toLowerCase()
          || col.toLowerCase() === ColIdEnum.attitudeRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.knowledgeRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.behaviourRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.hazardRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.driverRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.journeyRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.vehicleRating.toLowerCase()) {
            column.cellClass = (params: { value: any }) => {
              if (typeof params.value === 'string') {
                if (params.value.toLowerCase() === 'green' || params.value.toLowerCase() === 'complete' 
                || params.value.toLowerCase().startsWith('low') || params.value.toLowerCase() === 'optional') {
                  return 'agGridGreenTexts';
                }
                else if (params.value.toLowerCase() === 'yellow' || params.value.toLowerCase().startsWith('medium')
                || params.value.toLowerCase() === 'incomplete') {
                  return 'agGridAmberTexts';
                }
                else if (params.value.toLowerCase() === 'red' || params.value.toLowerCase() === 'not started'
                || params.value.toLowerCase() === 'high' || params.value.toLowerCase() === 'very high' 
                || params.value.toLowerCase() === 'required') {
                  return 'agGridRedTexts';
                }
                else if (params.value.toLowerCase().startsWith('safe driver')) {
                  return 'agGridBlueTexts';
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.indexingDiffLastMonth.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.indexingDiffLast3Month.toLowerCase()
          || col.toLowerCase() === ColIdEnum.indexingDiffLast6Month.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.indexingDiffLast12Month.toLowerCase()
          || col.toLowerCase() === ColIdEnum.brakingDelta.toLowerCase()
          || col.toLowerCase() === ColIdEnum.speedingDelta.toLowerCase() 
          || col.toLowerCase() === ColIdEnum.distractionDelta.toLowerCase()
          || col.toLowerCase() === ColIdEnum.seatBeltOffDelta.toLowerCase()
          || col.toLowerCase() === ColIdEnum.reverseDelta.toLowerCase()) {
            column.cellClassRules = {
              agGridGreenTexts: (params: { value: any }) => {
                if (params.value || !isNaN(params.value)) {
                  return Number(params.value) < 0;
                }
              },
              agGridRedTexts: (params: { value: any }) => {
                if (params.value || !isNaN(params.value)) {
                  return Number(params.value) > 0;
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.ficoDelta.toLowerCase()
          || col.toLowerCase() === ColIdEnum.mpgDelta.toLowerCase()
          || col.toLowerCase() === ColIdEnum.distanceDelta.toLowerCase()) {
            column.cellClassRules = {
              agGridGreenTexts: (params: { value: any }) => {
                if (params.value || !isNaN(params.value)) {
                  return Number(params.value) > 0;
                }
              },
              agGridRedTexts: (params: { value: any }) => {
                if (params.value || !isNaN(params.value)) {
                  return Number(params.value) < 0;
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.station.toLowerCase()) {
            column.cellClass = () => {
              return 'agGridBlackTexts';
            };
          }

          if (col.toLowerCase() === ColIdEnum.accelerationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.brakingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.corneringRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.distractionRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.speedRating.toLowerCase()) {
            // NOTE: cellClassRules used here so that color applied to
            // cell-reference's text would not be overridden when exporting to Excel
            column.cellClassRules = {
              agGridRedTexts: (params: { value: any }) => {
                if (typeof params.value === 'number') {
                  return params.value >= this.cService.mentorDrivingRatingValues.highRisk.min
                  && params.value <= this.cService.mentorDrivingRatingValues.highRisk.max;
                }
              },
              agGridAmberTexts: (params: { value: number }) => {
                if (typeof params.value === 'number') {
                  return params.value >= this.cService.mentorDrivingRatingValues.mediumRisk.min
                  && params.value <= this.cService.mentorDrivingRatingValues.mediumRisk.max;
                }
              },
              agGridGreenTexts: (params: { value: number }) => {
                if (typeof params.value === 'number') {
                  return params.value >= this.cService.mentorDrivingRatingValues.lowRisk.min;
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.companyRiskLevel.toLowerCase()) 
          {
            const companyRiskLevelValues = this.cService.userProfile.companyRiskLevelValues;

            column.cellClassRules = {
              agGridBlueTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value < companyRiskLevelValues.low;
                }
              },

              agGridGreenTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value >= companyRiskLevelValues.low
                    && params.value < companyRiskLevelValues.medium;
                }
              },

              agGridAmberTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value >= companyRiskLevelValues.medium
                    && params.value < companyRiskLevelValues.high;
                }
              },

              agGridRedTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value >= companyRiskLevelValues.high;
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.indexingRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.locationRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.rating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.profileDriver.toLowerCase()
          || col.toLowerCase() === ColIdEnum.defensiveDriving.toLowerCase()
          || col.toLowerCase() === ColIdEnum.attitudeRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.knowledgeRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.behaviourRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.hazardRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.driverRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.journeyRating.toLowerCase()
          || col.toLowerCase() === ColIdEnum.vehicleRating.toLowerCase()) {
            const ratings = this.cService.highMediumLowRatingValues;

            // NOTE: cellClassRules used here so that color applied to
            // cell-reference's text would not be overridden when exporting to Excel
            column.cellClassRules = {
              agGridBlackTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === ratings.veryHigh;
                }
              },

              agGridRedTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === ratings.high;
                }
              },

              agGridAmberTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === ratings.medium;
                }
              },

              agGridGreenTexts: (params: { value: any }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === ratings.low;
                }
              }
            };
          }

          if (col.toLowerCase() === ColIdEnum.overallScore.toLowerCase()) {
            column.cellClassRules = {
              agGridGreenTexts: (params: { value: number }) => {
                if (typeof params.value === 'number') {
                  return params.value >= this.cService.ficoScoreValues.lowRisk.from 
                    && params.value <= this.cService.ficoScoreValues.veryLowRisk.to;
                }
              },
    
              agGridAmberTexts: (params: { value: number }) => {
                if (typeof params.value === 'number') {
                  return params.value >= this.cService.ficoScoreValues.mediumRisk.from 
                    && params.value <= this.cService.ficoScoreValues.mediumRisk.to;
                }
              },
    
              agGridRedTexts: (params: {value: number}) => {
                if (typeof params.value === 'number') {
                  return params.value >= 1 
                  && params.value <= this.cService.ficoScoreValues.highRisk.to;
                }
              }
            };
          }
          
          if (col.toLowerCase() === ColIdEnum.playlist.toLowerCase()) {
            column.cellClassRules = {
              agGridGreenTexts: (params: { value: number }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === this.cService.completeIncompleteNotStartedNa.complete;
                }
              },
    
              agGridAmberTexts: (params: { value: number }) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === this.cService.completeIncompleteNotStartedNa.incomplete;
                }
              },
    
              agGridRedTexts: (params: {value: number}) => {
                if (typeof params.value === 'number' && !isNaN(params.value)) {
                  return params.value === this.cService.completeIncompleteNotStartedNa.notStarted;
                }
              }
            };
          }
    
          const percColEnumTags = Object.keys(PercentageColEnums);
          percColEnumTags.forEach((tag, key) => {
            if (col.toLowerCase() === PercentageColEnums[tag].toLowerCase()) {
              column.cellClassRules = {
                agGridRedTexts: (params: {value: any}) => {
                  return params.value >= 50;
                },
    
                agGridGreenTexts: (params: {value: any}) => {
                  return params.value < 50;
                }
              };
            }
          });
    
          agGridColumnDef.push(column);
        });
      }
    });

    console.log('Transformed column structure', agGridColumnDef);
    return agGridColumnDef;
  }

  setCellValue(params: { value: string }, mappings: any) {
    const mapping = mappings[params.value];
    return (mapping) ? mapping : params.value;
  }

  getExportDataCustomization(reportStructure: any): any {
    return {
      fileName: this.cService.pageHeader.pageTitle,
      sheetName: `${this.cService.pageHeader.pageTitle} (VRM Mentor)`,

      processCellCallback: (params: { value: any; column: { colId: string; cellClass: string } }) => {
        const columnName = params.column.colId;
        const value = params.value;
        let columnDataType: string;
        
        if (reportStructure && reportStructure[columnName]) {
          columnDataType = reportStructure[columnName].type;
        }

        let exportValue: any = value;
    
        if (value || !isNaN(value)) {
          if (typeof value === 'number' && columnDataType) {
            if (columnDataType.toLowerCase() === VrmColumnTypes.percentage) {
              exportValue = this.cService.displayNumberAsPercentage(value);
            }
            else if (columnDataType.toLowerCase() === VrmColumnTypes.decimal) {
              if (ReportNameEnum.mentor && columnName.toLowerCase().includes('time')) {
                exportValue = this.cService.numberToDecimalPlace(value, 1);
              }
              else if (columnName.includes('indexingDiffLast')) {
                exportValue = this.cService.prefixNumberWithPlusSign(value, true);
              }
              else {
                exportValue = this.cService.numberToDecimalPlace(value);
              }
            }
            else if (columnDataType.toLowerCase() === VrmColumnTypes.number) {
              if (columnName.toLowerCase() === ColIdEnum.indexingRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.locationRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.rating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.profileDriver.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.defensiveDriving.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.attitudeRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.knowledgeRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.behaviourRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.hazardRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.driverRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.journeyRating.toLowerCase()
              || columnName.toLowerCase() === ColIdEnum.vehicleRating.toLowerCase()) {
                exportValue = this.cService.mapNumericRatingToText(
                  NumericRatingToTextEnum.highMediumLowNa, value);
              }
              else if (columnName.toLowerCase() === ColIdEnum.companyRiskLevel.toLowerCase()) {
                  const companyRiskLevelValues = this.cService.userProfile.companyRiskLevelValues;
      
                  if (value < companyRiskLevelValues.low) {
                    exportValue = `${this.cService.readyTranslations.reportData.safeDriver} (${value})`;
                  }
      
                  if (value >= companyRiskLevelValues.low && value < companyRiskLevelValues.medium) {
                    exportValue = `${this.cService.readyTranslations.reportData.low} (${value})`;
                  }
      
                  if (value >= companyRiskLevelValues.medium && value < companyRiskLevelValues.high) {
                    exportValue = `${this.cService.readyTranslations.reportData.medium} (${value})`;
                  }
      
                  if (value >= companyRiskLevelValues.high) {
                    exportValue = `${this.cService.readyTranslations.reportData.high} (${value})`;
                  }
              }
              else if (columnName.toLowerCase() === ColIdEnum.accelerationRating.toLowerCase()
                || columnName.toLowerCase() === ColIdEnum.brakingRating.toLowerCase()
                || columnName.toLowerCase() === ColIdEnum.corneringRating.toLowerCase()
                || columnName.toLowerCase() === ColIdEnum.distractionRating.toLowerCase()
                || columnName.toLowerCase() === ColIdEnum.speedRating.toLowerCase()) {
                  exportValue = this.cService.mapNumericRatingToText(
                    NumericRatingToTextEnum.poorAverageGoodNa, value);
              }
              else if (columnName.toLowerCase() === ColIdEnum.playlist.toLowerCase()) {
                exportValue = this.cService.mapNumericRatingToText(
                  NumericRatingToTextEnum.completeIncompleteNotStartedNa, value);
              }
            }
          }

          if (typeof exportValue === 'string') {
            if (!exportValue.includes('@')) {
              exportValue = this.cService.toTitleCase(exportValue);
            }
          }

        }

        return exportValue;
      }
    };
  }

  // Used when regenerating fewer columns for mobile
  convertColNamesToAgColDefinition(agGridColumns: Array<ColDef>, colNames: Array<string>): Array<ColDef | ColGroupDef> {
    const agGridColumnDef: Array<ColDef> = [];

    colNames.forEach((col: string) => {
      const agColumn = agGridColumns.find(agCol => agCol.field === col);

      if (agColumn) {
        agGridColumnDef.push(agColumn);
      }
    });

    return agGridColumnDef;
  }

  convertToAgGroupedColumnDefinition(groupStructure: any): Array<ColGroupDef> {
    const agGridGroupDef: Array<ColGroupDef> = [];

    groupStructure.forEach((group: any) => {
      const columnGroup: ColGroupDef = {
        headerName: group.groupName,
        children: this.convertToAgColumnDefinition(group.structure)
      };

      agGridGroupDef.push(columnGroup);
    });

    console.log('Transformed grouped column definition', agGridGroupDef);
    return agGridGroupDef;
  }

  customAgColumnSum(values: Array<number>): number {
    let sum: number = 0;
    values.forEach((value) => {
      if (typeof value === 'number') {
        sum += value;
      }
    });

    return Number(sum.toFixed(2));
  }

  caseInsensitiveSort(valueA: any, valueB: any): number {
    if (!valueA) { valueA = ''; }
    if (!valueB) { valueB = ''; }

    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }

  saveReportState(): Observable<any> {
    const stringifiedGridState = JSON.stringify(this.gridState);
    console.log('%c Stringified Report State', 'color: limegreen', stringifiedGridState);

    return this.http.post(`${Config.apiRoot.uri}preferences/gridstate`, 
      { preferences: stringifiedGridState }, 
      {
        headers: { vrmCustom_requestName: this.cService.readyTranslations.generic.reportState }
      }
    ).pipe(
      map(response => {
        // Since we only get updated user-profile on login, we need to ensure this is cached for next refresh;
        if (this.cService.userProfile.preferences) {
          this.cService.userProfile.preferences.gridstate = this.gridState;
        }
        else {
          this.cService.userProfile.preferences = {
            gridstate: this.gridState
          };
        }

        if (this.cService.userProfile.preferences.gridstate) {
          this.cService.setUserProfileToLocalStorage();
        }

        console.log('Report State Saved', response);
        return response;
      })
    );
  }

  autoPopulateManagedDriversForManagement(reportData?: Array<any>, agGridApi?: any) {
    if (this.cService.userProfile.manages && this.cService.userProfile.manages.length > 0) {
      if (reportData && this.currentReportData.length === 0 && reportData.length > 0) {
        this.currentReportData = reportData;
      }

      this.cService.userProfile.manages.forEach((driverPin) => {
          const driverData = this.currentReportData.find(data => data.pin == driverPin);
          
          if (driverData) {
            this.selectedRows.push(driverData);
          }
        
          const selectedRowIndex: number = this.currentReportData.findIndex(data => data.pin == driverPin);
          // TODO: Look into the following agGrid warning later:
          // Grid: do not use api for selection, call node.setSelected(value) instead
          // For information on node.setSelected, check: https://www.ag-grid.com/javascript-grid-selection/
          if (agGridApi) { agGridApi.selectIndex(selectedRowIndex, true, false); }
        });
    }

    this.ensureDistinctRowSelection();

    console.log('%c Load drivers managed by this user', 'color: orange', {
      selectedRows: this.selectedRows,
      currentReportData: this.currentReportData
    });
  }
  ensureDistinctRowSelection() {
    if (this.selectedRows.length > 0 && this.selectedRows[0] && this.selectedRows[0].pin) {
      this.selectedRows = Array.from(new Set(
        this.selectedRows.map((item: any) => item)
      ));
    }
  }

  // ---- COMMON REPORTS ----
  commonReport(reportName: string): Observable<any> 
  {
    const orderBy = (this.searchOrderPageData.order.direction) ? 
      {[this.searchOrderPageData.order.column] : 
      this.searchOrderPageData.order.direction} : null;
    
    this.filterData = {
      filter: this.currentFilter,
      order: orderBy,
      page: this.searchOrderPageData.page,
      per_page: this.searchOrderPageData.per_page,

      periodFilter: this.selectedTimeFrame.timeFrame,
      course: (this.endpointFilterValue) ? this.endpointFilterValue.value : '',

      // Full name search 
      // TODO: Ask Martin to remove the need for this to be sent through
      // Remove this as it is now implemented on the Front-end
      search: { }
    };

    console.log(':: Filter data', this.filterData);

    return this.http.post(`${Config.apiRoot.uri}reports/${reportName}`, this.filterData, {
      headers: { vrmCustom_requestName: reportName }
    })
    .pipe(
        map(response => {
          console.log('Report - API', response);
          return response;
        })
      );
  }

  commonReportGet(reportName: string) {
    return this.http.get(`${Config.apiRoot.uri}reports/${reportName}`, { 
      headers: { vrmCustom_requestName: reportName } 
    })
    .pipe(
        map(response => {
          console.log('Report - API', response);
          return response;
        })
      );
  }

  commonReportMeta(reportName: string): Observable<any> {
    return this.http.get(`${Config.apiRoot.uri}reports/${reportName}/meta`, { 
      headers: { vrmCustom_requestName: reportName } 
    })
    .pipe(
        map(response => {
          console.log('Report Meta', response);
          return response;
        })
      );
  }

  // ---- JOINS ----
  commonReportMetaForkJoined(reportName: string): Observable<any> {
    return forkJoin([
      this.commonReportMeta(reportName),
      this.commonReport(reportName)
    ]);
  }

  // ---- DASHBOARD: OVERVIEW ----
  dashboardOverviewForkJoined(): Observable<any> {
    return forkJoin([
      this.commonReportMetaForkJoined(this.reportEndpoint.dashboardActivitySummary),
      this.commonReport(this.reportEndpoint.population),
      this.commonReport(this.reportEndpoint.activitySummaryOverview)
    ]);
  }
}
