import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { CommonService, NumericRatingToTextEnum } from '../../services/common.service';

@Component({
    selector: 'mask-poor-average-good-rating',
    template: `
    <span>{{ value }}</span>
    `,
    styles: []
})
export class MaskPoorAverageGoodRatingComponent implements ICellRendererAngularComp
{
    value: string;

    constructor(private cService: CommonService) { }

    agInit(params: { value: any }): void {
        this.value = this.cService.mapNumericRatingToText(
            NumericRatingToTextEnum.poorAverageGoodNa, params.value);
    }

    refresh() {
        return false;
    }
}