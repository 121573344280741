import { Component, Input } from '@angular/core';

@Component({
    selector: 'counter-badge',
    template: `
    <div class="requirements-counter" *ngIf="counter">
        <small>{{ counter }}</small>
    </div>
    `,
    styles: []
})
export class CounterBadgeComponent {
    @Input() counter: number;
}