import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

// AG-GRID Licence 
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
  'eDriving_LLC__multi_2_Devs_1_Deployment_License_6_September_2020_[v2]_MTU5OTM1MDQwMDAwMA==34450b9a5f30f8e1a0cb8f35d8fcca5d'
  );

if (environment.production || environment.staging) {
  enableProdMode();

  // Don't log to console in production
  if (window) {
    const consolePropKeys = Object.keys(window.console);
    consolePropKeys.forEach((key) => {
      if (typeof window.console[key] === 'function') {
        window.console[key] = function() { };
      }
    });
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

