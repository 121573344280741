const flagRoot = '../../images/flags/';

export const vrmLanguageList = {
    vrmLanguages: [
        {
            country: 'English',
            translatedLabel: 'English',
            code: 'en',
            flag: flagRoot + 'en.svg',
            isRtl: false
        },
        {
            country: 'Spanish',
            translatedLabel: 'Español',
            code: 'es',
            flag: flagRoot + 'es.svg',
            isRtl: false
        },
        {
            country: 'French',
            translatedLabel: 'Français',
            code: 'fr',
            flag: flagRoot + 'fr.svg',
            isRtl: false
        },
        {
            country: 'German',
            translatedLabel: 'Deutsch',
            code: 'de',
            isRtl: false
        },
        {
            country: 'Italian',
            translatedLabel: 'Italiano',
            code: 'it',
            isRtl: false
        },
        {
            country: 'Korean',
            translatedLabel: '한국어',
            code: 'kr',
            isRtl: false
        },
        {
            country: 'Japanese',
            translatedLabel: '日本語',
            code: 'jp',
            isRtl: false
        },
        {
            country: 'Simplified Chinese',
            translatedLabel: '简体中文',
            code: 'cn',
            isRtl: false
        },
        {
            country: 'Traditional Chinese',
            translatedLabel: '繁體中文',
            code: 'zh',
            isRtl: false
        },
        {
            country: 'Portuguese',
            translatedLabel: 'Português',
            code: 'brpt',
            isRtl: false
        },
        {
            country: 'Turkish',
            translatedLabel: 'Türkçe',
            code: 'tr',
            isRtl: false
        },
        {
            country: 'Polish',
            translatedLabel: 'Polski',
            code: 'pl',
            isRtl: false
        },
        {
            country: 'Romanian',
            translatedLabel: 'Română',
            code: 'ro',
            isRtl: false
        }
    ]
};