import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class VrmLangTranslationLoader implements TranslateLoader {
    constructor(private httpClient: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        // This can be replaced with an actual Language CDN if so wished
        return this.httpClient.get(`../../assets/i18n/${lang}.json`);
    }
}