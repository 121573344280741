import { Component } from '@angular/core';

import { ReportOption, OtherMiniReportOptions } from 'src/app/model/reports';

// Local data imports
import { mentorCompareData, structure } from '../../data/mentor-compare-report';
import { driverIndexCompareData, groupStructure } from '../../data/driver-index-compare-report';

@Component({
    selector: 'vrm-compare',
    templateUrl: './vrm-compare.component.html',
    styleUrls: ['./vrm-compare.component.scss']
})
export class VrmCompareComponent
{
    // Mentor Compare
    mentorCompareReportTitle = 'Mentor Compare';
    mentorRptOptions: ReportOption = {
        reportEndpointName: '',
        mobileColumns: []
    };
    mentorCompareMeta: any = {
        structure: structure
    };
    rawMentorCompareData: Array<any> = mentorCompareData;

    mentorCompareOptions: OtherMiniReportOptions = {
        hasHeader: true,
        hideBookmark: false,
        reportHeight: '400px'
    };


    // Driver Index Compare
    driverIndexCompareReportTitle = 'DriverINDEX Compare';
    driverIndexRptOptions: ReportOption = {
        reportEndpointName: '',
        mobileColumns: []
    };
    driverIndexCompareMeta: any = {
        groupStructure: groupStructure
    };
    rawDriverIndexCompareData: Array<any> = driverIndexCompareData;

    driverIndexCompareOptions: OtherMiniReportOptions = {
        hasHeader: true,
        hideBookmark: true,
        reportHeight: '400px'
    };

    constructor() {}
}