

import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DrilldownFormsService, PccCrash } from '../../services/drilldown-forms.service';
import { CommonService } from '../../services/common.service';
import { ReportsService, ReportMeta } from '../../services/reports.service';

import { ReviewPeriod } from 'vrm-forms';
import { SidepaneItem } from '../../model/dialogs';
import { OtherMiniReportOptions } from 'src/app/model/reports';
import { DriverHqService } from 'src/app/services/driver-hq.service';



interface DynamicFormsEvent {
  formData: any;
  submissionId?: string;
  formComplete?: boolean;
  triggeredByAutosave: boolean;
}

interface Intervention {
  name: string;
  value: string;
  allowUserCreation?: boolean;
}


@Component({
  selector: 'app-coaching',
  templateUrl: './coaching.component.html',
  styleUrls: ['./coaching.component.scss']
})
export class CoachingComponent implements OnInit {
  firstLastNames: string;
  nameContainerWidth: string;
  historyTabDataFailedOnLoad: boolean = false;

  showDetailsBufferProgress: boolean = false;
  sidepaneItems: Array<SidepaneItem> = [];
  reportEndpointName: string;

  applicableCoachingInterventions: Array<Intervention> = [];
  pccReviewCrashes: Array<PccCrash> = [];
  canCreateAnIntervention: boolean = false;

  coachingHistoryMeta: ReportMeta;
  coachingHistory: Array<any>;
  reportOptions: OtherMiniReportOptions = {
    reportHeight: '100%'
  };

  reviewPeriods: Array<ReviewPeriod> = [];
  outstandingReviews: number;
  outstandingPccReviews: number;
  outstandingOneToOnePlusReviews: number;
  selectedCoachFormData: any;
  selectedPeriod: any;
  coachFormIsReadonly: boolean = false;
  dataReady: boolean = false;
  renderMultipleCharts: boolean = false;
  formDataUpdated: boolean = false;
  public data: any = { "selectedRow": { "firstName": "MNT89F", "lastName": "MNT89L", "pin": "MEN237888", "email": "MNT89@edriving.com", "location1": "London", "profileDriver": 0, "defensiveDriving": 0, "rating": 0, "completionDate": null }, "reportEndpointName": "roadrisk/driver_risk_rating" }

  showTable: boolean;

  constructor(
    public translate: TranslateService,
    public dfService: DrilldownFormsService,
    private driverHqService: DriverHqService,
    public cService: CommonService,
    public rptService: ReportsService
  ) { }

  ngOnInit() {
    console.log('DIALOG DATA', this.data);
    this.dfService.selectedDriver = this.data.selectedRow;
    this.showTable = true;

    setTimeout(() => {
      this.initialize();
    }, 100);

  }

  initialize() {

    this.showDetailsBufferProgress = true;
    this.reportEndpointName = this.data.reportEndpointName;

    this.fetchData();

    if (!this.cService.isPcViewport) {
      this.toggleFormPaneWidth();
    }
  }

  // EVENTS . METHODS
  toggleFormPaneWidth() {
    if (this.dfService.showFormPane) {
      this.dfService.widenForm = !this.dfService.widenForm;
    }
    else {
      this.dfService.widenForm = false;
    }
  }


  openModule($event, item) {
    setTimeout(() => {
      this.showTable = false;
      this.dfService.launchSelectedIntervention(
        item);
    }, 100);

    this.connectDynamicForm();

  }
  connectDynamicForm() {//this is a hack. it needs to be done from the dynamic form control'
    let o = [100, 200, 300, 400, 500, 700, 1000, 1500, 2000]
    o.map(t => {
      setTimeout(() => {
        document.querySelectorAll('.mat-icon').forEach(a => {
          if (a.textContent == 'close') {
            let e = a as HTMLElement;
            e.addEventListener('click', this.onShowTable.bind(this));
          }
          if (a.textContent == 'save') {
            let e = a as HTMLElement;
            e.parentElement.parentElement.style.display = "none"
          }
          if (a.textContent == 'swap_horiz') {
            let e = a as HTMLElement;
            e.style.display = "none"
          }

        })
      }, t);
    })

  }
  onShowTable() {
    this.showTable = true;
  }
  async getCoachingHistoryTabData() {
    try {
      let response = await this.driverHqService.getCoachingHistoryTabDataForkJoined(this.dfService.selectedDriver.pin).toPromise();

      this.historyTabDataFailedOnLoad = false;
      console.log(response);

      this.coachingHistoryMeta = response[0];
      this.coachingHistory = response[1].data;
      console.log(this.coachingHistory)

      setTimeout(() => {
        this.dataReady = true;
        this.showTable = true;
      });
    } catch (e) {
      this.historyTabDataFailedOnLoad = true;
    };

  }

  fetchData() {
    this.dataReady = false;
    this.getCoachingHistoryTabData();
  }


}
