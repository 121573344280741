import { Injectable } from '@angular/core';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private dataLayer: any = window.dataLayer || [];

  constructor() { }

  public trackUserId(userId: number): void {
    this.dataLayer.push('config', 'GA_MEASUREMENT_ID', {
      'user_id': userId
    });  
  }

}
