import { Pipe, PipeTransform } from '@angular/core';

import { CommonService } from '../services/common.service';

interface EnglishMonth {
    abbreviation: string; 
    full: string;
}

@Pipe({
    name: 'translateDateMonth'
})
export class TranslateDateMonthPipe implements PipeTransform 
{
    englishMonths: Array<EnglishMonth> = [
        { abbreviation: 'jan', full: 'january' }, 
        { abbreviation: 'feb', full: 'february' }, 
        { abbreviation: 'mar', full: 'march' }, 
        { abbreviation: 'apr', full: 'april' }, 
        { abbreviation: 'may', full: 'may' }, 
        { abbreviation: 'jun', full: 'june' }, 
        { abbreviation: 'jul', full: 'july' }, 
        { abbreviation: 'aug', full: 'august' }, 
        { abbreviation: 'sep', full: 'september' },
        { abbreviation: 'oct', full: 'october' },
        { abbreviation: 'nov', full: 'november' },
        { abbreviation: 'dec', full: 'december' }
    ];

    constructor(private cService: CommonService) {}

    transform(dateValue: string) {
        let translatedMonth: string = '';
        let translatedDate: string = dateValue;

        if (this.cService.userProfile && this.cService.userProfile.languageCode !== 'en') {
            for (let i = 0; i < this.englishMonths.length; i++) {
                const engMonth: EnglishMonth = this.englishMonths[i];
    
                // NOTE: Moment would already translate some languages it can match.
                // The following would only happen if no translations applied already
                if (dateValue.toLowerCase().includes(engMonth.abbreviation)) {
                    translatedMonth = this.cService.readyTranslations.generic[engMonth.abbreviation];
    
                    const useFullMonth: boolean = dateValue.toLowerCase().includes(engMonth.full);
    
                    translatedDate = (useFullMonth) 
                    ? dateValue.toLowerCase().replace(engMonth.full, translatedMonth) 
                    : dateValue.toLowerCase().replace(engMonth.abbreviation, translatedMonth);

                    translatedDate = 
                        translatedDate.toLowerCase().replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');
    
                    break;
                }
            }
        }

        return translatedDate;
    }
}