import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CommonService } from '../../services/common.service';
import { FleetReportDvcrService } from '../../services/fleet-report-dvcr.service';

@Component({
  selector: 'dvcr-notification-badge',
  templateUrl: './dvcr-notification-badge.html',
  styleUrls: ['./dvcr-notification-badge.scss']
})
export class DvcrNotificationBadgeComponent implements OnInit, OnDestroy {
  canSeeDvcrNotifications: boolean = false;
  dvcrNotificationsCount: number = 0;
  dvcrDamageDetailsS1: number = 0;
  dvcrDamageDetailsS2: number = 0;
  private _dvcrNotificationSubscription: Subscription;

  constructor(
    public cService: CommonService,
    private fleetDvcrService: FleetReportDvcrService,
    private translate: TranslateService
  ) { }
 
  ngOnInit() {
    // Load the DVCR notifications data, only for specific companies
    this.canSeeDvcrNotifications = true; // @TODO: Change this
      /*    
      this.cService.userProfile.company === 'amzl audits' ||
      this.cService.userProfile.company === 'eDriving';
      */

    if (this.canSeeDvcrNotifications) {
      this._dvcrNotificationSubscription = this.fleetDvcrService.loadNotifications().subscribe({
        next: (response) => {
          if (response) {
            for (let i = 0; i < response.length; i++) {
              this.dvcrDamageDetailsS1 = this.dvcrDamageDetailsS1 + response[i].severity1_count;
              this.dvcrDamageDetailsS2 = this.dvcrDamageDetailsS2 + response[i].severity2_count;
           }
            this.dvcrNotificationsCount = this.dvcrDamageDetailsS1 + this.dvcrDamageDetailsS2;
          }
        },
        error: error => this.cService.showToast(this.translate.instant('DVCR').failedToLoadDvcrNotifications)
      });
    }
  }

  ngOnDestroy() {
    if (this._dvcrNotificationSubscription) { this._dvcrNotificationSubscription.unsubscribe(); }
  }
}
