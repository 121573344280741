import { Component, OnDestroy } from '@angular/core';
import { Config } from '../../config';
import { ReportOption } from 'src/app/model/reports';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';
import { FleetReportDvcrService } from 'src/app/services/fleet-report-dvcr.service';
import { SeverityDetailsComponent } from './severity-details.component';
import * as moment from 'moment';
import { Credential } from 'src/app/model/credential';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fleet-report-dvcr',
  templateUrl: './fleet-report-dvcr.component.html',
  styleUrls: ['./fleet-report-dvcr.component.scss']
})
export class FleetReportDvcrComponent implements OnDestroy {

  credentials: Credential;

  dvcrOptionCsv: any = {
    skipHeader: false,
    columnGroups: false,
    skipFooters: false,
    skipGroups: false,
    skipPinnedTop: false,
    skipPinnedBottom: false,
    allColumns: false,
    onlySelected: false,
    suppressQuotes: false,
    fileName: 'DVCR',
    columnSeparator: null
  };
  fleetReportDvcrReady: boolean;
  dvcrTitle: string = '';
  fleetReportDvcrMeta: any = {
    name: 'DVCR',
    structure: {
     firstName: {
        name: 'First name',
        type: 'text'
      },
      lastName: {
        name: 'Last name',
        type: 'text'
      },
      vin: {
        name: 'VIN',
        type: 'text'
      },
      driverId: {
        name: 'Driver ID',
        type: 'number'
      },
      station: {
        name: 'Station',
        type: 'text',
      },
      preTrip: {
        name: 'Pre-Trip',
        type: 'text'
      },
      s1Pre: {
        name: 'S1',
        type: 'number',
        cellClassRules: {
          'sev-red': function (param) {
            // tslint:disable-next-line: radix
            return param.value && parseInt(param.value) > 0;
          }
        }
      },
      s2Pre: {
        name: 'S2',
        type: 'number',
        cellClassRules: {
          'sev-amber': function (param) {
            // tslint:disable-next-line: radix
            return param.value && parseInt(param.value) > 0;
          }
        }
      },
      same: {
        name: 'Same',
        type: 'text'
      },
      postTrip: {
        name: 'Post-Trip',
        type: 'date'
      },
      s1Post: {
        name: 'S1',
        type: 'number',
        cellClassRules: {
          'sev-red': function (param) {
            // tslint:disable-next-line: radix
            return param.value && parseInt(param.value) > 0;
          }
        }
      },
      s2Post: {
        name: 'S2',
        type: 'number',
        cellClassRules: {
          'sev-amber': function (param) {
            // tslint:disable-next-line: radix
            return param.value && parseInt(param.value) > 0;
          }
        }
      }
    }
  };
  fleetReportDvcrData: Array<any>;
  dvcrReportOptions: ReportOption = {
    reportEndpointName: 'Dvcr',
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        },
        {
          id: 'customDetails',
          labelDefault: 'VRM Mentor',
          labelKey: 'customDetails',
          iconKey: 'details',
          toolPanel: 'customDetailsToolPaneComponent',
          toolPanelParams: {
            moreDetails: SeverityDetailsComponent,
            apiService: null
          }
        }
      ],
      defaultToolPanel: 'customDetails',
      hiddenByDefault: false
    }
  };

  private _dateFilterSubscription: Subscription;
  private _renderReportSubscription: Subscription;

  constructor(
    private rptService: ReportsService,
    private translate: TranslateService,
    private cService: CommonService,
    private dvcrService: FleetReportDvcrService
  ) {
    // This clears the common report dates when viewing the DVCR report
    this.cService.currentPageDateOrPeriod = {};

    this.rptService.hasTimeFrameFilter = true;
    this.rptService.availableFrequencies = [{ label: 'Daily', value: 'day' }];
    this.rptService.selectedTimeFrame = {
      frequency: this.rptService.availableFrequencies[0],
      timeFrame: {
        period: 'day',
        date: moment().format(Config.momentDateFormatUs)
      }
    };
    this._dateFilterSubscription = this.cService.dateFilterChanged$.subscribe({
      next: (isCommonReport: boolean) => {
        console.log('bb', this.rptService.selectedTimeFrame);
        this.dvcrService.setSelectedDate(this.rptService.selectedTimeFrame.timeFrame.date);
        console.log('Date Filter Data CHANGED! Is Common Report:', isCommonReport);
        this._renderReport();  
      }
    });
  }

  _renderReport() {
     this.dvcrTitle = `${this.translate.instant('MESSAGESANDWARNINGS').working}...`;
     this._renderReportSubscription = this.dvcrService.getTripsData({'vrmCustom_manuallyHideProgress': 'true'}).subscribe({
      next: (response) => {
        console.log('DVCR', JSON.stringify(response));
        this.fleetReportDvcrData = response;
      },
      error: (error) => {
        console.log('ERROR', error);
        this.dvcrTitle = `:: error`;
        this.cService.hideIndeterminateProgress = true;
      },
      complete: () => {
        this.dvcrTitle = this.translate.instant('DVCR').dvcrTitle;
        this.fleetReportDvcrReady = true;
        this.cService.hideIndeterminateProgress = true;
      }
    });
    }



  ngOnDestroy(): void {
    this.rptService.availableFrequencies = 
    [
      { label: 'Monthly', value: 'month' },
      { label: 'Weekly', value: 'week' },
      { label: 'Daily', value: 'day' }
    ];
    this.rptService.selectedTimeFrame = {
      frequency: this.rptService.availableFrequencies[0],
      timeFrame: null
    };
    if (this._dateFilterSubscription) {
      this._dateFilterSubscription.unsubscribe();
    }
    if (this._renderReportSubscription) {
      this._renderReportSubscription.unsubscribe();
    }

    this.rptService.hasTimeFrameFilter = false;
  }
}
