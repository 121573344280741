import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classBasedOnValue'
})
export class ClassBasedOnValue implements PipeTransform {
  transform(valuePipe: string, otherClassesPipe: string = '') {
    let applyClass: string = '';

    const signs = {
      minus: {name: 'minussign', sign: '-'},
      plus: {name: 'plussign', sign: '+'},
    };

    const searchString: Object = {
      'poor': 'rt',
      'red': 'rt',
      'red [out_of_date]': 'rt',
      'red [new_event]': 'rt',
      'not started': 'rt',
      'complete': 'gt',
      'good': 'gt',
      'green': 'gt',
      'green [out_of_date]': 'gt',
      'green [new_event]': 'gt',
      'gt': 'gt',
      'incomplete': 'at',
      'in progress': 'at',
      'inprogress': 'at',
      'average': 'at',
      'partial': 'at',
      'yellow': 'at',
      'yellow [out_of_date]': 'at',
      'yellow [new_event]': 'at',
    };

    let value: string;
    let otherClasses: string;

    if (valuePipe && typeof valuePipe === 'string') {
      value = valuePipe.toLowerCase().trim();
      otherClasses = otherClassesPipe.toLowerCase().trim();
    } else {
      return;
    }

    if (value.startsWith(signs.minus.sign)) {
      applyClass = 'red-number';
    } else if (value.startsWith(signs.plus.sign)) {
      applyClass = 'green-number';
    } else if (searchString.hasOwnProperty(value)) {
      applyClass = searchString[value];
      switch (applyClass) {
        case 'rt':
          applyClass = 'red-text';
          break;
        case 'gt':
          applyClass = 'green-text';
          break;
        case 'at':
          applyClass = 'amber-text';
          break;
      }
    }
    applyClass += (otherClasses !== '') ? ' ' + otherClasses : '';
    return applyClass;
  }
}
