import { Directive, OnInit, Renderer2, RendererStyleFlags2, ElementRef } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';

@Directive({
    selector: '[vrm-raised-button]'
})
export class VrmRaisedButtonDirective implements OnInit
{
    constructor(
        private cService: CommonService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        const companyColor = (this.cService.userProfile && this.cService.userProfile.HqColour) ? 
            '#' + this.cService.userProfile.HqColour : 'black';

        this.renderer.setStyle(
            this.elementRef.nativeElement, 'background-color', `${ companyColor }`, RendererStyleFlags2.Important
        );

        if (!this.cService.userProfile) {
            this.renderer.setStyle(
                this.elementRef.nativeElement, 'color', 'white', RendererStyleFlags2.Important
            );
        }
    }
}