import { Injectable, ApplicationRef, NgZone } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Config } from '../config';
import { SnackBarFromComponentComponent } from '../components/snack-bar-from-component/snack-bar-from-component.component';
import { first } from 'rxjs/operators';

// Models & Data
import { navData } from '../data/nav-data';
import { Language } from '../model/language';
import { User, Navigation, NavigationChild } from '../model/user';
import { culture, roadRisk, eLearning } from '../data/course';
import { vrmLanguageList } from '../../assets/ngx-translations/vrm-language-scripts/vrm-language-list';
import { readyTranslations } from '../../assets/ngx-translations/vrm-language-scripts/ready-translations';

export enum DaysOfTheWeekEnum { sun, mon, tue, wed, thu, fri, sat }

export interface PageName {
  page: string;
  isCommonReport?: boolean;
}

export interface ReportDate {
  period: string;
  startPeriod: string;
  endPeriod: string;
}
interface PageDatePeriod {
  date?: ReportDate;
  dateComparison?: { period: string; previousPeriod: string; currentPeriod: string; };
}

interface ReportResourceLocation {
  endpoint: string;
  commonReportPath?: string;
  navigationData?: Navigation | NavigationChild;
}

export interface PageInterfaceSettings {
  hasMainNav?: boolean;
  hasMainHeader?: boolean;
  hasTitleHeader?: boolean;
  hasTranslationSwitch?: boolean;
  hasMoreOptions?: boolean;
  forceLocalPageTitle?: boolean;
}
interface PageHeader {
  // NOTE: All of them must be set by each instance
  pageTitle: string;
  canSearch: boolean;
  isDashboard: boolean;
  isReportPage: boolean;
  pageUiSettings?: PageInterfaceSettings;
}

export interface PromptDialogConfig {
  title?: string;
  titleDescription?: string;
  body?: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  clickOutsideToClose?: boolean;
}

export interface VrmFileIcon {
  icon: string;
  color?: string;
}

export type RatingToTextType = 'highMediumLowNa' | 'poorAverageGoodNa' | 'completeIncompleteNotStartedNa';
export enum NumericRatingToTextEnum {
  highMediumLowNa = 'highMediumLowNa',
  poorAverageGoodNa = 'poorAverageGoodNa',
  completeIncompleteNotStartedNa = 'completeIncompleteNotStartedNa'
}

@Injectable({ providedIn: 'root' })
export class CommonService {
  imageRoot: string = '../../assets/images/';
  companiesLogoRoot: string = '../../assets/images/companies/';


  private _reportGridDefult = {
    defaultHeight: 33
  };
  private _uploadBatchSize: number = 1;
  public get uploadBatchSize(): number {
    return this._uploadBatchSize;
  }
  private _sendActivationBatchSize: number = 10;
  public get sendActivationBatchSize(): number {
    return this._sendActivationBatchSize;
  }
  public get reportGridDefult() {
    return this._reportGridDefult;
  }
  public set reportGridDefult(value) {
    this._reportGridDefult = value;
  }

  responseTypes = {
    error: {
      unauthorized: 401,
      unprocessableEntity: 422,
      tooManyRequests: 429
    }
  };

  pageHeader: PageHeader = {
    canSearch: false,
    pageTitle: '',
    isDashboard: false,
    isReportPage: false
  };
  defaultPageUiSettings: PageInterfaceSettings = {
    hasMainNav: true,
    hasMainHeader: true,
    hasTitleHeader: true,
    hasTranslationSwitch: true,
    hasMoreOptions: true
  };

  promptConfig: PromptDialogConfig;

  regEx = {
    underscoreAndCharsAfter: new RegExp(/_.*$/)
  };

  private _mainSidenavToggled = new BehaviorSubject(null);
  mainSidenavToggled$ = this._mainSidenavToggled.asObservable();

  private _navigationReady = new BehaviorSubject(null);
  navigationReady$ = this._navigationReady.asObservable();

  private _dateFilterChanged = new BehaviorSubject(false);
  dateFilterChanged$ = this._dateFilterChanged.asObservable();

  private _userProfileReady = new BehaviorSubject(null);
  userProfileReady$ = this._userProfileReady.asObservable();

  private _languageChangedWithoutReload = new BehaviorSubject('');
  languageChangedWithoutReload$ = this._languageChangedWithoutReload.asObservable();

  /* ============================
    Shared Page-header events
  ============================= */
  private _headerSearchToggled = new BehaviorSubject(null);
  headerSearchToggled$ = this._headerSearchToggled.asObservable();

  private _headerSearchInputChanged = new BehaviorSubject(null);
  headerSearchInputChanged$ = this._headerSearchInputChanged.asObservable();

  private _downloadReportTriggered = new BehaviorSubject(null);
  downloadReportTriggered$ = this._downloadReportTriggered.asObservable();
  // -- End

  appVersion = Config.appVersion;
  appSettings = {
    waitInMsForInitialApiCalls: 2500,
    checkUpdateInHours: 3 * 60 * 60 * 1000, // <-- 3hrs
    defaultPage: Config.defaultPage,
    supportEmailAddress: 'support@virtualriskmanager.net',
    eDrivingWebsite: 'https://www.edriving.com'
  };

  permLevels = {
    TRUE: 'TRUE',
    FALSE: 'FALSE',
    view: 'view',
    viewEdit: 'view_edit',
    none: 'none',
    readonly: 'readonly',
    limited: 'limited',
    full: 'full',
    plus: 'plus',
    all: 'all',
    lite: 'lite',
    custom: 'custom',
    override: 'override'
  };

  passwordResetInProgress: boolean = false;
  passwordResetNameSpace: string = '';
  navigation: Array<Navigation> = [];
  localStorageKeys: any;
  commonReportTitle: string;
  selectedAccountAndSecTab: number = 0;
  navigatedReport: ReportResourceLocation;
  mainSidenavOpened: boolean = false;
  currentPageDateOrPeriod: PageDatePeriod = {};

  appStableSinceInitLoad$: Observable<boolean>;
  isOnline: boolean = true;
  isFullscreen: boolean = false;
  isDarkTheme: boolean = false;
  prodDataInUseInDev: boolean = false;
  productionInUse: boolean = false;
  appReady: boolean = false;
  hideIndeterminateProgress: boolean = true;
  elementIsFullscreen: boolean = false;
  currentTheme: string = 'grey-theme';
  naturalBgColor: '#fafafa';
  accentColor: string = '#795548';
  piiDisclaimer: string;
  copyright: string;
  vrmLanguages: Array<Language> = [];
  selectedLanguage: Language;
  matLangCode: string;
  languageParam: string;
  reloadingLanguage: boolean = false;
  userProfile: User = {
    preferences: {}
  };
  paginationSizeOptions = [10000, 20000, 40000, 60000, 100000, 150000, 200000];
  dataRowCanExpand: boolean = false;

  carouselItemIndex: number = 0;
  carouselIntervalInterrupter: any;
  carouselItemIndicatorList: Array<number> = [];
  carouselPaused: boolean = false;

  provisioningErrors = {
    uploadErrorGeneral: 'Errors found while uploading.Please check error below then click on \'Continue\' button to try again.',
    invalidStations: 'Station(s) not found.',
    unknownServerError: 'Server Error. Please retry.',
    usernameOrEmailExists: 'User name or email exists',
    usernameExists: 'User name exists',
    emailExists: 'Email exists',
    invalidEmail: 'Invalid Email.',
    invalidEntry: 'Invaid Entry'
  };
  ficoScoreRatings: Array<any> = [];
  ficoScoreValues = {
    veryHighRisk: { from: 100, to: 499 },
    highRisk: { from: 500, to: 559 },
    mediumRisk: { from: 560, to: 709 },
    lowRisk: { from: 710, to: 799 },
    veryLowRisk: { from: 800, to: 850 }
  };

  mentorDrivingRatingValues = {
    highRisk: { min: 100, max: 559 },
    mediumRisk: { min: 560, max: 709 },
    lowRisk: { min: 710 }
  };

  highMediumLowRatingValues = {
    veryHigh: 4,
    high: 3,
    medium: 2,
    low: 1
    // Less than 1 is NA
  };

  completeIncompleteNotStartedNa = {
    notStarted: 3,
    incomplete: 2,
    complete: 1,
    // Less than 1 is NA <-- Caters for null values
  };

  fileTypesToIcon = {
    pdf: { icon: 'far fa-file-pdf', color: '#ff0000' },
    mp4: { icon: 'far fa-file-video' },
    doc: { icon: 'far fa-file-word', color: ' #2b579a' },
    docx: { icon: 'far fa-file-word', color: ' #2b579a' }
  };

  isMobileViewport: boolean = false;
  isTabletViewport: boolean = false;
  isPcViewport: boolean = false;
  isLargePcViewport: boolean = false;


  readyTranslations = readyTranslations;

  commonReports = 'commonreports';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    private appRef: ApplicationRef, private zone: NgZone,
    private dateAdapter: DateAdapter<any>
  ) {
    this.initialize();
  }

  // RxJs EVENT
  triggerMainSidenavToggledAsObservable(opened: boolean) {
    this._mainSidenavToggled.next(opened);
  }

  private triggerNavigationReadyObservable() {
    this._navigationReady.complete();
  }

  triggerDateFilterChangedObservable(isCommonReport: boolean) {
    this._dateFilterChanged.next(isCommonReport);
  }

  triggerUserProfileReadyObservable(userProfile: User) {
    this._userProfileReady.next(userProfile);
  }

  triggerLanguageChangedObservable(langCode: string) {
    this._languageChangedWithoutReload.next(langCode);
  }

  /* ===============================
  Shared Page-header events triggers
  ================================= */
  triggerHeaderSearchToggledAsObservable(closed: boolean) {
    this._headerSearchToggled.next(closed);
  }

  triggerHeaderSearchInputChangedAsObservable(searchText: string) {
    this._headerSearchInputChanged.next(searchText);
  }

  triggerReportDownloadRequestAsObservable() {
    this._downloadReportTriggered.next(null);
  }
  // -- End

  initialize(): void {
    /**
     * NOTE
     * The app is NOT stable if timeout / intervals are running
     * from start-up. Implementing a check via this observable 
     * helps to know when all the time-related tasks are done.
     * 
     * When checking that the app is stable, if there's a process that updates
     * the DOM, such process must be implemented to run within the Angular process.
     * This is because the stability check runs outside the Angular zone, hence
     * Change Detection will not be triggered.
     */
    this.appStableSinceInitLoad$ = this.appRef.isStable;

    this.productionInUse = environment.production;

    this.copyright = `&copy; ${new Date().getFullYear()} eDriving LLC . `;

    this.localStorageKeys = {
      cookiesAccepted: this.applyPrefix('cookieAccepted'),
      language: this.applyPrefix('language'),
      isDarkTheme: this.applyPrefix('isDarkTheme'),
      currentTheme: this.applyPrefix('currentTheme'),
      accessToken: this.applyPrefix('access_token'),
      accessTokenExpiration: this.applyPrefix('access_token_exp'),
      userProfile: this.applyPrefix('userProfile'),
      selectedDriver: this.applyPrefix('selectedDriver'),
      selectedCasualReportRow: this.applyPrefix('casualReportRow')
    };

    // Languages
    this.vrmLanguages = vrmLanguageList.vrmLanguages;

    this.userProfile = JSON.parse(localStorage.getItem(this.localStorageKeys.userProfile));
    if (this.userProfile) {
      this.setSelectedLanguage(this.userProfile['languageCode']);
    }
    else {
      this.setSelectedLanguage('en');
    }
  }

  // Helper methods
  getLanguageByCode(code: string): Language {
    let language = this.vrmLanguages.find(lang => lang.code.toLowerCase() === code);
    if (!language) {
      language = this.vrmLanguages.find(lang => lang.code.toLowerCase() === 'en');
    }

    return language;
  }
  applyPrefix(suffix: string): string {
    return 'mis.vrm.eDriving.' + suffix;
  }
  applyUserProfile() {
    if (this.userProfile) {
      this.currentTheme = this.userProfile.color;
      const savedTheme = localStorage.getItem(this.localStorageKeys.currentTheme);
      if (savedTheme !== this.currentTheme) {
        localStorage.setItem(this.localStorageKeys.currentTheme, this.userProfile.color);
      }

      const langCode = (this.userProfile && this.userProfile.languageCode)
        ? this.userProfile.languageCode.toLowerCase()
        : this.languageParam;

      const selectedLanguage: Language = this.getLanguageByCode(langCode);
      this.setSelectedLanguage(langCode);

      this.processLanguageTranslations(selectedLanguage);
    }
    else {
      console.warn(':: VRM Warning: User profile not found');
    }
  }

  applyBreakpoint() {
    const breakpoint = 500;
    const mobileBreakpoint = breakpoint + 'px';
    const tabletBreakpoint = (breakpoint + 200) + 'px';
    const pcBreakpoint = (breakpoint + 400) + 'px';
    const largePcBreakpoint = (breakpoint + 1000) + 'px';

    this.isMobileViewport = this.breakpointObserver.isMatched(`(max-width: ${mobileBreakpoint})`);
    this.isTabletViewport = this.breakpointObserver.isMatched(`(max-width: ${tabletBreakpoint})`);

    this.isPcViewport = !this.breakpointObserver.isMatched(`(max-width: ${pcBreakpoint})`);
    this.isLargePcViewport = !this.breakpointObserver.isMatched(`(max-width: ${largePcBreakpoint})`);

    setTimeout(() => {
      if (this.isPcViewport) {
        this.mainSidenavOpened = true;
      }
      else {
        this.mainSidenavOpened = false;
      }
    });
  }

  /**
   * @param waitCallback If needed, ensures sidenav toggle is done and value
   * propagated before next action.
   */
  toggleMainSidenav(waitCallback?: () => void) {
    this.mainSidenavOpened = !this.mainSidenavOpened;

    setTimeout(() => {
      if (waitCallback) { waitCallback(); }
    }, 60);
  }

  setPageHeader(
    pageTitle: string, canSearch: boolean = false, isReportPage: boolean = false, isDashboard: boolean = false,
    pageUiSettings: PageInterfaceSettings = this.defaultPageUiSettings) {
    setTimeout(() => {
      if (!this.pageHeader.pageTitle || pageUiSettings.forceLocalPageTitle) {
        // NOTE: pageTitle is set in the getNavigatedReportData() method
        // to ensure the same navigation translated name is applied.
        // Only set here if not already set in the method mentioned above.
        this.pageHeader.pageTitle = pageTitle;
        if (!pageUiSettings.forceLocalPageTitle) {
          console.log('%c Should set page title as', 'color: limegreen', pageTitle);
        }
      }
      this.pageHeader.isReportPage = isReportPage;
      this.pageHeader.canSearch = canSearch;
      this.pageHeader.isDashboard = isDashboard;
      this.pageHeader.pageUiSettings = pageUiSettings;

      console.log('%c Page Header Properties', 'color: yellow', this.pageHeader);
    });

    this.awaitPageReadiness();
  }
  private awaitPageReadiness() {
    setTimeout(() => { this.appReady = true; }, this.appSettings.waitInMsForInitialApiCalls);
  }

  /**
   * @param message Shows translated 'Working...' if no message provided
   * @param action do not translate or provide action method for 'Close' or 'Refresh now' actions
   * @param actionMethod only provide when action is not 'Close' or 'Refresh now'
   */
  showToast(message?: string, duration?: number, action?: string, actionMethod?: () => void) {
    const actualMessage = message ? message : this.readyTranslations.messagesAndWarnings.working;
    const actualDuration = duration ? duration : 4000;

    let actionText = action;
    if (action) {
      if (action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
        actionText = this.readyTranslations.generic.refresh;
      }
      else if (action.toLowerCase() === 'close') {
        actionText = this.readyTranslations.generic.close;
      }
    }

    this.snackbar.open(actualMessage, actionText, {
      duration: actualDuration
    })
      .onAction().subscribe({
        next: () => {
          if (actionMethod) {
            actionMethod();
          }
          else {
            if (action) {
              if (action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
                location.reload();
              }

              if (action.toLowerCase() === 'close') {
                this.snackbar.dismiss();
              }
            }
          }
        }
      });
  }
  /**
   * @param message Shows translated 'Working...' if no message provided
   * @param action do not translate or provide action method for 'Close' or 'Refresh now' actions
   * @param actionMethod only provide when action is not 'Close' or 'Refresh now'
   */
  showToastWithoutDuration(message?: string, action?: string, actionMethod?: () => void) {
    let actionText = action;
    if (action && action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
      actionText = this.readyTranslations.generic.refresh;
    }
    else if (action && action.toLowerCase() === 'close') {
      actionText = this.readyTranslations.generic.close;
    }

    this.snackbar.open(message, actionText).onAction().subscribe({
      next: () => {
        if (actionMethod) {
          actionMethod();
        }
        else {
          if (action) {
            if (action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
              location.reload();
            }

            if (action.toLowerCase() === 'close') {
              this.snackbar.dismiss();
            }
          }
        }
      }
    });
  }
  closeCurrentToast() {
    this.snackbar.dismiss();
  }

  showTimeoutToastFromComponent(duration: number, timeoutInSeconds: number) {
    this.snackbar.openFromComponent(SnackBarFromComponentComponent, {
      duration: (duration) ? duration : 4000,
      data: {
        countdownFrom: timeoutInSeconds
      }
    }).onAction().subscribe({
      next: () => {
        this.snackbar.dismiss();
      }
    });
  }

  // Translations & Navigation
  processReadyTranslations() {
    // Errors and warning ready translations
    this.translate.get('MESSAGESANDWARNINGS').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.messagesAndWarnings);

        objectKeys.forEach((objKey) => {
          this.readyTranslations.messagesAndWarnings[objKey] = translation[objKey];
        });
      }
    });

    // Generic ready translations
    this.translate.get('GENERIC').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.generic);

        objectKeys.forEach((objKey) => {
          this.readyTranslations.generic[objKey] = translation[objKey];
        });
      }
    });

    // Form and validation
    this.translate.get('FORM').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.form);

        objectKeys.forEach((objKey) => {
          this.readyTranslations.form[objKey] = translation[objKey];
        });
      }
    });

    this.translate.get('USER').subscribe(translation => {
      const objectKeys = Object.keys(this.readyTranslations.user);

      objectKeys.forEach((objKey) => {
        this.readyTranslations.user[objKey] = translation[objKey];
      });
    });


    // Order MVR
    this.translate.get('ORDERMVR').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.orderMvr);

        objectKeys.forEach((objKey) => {
          this.readyTranslations.orderMvr[objKey] = translation[objKey];
        });
      }
    });
    // USER CSV
    this.translate.get('USER_CSV').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.userCsv);
        objectKeys.forEach((objKey) => {
          this.readyTranslations.userCsv[objKey] = translation[objKey];
        });
      }
    });

    // Resolve Alert
    this.translate.get('ALERTS').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.alerts);

        objectKeys.forEach((objKey) => {
          this.readyTranslations.alerts[objKey] = translation[objKey];
        });
      }
    });

    // Report data
    this.translate.get('REPORTDATA').subscribe({
      next: (translation) => {
        const objectKeys = Object.keys(this.readyTranslations.reportData);

        objectKeys.forEach((objKey) => {
          this.readyTranslations.reportData[objKey] = translation[objKey];
        });

        // FICO Score Ratings
        this.ficoScoreRatings = [
          {
            scoreMargin: `${this.ficoScoreValues.veryHighRisk.from} - ${this.ficoScoreValues.veryHighRisk.to}`,
            rating: (translation.veryHighRisk) ? translation.veryHighRisk : 'Very High Risk',
            color: 'red', class: 'vrm-red-label'
          },
          {
            scoreMargin: `${this.ficoScoreValues.highRisk.from} - ${this.ficoScoreValues.highRisk.to}`,
            rating: (translation.highRisk) ? translation.highRisk : 'High Risk',
            color: 'red', class: 'vrm-red-label'
          },
          {
            scoreMargin: `${this.ficoScoreValues.mediumRisk.from} - ${this.ficoScoreValues.mediumRisk.to}`,
            rating: (translation.mediumRisk) ? translation.mediumRisk : 'Medium Risk',
            color: 'yellow', class: 'vrm-yellow-label'
          },
          {
            scoreMargin: `${this.ficoScoreValues.lowRisk.from} - ${this.ficoScoreValues.lowRisk.to}`,
            rating: (translation.lowRisk) ? translation.lowRisk : 'Low Risk',
            color: 'green', class: 'vrm-green-label'
          },
          {
            scoreMargin: `${this.ficoScoreValues.veryLowRisk.from} - ${this.ficoScoreValues.veryLowRisk.to}`,
            rating: (translation.veryLowRisk) ? translation.veryLowRisk : 'Very Low Risk',
            color: 'green', class: 'vrm-green-label'
          }
        ];

        console.log('%c Ready Translations Processed', 'color: pink', this.readyTranslations);
      }
    });
  }

  private convertToMaterialLanguageCode(vrmLangCode: string): string {
    const langCodeMatch = {
      jp: 'ja-JP',
      kr: 'ko',
      brpt: 'br-PT'
    };

    return langCodeMatch[vrmLangCode];
  }
  processLanguageTranslations(language: Language): void {
    this.selectedLanguage = language;
    this.translate.use(language.code.toLowerCase()).subscribe({
      next: () => {
        moment.locale(language.code.toLowerCase());

        this.matLangCode = this.convertToMaterialLanguageCode(language.code.toLowerCase());
        this.matLangCode = (this.matLangCode) ? this.matLangCode : language.code.toLowerCase();
        this.dateAdapter.setLocale(this.matLangCode);
        // Force Day of the week to Sunday for all Languages
        this.dateAdapter.getFirstDayOfWeek = () => { return DaysOfTheWeekEnum.sun; };

        localStorage.setItem(this.localStorageKeys.language, language.code.toLowerCase());

        this.processReadyTranslations();
        this.processMainNavTranslations();

        this.triggerLanguageChangedObservable(language.code);
      }
    });
  }
  getConciseEndpointAndCommonReportNavPath(commonReportEndpoint: string): ReportResourceLocation {
    let endpoint = commonReportEndpoint.replace(/\/reports\//, '');
    if (endpoint.startsWith(`/`)) {
      endpoint = endpoint.replace(/\//, '');
    }

    return {
      endpoint: endpoint,
      commonReportPath: `#/commonreports/${endpoint.replace(/\//, `-`)}`
    };
  }
  processMainNavTranslations(): void {
    this.translate.get('DRIVER_HQ_NAVIGATION_TITLE').subscribe({
      next: translation => {

        this.navigation = [

          { name: 'Training', url: '#/training', translatedName: translation.training, hidden: false, fallbackName: '' },
          // {
          //   name: 'Driving', url: '#/performance', translatedName: translation.driving, hidden: false, fallbackName: '',
          //   children: [
          //     { name: 'DriverIndex', url: `#/performance`, translatedName: translation.driverIndex, hidden: false, fallbackName: '' },
          //     { name: 'Mentor', url: `#/performance-mentor`, translatedName: translation.mentor, hidden: false, fallbackName: '' },
          //   ],
          // },
          // { name: 'Coaching', url: '#/coaching', translatedName: translation.coaching, hidden: false, fallbackName: '' },
          // { name: 'Resources', url: '#/resources', translatedName: translation.resources, hidden: false, fallbackName: '' },
          // { name: 'Guides/Faq', url: '#/guides', translatedName: translation.guides, hidden: false, fallbackName: '' },
          //{ name: 'Billing', url: '#/billing', translatedName: translation.billing, hidden: false, fallbackName: '' }
        ];

        this.setNavigationPermissions();

        console.log('NAVIGATION', this.navigation);
        this.identifySelectedNavAndParent();
      }
    });
  }
  public setCachedLanguage(languageCode: string, reload?: boolean): void {
    if (languageCode) {
      this.languageParam = languageCode.toLowerCase();
      this.setSelectedLanguage(this.languageParam);

      if (this.userProfile) {
        this.userProfile.languageCode = this.languageParam;
        this.setUserProfileToLocalStorage();
      }
    }
    else {
      // Check for language in Local Storage
      const storedLang = localStorage.getItem(this.localStorageKeys.language);

      if (storedLang) {
        this.setSelectedLanguage(storedLang);
        this.languageParam = storedLang;
      }
      else {
        this.setSelectedLanguage('en');
        this.languageParam = 'en';

        if (this.userProfile) {
          this.userProfile.languageCode = 'en';
          this.setUserProfileToLocalStorage();
        }
      }
    }

    if (reload) {
      this.reloadingLanguage = true;
      const reloading = (this.readyTranslations.generic.reloading) ? this.readyTranslations.generic.reloading : 'Reloading';
      this.showToast(reloading + '...');
      location.reload();
    }
    else {
      this.processLanguageTranslations(this.selectedLanguage);
    }
  }

  public setSelectedLanguage(language: string): void {
    this.selectedLanguage = this.getLanguageByCode(language);
  }

  /*
    NOTE: This would only set cService's version of userProfile
  */
  public setUserProfileToLocalStorage() {
    localStorage.setItem(this.localStorageKeys.userProfile, JSON.stringify(this.userProfile));
  }

  public identifySelectedNavAndParent(): void {
    setTimeout(() => {
      this.navigation.forEach((navigation: Navigation) => {
        const currentPage = this.predictPageNameFromUrl();

        if (currentPage && currentPage.page) {
          // All OFF Initially
          navigation.selected = false;
          navigation.expanded = false;

          if (navigation.children && navigation.children.length > 0) {
            navigation.children.forEach((child: NavigationChild) => {
              child.selected = false;
            });

            // Switch Selected ON
            const hasSelectedChild = (currentPage.isCommonReport) ? navigation.children.find((child: NavigationChild) => {
              // Process Common Report
              const childLocation = this.predictPageNameFromUrl(child.url).page;

              if (childLocation) {
                const selected = childLocation.toLowerCase() === currentPage.page.toLowerCase();
                if (selected === true) {
                  child.selected = true;
                  this.getNavigatedReportData(child, true);
                }

                return selected;
              }
            }) : navigation.children.find((child: NavigationChild) => {
              const childLocation = this.predictPageNameFromUrl(child.url).page;

              if (childLocation) {
                const selected = childLocation.toLowerCase() === currentPage.page.toLowerCase();
                if (selected === true) {
                  child.selected = true;
                  this.getNavigatedReportData(child, false);
                }

                return selected;
              }
            });

            if (hasSelectedChild) {
              navigation.selected = true;
              navigation.expanded = true;
            }
          }

          // Process selection for nav without children
          if (navigation.url) {
            const navLocation = this.predictPageNameFromUrl(navigation.url);

            if (navLocation.isCommonReport) {
              const commonReportLocation = navLocation.page;

              if (commonReportLocation.toLowerCase() === currentPage.page.toLowerCase()) {
                navigation.selected = true;
                this.getNavigatedReportData(navigation, true);
              }
            }
            else {
              if (navLocation.page === currentPage.page.toLowerCase()) {
                navigation.selected = true;
                this.getNavigatedReportData(navigation, false);
              }
            }
          }

        }

      });

      if (!this.commonReportTitle) {
        const highLevelNav = this.navigation.find(nav => nav.selected === true);

        if (highLevelNav) {
          if (highLevelNav.children && highLevelNav.children.length > 0) {
            const childNavTitle = highLevelNav.children.find(child => child.selected === true);

            this.commonReportTitle = (childNavTitle.translatedName)
              ? childNavTitle.translatedName : childNavTitle.fallbackName;
          }
          else {
            this.commonReportTitle = (highLevelNav.translatedName)
              ? highLevelNav.translatedName : highLevelNav.fallbackName;
          }
        }
      }

    }, 50);
  }
  /**
   * Use especially when nav identification is likely to
   * be wrongly predicted due to manual URL manipulation 
   * by the user.
   */
  clearNavSelection() {
    this.navigation.map(nav => {
      if (nav.selected) { nav.selected = false; }

      if (nav.children) {
        nav.children.map(child => {
          if (child.selected) { child.selected = false; }
        });
      }
    });
  }

  public predictPageNameFromUrl(url?: string): PageName {
    const pageName: PageName = {
      page: '',
      isCommonReport: false
    };

    const currentLocation = (url) ? url : this.location.path();
    let locationStrings = currentLocation.split('/');
    locationStrings = locationStrings.filter(loc => loc != '' && loc != `#`);

    if (currentLocation.toLowerCase().includes(this.commonReports)) {
      pageName.page = locationStrings[1];
      pageName.isCommonReport = true;
    }
    else {
      pageName.page = locationStrings[0];
    }

    return pageName;
  }

  private getNavigatedReportData(currentNav: Navigation | NavigationChild, isCommonReport: boolean) {
    this.navigatedReport = {
      commonReportPath: (isCommonReport) ? currentNav.url : '',
      endpoint: currentNav.reportPageEndpoint,
      navigationData: currentNav
    };

    let pageTitle = currentNav.translatedName;
    if (pageTitle && pageTitle.toLowerCase().includes('edriving')) {
      // Apply trademark
      pageTitle = pageTitle.replace(/eDriving/g, 'eDriving℠');
    }

    setTimeout(() => { this.pageHeader.pageTitle = pageTitle; });
  }

  public navigateToPage(page: string) {
    const currentPage = this.predictPageNameFromUrl(page);
    if (currentPage.isCommonReport) {
      this.commonReportTitle = this.toTitleCase(currentPage.page);
    }

    this.router.navigate([page]);
    this.identifySelectedNavAndParent();
  }

  public setNavigationPermissions() {
    if (this.userProfile) {
      const userPermissions = this.userProfile.userPermissions;
      const companyPermissions = this.userProfile.companyPermissions;
      const distributorPermissions = this.userProfile.distributorPermissions;

      // The Risk Foundations are loaded dynamically from the back-end upon login so are set here
      if (this.userProfile.riskFoundationCourses) {
        culture.riskFoundation.codes = this.userProfile.riskFoundationCourses;
      }

      if (userPermissions && companyPermissions) {
        this.navigation.forEach((navigation: Navigation) => {
          const children = navigation.children;

          // AMZL related Amazon companies
          if (navigation.name.toLowerCase() === navData.dspPerformance.idName.toLowerCase()) {
            if (distributorPermissions && distributorPermissions.permMisMenuDspPerformance === this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.dvir.idName.toLowerCase()) {
            if (companyPermissions.permMisMenuDvir == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.dvcr.idName.toLowerCase()
            && this.userProfile.companyPermissions.permMisMenuDvcr === this.permLevels.TRUE) {
            navigation.hidden = !this.userProfile.fpId;
          }
          else if (navigation.name.toLowerCase() == navData.users.idName.toLowerCase() &&
            this.userProfile.companyPermissions.permMisMenuUsers === this.permLevels.TRUE) {
            if (this.userProfile.isDistributor) {
              if (this.userProfile.isIdsUser) {
                navigation.hidden = false;
              }
            } else {
              navigation.hidden = this.userProfile.userPermissions.permEditUsers === this.permLevels.none || !this.userProfile.fpId;
            }
          }

          // Non AMZL Companies | NOTE: Some of these are Amazon but not under the AMZL dist-name. E.g. Amazon Japan
          else if (navigation.name.toLowerCase() == navData.statusReport.idName.toLowerCase()) {
            if (environment.production) {
              navigation.hidden = true;
            }
            else if (companyPermissions.permMisMenuStatusReportVisible == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.culture.idName.toLowerCase()) {

            children.forEach((child: NavigationChild) => {
              child.hidden = this.hasCourseCode(culture, child);
            });

            const hasChildNav = children.find(childNav => childNav.hidden === false);
            if (hasChildNav) {
              navigation.hidden = false;
            }

          }
          else if (navigation.name.toLowerCase() == navData.riskCoach.idName.toLowerCase()) {

            // company courses returned dynamically from the API
            // only show the RiskCoach menu item when a company has at least one RiskCoach course
            if (environment.production) {
              navigation.hidden = true;
            }
            else if ((this.userProfile.riskCoachCourses) && (this.userProfile.riskCoachCourses.length)) {
              navigation.hidden = false;
            }

          }
          else if (navigation.name.toLowerCase() == navData.eLearning.idName.toLowerCase()) {
            // Same as Culture i.e. only shows when at least one eLearning course is found
            children.forEach((child: NavigationChild) => {
              child.hidden = this.hasCourseCode(eLearning, child);
            });

            const hasChildNav = children.find(childNav => childNav.hidden === false);
            if (hasChildNav) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.roadRisk.idName.toLowerCase()) {
            // Same as Culture i.e. only shows when at least one RR course is found
            children.forEach((child: NavigationChild) => {
              child.hidden = this.hasCourseCode(roadRisk, child);
            });

            const hasChildNav = children.find(childNav => childNav.hidden === false);
            if (hasChildNav) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.driverIndex.idName.toLowerCase()) {

            if (userPermissions.permIndexing == this.permLevels.view) {
              navigation.hidden = false;

              children.forEach((child: NavigationChild) => {

                if (child.name.toLowerCase() == navData.scorecard.idName.toLowerCase()) {
                  child.hidden = false; // Always shown if Indexing permission is 'view'
                }
                else if (child.name.toLowerCase() == navData.mentorByEdriving.idName.toLowerCase()) {

                  if ((companyPermissions.permIsMentor)
                    && (companyPermissions.permMisMenuMentorVisible == this.permLevels.TRUE)) {
                    child.hidden = false;
                  }

                } else if (child.name.toLowerCase() == navData.eventsTable.idName.toLowerCase()) {

                  if ((companyPermissions.permMisMenuDriverIndexEventsTableVisible == this.permLevels.TRUE)
                    && (userPermissions.permEventsTable.toLowerCase() !== this.permLevels.none)) {
                    child.hidden = false;
                  }

                } else if (child.name.toLowerCase() == navData.riskLevelChanges.idName.toLowerCase()) {
                  if ((userPermissions.permIndexing === this.permLevels.view)
                    && (this.userProfile.companySettings.pointsSystem === 'yes')
                    && (this.userProfile.companySettings.pointsRating === 'yes')
                  ) {
                    child.hidden = false;
                  }

                }
              });
            }
          }
          else if (navigation.name.toLowerCase() == navData.mvrPlus.idName.toLowerCase()) {
            if (userPermissions.permUsMvr
              && userPermissions.permUsMvr.toLowerCase() !== this.permLevels.none) {
              navigation.hidden = false;

              children.forEach((child: NavigationChild) => {
                if (child.name.toLowerCase() === navData.drm.idName.toLowerCase()) {
                  if (companyPermissions.permDrm === this.permLevels.TRUE) {
                    child.hidden = false;
                  }
                }
              });
            }
          }
          else if (navigation.name.toLowerCase() == navData.mentorTspSummary.idName.toLowerCase()) {
            if (companyPermissions.permMisMenuMentorTsp == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.vrmAlerts.idName.toLowerCase()
            && userPermissions.permVrmAlerts.toLowerCase() !== this.permLevels.none) {
            if (companyPermissions.permMisAlerts == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.driverPerformance.idName.toLowerCase()) {
            if (companyPermissions.permMisMenuDriverPerformance == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() == navData.stationReport.idName.toLowerCase()) {
            if (companyPermissions.permMisMenuStationPerformance == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() === navData.guidesFaq.idName.toLowerCase()) {
            if (companyPermissions.permMisMenuFaq == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() === navData.contactUs.idName.toLowerCase()) {
            if (companyPermissions.permMisMenuContactUs == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }
          else if (navigation.name.toLowerCase() === navData.threeSixty.idName.toLowerCase()) {
            if (companyPermissions.permMisMenu360 == this.permLevels.TRUE) {
              navigation.hidden = false;
            }
          }

          // TODO: Hide the Delta comparison reports in Production
          // if (environment.production) {

          //   if (((navigation.name.toLowerCase() == navData.driverPerformance.idName.toLowerCase()) 
          //      || (navigation.name.toLowerCase() == navData.stationReport.idName.toLowerCase())) && 
          //       (navigation.children.length)) {

          //       navigation.children.forEach((child: NavigationChild) => {
          //         if (child.name.toLowerCase() === 'comparison') {
          //           child.hidden = true;
          //         }
          //       });

          //   }

          // }
        });
      }

      this.triggerNavigationReadyObservable();
    }
  }

  public mvrPageHidden(): boolean {
    let hidden = true;

    if (this.userProfile.userPermissions.permUsMvr
      && this.userProfile.userPermissions.permUsMvr.toLowerCase() !== this.permLevels.none) {
      hidden = false;
    }

    return hidden;
  }

  private hasCourseCode(course: any, navigation: Navigation | NavigationChild): boolean {
    let hidden: boolean = true;
    const courseKeys = Object.keys(course);

    for (let i = 0; i < courseKeys.length; i++) {
      const courseKey = courseKeys[i];

      if (navigation.name.toLowerCase() === course[courseKey].name.toLowerCase()) {
        for (let x = 0; x < course[courseKey].codes.length; x++) {
          const courseCode = course[courseKey].codes[x];
          if (this.userProfile.courseList.includes(courseCode)) {
            hidden = false;
            break;
          }
        }

        break;
      }
    }

    return hidden;
  }

  public passwordMismatch(currentPass: string, newPass: string): boolean {
    const mismatch = currentPass !== newPass;
    return mismatch;
  }

  public convertMinToSeconds(minutes: number): number {
    // Must never return zero or less
    const minimumSeconds = 10;
    const convertedSeconds = minutes * 60;

    return (convertedSeconds <= 0) ? minimumSeconds : convertedSeconds;
  }
  public convertSecondsToMilliseconds(seconds: number): number {
    let convertedMilliseconds = 0;

    if (seconds > 0) {
      convertedMilliseconds = seconds * 1000;
    }

    return convertedMilliseconds;
  }

  /**
   * @param value Value to be displayed as percentage.
   * decimal will be removed.
   */
  public displayNumberAsPercentage(value: any): string {
    let convertedValue = value;

    if ((value || !isNaN(value)) && (value != null || value != undefined)) {
      convertedValue = (value.toString().includes('.'))
        ? Number(value).toFixed(0) + '%'
        : value + '%';
    }

    return convertedValue;
  }
  /**
   * @param value Value to be converted to decimal
   * @param decimalPlaces Optional Decimal places, defaults to 2 if already has more than 2 decimals.
   * Otherwise, it'll default to 1 for data already returning 1 decimal place.
   */
  public numberToDecimalPlace(value: any, decimalPlaces: number = 2) {
    let convertedValue = value;

    if ((value != null || value != undefined) && !isNaN(value)) {
      convertedValue = Number(value).toFixed(decimalPlaces);
    }

    return convertedValue;
  }
  public prefixNumberWithPlusSign(value: number, hasDecimal: boolean): any {
    let decimalValue: string;
    let finalValue: any = value;

    if (typeof value == 'number' && value != null && value != undefined) {
      if (hasDecimal) {
        decimalValue = this.numberToDecimalPlace(value);
        finalValue = (value > 0) ? '+' + decimalValue : decimalValue;
      }
      else {
        finalValue = (value > 0) ? '+' + value : value;
      }
    }

    return finalValue;
  }
  public mapNumericRatingToText(ratingType: RatingToTextType, value: number): string {
    let translatedMappedRatingText = this.readyTranslations.reportData.na;

    if (!isNaN(value)) {
      if (ratingType === NumericRatingToTextEnum.highMediumLowNa) {
        if (value === this.highMediumLowRatingValues.veryHigh) {
          translatedMappedRatingText = this.readyTranslations.reportData.veryHigh;
        }

        if (value === this.highMediumLowRatingValues.high) {
          translatedMappedRatingText = this.readyTranslations.reportData.high;
        }

        if (value === this.highMediumLowRatingValues.medium) {
          translatedMappedRatingText = this.readyTranslations.reportData.medium;
        }

        if (value === this.highMediumLowRatingValues.low) {
          translatedMappedRatingText = this.readyTranslations.reportData.low;
        }
      }
      else if (ratingType === NumericRatingToTextEnum.poorAverageGoodNa) {
        if (value === 0) {
          translatedMappedRatingText = this.readyTranslations.reportData.noData;
        }
        else if (value <= this.mentorDrivingRatingValues.highRisk.max) {
          translatedMappedRatingText = this.readyTranslations.reportData.highRisk;
        }
        else if (value >= this.mentorDrivingRatingValues.mediumRisk.min
          && value <= this.mentorDrivingRatingValues.mediumRisk.max) {
          translatedMappedRatingText = this.readyTranslations.reportData.mediumRisk;
        }
        else {
          translatedMappedRatingText = this.readyTranslations.reportData.lowRisk;
        }
      }
      else if (ratingType === NumericRatingToTextEnum.completeIncompleteNotStartedNa) {
        if (value === this.completeIncompleteNotStartedNa.notStarted) {
          translatedMappedRatingText = this.readyTranslations.reportData.notStarted;
        }

        if (value === this.completeIncompleteNotStartedNa.incomplete) {
          translatedMappedRatingText = this.readyTranslations.reportData.incomplete;
        }

        if (value === this.completeIncompleteNotStartedNa.complete) {
          translatedMappedRatingText = this.readyTranslations.reportData.complete;
        }
      }
      else {
        console.error('VRM ERROR: NumericToTextRating mapping not supported');
      }
    }

    return translatedMappedRatingText;
  }

  public predictMinWidthByColName(columnName: string): number {
    if (columnName) {
      let predictedWidth: number;
      const colNameWords: Array<string> = columnName.split(' ');
      let pixelPerChar: number;

      const charCount: number = (colNameWords.length > 1)
        ? this.getLongestWord(colNameWords).length
        : columnName.split('').length;

      pixelPerChar = (charCount > 8) ? 11.5 : 15;
      predictedWidth = charCount * pixelPerChar;

      return predictedWidth;
    }
  }
  public predictMinWidthInPixelByWord(wordOrPhrase: string): number {
    let predictedWidth: number;
    const pixelPerChar: number = 11;
    const wordArray = wordOrPhrase.split(' ');
    const charCount: number = (wordArray.length > 1)
      ? this.getLongestWord(wordArray).length
      : wordOrPhrase.length;

    predictedWidth = charCount * pixelPerChar;

    return predictedWidth;
  }
  private getLongestWord(words: Array<string>): string {
    let longestWord: string;

    words.forEach((word: string, key: number) => {
      const nextKey: number = key + 1;
      const currentWordLength: number = (word) ? word.split('').length : 0;
      const nextWordLength: number = (words[key + 1]) ? words[nextKey].length : 0;
      let cachedLongestWord: string;

      if (nextWordLength > currentWordLength) {
        cachedLongestWord = words[nextKey];
      }
      else {
        cachedLongestWord = word;
      }

      if (longestWord) {
        if (cachedLongestWord.split('').length > longestWord.split('').length) {
          longestWord = cachedLongestWord;
        }
      }
      else {
        longestWord = cachedLongestWord;
      }
    });

    return longestWord;
  }

  public getInitialFromNames(names: string): string {
    let initial: string;

    const nameArray: Array<string> = names.split(' ');
    if (nameArray.length > 1) {
      initial = nameArray[0].substring(0, 1) + nameArray[1].substring(0, 1);
    }
    else {
      initial = names.substring(0, 1);
    }

    return initial.toUpperCase();
  }

  public toTitleCase(text: string): string {
    const words = text.split(' ');
    let titleText = '';
    words.forEach((word: string) => {
      const titleCaseWord = this.wordToTitleCase(word);
      titleText += titleCaseWord + ' ';
    });

    return titleText.trim();
  }
  private wordToTitleCase(word: string) {
    const characters = word.split('');
    let titleCase = '';
    characters.forEach((char: string, key: number) => {
      char = (key === 0) ? char.toUpperCase() : char.toLowerCase();
      titleCase += char;
    });

    return titleCase;
  }
  public toVrmCaps(sentence: string): string {
    let modifiedSentence = sentence;
    const vrmCapitalWords = [
      'VRM', 'DriverINDEX', 'MVR', 'MVR+', 'RoadRISK', 'DRM',
      'FAQ', 'LLC', 'DSP', 'DVCR', 'eDriving'
    ];

    if (sentence) {
      vrmCapitalWords.forEach((vrmCap) => {
        if (sentence.toLowerCase().includes(vrmCap.toLowerCase())) {
          const wordSearch = new RegExp(vrmCap, 'gi');
          modifiedSentence = sentence.replace(wordSearch, vrmCap);
        }
      });
    }

    return modifiedSentence;
  }

  public getApplicableIconForExtension(extension: string): VrmFileIcon {
    const ext = (extension) ? extension.trim().replace(/\./g, '') : '';
    const unknownFile: VrmFileIcon = { icon: 'far fa-file' };
    let fileIcon: VrmFileIcon = unknownFile;

    if (extension) {
      fileIcon = this.fileTypesToIcon[ext];

      if (!fileIcon) {
        fileIcon = unknownFile;
      }
    }

    return fileIcon;
  }

  // NOTE: Use pipe version when calling from DOM
  convertHexColorToRgba(transparency: number, hexColorCode?: string): string {
    if (this.userProfile) {
      hexColorCode = (hexColorCode) ? hexColorCode.trim() : this.userProfile.HqColour.trim();
      hexColorCode = (hexColorCode.startsWith('#')) ? hexColorCode : '#' + hexColorCode;

      const hexPattern: RegExp = /^#([A-Fa-f0-9]{3}){1,2}$/;

      if (hexPattern.test(hexColorCode)) {
        let rgbaBuilder = [];
        const iterableHexCode = hexColorCode.substring(1).split('');
        const shorthandInUse = iterableHexCode.length === 3;
        let builtHex: any;

        if (shorthandInUse) {
          rgbaBuilder = [
            iterableHexCode[0], iterableHexCode[0],
            iterableHexCode[1], iterableHexCode[1],
            iterableHexCode[2], iterableHexCode[2]
          ];
        }
        else {
          rgbaBuilder = hexColorCode.substring(1).split('');
        }

        builtHex = '0x' + rgbaBuilder.join('');

        // tslint:disable-next-line: no-bitwise
        const rgbaPart1 = (builtHex >> 16) & 255;
        // tslint:disable-next-line: no-bitwise
        const rgbaPart2 = (builtHex >> 8) & 255;
        // tslint:disable-next-line: no-bitwise
        const rgbaPart3 = builtHex & 255;

        const finalRgba = `rgba(${[rgbaPart1, rgbaPart2, rgbaPart3].join(',')}, ${transparency})`;

        return finalRgba;
      }

      console.error(':: VRM Error: Pass in a correct HEX Colour code');
    }
    else {
      console.error(':: VRM Error: Profile data unavailable. Unable to convert HEX Color to RGB');
    }
  }

  /**
   * NOTE
   * If calling the method, ensure that it is also called onDestroy with pauseOrDestroy set to true.
   */
  carouselDriver(listLength: number, pausedOrDestroy: boolean = false, delayInMs: number = 12000) {
    this.carouselItemIndicatorList = new Array(listLength);
    this.carouselPaused = pausedOrDestroy;
    const boundary = listLength - 1;

    if (!pausedOrDestroy) {
      this.appStableSinceInitLoad$.pipe(
        first(stable => stable)).subscribe({
          next: (appStatus) => this.zone.run(() => {
            this.carouselIntervalInterrupter = setInterval(() => {
              if (this.carouselItemIndex >= boundary) {
                this.carouselItemIndex = 0;
              }
              else {
                this.carouselItemIndex++;
              }
            },
              delayInMs);
          })
        });
    }
    else {
      if (this.carouselIntervalInterrupter) {
        clearInterval(this.carouselIntervalInterrupter);
      }
    }
  }

  trackByFn(index: number) {
    return index;
  }
}




// import { Injectable, ApplicationRef, NgZone } from '@angular/core';
// import { DateAdapter } from '@angular/material/core';
// import * as moment from 'moment';
// import { TranslateService } from '@ngx-translate/core';
// import { Router } from '@angular/router';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { Location } from '@angular/common';
// import { BreakpointObserver } from '@angular/cdk/layout';
// import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from 'src/environments/environment';

// import { Config } from '../config';
// import { SnackBarFromComponentComponent } from '../components/snack-bar-from-component/snack-bar-from-component.component';
// import { first } from 'rxjs/operators';

// // Models & Data
// import { Language } from '../model/language';
// import { User, Navigation, NavigationChild } from '../model/user';
// import { culture, roadRisk, eLearning } from '../data/course';
// import { readyTranslations } from '../../assets/i18n/ready-translations';

// export enum DaysOfTheWeekEnum {
//   sun,
//   mon,
//   tue,
//   wed,
//   thu,
//   fri,
//   sat
// }

// export interface PageName { 
//   page: string; 
//   isCommonReport?: boolean;
// }

// interface PageDatePeriod {
//   date?: { period: string; startPeriod: string; endPeriod: string; };
//   dateComparison?: { period: string; previousPeriod: string; currentPeriod: string; };
// }

// interface ReportResourceLocation {
//   endpoint: string; 
//   commonReportPath?: string;
//   navigationData?: Navigation | NavigationChild;
// }

// interface PageHeader {
//   // NOTE: All of them must be set by each instance
//   pageTitle: string;
//   canSearch: boolean;
//   isDashboard: boolean;
//   isReportPage: boolean;
// }

// export interface VrmFileIcon { 
//   icon: string; 
//   color?: string; 
// }

// export type RatingToTextType = 'highMediumLowNa' | 'poorAverageGoodNa' | 'completeIncompleteNotStartedNa';
// export enum NumericRatingToTextEnum {
//   highMediumLowNa = 'highMediumLowNa',
//   poorAverageGoodNa = 'poorAverageGoodNa',
//   completeIncompleteNotStartedNa = 'completeIncompleteNotStartedNa'
// }

// @Injectable({ providedIn: 'root' })
// export class CommonService
// {
//   imageRoot: string = '../../assets/images/';
//   companiesLogoRoot: string = '../../assets/images/companies/';
//   flagRoot: string = '../../assets/images/flags/';

//   responseTypes = {
//     error: {
//         unauthorized: 401,
//         unprocessableEntity: 422,
//         tooManyRequests: 429
//     }
//   };

//   pageHeader: PageHeader = {
//     canSearch: false,
//     pageTitle: '',
//     isDashboard: false,
//     isReportPage: false
//   };

//   regEx = {
//     underscoreAndCharsAfter: new RegExp(/_.*$/)
//   };

//   private _mainSidenavToggled = new BehaviorSubject(null);
//   mainSidenavToggled$ = this._mainSidenavToggled.asObservable();

//   private _navigationReady = new BehaviorSubject(null);
//   navigationReady$ = this._navigationReady.asObservable();

//   private _dateFilterChanged = new BehaviorSubject(false);
//   dateFilterChanged$ = this._dateFilterChanged.asObservable();

//   private _userProfileReady = new BehaviorSubject(null);
//   userProfileReady$ = this._userProfileReady.asObservable();

//   /* ============================
//     Shared Page-header events
//   ============================= */
//   private _headerSearchToggled = new BehaviorSubject(null);
//   headerSearchToggled$ = this._headerSearchToggled.asObservable();

//   private _headerSearchInputChanged = new BehaviorSubject(null);
//   headerSearchInputChanged$ = this._headerSearchInputChanged.asObservable();

//   private _downloadReportTriggered = new BehaviorSubject(null);
//   downloadReportTriggered$ = this._downloadReportTriggered.asObservable();
//   // -- End

//   appVersion = Config.appVersion;
//   appSettings = {
//     waitInMsForInitialApiCalls: 2500,
//     checkUpdateInHours: 3 * 60 * 60 * 1000, // <-- 3hrs
//     defaultPage: 'training',
//     supportEmailAddress: 'support@virtualriskmanager.net'
//   };

//   navigation: Array<Navigation> = [];
//   localStorageKeys: any;
//   commonReportTitle: string;
//   selectedAccountAndSecTab: number = 0;
//   navigatedReport: ReportResourceLocation;
//   mainSidenavOpened: boolean = false;
//   currentPageDateOrPeriod: PageDatePeriod = {};

//   appStableSinceInitLoad$: Observable<boolean>;
//   isOnline: boolean = true;
//   isFullscreen: boolean = false;
//   isDarkTheme: boolean = false;
//   prodDataInUseInDev: boolean = false;
//   productionInUse: boolean = false;

//   hideIndeterminateProgress: boolean = true;
//   elementIsFullscreen: boolean = false;
//   currentTheme: string = 'grey-theme';
//   naturalBgColor: '#fafafa';
//   accentColor: string = '#795548';
//   piiDisclaimer: string;
//   copyright: string;
//   vrmLanguages: Array<Language> = [];
//   selectedLanguage: Language;
//   languageParam: string;
//   reloadingLanguage: boolean = false;
//   userProfile: User = {
//     preferences: {}
//   };
//   paginationSizeOptions = [10000, 20000, 40000, 60000, 100000, 150000, 200000];
//   dataRowCanExpand: boolean = false;

//   carouselItemIndex: number = 0;
//   carouselIntervalInterrupter: any;
//   carouselItemIndicatorList: Array<number> = [];
//   carouselPaused: boolean = false;

//   ficoScoreRatings: Array<any> = [];
//   ficoScoreValues = {
//     veryHighRisk:    { from: 100, to: 499 },
//     highRisk:     { from: 500, to: 559 },
//     mediumRisk:  { from: 560, to: 709 },
//     lowRisk:     { from: 710, to: 799 },
//     veryLowRisk:    { from: 800, to: 850 }
//   };

//   mentorDrivingRatingValues = {
//     highRisk:     { min: 100, max: 559 },
//     mediumRisk:  { min: 560, max: 709 },
//     lowRisk:     { min: 710 }
//   };

//   highMediumLowRatingValues = {
//     veryHigh: 4,
//     high: 3,
//     medium: 2,
//     low: 1
//     // Less than 1 is NA
//   };

//   completeIncompleteNotStartedNa = {
//     notStarted: 3,
//     incomplete: 2,
//     complete: 1,
//     // Less than 1 is NA <-- Caters for null values
//   };

//   fileTypesToIcon = {
//     pdf: { icon: 'far fa-file-pdf', color: '#ff0000' },
//     mp4: { icon: 'far fa-file-video' },
//     doc: { icon: 'far fa-file-word', color: ' #2b579a' },
//     docx: { icon: 'far fa-file-word', color: ' #2b579a' }
//   };

//   isMobileViewport: boolean = false;
//   isTabletViewport: boolean = false;
//   isPcViewport: boolean = false;
//   isLargePcViewport: boolean = false;


//   readyTranslations = readyTranslations;

//   commonReports = 'commonreports';

//   constructor(
//     private router: Router,
//     private translate: TranslateService,
//     private snackbar: MatSnackBar,
//     private location: Location,
//     private breakpointObserver: BreakpointObserver,
//     private appRef: ApplicationRef, private zone: NgZone,
//     private dateAdapter: DateAdapter<any>
//     ) {
//     this.initialize();
//   }

//   // RxJs EVENT
//   triggerMainSidenavToggledAsObservable(opened: boolean) {
//     this._mainSidenavToggled.next(opened);
//   }

//   private triggerNavigationReadyObservable() {
//     this._navigationReady.complete();
//   }

//   triggerDateFilterChangedObservable(isCommonReport: boolean) {
//     this._dateFilterChanged.next(isCommonReport);
//   }

//   triggerUserProfileReadyObservable(userProfile: User) {
//     this._userProfileReady.next(userProfile);
//   }

//   /* ===============================
//   Shared Page-header events triggers
//   ================================= */
//   triggerHeaderSearchToggledAsObservable(closed: boolean) {
//     this._headerSearchToggled.next(closed);
//   }

//   triggerHeaderSearchInputChangedAsObservable(searchText: string) {
//     this._headerSearchInputChanged.next(searchText);
//   }

//   triggerReportDownloadRequestAsObservable() {
//     this._downloadReportTriggered.next(null);
//   }
//   // -- End

//   initialize(): void 
//   {
//     /**
//      * NOTE
//      * The app is NOT stable if timeout / intervals are running
//      * from start-up. Implementing a check via this observable 
//      * helps to know when all the time-related tasks are done.
//      * 
//      * When checking that the app is stable, if there's a process that updates
//      * the DOM, such process must be implemented to run within the Angular process.
//      * This is because the stability check runs outside the Angular zone, hence
//      * Change Detection will not be triggered.
//      */
//     this.appStableSinceInitLoad$ = this.appRef.isStable;

//     this.productionInUse = environment.production;

//     this.copyright = `&copy; ${new Date().getFullYear()} eDriving LLC . `;

//     this.localStorageKeys = {
//       cookiesAccepted: this.applyPrefix('cookieAccepted'),
//       language: this.applyPrefix('language'),
//       isDarkTheme: this.applyPrefix('isDarkTheme'),
//       currentTheme: this.applyPrefix('currentTheme'),
//       accessToken: this.applyPrefix('access_token'),
//       accessTokenExpiration: this.applyPrefix('access_token_exp'),
//       userProfile: this.applyPrefix('userProfile')
//     };

//     // Languages
//     this.vrmLanguages =
//       [
//         {
//           country: 'English',
//           translatedLabel: 'English',
//           code: 'en',
//           flag: this.flagRoot + 'en.svg',
//           isRtl: false
//         },
//         {
//           country: 'French',
//           translatedLabel: 'Français',
//           code: 'fr',
//           flag: this.flagRoot + 'fr.svg',
//           isRtl: false
//         },
//         {
//           country: 'Spanish',
//           translatedLabel: 'Español',
//           code: 'es',
//           flag: this.flagRoot + 'es.svg',
//           isRtl: false
//         },
//         {
//           country: 'Korean',
//           translatedLabel: '한국어',
//           code: 'kr',
//           flag: this.flagRoot + 'es.svg',
//           isRtl: false
//         },
//         {
//           country: 'Portuguese',
//           translatedLabel: 'Português',
//           code: 'brpt',
//           flag: this.flagRoot + 'es.svg',
//           isRtl: false
//         },
//         {
//           country: 'Japanese',
//           translatedLabel: '日本語',
//           code: 'jp',
//           flag: this.flagRoot + 'es.svg',
//           isRtl: false
//         },
//         {
//           country: 'Turkish',
//           translatedLabel: 'Türkçe',
//           code: 'tr',
//           flag: this.flagRoot + 'es.svg',
//           isRtl: false
//         }
//       ];

//     this.userProfile = JSON.parse(localStorage.getItem(this.localStorageKeys.userProfile));
//     if (this.userProfile) {
//       this.setSelectedLanguage(this.userProfile['languageCode']);
//     }
//     else {
//       this.setSelectedLanguage('en');
//     }
//   }

//   // Helper methods
//   getLanguageByCode(code: string): Language {

//     let language = this.vrmLanguages.find(lang => lang.code.toLowerCase() === code);
//     if (!language) {
//       language = this.vrmLanguages.find(lang => lang.code.toLowerCase() === 'en');
//     }

//     return language;
//   }
//   applyPrefix(suffix: string): string {
//     return 'mis.vrm.eDriving.' + suffix;
//   }
//   applyUserProfile()
//   {
//     if (this.userProfile) {
//       this.currentTheme = this.userProfile.color;
//       const savedTheme = localStorage.getItem(this.localStorageKeys.currentTheme);
//       if (savedTheme !== this.currentTheme) {
//         localStorage.setItem(this.localStorageKeys.currentTheme, this.userProfile.color);
//       }
//       console.log(this.userProfile.languageCode)
//       const selectedLanguage: Language = this.getLanguageByCode(this.userProfile.languageCode);
//       this.setSelectedLanguage(this.userProfile.languageCode);

//       this.processLanguageTranslations(selectedLanguage);
//     }
//     else {
//       console.warn(':: VRM Warning: User profile not found');
//     }
//   }

//   applyBreakpoint() {
//     const breakpoint = 500;
//     const mobileBreakpoint = breakpoint + 'px';
//     const tabletBreakpoint = (breakpoint + 200) + 'px';
//     const pcBreakpoint = (breakpoint + 400) + 'px';
//     const largePcBreakpoint = (breakpoint + 1000) + 'px';

//     this.isMobileViewport = this.breakpointObserver.isMatched(`(max-width: ${mobileBreakpoint})`);
//     this.isTabletViewport = this.breakpointObserver.isMatched(`(max-width: ${tabletBreakpoint})`);

//     this.isPcViewport = !this.breakpointObserver.isMatched(`(max-width: ${pcBreakpoint})`);
//     this.isLargePcViewport = !this.breakpointObserver.isMatched(`(max-width: ${largePcBreakpoint})`);

//     setTimeout(() => {
//       if (this.isPcViewport) {
//         this.mainSidenavOpened = true;
//       }
//       else {
//       this.mainSidenavOpened = false;
//       }
//     });
//   }

//   /**
//    * @param waitCallback If needed, ensures sidenav toggle is done and value
//    * propagated before next action.
//    */
//   toggleMainSidenav(waitCallback?: () => void) {
//     this.mainSidenavOpened = !this.mainSidenavOpened;

//     setTimeout(() => {
//       if (waitCallback) { waitCallback(); }
//     }, 60);
//   }

//   setPageHeader(pageTitle: string, canSearch: boolean = false, isReportPage: boolean = false, isDashboard: boolean = false) {
//     setTimeout(() => {
//       if (!this.pageHeader.pageTitle) {
//         // NOTE: pageTitle is set in the getNavigatedReportData() method
//         // to ensure the same navigation translated name is applied.
//         // Only set here if not already set in the method mentioned above.
//         this.pageHeader.pageTitle = pageTitle;
//       }
//       this.pageHeader.isReportPage = isReportPage;
//       this.pageHeader.canSearch = canSearch;
//       this.pageHeader.isDashboard = isDashboard;

//       console.log('%c Page Header Properties', 'color: yellow', this.pageHeader);
//     });
//   }

//   /**
//    * @param message Shows translated 'Working...' if no message provided
//    * @param action do not translate or provide action method for 'Close' or 'Refresh now' actions
//    * @param actionMethod only provide when action is not 'Close' or 'Refresh now'
//    */
//   showToast(message?: string, duration?: number, action?: string, actionMethod?: () => void)
//   {
//     const actualMessage = message ? message : this.readyTranslations.messagesAndWarnings.working;
//     const actualDuration = duration ? duration : 4000;

//     let actionText = action;
//     if (action) {
//       if (action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
//         actionText = this.readyTranslations.generic.refresh;
//       }
//       else if (action.toLowerCase() === 'close') {
//         actionText = this.readyTranslations.generic.close;
//       }
//     }

//     this.snackbar.open(actualMessage, actionText, {
//       duration: actualDuration
//     })
//     .onAction().subscribe({
//       next: () => {
//         if (actionMethod) {
//           actionMethod();
//         }
//         else {
//           if (action) {
//             if (action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
//               location.reload();
//             }

//             if (action.toLowerCase() === 'close') {
//               this.snackbar.dismiss();
//             }
//           }
//         }
//       }
//     });
//   }
//   /**
//    * @param message Shows translated 'Working...' if no message provided
//    * @param action do not translate or provide action method for 'Close' or 'Refresh now' actions
//    * @param actionMethod only provide when action is not 'Close' or 'Refresh now'
//    */
//   showToastWithoutDuration(message?: string, action?: string, actionMethod?: () => void)
//   {
//     let actionText = action;
//     if (action && action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
//       actionText = this.readyTranslations.generic.refresh;
//     }
//     else if (action && action.toLowerCase() === 'close') {
//       actionText = this.readyTranslations.generic.close;
//     }

//     this.snackbar.open(message, actionText).onAction().subscribe({
//       next: () => {
//         if (actionMethod) {
//           actionMethod();
//         }
//         else {
//           if (action) {
//             if (action.toLowerCase().replace(/ /g, '') === 'refreshnow') {
//               location.reload();
//             }

//             if (action.toLowerCase() === 'close') {
//               this.snackbar.dismiss();
//             }
//           }
//         }
//       }
//     });
//   }  
//   closeCurrentToast() {
//     this.snackbar.dismiss();
//   }

//   showTimeoutToastFromComponent(duration: number, timeoutInSeconds: number) {
//     this.snackbar.openFromComponent(SnackBarFromComponentComponent, {
//       duration: (duration) ? duration : 4000,
//       data: {
//         countdownFrom: timeoutInSeconds
//       }
//     }).onAction().subscribe({
//       next: () => {
//         this.snackbar.dismiss();
//       }
//     });
//   }

//   // Translations & Navigation
//   processReadyTranslations() {
//     // Errors and warning ready translations
//     this.translate.get('MESSAGESANDWARNINGS').subscribe(translation => {
//       const objectKeys = Object.keys(this.readyTranslations.messagesAndWarnings);

//       objectKeys.forEach((objKey) => {
//         this.readyTranslations.messagesAndWarnings[objKey] = translation[objKey];
//       });
//     });

//     // Generic ready translations
//     this.translate.get('GENERIC').subscribe(translation => {
//       const objectKeys = Object.keys(this.readyTranslations.generic);

//       objectKeys.forEach((objKey) => {
//         this.readyTranslations.generic[objKey] = translation[objKey];
//       });
//     });

//     // Form and validation
//     this.translate.get('FORM').subscribe(translation => {
//       const objectKeys = Object.keys(this.readyTranslations.form);

//       objectKeys.forEach((objKey) => {
//         this.readyTranslations.form[objKey] = translation[objKey];
//       });
//     });

//     // Report data
//     this.translate.get('REPORTDATA').subscribe(translation => {
//       const objectKeys = Object.keys(this.readyTranslations.reportData);

//       objectKeys.forEach((objKey) => {
//         this.readyTranslations.reportData[objKey] = translation[objKey];
//       });

//       // FICO Score Ratings
//       this.ficoScoreRatings = [
//         { 
//           scoreMargin: `${this.ficoScoreValues.veryHighRisk.from} - ${this.ficoScoreValues.veryHighRisk.to}`, 
//           rating: (translation.veryHighRisk) ? translation.veryHighRisk : 'Very High Risk', 
//           color: 'red', class: 'vrm-red-label' 
//         },
//         { 
//           scoreMargin: `${this.ficoScoreValues.highRisk.from} - ${this.ficoScoreValues.highRisk.to}`, 
//           rating: (translation.highRisk) ? translation.highRisk : 'High Risk',
//           color: 'red', class: 'vrm-red-label' 
//         },
//         { 
//           scoreMargin: `${this.ficoScoreValues.mediumRisk.from} - ${this.ficoScoreValues.mediumRisk.to}`, 
//           rating: (translation.mediumRisk) ? translation.mediumRisk : 'Medium Risk',
//           color: 'yellow', class: 'vrm-yellow-label' 
//         },
//         { 
//           scoreMargin: `${this.ficoScoreValues.lowRisk.from} - ${this.ficoScoreValues.lowRisk.to}`, 
//           rating: (translation.lowRisk) ? translation.lowRisk : 'Low Risk',
//           color: 'green', class: 'vrm-green-label' 
//         },
//         { 
//           scoreMargin: `${this.ficoScoreValues.veryLowRisk.from} - ${this.ficoScoreValues.veryLowRisk.to}`, 
//           rating: (translation.veryLowRisk) ? translation.veryLowRisk : 'Very Low Risk',
//           color: 'green', class: 'vrm-green-label' 
//         }
//       ];

//       console.log('%c Ready Translations Processed', 'color: pink', this.readyTranslations);
//     });
//   }

//   private convertToMaterialLanguageCode(vrmLangCode: string): string {
//     const langCodeMatch = {
//       jp: 'ja-JP',
//       kr: 'ko',
//       brpt: 'br-PT'
//     };

//     return langCodeMatch[vrmLangCode];
//   }
//   processLanguageTranslations(language: Language): void
//   {
//     this.selectedLanguage = language;
//     this.translate.use(language.code.toLowerCase()).subscribe({
//       next: () => {
//         moment.locale(language.code.toLowerCase());

//         let matLangCode = this.convertToMaterialLanguageCode(language.code.toLowerCase());
//         matLangCode = (matLangCode) ? matLangCode : language.code.toLowerCase();
//         this.dateAdapter.setLocale(matLangCode);

//         localStorage.setItem(this.localStorageKeys.language, language.code.toLowerCase());

//         this.processReadyTranslations();
//         this.processMainNavTranslations();
//       }
//     });
//   }
//   getConciseEndpointAndCommonReportNavPath(commonReportEndpoint: string): ReportResourceLocation {    
//     let endpoint = commonReportEndpoint.replace(/\/reports\//, '');
//     if (endpoint.startsWith(`/`)) {
//       endpoint = endpoint.replace(/\//, '');
//     }

//     return {
//       endpoint: endpoint,
//       commonReportPath: `#/commonreports/${endpoint.replace(/\//, `-`)}`
//     };
//   }
//   processMainNavTranslations(): void
//   {
//     this.translate.get('DRIVER_HQ_NAVIGATION_TITLE').subscribe({
//       next: translation => {

//         this.navigation = [

//           { name: 'Training', url: '#/training', translatedName: translation.training, hidden: false },
//           {
//             name: 'Driving', url: '#/performance', translatedName: translation.driving, hidden: false,
//             children: [
//               { name: 'DriverIndex', url: `#/performance`, translatedName: translation.driverIndex, hidden: false },
//               { name: 'Mentor', url: `#/performance-mentor`, translatedName: translation.mentor, hidden: false },
//             ],
//           },
//           { name: 'Coaching', url: '#/coaching', translatedName: translation.coaching, hidden: false },
//           { name: 'Guides/Faq', url: '#/guides', translatedName: translation.guides, hidden: false },
//           { name: 'Billing', url: '#/billing', translatedName: translation.billing, hidden: false }
//         ];

//         this.setNavigationPermissions();

//         console.log('NAVIGATION', this.navigation);
//         this.identifySelectedNavAndParent();
//       }
//     });
//   }
//   public setCachedLanguage(languageCode: string, reload?: boolean): void
//   {

//     if (languageCode)
//     {

//       this.languageParam = languageCode.toLowerCase();
//       this.setSelectedLanguage(this.languageParam);

//       if (this.userProfile) {
//         this.userProfile.languageCode = this.languageParam;
//         this.setUserProfileToLocalStorage();
//       }
//     }
//     else {

//       // Check for language in Local Storage
//       const storedLang = localStorage.getItem(this.localStorageKeys.language);

//       if (storedLang) {
//         this.setSelectedLanguage(storedLang);
//         this.languageParam = storedLang;
//       }
//       else {
//         this.setSelectedLanguage('en');
//         this.languageParam = 'en';

//         if (this.userProfile) {
//           this.userProfile.languageCode = 'en';
//           this.setUserProfileToLocalStorage();
//         }
//       }
//     }

//     if (reload) 
//     {//JAY

//       this.reloadingLanguage = true;
//       const reloading = (this.readyTranslations.generic.reloading) ? this.readyTranslations.generic.reloading : 'Reloading';
//       this.showToast(reloading + '...');
//       location.reload();
//     }
//     else {
//       this.processLanguageTranslations(this.selectedLanguage);
//     }
//   }

//   public setSelectedLanguage(language: string): void {
//     this.selectedLanguage = this.getLanguageByCode(language);
//   }

//   /*
//     NOTE: This would only set cService's version of userProfile
//   */
//   public setUserProfileToLocalStorage() {
//     localStorage.setItem(this.localStorageKeys.userProfile, JSON.stringify(this.userProfile));
//   }

//   public identifySelectedNavAndParent(): void {
//     setTimeout(() =>
//     {
//       this.navigation.forEach((navigation: Navigation) =>
//       {
//         const currentPage = this.predictPageNameFromUrl();

//         if (currentPage && currentPage.page) {
//           // All OFF Initially
//           navigation.selected = false;
//           navigation.expanded = false;

//           if (navigation.children && navigation.children.length > 0) {
//             navigation.children.forEach((child: NavigationChild) => {
//               child.selected = false;
//             });

//             // Switch Selected ON
//             const hasSelectedChild = (currentPage.isCommonReport) ? navigation.children.find((child: NavigationChild) => {
//               // Process Common Report
//               const childLocation = this.predictPageNameFromUrl(child.url).page;

//               if (childLocation) {
//                 const selected = childLocation.toLowerCase() === currentPage.page.toLowerCase();
//                 if (selected === true) {
//                   child.selected = true;
//                   this.getNavigatedReportData(child, true);
//                 }

//                 return selected;
//               }
//             }) : navigation.children.find((child: NavigationChild) => {
//               const childLocation = this.predictPageNameFromUrl(child.url).page;

//               if (childLocation) {
//                 const selected = childLocation.toLowerCase() === currentPage.page.toLowerCase();
//                 if (selected === true) {
//                   child.selected = true;
//                   this.getNavigatedReportData(child, false);
//                 }

//                 return selected;
//               }
//             });

//             if (hasSelectedChild) {
//               navigation.selected = true;
//               navigation.expanded = true;
//             }
//           }

//           // Process selection for nav without children
//           if (navigation.url) {
//             const navLocation = this.predictPageNameFromUrl(navigation.url);

//             if (navLocation.isCommonReport) {
//               const commonReportLocation = navLocation.page;

//               if (commonReportLocation.toLowerCase() === currentPage.page.toLowerCase()) {
//                 navigation.selected = true;
//                 this.getNavigatedReportData(navigation, true);
//               }
//             }
//             else {
//               if (navLocation.page === currentPage.page.toLowerCase()) {
//                 navigation.selected = true;
//                 this.getNavigatedReportData(navigation, false);
//               }
//             }
//           }

//         }

//       });

//       if (!this.commonReportTitle) {
//         const highLevelNav = this.navigation.find(nav => nav.selected === true);

//         if (highLevelNav) {
//           if (highLevelNav.children && highLevelNav.children.length > 0) {
//             this.commonReportTitle = highLevelNav.children.find(child => child.selected === true).name;
//           }
//           else {
//             this.commonReportTitle = highLevelNav.translatedName;
//           }
//         }
//       }

//     }, 50);
//   }
//   /**
//    * Use especially when nav identification is likely to
//    * be wrongly predicted due to manual URL manipulation 
//    * by the user.
//    */
//   clearNavSelection() {
//     this.navigation.map(nav => {
//       if (nav.selected) { nav.selected = false; }

//       if (nav.children) {
//         nav.children.map(child => {
//           if (child.selected) { child.selected = false; }
//         });
//       }
//     });
//   }

//   public predictPageNameFromUrl(url?: string): PageName {
//     const pageName: PageName = {
//       page: '',
//       isCommonReport: false
//     };

//     const currentLocation = (url) ? url : this.location.path();
//     let locationStrings = currentLocation.split('/');
//     locationStrings = locationStrings.filter(loc => loc != '' && loc != `#`);

//     if (currentLocation.toLowerCase().includes(this.commonReports)) {
//       pageName.page = locationStrings[1];
//       pageName.isCommonReport = true;
//     }
//     else {
//       pageName.page = locationStrings[0];
//     }

//     return pageName;
//   }

//   private getNavigatedReportData(currentNav: Navigation | NavigationChild, isCommonReport: boolean) {
//     this.navigatedReport = {
//       commonReportPath: (isCommonReport) ? currentNav.url : '',
//       endpoint: currentNav.reportPageEndpoint,
//       navigationData: currentNav
//     };

//     setTimeout(() => { this.pageHeader.pageTitle = currentNav.translatedName; });
//   }

//   public navigateToPage(page: string) {
//     const currentPage = this.predictPageNameFromUrl(page);
//     if (currentPage.isCommonReport) {
//       this.commonReportTitle = this.toTitleCase(currentPage.page);
//     }

//     this.router.navigate([page]);
//     this.identifySelectedNavAndParent();
//   }

//   public setNavigationPermissions() {
//     if (this.userProfile) {
//       const userPermissions = this.userProfile.userPermissions;
//       const companyPermissions = this.userProfile.companyPermissions;

//       // The Risk Foundations are loaded dynamically from the back-end upon login so are set here
//       if (this.userProfile.riskFoundationCourses) {
//         culture.riskFoundation.codes = this.userProfile.riskFoundationCourses;
//       }      

//       if (userPermissions && companyPermissions)
//       {
//         this.navigation.forEach((navigation: Navigation) => 
//         {
//           const children = navigation.children;

//           // Amazon
//           if (this.userProfile.distributorName && this.userProfile.distributorName.toLowerCase() === 'amzl') 
//           {
//             if (navigation.distributorTags && navigation.distributorTags.includes('amzl')) {
//               navigation.hidden = false;
//             }
//             else if (navigation.name.toLowerCase() == 'users' && this.userProfile.companyPermissions.permMisMenuMentorVisible === 'TRUE') {
//               navigation.hidden = this.userProfile.userPermissions.permEditUser === 'none' || !this.userProfile.fpId;
//             } 
//             else if (navigation.name.toLowerCase() == 'dvcr' && this.userProfile.companyPermissions.permMisMenuMentorVisible === 'TRUE') {
//               navigation.hidden = !this.userProfile.fpId;
//             }
//             else {
//               navigation.hidden = true;
//             }
//           }
//           else {
//             // Non Amazon | NOTE: Some of these are Amazon but not under the AMZL dist-name. E.g. Amazon Japan
//             if (navigation.name.toLowerCase() == 'status report') {
//               if (environment.production) {
//                 navigation.hidden = true;
//               }
//               else if (companyPermissions.permMisMenuStatusReportVisible == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             } 
//             else if (navigation.name.toLowerCase() == 'culture') {            

//               children.forEach((child: NavigationChild) => {
//                 child.hidden = this.hasCourseCode(culture, child);
//               });

//               const hasChildNav = children.find(childNav => childNav.hidden === false);
//               if (hasChildNav) {
//                 navigation.hidden = false;
//               }

//             }
//             else if (navigation.name.toLowerCase() == 'riskcoach') {

//               // company courses returned dynamically from the API
//               // only show the RiskCoach menu item when a company has at least one RiskCoach course
//               if (environment.production) {
//                 navigation.hidden = true;
//               }
//               else if ((this.userProfile.riskCoachCourses) && (this.userProfile.riskCoachCourses.length)) {
//                 navigation.hidden = false;
//               }

//             }
//             else if (navigation.name.toLowerCase() == 'elearning') {
//               // Same as Culture i.e. only shows when at least one eLearning course is found
//               children.forEach((child: NavigationChild) => {
//                 child.hidden = this.hasCourseCode(eLearning, child);
//               });

//               const hasChildNav = children.find(childNav => childNav.hidden === false);
//               if (hasChildNav) {
//                 navigation.hidden = false;
//               }
//             }
//             else if (navigation.name.toLowerCase() == 'roadrisk') {
//               // Same as Culture i.e. only shows when at least one RR course is found
//               children.forEach((child: NavigationChild) => {
//                 child.hidden = this.hasCourseCode(roadRisk, child);
//               });

//               const hasChildNav = children.find(childNav => childNav.hidden === false);
//               if (hasChildNav) {
//                 navigation.hidden = false;
//               }
//             }
//             else if (navigation.name.toLowerCase() == 'driverindex') {

//               if (userPermissions.permIndexing == 'view') {
//                 navigation.hidden = false;

//                 children.forEach((child: NavigationChild) => {

//                   if (child.name.toLowerCase() == 'scorecard') {
//                     child.hidden = false; // Always shown if Indexing permission is 'view'
//                   }
//                   else if (child.name.toLowerCase() == 'mentor by edriving') {

//                     if ((companyPermissions.permIsMentor) && (companyPermissions.permMisMenuMentorVisible == 'TRUE')) {
//                       child.hidden = false;
//                     }

//                   } else if (child.name.toLowerCase() == 'events table') {

//                     if ((companyPermissions.permMisMenuDriverIndexEventsTableVisible == 'TRUE') && (userPermissions.permEventsTable.toLowerCase() !== 'none')) {
//                       child.hidden = false;
//                     }

//                   }
//                 });
//               }            
//             }
//             else if (navigation.name.toLowerCase() == 'mvr+') {
//               if (userPermissions.permUsMvr && userPermissions.permUsMvr.toLowerCase() !== 'none') {
//                 navigation.hidden = false;

//                 children.forEach((child: NavigationChild) => {
//                   if (child.name.toLowerCase() === 'drm') {
//                     if (companyPermissions.permDrm === 'TRUE') {
//                       child.hidden = false;
//                     }
//                   }
//                 });
//               }
//             }
//             else if (navigation.name.toLowerCase() == 'mentor tsp summary') {
//               if (companyPermissions.permMisMenuMentorTsp == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             } 
//             else if (navigation.name.toLowerCase() == 'driver performance') {
//               if (companyPermissions.permMisMenuDriverPerformance == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             } 
//             else if (navigation.name.toLowerCase() == 'station report') {
//               if (companyPermissions.permMisMenuStationPerformance == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             } 
//             else if (navigation.name.toLowerCase() == 'dvir') {
//               if (companyPermissions.permMisMenuDvir == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             }
//             else if (navigation.name.toLowerCase() === 'guides / faq') {
//               if (companyPermissions.permMisMenuFaq == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             }
//             else if (navigation.name.toLowerCase() === 'contact us') {
//               if (companyPermissions.permMisMenuContactUs == 'TRUE') {
//                 navigation.hidden = false;
//               }
//             }
//           }

//           // TODO: Hide the Delta comparison reports in Production
//           // if (environment.production) {

//           //   if (((navigation.name.toLowerCase() == 'driver performance') || (navigation.name.toLowerCase() == 'station report')) && 
//           //       (navigation.children.length)) {

//           //       navigation.children.forEach((child: NavigationChild) => {
//           //         if (child.name.toLowerCase() === 'comparison') {
//           //           child.hidden = true;
//           //         }
//           //       });

//           //   }

//           // }
//         });
//       }

//       this.triggerNavigationReadyObservable();
//     }
//   }

//   public mvrPageHidden(): boolean {
//     let hidden = true;

//     if (this.userProfile.userPermissions.permUsMvr 
//       && this.userProfile.userPermissions.permUsMvr.toLowerCase() !== 'none') {
//       hidden = false;
//     }

//     return hidden;
//   }

//   private hasCourseCode(course: any, navigation: Navigation | NavigationChild): boolean {
//     let hidden: boolean = true;
//     const courseKeys = Object.keys(course);

//     for (let i = 0; i < courseKeys.length; i++) {
//       const courseKey = courseKeys[i];

//       if (navigation.name.toLowerCase() === course[courseKey].name.toLowerCase()) 
//       {
//         for (let x = 0; x < course[courseKey].codes.length; x++) 
//         {
//           const courseCode = course[courseKey].codes[x];
//           if (this.userProfile.courseList.includes(courseCode)) {
//             hidden = false;
//             break;
//           }
//         }

//         break;
//       }
//     }

//     return hidden;
//   }

//   public passwordMismatch(currentPass: string, newPass: string): boolean {
//     const mismatch = currentPass !== newPass;
//     return mismatch;
//   }

//   public convertMinToSeconds(minutes: number): number {
//     // Must never return zero or less
//     const minimumSeconds = 10;
//     const convertedSeconds = minutes * 60;

//     return (convertedSeconds <= 0) ? minimumSeconds : convertedSeconds;
//   }
//   public convertSecondsToMilliseconds(seconds: number): number
//   {
//     let convertedMilliseconds = 0;

//     if (seconds > 0) {
//       convertedMilliseconds = seconds * 1000;
//     }

//     return convertedMilliseconds;
//   }

//   /**
//    * @param value Value to be displayed as percentage.
//    * decimal will be removed.
//    */
//   public displayNumberAsPercentage(value: any): string {
//     let convertedValue = value;

//     if ((value || !isNaN(value)) && (value != null || value != undefined)) {
//       convertedValue = (value.toString().includes('.'))
//       ? Number(value).toFixed(0) + '%'
//       : value + '%';
//     }

//     return convertedValue;
//   }
//   /**
//    * @param value Value to be converted to decimal
//    * @param decimalPlaces Optional Decimal places, defaults to 2 if already has more than 2 decimals.
//    * Otherwise, it'll default to 1 for data already returning 1 decimal place.
//    */
//   public numberToDecimalPlace(value: any, decimalPlaces: number = 2) {
//     let convertedValue = value;

//     if ((value != null || value != undefined) && !isNaN(value)) {        
//       convertedValue = Number(value).toFixed(decimalPlaces);
//     }

//     return convertedValue;
//   }
//   public prefixNumberWithPlusSign(value: number, hasDecimal: boolean): any {
//     let decimalValue: string;
//     let finalValue: any = value;

//     if (typeof value == 'number' && value != null && value != undefined) {
//       if (hasDecimal) {
//         decimalValue = this.numberToDecimalPlace(value);
//         finalValue = (value > 0) ? '+' + decimalValue : decimalValue;
//       }
//       else {
//         finalValue = (value > 0) ? '+' + value : value;
//       }
//     }

//     return finalValue;
//   }
//   public mapNumericRatingToText(ratingType: RatingToTextType, value: number): string {
//     let translatedMappedRatingText = this.readyTranslations.reportData.na;

//     if (!isNaN(value)) {
//       if (ratingType === NumericRatingToTextEnum.highMediumLowNa) {
//         if (value === this.highMediumLowRatingValues.veryHigh) {
//           translatedMappedRatingText = this.readyTranslations.reportData.veryHigh;
//         }

//         if (value === this.highMediumLowRatingValues.high) {
//           translatedMappedRatingText = this.readyTranslations.reportData.high;
//         }

//         if (value === this.highMediumLowRatingValues.medium) {
//           translatedMappedRatingText = this.readyTranslations.reportData.medium;
//         }

//         if (value === this.highMediumLowRatingValues.low) {
//           translatedMappedRatingText = this.readyTranslations.reportData.low;
//         }
//       }
//       else if (ratingType === NumericRatingToTextEnum.poorAverageGoodNa) {
//         if (value <= this.mentorDrivingRatingValues.highRisk.max) {
//           translatedMappedRatingText = this.readyTranslations.reportData.highRisk;
//         }
//         else if (value >= this.mentorDrivingRatingValues.mediumRisk.min
//           && value <= this.mentorDrivingRatingValues.mediumRisk.max) {
//           translatedMappedRatingText = this.readyTranslations.reportData.mediumRisk;
//         }
//         else {
//           translatedMappedRatingText = this.readyTranslations.reportData.lowRisk;
//         }
//       }
//       else if (ratingType === NumericRatingToTextEnum.completeIncompleteNotStartedNa) {
//         if (value === this.completeIncompleteNotStartedNa.notStarted) {
//           translatedMappedRatingText = this.readyTranslations.reportData.notStarted;
//         }

//         if (value === this.completeIncompleteNotStartedNa.incomplete) {
//           translatedMappedRatingText = this.readyTranslations.reportData.incomplete;
//         }

//         if (value === this.completeIncompleteNotStartedNa.complete) {
//           translatedMappedRatingText = this.readyTranslations.reportData.complete;
//         }
//       }
//       else {
//         console.error('VRM ERROR: NumericToTextRating mapping not supported');
//       }
//     }

//     return translatedMappedRatingText;
//   }

//   public predictMinWidthByColName(columnName: string): number {
//     if (columnName) {
//       let predictedWidth: number;
//       const colNameWords: Array<string> = columnName.split(' ');
//       let pixelPerChar: number;

//       const charCount: number = (colNameWords.length > 1) 
//         ? this.getLongestWord(colNameWords).length 
//         : columnName.split('').length;

//       pixelPerChar = (charCount > 8) ? 11.5 : 15;
//       predictedWidth = charCount * pixelPerChar;

//       return predictedWidth;
//     }
//   }
//   public predictMinWidthInPixelByWord(wordOrPhrase: string): number {
//     let predictedWidth: number;
//     const pixelPerChar: number = 11;
//     const wordArray = wordOrPhrase.split(' ');
//     const charCount: number = (wordArray.length > 1) 
//       ? this.getLongestWord(wordArray).length
//       : wordOrPhrase.length;

//     predictedWidth = charCount * pixelPerChar;

//     return predictedWidth;
//   }
//   private getLongestWord(words: Array<string>): string {
//     let longestWord: string;

//     words.forEach((word: string, key: number) => {
//       const nextKey: number = key + 1;
//       const currentWordLength: number = (word) ? word.split('').length : 0;
//       const nextWordLength: number = (words[key + 1]) ? words[nextKey].length : 0;
//       let cachedLongestWord: string;

//       if (nextWordLength > currentWordLength) {
//         cachedLongestWord = words[nextKey];
//       }
//       else {
//         cachedLongestWord = word;
//       }

//       if (longestWord) {
//         if (cachedLongestWord.split('').length > longestWord.split('').length) {
//           longestWord = cachedLongestWord;
//         }
//       }
//       else {
//         longestWord = cachedLongestWord;
//       }
//     });

//     return longestWord;
//   }

//   public getInitialFromNames(names: string): string {
//     let initial: string;

//     const nameArray: Array<string> = names.split(' ');
//     if (nameArray.length > 1) {
//       initial = nameArray[0].substring(0, 1) + nameArray[1].substring(0, 1);
//     }
//     else {
//       initial = names.substring(0, 1);
//     }

//     return initial.toUpperCase();
//   }

//   public toTitleCase(text: string): string {
//     const words = text.split(' ');
//     let titleText = '';
//     words.forEach((word: string) => {
//       const titleCaseWord = this.wordToTitleCase(word);
//       titleText += titleCaseWord + ' ';
//     });

//     return titleText.trim();
//   }
//   private wordToTitleCase(word: string) {
//     const characters = word.split('');
//     let titleCase = '';
//     characters.forEach((char: string, key: number) => {
//       char = (key === 0) ? char.toUpperCase() : char.toLowerCase();
//       titleCase += char;
//     });

//     return titleCase;
//   }
//   public toVrmCaps(sentence: string): string {
//     let modifiedSentence = sentence;
//     const vrmCapitalWords = [
//       'VRM', 'DriverINDEX', 'MVR', 'MVR+', 'RoadRISK', 'DRM',
//       'FAQ', 'LLC', 'DSP', 'DVCR'
//     ];

//     if (sentence) {
//       vrmCapitalWords.forEach((vrmCap) => {
//         if (sentence.toLowerCase().includes(vrmCap.toLowerCase())) {
//           const wordSearch = new RegExp(vrmCap, 'gi');
//           modifiedSentence = sentence.replace(wordSearch, vrmCap);
//         }
//       });
//     }

//     return modifiedSentence;
//   }

//   public getApplicableIconForExtension(extension: string): VrmFileIcon {
//     const ext = (extension) ? extension.trim().replace(/\./g, '') : '';
//     const unknownFile: VrmFileIcon = { icon: 'far fa-file' };
//     let fileIcon: VrmFileIcon = unknownFile;

//     if (extension) {
//         fileIcon = this.fileTypesToIcon[ext];

//         if (!fileIcon) {
//             fileIcon = unknownFile;
//         }
//     }

//     return fileIcon;
//   }

//   // NOTE: Use pipe version when calling from DOM
//   convertHexColorToRgba(transparency: number, hexColorCode?: string): string
//   {
//     if (this.userProfile) {
//       hexColorCode = (hexColorCode) ? hexColorCode.trim() : this.userProfile.HqColour.trim();
//       hexColorCode = (hexColorCode.startsWith('#')) ? hexColorCode : '#' + hexColorCode;

//       const hexPattern: RegExp = /^#([A-Fa-f0-9]{3}){1,2}$/;

//       if (hexPattern.test(hexColorCode)) 
//       {
//         let rgbaBuilder = [];
//         const iterableHexCode = hexColorCode.substring(1).split('');
//         const shorthandInUse = iterableHexCode.length === 3;
//         let builtHex: any;

//         if (shorthandInUse) {
//           rgbaBuilder = [
//             iterableHexCode[0], iterableHexCode[0],
//             iterableHexCode[1], iterableHexCode[1],
//             iterableHexCode[2], iterableHexCode[2]
//           ];
//         }
//         else {
//           rgbaBuilder = hexColorCode.substring(1).split('');
//         }

//         builtHex = '0x' + rgbaBuilder.join('');

//         // tslint:disable-next-line: no-bitwise
//         const rgbaPart1 = (builtHex >> 16) & 255;
//         // tslint:disable-next-line: no-bitwise
//         const rgbaPart2 = (builtHex >> 8) & 255;
//         // tslint:disable-next-line: no-bitwise
//         const rgbaPart3 = builtHex & 255;

//         const finalRgba = `rgba(${[rgbaPart1, rgbaPart2, rgbaPart3].join(',')}, ${transparency})`;

//         return finalRgba;
//       }

//       console.error(':: VRM Error: Pass in a correct HEX Colour code');
//     }
//     else {
//       console.error(':: VRM Error: Profile data unavailable. Unable to convert HEX Color to RGB');
//     }
//   }

//   /**
//    * NOTE
//    * If calling the method, ensure that it is also called onDestroy with pauseOrDestroy set to true.
//    */
//   carouselDriver(listLength: number, pausedOrDestroy: boolean = false, delayInMs: number = 12000)
//   {
//     this.carouselItemIndicatorList = new Array(listLength);
//     this.carouselPaused = pausedOrDestroy;
//     const boundary = listLength - 1;

//     if (!pausedOrDestroy) {
//       this.appStableSinceInitLoad$.pipe(
//         first(stable => stable)).subscribe({
//           next: (appStatus) => this.zone.run(() => {
//               this.carouselIntervalInterrupter = setInterval(() => 
//               {
//                 if (this.carouselItemIndex >= boundary) {
//                   this.carouselItemIndex = 0;
//                 }
//                 else {
//                   this.carouselItemIndex++;
//                 }
//               },
//               delayInMs);
//           })
//         });
//     }
//     else {
//       if (this.carouselIntervalInterrupter) {
//         clearInterval(this.carouselIntervalInterrupter);
//       }
//     }
//   }

//   trackByFn(index: number) {
//     return index;
//   }
// }


