import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { Config } from '../config';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { CommonService } from '../services/common.service';
import { Credential } from '../model/credential';
import { ApiUser } from '../model/user';

export interface CompanyPasswordPolicy {
  passwordMinLength: string | number;
  passwordMaxLength: string | number;
  passwordRequireNumeric: boolean;
  passwordRequireUppercase: boolean;
  passwordRequireLowercase: boolean;
  passwordRequireSpecialChars: boolean;
  passwordAllowedSpecialChars: string;
  passwordNotUseLastNumPasswords: string | number;
  passwordMinHoursBeforeChange: string | number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginErrorMessage: string;
  loginSuccessMessage: string;
  passwordChangeErrorMessage: string;
  messageHasCountdown: boolean = false;
  userProfileSet: boolean = false;
  doFullRefreshOnLogout: boolean = false;
  serverTokenReleaseFailedOnLogout: boolean = false;

  // TODO: Translate via readyTranslations
  pwStrengthLabels = ['', '(Weak)', '(Medium)', '(Strong)', '(Very strong)'];

  idleState: string = '';
  timedOut: boolean = false;
  lastPing?: Date = null;
  countdownSafeValue: number = 4;
  idleInSeconds: number;
  timeoutInSeconds: number = 120; // 2mins
  keepaliveInSeconds: number;
  timeoutCountdown: number;
  tokenExpirationInMins: number;

  private tokenRefreshSubscription: SubscriptionLike;
  private keepAliveSubscription: SubscriptionLike;

  constructor(
    private http: HttpClient, public jwtHelper: JwtHelperService, private router: Router,
    private cService: CommonService, private idle: Idle, private keepalive: Keepalive,
    private translate: TranslateService
  ) { }

  isAuthenticated(): boolean {
    const token = this.getToken();

    // Check if token expired
    return (token) ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  login(credentials: Credential): Observable<any> {
    credentials.companyCode = (this.cService.userProfile && this.cService.userProfile.loginNamespace) ?
      this.cService.userProfile.loginNamespace + credentials.companyCode : credentials.companyCode;

    return this.http.post(Config.apiRoot.uri + 'users/login', credentials).pipe(
      map(response => {
        this.successfulLoginCallback(response);
        return response;
      }));
  }


 parseJwt(token) {
   return this.jwtHelper.decodeToken(token)
}
  loginToDriverHq(credentials: Credential): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ed_app': "DriverHQ",
      'ed_locale': "en_US'",
    });
    let options = { headers: headers };
    return this.http.post(Config.apiRoot.uri + 'api/v1/auth', credentials,
      options).pipe(
        map(response => {
          //this is workaround for DriverHQ
          response = this.getLoginModel(response);
          response['pin']=credentials.pin;
          response['companyCode']=credentials.companyCode;
          this.successfulLoginCallback(response);
          
          return response;
        }));
  }

  geUiSetting(token:string): Observable<any> {
 
    let decoded = this.parseJwt(token);
  
    let companyId = decoded.vrmUser.companyId;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token,
      'ed_locale': "en_US'",
    });
    let options = { headers: headers };
    return this.http.get(Config.apiRoot.uri + `api/v1/companies/${companyId}/ui-setting`,
      options).pipe(
        map(response => {
          return response;
        }));
  }

  ssoLoginToDriverHq( shortLivedJWT: string, companyCode:string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ed_app': "DriverHQ",
      'ed_locale': "en_US'",
    });
    let options = { headers: headers };
    // let decoded = this.parseJwt(shortLivedJWT);

    return this.http.post(Config.apiRoot.uri + 'api/v1/auth_sso', { jwt: shortLivedJWT, companyCode: companyCode },
      options).pipe(
        map(response => {
          //this is workaround for DriverHQ
          response = this.getLoginModel(response);
          this.successfulLoginCallback(response);
          return response;
        }));

    
    }

  ssoLogin(token: string): Observable<any> {
    return this.http.post(Config.apiRoot.uri + 'users/login/sso', {
      token: token
    }).pipe(
      map(response => {
        this.successfulLoginCallback(response, 'SSO');
        return response;
      })
    );
  }

  private successfulLoginCallback(response: any, loginType: string = '') {
 
    this.setTokenToStorage(response['token']);
    console.log(`Logged In Successfully ${loginType}`, response);
    const profile = response;
    this.setUserProfileAndTokenExpiration(profile);
    this.geUiSetting(response['token']).subscribe(res2 => {

      for (let prop in res2) {//bind all the ui attribute to the profile
        profile[prop] = res2[prop];
      }
      profile.logo = this.cService.companiesLogoRoot + res2.companyLogo + '.png';


      this.setUserProfileAndTokenExpiration(profile);


    })


   


  }

  refreshToken(): Observable<{ token: string }> {
    return;
    if (this.isAuthenticated) {
      return this.http.post<{ token: string }>(Config.apiRoot.uri + 'users/token/refresh', {}, {
        headers: {
          vrmCustom_hideProgress: 'true',
          vrmCustom_requestType: 'tokenRefresh'
        }
      }).pipe(
        map(response => {
          this.setTokenToStorage(response.token);
          console.log('Token Successfully Refreshed', response.token);
          return response;
        })
      );
    }
  }

  getToken(): string {
    return localStorage.getItem(this.cService.localStorageKeys.accessToken);
  }
  setTokenToStorage(token: string) {
    localStorage.setItem(this.cService.localStorageKeys.accessToken, token);

    this.tokenExpirationInMins = Number(this.getTokenExpirationTime({ inMinutes: true }));
    localStorage.setItem(this.cService.localStorageKeys.accessTokenExpiration, this.tokenExpirationInMins.toString());
  }
  getTokenExpirationTime({ inMinutes }) {
    const token = this.getToken();
    const expirationDate = this.jwtHelper.getTokenExpirationDate(token);

    if (inMinutes) {
      const momentDateTimeNow = moment();
      const momentExpDate = moment(expirationDate);
      return Math.floor(moment.duration(momentExpDate.diff(momentDateTimeNow)).asMinutes());
    }

    return expirationDate;
  }
  releaseApiTokenOnLogout(): Observable<any> {
    // Release token by logging out of the API
    return this.http.post(Config.apiRoot.uri + 'users/logout', {});
  }
  releaseClientCachedTokenOnLogoutAttempt(doNotRedirect?: boolean, callback?: () => void) {

    this.cService.closeCurrentToast();
    localStorage.removeItem(this.cService.localStorageKeys.accessToken);

    if (doNotRedirect) {
      callback();
    }
    else {
      if (this.cService.userProfile && this.cService.userProfile.isSso) {
        if (this.cService.userProfile.ssoLogoutUrl) {
          location.href = this.cService.userProfile.ssoLogoutUrl;
        }
        else {
          this.router.navigate(['sso']);
        }
      }
      else {
        this.navigateToLoginPage();
      }
    }
  }

  navigateToLoginPage() {
    if (this.cService.userProfile && this.cService.userProfile.loginNamespace) {
      console.log('%c :::: Namespace login prior navigation:', 'color: orange', this.cService.userProfile.loginNamespace);
      this.router.navigate([`login/${this.cService.userProfile.loginNamespace}`]);
    }
    else {

      this.router.navigate(['login']);
    }
  }

  /**
   * @clientOnly - set this flag whenever there's no need to hit the server on logout.
   * There's an instance where hitting the server to confirm logout would lead to a loop.
   * 
   * E.g. when called in the http.interceptor after a 401. Logout would also return a 401
   * (since there's no valid token to present to the server at the time). For this reason,
   * logout would be called over and over again as long as 401 is returned
   */
  logout(clientOnly?: boolean, preventReload?: boolean) {
    if (!preventReload) {
      this.doFullRefreshOnLogout = true;
    }

    if (this.keepAliveSubscription) { this.keepAliveSubscription.unsubscribe(); }
    if (this.tokenRefreshSubscription) { this.tokenRefreshSubscription.unsubscribe(); }
    localStorage.removeItem(this.cService.localStorageKeys.accessToken);
    this.cService.closeCurrentToast();
    this.clearUserProfileButPreserveSettings();

    if (clientOnly) {
      this.releaseClientCachedTokenOnLogoutAttempt();
    }
    else {
      this.releaseApiTokenOnLogout().subscribe({
        next: (response) => {
          this.releaseClientCachedTokenOnLogoutAttempt();
        },

        error: (error) => {
          this.releaseClientCachedTokenOnLogoutAttempt();
        }
      });
    }
  }
  /**
   * Use ONLY WHEN there's limited time to await a callback.
   * For instance, when logging the user out on browser-tab closed.
   */
  quickLogout(doNotRedirect?: boolean, callback?: () => void) {
    this.releaseClientCachedTokenOnLogoutAttempt(doNotRedirect, callback);
    this.clearUserProfileButPreserveSettings();
  }
  clearUserProfileButPreserveSettings() {
    const notToBeCachedProfileData = {
      username: 'username',
      userId: 'userId',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      company: 'company',
      startPage: 'startPage',
      userPermissions: 'userPermissions',
      companyPermissions: 'companyPermissions',
      navigation: 'navigation',
      token: 'token',
      distributorCompanies: 'distributorCompanies',
      preferences: 'preferences'
    };

    if (this.cService.userProfile) {
      // Clear profile related data
      const dataNodeToClear = Object.keys(notToBeCachedProfileData);
      dataNodeToClear.forEach((node) => {
        this.cService.userProfile[node] = null;
      });

      // Store with settings related data preserved
      localStorage.setItem(this.cService.localStorageKeys.userProfile, JSON.stringify(this.cService.userProfile));
    }

    // Clear others
    localStorage.removeItem(this.cService.localStorageKeys.accessTokenExpiration);
  }

  resetTimeoutAndIdleState() {
    this.idle.watch();
    this.idleState = '';
    this.timedOut = false;
  }

  getPasswordPolicyForAuthenticatedUser(): Observable<any> {
    return this.http.get(Config.apiRoot.uri + 'company/password_policy').pipe(
      map(response => { return response; })
    );
  }

  changeAccountPassword(credential: Credential, newPassword: string): Observable<boolean> {
    return this.http.post(Config.apiRoot.uri + 'users/password/update', {
      username: credential.username,
      company: credential.companyCode,
      password: credential.password,
      newPassword: newPassword
    }).pipe(
      map(response => {
        return true;
      })
    );
  }
  requestPasswordRestLink(credentials: Credential): Observable<any> {
    return this.http.post(Config.apiRoot.uri + 'users/reset_password', credentials)
      .pipe(
        map(response => { return response; })
      );
  }
  validatePasswordResetTokenAndGetPolicy(token: string): Observable<any> {
    return this.http.get(Config.apiRoot.uri + 'users/reset_password/' + token)
      .pipe(
        map(response => { return response; })
      );
  }
  resetAccountPassword(token: string, password: string): Observable<any> {
    return this.http.patch(Config.apiRoot.uri + 'users/reset_password/' + token, { password })
      .pipe(
        map(response => { return response; })
      );
  }

  setUserProfileAndTokenExpiration(profile: ApiUser) {
    this.triggerTokenExpirationAndKeepaliveWatch();

    console.log('Profile - API', profile);
    const companyLogo = profile.company.companyLogo;

    const missingProfileData = {
      color: 'generic-theme',
      logo: `${this.cService.companiesLogoRoot}${companyLogo}.png`,
      mobileLogo: `${this.cService.companiesLogoRoot}${companyLogo}-mobile.png`,
      languageCode: this.cService.selectedLanguage ? this.cService.selectedLanguage.code : 'en'
    };
    const tempProfile = Object.assign(missingProfileData, profile);
    this.cService.userProfile = Object.assign(tempProfile, profile.company);
    this.cService.triggerUserProfileReadyObservable(this.cService.userProfile);

    localStorage.setItem(this.cService.localStorageKeys.userProfile, JSON.stringify(this.cService.userProfile));
    console.log('*Full profile data*', this.cService.userProfile);

    this.cService.applyUserProfile();
    this.userProfileSet = true;
  }

  determineDefaultStartPage(startPage: string) {
    if (startPage) {
      // Try and confirm that start-page exists
      for (let i = 0; i < this.cService.navigation.length; i++) {
        const navParent = this.cService.navigation[i];

        if (navParent.url && navParent.url.toLowerCase().includes(startPage)) {
          Config.defaultPage = startPage;
          this.cService.appSettings.defaultPage = startPage;
          break;
        }
        else {
          const navChildren = navParent.children;
          let existsInChildren: any;

          if (navChildren && navChildren.length > 0) {
            existsInChildren = navChildren.find(child => child.url && child.url.toLowerCase().includes(startPage));

            if (existsInChildren) {
              Config.defaultPage = startPage;
              this.cService.appSettings.defaultPage = startPage;
              break;
            }
          }
        }
      }
    }
  }

  switchToSelectedCompany(selectedCompany: string) {
    const currentCompany = this.cService.userProfile.company.toLowerCase();

    if (selectedCompany.toLowerCase() !== currentCompany) {
      this.cService.showToastWithoutDuration(`Switching Distributor Company to: ${selectedCompany.toUpperCase()}`);
      console.log('User attempted to switch Company', selectedCompany);

      this.commonPost(`/distributor/company/${selectedCompany}`, {}, 'distributor-company-switch').subscribe({
        next: (response: ApiUser) => {
          this.successfulLoginCallback(response, 'Company Switch');
          location.reload();
        }
      });
    }
  }

  triggerTokenExpirationAndKeepaliveWatch() {
    if (this.isAuthenticated()) {
      /**
       * IMPORTANT
       * When testing, test with minimum of 9mins
       */
      this.tokenExpirationInMins = Number(this.getTokenExpirationTime({ inMinutes: true }));

      const maxIdeKeepaliveInSeconds = this.cService.convertMinToSeconds(this.tokenExpirationInMins - this.countdownSafeValue);
      this.idleInSeconds = maxIdeKeepaliveInSeconds;
      this.keepaliveInSeconds = maxIdeKeepaliveInSeconds;

      console.log('%c Token EXPIRATION & REFRESH Data', 'color: salmon', {
        tokenExpirationInMins: this.tokenExpirationInMins,
        idleInSeconds: this.idleInSeconds,
        keepaliveInSeconds: this.keepaliveInSeconds
      });

      if (this.tokenExpirationInMins) {
        this.idle.setIdle(this.idleInSeconds);
        this.idle.setTimeout(this.timeoutInSeconds);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleStart.subscribe({
          next: () => {
            this.idleState = `System idle`;
            this.openTimeoutWarning();
            console.log('IDLE START Kicked In');
          }
        });

        this.idle.onTimeoutWarning.subscribe({
          next: (countdown: number) => {
            this.timeoutCountdown = countdown;
            this.idleState = `${this.translate.instant('SECURITY').youWillBeSignedOutIn} ${countdown} ${this.translate.instant('UNITS').seconds}`;
            console.log(this.idleState);
          }
        });
        this.idle.onTimeout.subscribe({
          next: () => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.logout();
          }
        });

        /** 
         * In case user isn't idle for long, then ensure token is not expired
        */
        this.idle.onInterrupt.subscribe({
          next: () => {
            this.closeTimeoutWarning();
          }
        });

        /**
         * Keepalive kicks in and refreshes the token, 
         * if the user interrupts idle state / the set keepalive interval is reached
         */
        this.keepalive.interval(this.keepaliveInSeconds);
        this.keepAliveSubscription = this.keepalive.onPing.subscribe({
          next: () => {
            this.tokenRefreshSubscription = this.refreshToken().subscribe({
              next: () => {
                this.lastPing = new Date();
                this.cService.hideIndeterminateProgress = true;
                console.log('Token Expiration in Minutes', this.tokenExpirationInMins);
              }
            });
          }
        });

        this.resetTimeoutAndIdleState();
      }
      else {
        console.error(':: VRM ERROR : Token expiration management failed due to undefined token expiration time');
      }
    }
  }
  openTimeoutWarning() {
    const toastDuration = this.cService.convertSecondsToMilliseconds(this.timeoutInSeconds);
    this.cService.showTimeoutToastFromComponent(toastDuration, this.timeoutInSeconds);
  }
  closeTimeoutWarning() {
    this.cService.closeCurrentToast();
  }

  // Helper methods
  commonGet(endpointPath: string) {
    return this.http.get(Config.apiRoot.uri + endpointPath)
      .pipe(
        map(response => {
          console.log(`Auth GET - API - ${endpointPath}`, response);
          return response;
        })
      );
  }

  commonPost(endpointPath: string, data: any, vrmRequestName: string = '') {
    return this.http.post(Config.apiRoot.uri + endpointPath, data, {
      headers: { vrmCustom_requestName: vrmRequestName }
    })
      .pipe(
        map(response => {
          console.log(`Auth POST - API - ${endpointPath}`, response);
          return response;
        })
      );
  }


  getLoginModel(userObj) {

  var  token, cdbUserId;
    token = userObj.token;
    cdbUserId = userObj.cdbUserId;
    let sobj: string = `
    {
   "userId":"${cdbUserId}",
   "username":"TspTest",
   "fullName":"TSP Test",
   "firstName":"TSP",
   "lastName":"Test",
   "email":"colo_test@amzl.com",
   "lastLoginTime":"2020-07-02 00:43:39",
   "isDistributor":false,
   "distributorName":"",
   "company":{
     
   },
   "courseList":[
      "PN",
      "P",
      "SUP",
      "PRO",
      "RR",
      "RRFB",
      "RC_ATT",
      "RC_DEC"
   ],
   "riskFoundationCourses":[
      "RF",
      "RFMD",
      "RFT",
      "RF_2010",
      "RC_ECOLAB"
   ],
   "riskCoachCourses":[
      {
         "value":"RC_ATT",
         "text":"Attitude"
      },
      {
         "value":"RC_DEC",
         "text":"Decision Making"
      }
   ],
   "preferences":{
      "gridstate":{
         "sortModel":[

 

         ],
         "filterModel":{
            "status":{
               "filterType":"set",
               "values":[
                  "Active"
               ]
            }
         },
         "displayedColumns":[

 

         ],
         "hiddenColumns":[
            "playlist",
            "playlistOutstanding"
         ],
         "columnGroups":[
         ]
      }
   },
   "startPage":"\/training",
   "companySettings":{
      "coachingReasonText":"READONLY"
   },
   "manages":[
   ],
   "companyRiskLevelValues":{
      "high":0,
      "medium":0,
      "low":0
   },
   "userPermissions":{
      "permIndexing":"view",
      "permUkLicense":"none",
      "permUsMvr":"view",
      "permStats":"none",
      "permEditUsers":"none",
      "permEditDrivers":"none",
      "permEditIncidents":"none",
      "permEditSecurity":"none",
      "permEditReview":"none",
      "permCarrierProfile":"none",
      "permOnetoone":"full",
      "permIndexOverride":"none",
      "permMvrOverride":"none",
      "permVrmAlerts":"none",
      "permMvrReport":"view",
      "permMvrDupOverride":null,
      "permSvcColumn":"view",
      "permAgeColumn":"none",
      "permHequ":"none",
      "permPccReview":"none",
      "permMentorEvaluation":"none",
      "permEventsTable":"none"
   },
   "companyPermissions":{
      "permMisMenuMentorVisible":"FALSE",
      "permIsMentor":1,
      "permMisMenuStatusReportVisible":"FALSE",
      "permMisMenuDriverIndexEventsTableVisible":"TRUE",
      "permMisMenuMentorTsp":"TRUE",
      "permMisMenuDvir":"TRUE"
   },
   "isSso":false,
   "token":"${token}",
   "cdbUserId":"${cdbUserId}"
}`
    return JSON.parse(sobj);
  }


  
}