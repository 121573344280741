import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vrm-expansion-panel',
  template: `
    <mat-expansion-panel class="vrm-expansion-panel" style="border-radius: 0;"
      [ngClass]="'mat-elevation-z' + elevation" 
      [style.border-bottom]="formStyleBorderWidth + ' ' + 'solid'" [style.border-color]="formStyleBorderColor"
      [expanded]="panelExpanded" (opened)="expansionPanelOpened()" (closed)="expansionPanelClosed()"
      [style.background]="transparent ? 'transparent' : ''">

      <mat-expansion-panel-header [style.padding-left]="sidePadding" [style.padding-right]="sidePadding"
        [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight" [style.min-height]="panelHeaderHeight"
        [class.vrm-off-white-border-bottom]="underlineHeading && panelExpanded" [class.expansion-panel-header-shade]="applyHeaderShade">
        <mat-panel-title class="bold">
          <div *ngIf="panelTitle; else useRichHeader" [innerHTML]="panelTitle" class="force" [class.subtle-heading]="subtleTitle"></div>
          
          <ng-template #useRichHeader>
            <ng-content select="[header]"></ng-content>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-content *ngIf="initialExpansionOccurred"></ng-content>
    </mat-expansion-panel>
  `,
  styles: [`
  .use-form-style {
    border-bottom: 2px solid;
  }

  ::ng-deep .vrm-expansion-panel .mat-expansion-panel-body {
    padding: 0 0px 0px !important;
  }
  `]
})
export class VrmExpansionPanelComponent implements OnInit
{
  @Input() panelTitle: string;
  @Input() applyFormStyle: boolean = false;
  @Input() formStyleBorderColor: string = '#333';
  @Input() formStyleBorderWidth: string = '2px';
  @Input() applyHeaderShade: boolean = false;
  @Input() panelHeaderHeight: string = '50px';
  @Input() elevation: number = 1;
  @Input() underlineHeading?: boolean = true;
  @Input() panelExpanded: boolean = false;
  @Input() transparent: boolean = false;
  @Input() subtleTitle: boolean = false;
  @Input() sidePadding: string = '';

  @Output() onExpansionToggle = new EventEmitter<boolean>();

  initialExpansionOccurred: boolean = false;
  panelOpened: boolean;

  constructor() { }

  ngOnInit() {
  }

  expansionPanelOpened() {
    // Render only on first opening
    this.initialExpansionOccurred = true;
    this.panelExpanded = true;

    this.onExpansionToggle.emit(this.panelExpanded);
  }
  expansionPanelClosed() {
    this.panelExpanded = false;
    this.onExpansionToggle.emit(this.panelExpanded);
  }
}
