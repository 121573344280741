const commonRoadRiskCodes = ['PRO', 'PRO_2010', 'RR', 'RRT', 'RRMD', 'RR_2010'];
const commonElearningCodes = ['OMS'];

export const culture = {
    privacy: {
        name: 'Privacy',
        codes: ['PN']
    },
    altPrivacy: {
        name: 'Alt Privacy',
        codes: ['APN']
    },
    policy: {
        name: 'Policy',
        codes: ['POL']
    },
    pledge: {
        name: 'Pledge',
        codes: ['P']
    },
    managerPledge: {
        name: 'Manager Pledge',
        codes: ['AP']
    },
    mentorConsent: {
        name: 'Mentor Consent',
        codes: ['MC']
    },
    authorizationToDrive: {
        name: 'Authorization To Drive',
        codes: ['ATD']
    },
    travellingForWork: {
        name: 'Travelling For Work',
        codes: ['DW']
    },
    tutorialVideos: {
        name: 'Tutorial Videos',
        codes: ['VID_ECOLAB1', 'VID_ECOLAB2']
    },
    // course codes for RF are empty as theses need to be set dynamically from the API
    riskFoundation: {
        name: 'Risk Foundation',
        codes: []
    }
};

export const roadRisk = {
    profileSummary: {
        name: 'Profile Summary',
        codes: commonRoadRiskCodes
    },
    Feedback: {
        name: 'Feedback',
        codes: commonRoadRiskCodes
    },
    defensiveDriving: {
        name: 'Defensive Driving',
        codes: commonRoadRiskCodes
    },
    driverRiskRating: {
        name: 'Driver Risk Rating',
        codes: commonRoadRiskCodes
    }
};

export const eLearning = {
        oneMoreSecond: {
            name: 'One More Second',
            codes: commonElearningCodes
        } 
};