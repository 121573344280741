import { Component } from '@angular/core';
import { IFilterParams, IDoesFilterPassParams, RowNode } from 'ag-grid-community';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'custom-complete-incomplete-notstarted-filter',
    template: `
    <div class="padding-10" fxLayout="column" fxFlex>
        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="completeSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.complete' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="incompleteSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.incomplete' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="notStartedSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.notStarted' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="notApplicableSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.na' | translate }}
        </mat-checkbox>
    </div>
    `,
    styles: [`
        :host ::ng-deep label.mat-checkbox-layout {
            width: 100%;
        }
        :host ::ng-deep label .mat-checkbox-inner-container {
            margin-left: 0px;
        }
    `]
})
export class CustomCompleteIncompleteNotstartedRatingFilterComponent
{
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    completeSelected: boolean = true;
    incompleteSelected: boolean = true;
    notStartedSelected: boolean = true;
    notApplicableSelected: boolean = true;

    constructor(private cService: CommonService) { }

    agInit(params: IFilterParams) {
        this.params = params;
        this.valueGetter = params.valueGetter;
    }

    isFilterActive(): boolean {
        return !this.completeSelected || !this.incompleteSelected || !this.notStartedSelected
            || !this.notApplicableSelected;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        let filterExpression: boolean;
        const nodeValue = this.valueGetter(params.node);
        const rating = this.cService.completeIncompleteNotStartedNa;

        // Single de-selections
        if (!this.notStartedSelected) {
            filterExpression = nodeValue < rating.notStarted;
        }

        if (!this.incompleteSelected) {
            filterExpression = nodeValue != rating.incomplete;
        }

        if (!this.completeSelected) {
            filterExpression = nodeValue != rating.complete;
        }

        if (!this.notApplicableSelected) {
            filterExpression = nodeValue >= rating.complete;
        }

        // Double de-selections
        if (!this.notStartedSelected && !this.incompleteSelected) {
            filterExpression = nodeValue <= rating.complete;
        }

        if (!this.notStartedSelected && !this.completeSelected) {
            filterExpression = nodeValue == rating.incomplete
                || nodeValue < rating.complete; // <-- Captures NA
        }

        if (!this.notStartedSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue == rating.incomplete
                || nodeValue == rating.complete;
        }

        if (!this.incompleteSelected && !this.completeSelected) {
            filterExpression = nodeValue == rating.notStarted
                || nodeValue < rating.complete; // <-- Less than low = NA
        }

        if (!this.incompleteSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue == rating.notStarted
                || nodeValue == rating.complete;
        }

        if (!this.completeSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue >= rating.incomplete;
        }

        // Triple de-selections / single selection
        if (!this.notStartedSelected && !this.incompleteSelected && !this.completeSelected) {
            // Not applicable selected
            filterExpression = nodeValue < rating.complete;
        }

        if (!this.notStartedSelected && !this.incompleteSelected && !this.notApplicableSelected) {
            // Low selected
            filterExpression = nodeValue == rating.complete;
        }

        if (!this.notStartedSelected && !this.completeSelected && !this.notApplicableSelected) {
            // Medium selected
            filterExpression = nodeValue == rating.incomplete;
        }

        if (!this.incompleteSelected && !this.completeSelected && !this.notApplicableSelected) {
            // High selected
            filterExpression = nodeValue == rating.notStarted;
        }

        // Catch all - nothing selected
        if (!this.notStartedSelected && !this.incompleteSelected && !this.completeSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue == null;
        }

        return filterExpression;
    }

    getModel(): any {
        return;
    }

    setModel(model: any) {
        
    }

    // ----------------------

    selectionChanged() {
        this.params.filterChangedCallback();
    }
}