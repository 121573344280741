import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { ReportsService, ReportMeta, EndpointFilterValue, ReportNameEnum } from '../../services/reports.service';
import { CommonService } from '../../services/common.service';

// Dialog
import { DriverIndexComponent } from '../../dialogs/driver-index/driver-index.component';
import { DriverIndexClassicComponent } from 'src/app/dialogs/driver-index-classic/driver-index-classic.component';

import { ReportOption } from 'src/app/model/reports';

@Component({
    selector: 'common-reports',
    templateUrl: './common-reports.component.html',
    styleUrls: ['./common-reports.component.scss']
})
export class CommonReportsComponent implements OnDestroy
{
    requestedReport: string = '';
    reportTitle: string = '';
    commonReportReady: boolean = false;
    multiRowSelection: boolean = true;
    primaryKey: string = 'pin';

    rptOptions: ReportOption;
    reportMeta: ReportMeta;
    rawReportsData: Array<any>;
    pinnedTopRowData: Array<any> = [];
    pinnedBottomRowData: Array<any> = [];

    private currentReportEndpoint: string;
    private dateFilterSubscription: any;
    private reportEndpointFilterSubscription: any;
    private dialogComponent = DriverIndexComponent;
    private classicDialogComponent = DriverIndexClassicComponent;

    constructor(
        private rptService: ReportsService,
        private activatedRoute: ActivatedRoute,
        private cService: CommonService,
        private translate: TranslateService,
        private dialog: MatDialog
    ) {
        this.activatedRoute.params.subscribe({
            next: (param: { report: string }) => {
                this.currentReportEndpoint = param.report.replace(/-/g, `/`);
    
                if (this.currentReportEndpoint) {
                    this.requestedReport = this.currentReportEndpoint;
                }
                else {
                    console.error('ERROR: Requested report endpoint not found');
                }
    
                this.rptOptions = {
                    reportEndpointName: this.requestedReport,
                    mobileColumns: rptService.commonReportOptions.mobileColumns,
                    sideBar: {
                        toolPanels: [
                          {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel'
                          },
                          {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel'
                          },
                          {
                            id: 'customDetails',
                            labelDefault: 'VRM Mentor',
                            labelKey: 'customDetails',
                            iconKey: 'details',
                            toolPanel: 'customDetailsToolPaneComponent'
                          }
                        ],
                
                        defaultToolPanel: 'customDetails',
                        hiddenByDefault: false
                    }
                };
    
                this.renderReport(true);
            }
        });


        this.dateFilterSubscription = this.cService.dateFilterChanged$.subscribe({
            next: (isCommonReport: boolean) => {
                if (this.commonReportReady) {
                    console.log('Date Filter Data CHANGED! Is Common Report:', isCommonReport);
                    this.renderReport(false);
                }
            }
        });

        this.reportEndpointFilterSubscription = this.rptService.reportEndpointFilterChanged$.subscribe({
            next: (filterValue: EndpointFilterValue) => {
                if (this.commonReportReady) {
                    this.renderReport(false);
                    console.log('::::: REPORT ENDPOINT FILTER Changed!', filterValue);
                }
            }
        });
    }

    private renderReport(initialLoad: boolean) {
        if (this.currentReportEndpoint) {
            this.reportTitle = `${this.translate.instant('MESSAGESANDWARNINGS').working}...`;

            this.rptService.commonReportMetaForkJoined(this.currentReportEndpoint)
            .subscribe({
                next: (responses: Array<any>) => {
                    this.reportMeta = responses[0];
                    this.rptService.currentReportMeta = this.reportMeta;
                    this.rawReportsData = responses[1].data;

                    this.cService.currentPageDateOrPeriod = {};
                    
                    if (responses[1].date) {
                        this.cService.currentPageDateOrPeriod.date = responses[1].date;
                    }
                    else if (responses[1].dateComparison) {
                        this.cService.currentPageDateOrPeriod.dateComparison = responses[1].dateComparison;
                    }

                    if (this.reportMeta.name === ReportNameEnum.station) {
                        this.pinnedTopRowData = [];
                    
                        if (this.rawReportsData.length > 0) {
                            const rowToPin = responses[1].data.find(
                                (data: any) => data.station.toLowerCase() === 'all stations' || data.station.toLowerCase() === 'total');

                            if (rowToPin) {
                                this.pinnedTopRowData = [rowToPin];
                                this.rawReportsData = this.rawReportsData.filter(data => data.station != rowToPin['station']);
                            }
                        }
                    }

                    this.rptService.hasTimeFrameFilter = this.reportMeta.defaultPeriod ? true : false;
                    if (this.reportMeta.endpointFilters) {
                        this.translate.get('FILTERS').subscribe({
                            next: (translation) => {
                                if (this.reportMeta.endpointFilters.length > 1) {
                                    this.rptService.multipleEndpointFilters = this.reportMeta.endpointFilters;

                                    this.rptService.multipleEndpointFilters.forEach((endpointFilters) => {
                                        const filterName = endpointFilters.name;
                                        endpointFilters.translatedName = translation[filterName];

                                        // Reset selected value & apply translations
                                        endpointFilters.values.map(value => { 
                                            value.selected = false;
                                            value.translatedText = (translation[value.text]) ? translation[value.text] : value.text;
                                        });

                                        if (!this.rptService.endpointFilterValue) {
                                            const defaultValue = endpointFilters.default;
                                            endpointFilters.values.find(filter => filter.value === defaultValue).selected = true;
                                        }
                                    });

                                    if (this.rptService.endpointFilterValue) {
                                        const filterValue = this.rptService.endpointFilterValue;
                                        
                                        this.rptService.multipleEndpointFilters
                                        .find(endpointFilter => endpointFilter.name === filterValue.filterName).values
                                        .find(filter => filter.value === filterValue.value).selected = true;
                                    }
                                }
                                else {
                                    if (this.reportMeta.endpointFilters.length > 0) {
                                        this.rptService.singleEndpointFilters = this.reportMeta.endpointFilters[0];

                                        const filterName = this.rptService.singleEndpointFilters.name;
                                        this.rptService.singleEndpointFilters.translatedName = translation[filterName];
        
                                        // Reset selected value & apply translations
                                        this.rptService.singleEndpointFilters.values.map(value => { 
                                            value.selected = false;
                                            value.translatedText = (translation[value.text]) ? translation[value.text] : value.text;
                                        });
        
                                        if (this.rptService.endpointFilterValue) {
                                            const filterValue = this.rptService.endpointFilterValue;
                                            this.rptService.singleEndpointFilters.values.find(filter => filter.value === filterValue.value).selected = true;
                                        }
                                        else {
                                            const defaultValue = this.rptService.singleEndpointFilters.default;
                                            this.rptService.singleEndpointFilters.values.find(filter => filter.value === defaultValue).selected = true;
                                        }
        
                                    }
                                }
                            }
                        });
                    }

                    this.commonReportReady = true;
                    this.reportTitle = this.cService.commonReportTitle;

                    if (initialLoad) {
                        if (this.reportMeta.defaultPeriod) {
                            this.rptService.selectedTimeFrame.timeFrame = this.reportMeta.defaultPeriod;
                            console.log('Selected Time-Frame', this.rptService.selectedTimeFrame.timeFrame);
                        }
                        else {
                            this.rptService.selectedTimeFrame.timeFrame = null;
                        }
                    }

                    this.rptService.triggerBehaviourSubjectOnNewCommonReportsPage(this.reportMeta);
                },
                
                error: (error) => {
                    this.reportTitle = `:: ${this.translate.instant('REPORTS').requestedReportFailed}`;
                    this.rptService.triggerBehaviourSubjectOnNewCommonReportsPage(this.reportMeta);
                }
            });
        }
        else {
            console.error(':: ERROR - No endpoint provided');
        }
    }

    makeSelectedRowEditableViaDialog($event: any) {        
        // Open classic dialog or new
        if (this.cService.userProfile.companySettings 
            && this.cService.userProfile.companySettings.driverPerformanceClassic === 'TRUE') {
            this.dialog.open(this.classicDialogComponent, $event);
        }
        else {
            this.dialog.open(this.dialogComponent, $event);
        }
    }

    setDefaultEndpointFilterAndTranslate() {

    }

    ngOnDestroy(): void {
        if (this.dateFilterSubscription) {
            this.dateFilterSubscription.unsubscribe();
        }

        if (this.reportEndpointFilterSubscription) {
            this.reportEndpointFilterSubscription.unsubscribe();
        }

        this.rptService.hasTimeFrameFilter = false;
    }
}