import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from 'src/app/config';

import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'vrm-driver-hq-sso',
  templateUrl: './driver-hq-sso.component.html',
  styleUrls: ['./driver-hq-sso.component.scss']
})
export class DriverHqSsoComponent implements OnInit {
  token: string;
  companyCode: string;
  ssoFailed: boolean = false;
  signInDone: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cService: CommonService
  ) { }

  ngOnInit() {
    // Clear existing token in case user did not logout.
    // This ensures that the newly issued SSO Token is used.
    this.authService.quickLogout(true, () => {
      this.activatedRoute.queryParams.subscribe({
        next: (params: { jwt: string, companyCode: string }) => {
          this.token = params.jwt;
          this.companyCode = params.companyCode;
          console.log('SSO Token', this.token, this.companyCode);

          if (this.token) {
            this.cService.showToast('Logging in...');
            this.authService.ssoLoginToDriverHq(this.token, this.companyCode).subscribe({
              next: (response) => {
                this.goToDefaultPage();
              },

              error: (error) => {
                this.loginFailed();
              }
            });
          }
          else {
            this.loginFailed();
            console.error(':: DriverHq ERROR: Token cannot be empty');
          }
        }
      });
    });
  }

  loginFailed() {
    this.ssoFailed = true;
//loginRedirection
  if(Config.loginRedirection[this.companyCode]){
    let url = Config.loginRedirection[this.companyCode];

   let return_to= encodeURIComponent(`https://driverhq.edriving.com/login_sso?companyCode=${this.companyCode}`)
    let timestamp = Math.floor((new Date()).getTime() / 1000);
    url=url.replace('{0}',return_to).replace('{1}',timestamp);
    location.href =url;
  }
  
    this.cService.showToast(':: Unable to process login');
  }

  goToDefaultPage() {
    this.router.navigate([this.cService.appSettings.defaultPage]);
    if (!this.ssoFailed) {
      this.signInDone = true;
    }
  }
}