export const structure = {
    location:       { name: 'Location',         type: 'text', pinned: true },
    fico_score:     { name: 'Fico Score',       type: 'number' },
    fico_great:     { name: 'Fico Great',       type: 'percentage' },
    fico_good:      { name: 'Fico Good',        type: 'percentage' },
    fico_avg:       { name: 'Fico Avg',         type: 'percentage' },
    fico_poor:      { name: 'Fico Poor',        type: 'percentage' },
    fico_risky:     { name: 'Fico Risky',       type: 'percentage' },
    accel_good:     { name: 'Accel Good',       type: 'percentage' },
    accel_avg:      { name: 'Accel Avg',        type: 'percentage' },
    accel_poor:     { name: 'Accel Poor',       type: 'percentage' },
    braking_good:   { name: 'Braking Good',     type: 'percentage' },
    braking_avg:    { name: 'Braking Avg',      type: 'percentage' },
    braking_poor:   { name: 'Braking Poor',     type: 'percentage' },
    cornering_good: { name: 'Cornering Good',   type: 'percentage' },
    cornering_avg:  { name: 'Cornering Avg',    type: 'percentage' },
    cornering_poor: { name: 'Cornering Poor',   type: 'percentage' },
    speeding_good:  { name: 'Speeding Good',    type: 'percentage' },
    speeding_avg:   { name: 'Speeding Avg',     type: 'percentage' },
    speeding_poor:  { name: 'Speeding Poor',    type: 'percentage' }
};

export const mentorCompareData = [
    {
        "location": "Cape May",
        "fico_score":       820,
        "fico_great":       30,
        "fico_good":        20,
        "fico_avg":         20,
        "fico_poor":        20,
        "fico_risky":       10,
        "accel_good":       40,
        "accel_avg":        40,
        "accel_poor":       20,
        "braking_good":     30,
        "braking_avg":      40,
        "braking_poor":     30,
        "cornering_good":   40,
        "cornering_avg":    40,
        "cornering_poor":   20,
        "speeding_good":    30,
        "speeding_avg":     40,
        "speeding_poor":    30
    },
    {
        "location": "Huddersfield",
        "fico_score":       780,
        "fico_great":       20,
        "fico_good":        50,
        "fico_avg":         10,
        "fico_poor":        10,
        "fico_risky":       10,
        "accel_good":       30,
        "accel_avg":        50,
        "accel_poor":       20,
        "braking_good":     30,
        "braking_avg":      30,
        "braking_poor":     40,
        "cornering_good":   30,
        "cornering_avg":    50,
        "cornering_poor":   20,
        "speeding_good":    30,
        "speeding_avg":     30,
        "speeding_poor":    40
    },
    {
        "location": "BENCHMARK",
        "fico_score":       741,
        "fico_great":       23.33,
        "fico_good":        24.17,
        "fico_avg":         16.67,
        "fico_poor":        21.67,
        "fico_risky":       14.17,
        "accel_good":       30,
        "accel_avg":        41.67,
        "accel_poor":       28.33,
        "braking_good":     30,
        "braking_avg":      40,
        "braking_poor":     30,
        "cornering_good":   30,
        "cornering_avg":    41.67,
        "cornering_poor":   28.33,
        "speeding_good":    30,
        "speeding_avg":     40,
        "speeding_poor":    30
    },
    {
        "location": "Boston",
        "fico_score":       730,
        "fico_great":       35,
        "fico_good":        25,
        "fico_avg":         20,
        "fico_poor":        10,
        "fico_risky":       10,
        "accel_good":       40,
        "accel_avg":        30,
        "accel_poor":       30,
        "braking_good":     40,
        "braking_avg":      30,
        "braking_poor":     30,
        "cornering_good":   40,
        "cornering_avg":    30,
        "cornering_poor":   30,
        "speeding_good":    40,
        "speeding_avg":     30,
        "speeding_poor":    30
    },
    {
        "location": "New York",
        "fico_score":       720,
        "fico_great":       30,
        "fico_good":        20,
        "fico_avg":         20,
        "fico_poor":        20,
        "fico_risky":       10,
        "accel_good":       30,
        "accel_avg":        40,
        "accel_poor":       30,
        "braking_good":     30,
        "braking_avg":      50,
        "braking_poor":     20,
        "cornering_good":   30,
        "cornering_avg":    40,
        "cornering_poor":   30,
        "speeding_good":    30,
        "speeding_avg":     50,
        "speeding_poor":    20
    },
    {
        "location": "San Jose",
        "fico_score":       700,
        "fico_great":       15,
        "fico_good":        20,
        "fico_avg":         20,
        "fico_poor":        20,
        "fico_risky":       25,
        "accel_good":       30,
        "accel_avg":        30,
        "accel_poor":       40,
        "braking_good":     40,
        "braking_avg":      30,
        "braking_poor":     30,
        "cornering_good":   30,
        "cornering_avg":    30,
        "cornering_poor":   40,
        "speeding_good":    40,
        "speeding_avg":     30,
        "speeding_poor":    30
    },
    {
        "location": "Rome",
        "fico_score":       695,
        "fico_great":       10,
        "fico_good":        10,
        "fico_avg":         10,
        "fico_poor":        50,
        "fico_risky":       20,
        "accel_good":       10,
        "accel_avg":        60,
        "accel_poor":       30,
        "braking_good":     10,
        "braking_avg":      60,
        "braking_poor":     30,
        "cornering_good":   10,
        "cornering_avg":    60,
        "cornering_poor":   30,
        "speeding_good":    10,
        "speeding_avg":     60,
        "speeding_poor":    30
    }
];