import * as rg4js from 'raygun4js';
import { ErrorHandler } from '@angular/core';

import { environment } from '../../environments/environment';

export class RaygunErrorHandler implements ErrorHandler
{
    handleError(e: any) {
        if (environment.production || environment.staging) {
            rg4js('enableCrashReporting', true);

            rg4js('send', {
                error: e
            });
        }
    }
}