import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { Score } from '../../model/dashboards';

@Component({
    selector: 'mentor-dashboard-tab',
    templateUrl: './mentor-dashboard-tab.component.html',
    styleUrls: ['./mentor-dashboard-tab.component.scss']
})
export class MentorDashboardTabComponent
{
    ficoOverallScores = [
        { percentage: 40, color: 'white', verbalRating: 'Great' },
        { percentage: 10, color: 'white', verbalRating: 'Good' },
        { percentage: 20, color: 'white', verbalRating: 'Average' },
        { percentage: 20, color: 'white', verbalRating: 'Poor' },
        { percentage: 10, color: 'white', verbalRating: 'Risky' }
    ];

    mentorStats = [
        {
            title: 'Acceleration',
            titleIconName: 'acceleration-icon',
            scores: [
                {
                    verbalRating: 'Good',
                    percentageRating: 60,
                    color: 'green',
                    ratingIcon: 'check_box'
                },
                {
                    verbalRating: 'Average',
                    percentageRating: 30,
                    color: 'orange',
                    ratingIcon: 'remove_circle'
                },
                {
                    verbalRating: 'Poor',
                    percentageRating: 10,
                    color: 'red',
                    ratingIcon: 'cancel'
                }
            ]
        },

        {
            title: 'Braking',
            titleIconName: 'braking-icon',
            scores: [
                {
                    verbalRating: 'Good',
                    percentageRating: 40,
                    color: 'green',
                    ratingIcon: 'check_box'
                },
                {
                    verbalRating: 'Average',
                    percentageRating: 40,
                    color: 'orange',
                    ratingIcon: 'remove_circle'
                },
                {
                    verbalRating: 'Poor',
                    percentageRating: 20,
                    color: 'red',
                    ratingIcon: 'cancel'
                }
            ]
        },

        {
            title: 'Cornering',
            titleIconName: 'cornering-icon',
            scores: [
                {
                    verbalRating: 'Good',
                    percentageRating: 80,
                    color: 'green',
                    ratingIcon: 'check_box'
                },
                {
                    verbalRating: 'Average',
                    percentageRating: 0,
                    color: 'orange',
                    ratingIcon: 'remove_circle'
                },
                {
                    verbalRating: 'Poor',
                    percentageRating: 20,
                    color: 'red',
                    ratingIcon: 'cancel'
                }
            ]
        },

        {
            title: 'Distraction',
            titleIconName: 'distraction-icon',
            scores: [
                {
                    verbalRating: 'Good',
                    percentageRating: 70,
                    color: 'green',
                    ratingIcon: 'check_box'
                },
                {
                    verbalRating: 'Average',
                    percentageRating: 25,
                    color: 'orange',
                    ratingIcon: 'remove_circle'
                },
                {
                    verbalRating: 'Poor',
                    percentageRating: 5,
                    color: 'red',
                    ratingIcon: 'cancel'
                }
            ]
        },

        {
            title: 'Speeding',
            titleIconName: 'speed-icon',
            scores: [
                {
                    verbalRating: 'Good',
                    percentageRating: 90,
                    color: 'green',
                    ratingIcon: 'check_box'
                },
                {
                    verbalRating: 'Average',
                    percentageRating: 5,
                    color: 'orange',
                    ratingIcon: 'remove_circle'
                },
                {
                    verbalRating: 'Poor',
                    percentageRating: 5,
                    color: 'red',
                    ratingIcon: 'cancel'
                }
            ]
        }
    ];

    constructor(public cService: CommonService) {}
}