import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouterLinkWithHref } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { NavigationGuard } from './guards/navigation.guard';

import { Config } from './config';

import {
  ActivitySummaryMiniReportResolver
} from './services/pageloadresolver.service';

// Components
import { DashboardComponent } from './pages/dashboard/dashboard.component';


import { SecurityComponent } from './pages/security/security.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { FleetActivationComponent } from './pages/fleet-activation/fleet-activation.component';

// Guide / FAQ / Other pages
import { CompanyGuideComponent } from './pages/company-guide/company-guide.component';
import { VrmGuideComponent } from './pages/vrm-guide/vrm-guide.component';

// Reports
import { CommonReportsComponent } from './pages/common-reports/common-reports.component';
import { VrmCompareComponent } from './pages/vrm-compare/vrm-compare.component';

// DVCR
import { FleetReportDvcrComponent } from './pages/fleet-report-dvcr/fleet-report-dvcr.component';

// Provisioning
import { SelfProvisioningComponent } from './pages/self-provisioning/self-provisioning.component';
import { VrmUploadUsersFormComponent } from './components/vrm-upload-users-form/vrm-upload-users-form.component';
import { TrainingComponent } from './pages_driverhq/training/training.component';
import { CoachingComponent } from './pages_driverhq/coaching/coaching.component';
import { DriverPerformanceComponent } from './pages_driverhq/driver-performance/driver-performance.component';
import { DriverPerformanceMentorComponent } from './pages_driverhq/driver-performance-mentor/driver-performance-mentor.component';
import { EventsComponent } from './pages_driverhq/events/events.component';
import { AuthDriverhqGuard } from './guards/auth-driverhq.guard';
import { DriverhqGuideComponent } from './pages_driverhq/driverhq-guide/driverhq-guide.component';
import { DriverHqSsoComponent } from './pages_driverhq/driver-hq-sso/driver-hq-sso.component';
import { LoginComponent } from './pages_driverhq/login/login.component';
import { BillingComponent } from './pages_driverhq/billing/billing.component';
import { ResourcesComponent } from './pages_driverhq/documents/resources.component';


const routes: Routes = [
  { path: '', redirectTo: Config.defaultPage, pathMatch: 'full' },

  {
    path: 'training',
    component: TrainingComponent,
    data: { title: 'Trainig' },
    canActivate: [AuthDriverhqGuard]

  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Portal Login' }

  },
  {
    path: 'performance',
    component: DriverPerformanceComponent,
    data: { title: 'Driver Peformance' },
    canActivate: [AuthDriverhqGuard]
  },
  {
    path: 'performance-mentor',
    component: DriverPerformanceMentorComponent,
    data: { title: 'Driver Peformance - Mentor' },
    canActivate: [AuthDriverhqGuard]
  },
  {
    path: 'events',
    component: EventsComponent,
    data: { title: 'Events' },
    canActivate: [AuthDriverhqGuard]
  },
  {
    path: 'coaching',
    component: CoachingComponent,
    data: { title: 'Coaching' },
    canActivate: [AuthDriverhqGuard]
  },
  {
    path: 'billing',
    component: BillingComponent,
    data: { title: 'Billing' },
    canActivate: [AuthDriverhqGuard]
  }
  ,

  {
    path: 'guides',
    component: DriverhqGuideComponent,
    data: { title: 'Guides/Faq' },
    canActivate: [AuthDriverhqGuard]
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    data: { title: 'My Documents' },
    canActivate: [AuthDriverhqGuard]
  },
  {
    path: 'sso',
    component: DriverHqSsoComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [RouterLinkWithHref]
})
export class AppRoutingModule { }
