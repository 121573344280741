import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'vrm-mat-paginator',
  template: `
    <mat-paginator [pageSizeOptions]="cService.paginationSizeOptions" [length]="length"
    showFirstLastButtons (page)="onPageChange($event)" [pageSize]="pageSize" [pageIndex]="pageIndex"></mat-paginator>
  `,
  styles: []
})
export class VrmMatPaginatorComponent implements AfterViewInit
{
  @Input() dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() length: number;
  pageSize = 10;
  pageIndex: number;
  pageEvent: PageEvent;

  constructor(public cService: CommonService) { }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  onPageChange(event: any) {
    this.pageEvent = event; 
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // console.log("Event", event);
  }
}
