import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';

import { GuideViewerComponent } from 'src/app/dialogs/guide-viewer/guide-viewer.component';
import { CasualReportService, CasualDialogTypesEnum } from 'src/app/services/casual-report.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'title-link-button',
    template: `
    <span>
        <button mat-button fxFlex class="sharp-corners bold grid-text" 
            style="text-decoration: underline" vrm-color
            (click)="triggerLinkButtonAction()" *ngIf="value; else displayValueOnly">
            {{ value }}
        </button>
        <ng-template #displayValueOnly>{{ value }}</ng-template>
    </span>
    `,
    styles: []
})
export class TitleLinkButtonCasualReportComponent implements ICellRendererAngularComp
{
    public value: string;
    private guideViewerDialog = GuideViewerComponent;

    constructor(
        private dialog: MatDialog,
        private crService: CasualReportService,
        private cService: CommonService
    ) { }

    agInit(params: { value: any }) {
        this.value = params.value;
    }

    triggerLinkButtonAction() {
        setTimeout(() => {
            const defaultSelection = this.crService.selectedRows[0];

            if (defaultSelection) {
                this.dialog.open(this.guideViewerDialog, {
                    maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
                    maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
                    height: '100%',
                    width: (this.cService.isMobileViewport) ? '100%' : '95%',
            
                    data: {
                        selectedRow: defaultSelection,
                        casualDialogType: CasualDialogTypesEnum.guideViewer
                    },
            
                    disableClose: true
                });
            }
        }, 100); // <-- Wait for selectedRow to be up-to-date
    }

    refresh(): boolean {
        return false;
    }
}