import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MoreOption } from '../../model/moreoption';

@Component({
  selector: 'vrm-card',
  template: `
    <div class="vrm-card" [style.marginTop]="marginTop + 'px'">
      <div class="header">
        <div fxLayout="row" fxLayoutAlign="space-between" [class]="headerShadeClass" class="header-content">
          <div class="title">{{ cardTitle }}</div>

          <div *ngIf="actions">
            <div style="padding-top: 6px" *ngIf="cService.isPcViewport else moreOptionsMenu">
              <button *ngFor="let action of actions" mat-button color="primary" [matTooltip]="action.description"
                (click)="action.method()" style="margin-right: 5px; border-radius: 0">
                {{ action.name }}
              </button>
            </div>

            <ng-template #moreOptionsMenu>
              <div class="margin-t-5">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button *ngFor="let action of actions" mat-menu-item [matTooltip]="action.description"
                    (click)="action.method()" style="margin-right: 5px; border-radius: 0">
                    <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
                    <span>{{ action.name }}</span>
                  </button>
                </mat-menu>
              </div>
            </ng-template>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div class="body" [class.padding-bottom]="paddingBottom === true" [style.maxHeight]="maxHeight">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .vrm-card div.header div.title {
      padding-top: 15px;
      padding-left: 10px;
      padding-right: 10px;

      font-size: 14px;
      font-weight: bold;
      height: 33px;
    }
    .vrm-card div.body {
      overflow-y: auto;
    }
    .vrm-card .padding-bottom {
      padding-bottom: 40px;
    }
  `]
})
export class VrmCardComponent implements OnInit 
{
  @Input() cardTitle: string;
  @Input() raised: boolean = true;
  @Input() marginTop = 20;
  @Input() maxBodyHeight = 'auto';
  @Input() actions?: MoreOption[];
  @Input() shadedHeaderLevel?: number;
  @Input() paddingBottom?: boolean = false;

  maxHeight: string;
  width = '100%';
  headerShadeClass: string = '';

  constructor(public cService: CommonService) { }

  ngOnInit() {
    this.maxHeight = (this.maxBodyHeight == 'auto') ? this.maxBodyHeight.toString() : this.maxBodyHeight + 'px';
    if (this.shadedHeaderLevel) {
      if (this.shadedHeaderLevel == 5 || this.shadedHeaderLevel >= 10 || this.shadedHeaderLevel <= 18) {
        this.headerShadeClass = `light-grey-bg-${this.shadedHeaderLevel}pc`;
      }
    }
  }

}
