import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material';

import { GuideViewerComponent } from 'src/app/dialogs/guide-viewer/guide-viewer.component';
import { CasualDialogTypesEnum } from 'src/app/services/casual-report.service';
import { CommonService, VrmFileIcon } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';
import { GuideAndBucketContentService } from '../../services/guide-and-bucket-content.services';

@Component({
    selector: 'column-to-icon-link',
    template: `
    <span [matTooltip]="value">
        <button mat-button fxFlex class="sharp-corners bold grid-text" 
            style="text-decoration: underline" vrm-color
            (click)="triggerLinkButtonAction()" *ngIf="value; else displayValueOnly">
            
            <i [class]="fileIcon.icon" [style.color]="fileIcon.color"
                style="font-size: 25px; position: relative; top: 2px">
            </i>
        </button>

        <ng-template #displayValueOnly>{{ value }}</ng-template>
    </span>
    `,
    styles: []
})
export class FileExtToIconAndLinkButtonComponent implements ICellRendererAngularComp
{
    fileIcon: VrmFileIcon;
    value: string;
    private guideViewerDialog = GuideViewerComponent;

    constructor(
        private dialog: MatDialog,
        private cService: CommonService,
        private rptService: ReportsService,
        private gBucketService: GuideAndBucketContentService
    ) { }

    agInit(params: { value: any }) {
        this.value = params.value;
        this.fileIcon = this.cService.getApplicableIconForExtension(this.value);
    }


    triggerLinkButtonAction() {
        setTimeout(() => {
            const defaultSelection = this.rptService.selectedRows[0];
            console.log('%c PDF LINK BUTTON ACTION', 'color: lightblue', {
                defaultSelection,
                value: this.value
            });

            if (defaultSelection) {
                this.dialog.open(this.guideViewerDialog, {
                    maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
                    maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
                    height: '100%',
                    width: (this.cService.isMobileViewport) ? '100%' : '95%',
            
                    data: {
                        selectedRow: defaultSelection,
                        casualDialogType: CasualDialogTypesEnum.guideViewer,
                        fileToFetch: 'report-file'
                    },
            
                    disableClose: true
                });
            }

        }, 100); // <-- Wait for selectedRow to be up-to-date
    }


    refresh(): boolean {
        return false;
    }
}