


import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { CommonService } from '../../services/common.service';
import { DriverHqService } from 'src/app/services/driver-hq.service';
interface GuideViewerData {
  id?: number;
  title?: string;
  company?: string;
  content_region?: string;
  lang?: string;
  asset?: string;
  extension?: string;
  use?: string;
  external?: boolean;
  manager_only?: boolean;
  show_in_mis?: boolean;
  created_at?: string;

  // MVR . Report
  pin?: string;
  mvrReport?: string;
  firstName?: string;
  lastName?: string;
}
interface DialogData {
  selectedRow: GuideViewerData;
  casualDialogType: string;
  fileToFetch?: string;
}

@Component({
  selector: 'vrm-driver-hq-guide-viewer',
  templateUrl: './driver-hq-guide-viewer.component.html',
  styleUrls: ['./driver-hq-guide-viewer.component.scss']
})
export class DriverHqGuideViewerComponent {
  dialogTitle: string = '';
  titleDescription: string = '';
  showDetailsBufferProgress: boolean = false;
  streamSource: any;
  resourceReadyOrFailed: boolean = false;

  searchText = new FormControl();
  minDocSearchCharTrigger: number = 2;

  pdfDocumentReady: boolean = false;
  autoresizePdf: boolean = true;
  showAllPdfPages: boolean = true;
  defaultPdfZoomValue: number = 1.1;
  pdfZoomValue: number;
  currentPdfPage: number = 1;
  totalPdfPages: number;

  fileExtension: string;

  @ViewChild(PdfViewerComponent, { static: false }) private pdfComponent: PdfViewerComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dHqService: DriverHqService,
    public cService: CommonService
  ) {
    console.log('inside constructor')
    console.log(data)
    this.initialize();
  }

  initialize() {
    this.pdfZoomValue = this.defaultPdfZoomValue;
    let title = '';
    let description = '';

    if (this.data.selectedRow.title) {
      title = (this.data.selectedRow.title) ? this.data.selectedRow.title : this.data.selectedRow.asset;
      description = (this.data.selectedRow.asset) ? this.data.selectedRow.asset : '';
    }
    else {
      // Assume MVR
      title = `MVR . ${this.data.selectedRow.firstName} ${this.data.selectedRow.lastName}`;
      description = this.data.selectedRow.pin;
    }

    this.fileExtension = this.data.selectedRow.extension || this.data.selectedRow.mvrReport;
    console.log('%c File Ext', 'color: limegreen', this.fileExtension);

    setTimeout(() => {
      this.dialogTitle = title;
      this.titleDescription = this.cService.toTitleCase(description);
    });

    console.log('%c Dialog Data', 'color: tomato', this.data);


    this.showDetailsBufferProgress = true;
    this.dHqService.getGuideData(
      this.data.selectedRow.id, this.data.selectedRow.extension).subscribe({
        next: (response: any) => {
          this.showDetailsBufferProgress = false;
          console.log('%c Requested Guide Stream Link', 'color: salmon', response);

          // Render PDF
          if (this.data.selectedRow.extension.toLowerCase() === 'pdf') {
            setTimeout(() => {
              this.streamSource = response;
              this.resourceReadyOrFailed = true;
            });
          }
        },

        error: (error: any) => {
          this.showDetailsBufferProgress = false;
          this.resourceReadyOrFailed = true;
        }
      });


    // Search input event
    this.searchText.valueChanges.pipe(debounceTime(500))
      .subscribe({
        next: (searchInput: string) => {
          const searchCommandParams = {
            caseSensitive: false,
            findPrevious: undefined,
            highlightAll: true,
            phraseSearch: true,
            query: searchInput
          };

          if (searchInput && searchInput.split('').length >= this.minDocSearchCharTrigger) {
            this.pdfComponent.pdfFindController.executeCommand('find', searchCommandParams);
          }
        }
      });
  }

  pdfDocumentLoaded(pdfData: { numPages: number }) {
    this.totalPdfPages = pdfData.numPages;
    this.pdfDocumentReady = true;
  }

  showAllPdfPagesToggled() {
    this.showAllPdfPages = !this.showAllPdfPages;

    // TODO: This is a workaround for a situation where the PDF Viewer
    // failed to sync pages after turning on show-all while on the last page.
    // This workaround throws an error from the PDF Viewer but harmless one.
    if (this.showAllPdfPages) {
      this.currentPdfPage = 1;
    }
  }

  nextPage(currentPage: number) {
    this.currentPdfPage = currentPage;
    console.log('%c Current PDF Page', 'color: limegreen', this.currentPdfPage);
  }
  previousPage(currentPage: number) {
    this.currentPdfPage = currentPage;
    console.log('%c Current PDF Page', 'color: limegreen', this.currentPdfPage);
  }
  switchPageToSelectedNumber(currentPage: number) {
    this.currentPdfPage = currentPage;
    console.log('%c Current PDF Page', 'color: limegreen', this.currentPdfPage);
  }

  downloadFile() {
    window.open(this.streamSource, '_blank');
  }
}