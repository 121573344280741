import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';

import { CommonService } from '../../services/common.service';
import { ReportsService } from '../../services/reports.service';
import { CasualReportService, CasualDialogTypesEnum } from '../../services/casual-report.service';
import { ReportOption, OtherMiniReportOptions } from 'src/app/model/reports';

import { TitleLinkButtonCasualReportComponent } from '../ag-grid-cell-renderers/title-link-button-casual-report.component';
import { FileExtensionAsIconComponent } from '../ag-grid-cell-renderers/file-extension-as-icon.component';
import { ActionOptionButtonCasualReportComponent } from '../ag-grid-cell-renderers/action-option-button-casual-report.component';

@Component({
    selector: 'vrm-casual-report',
    templateUrl: './vrm-casual-report.component.html',
    styleUrls: ['./vrm-casual-report.component.scss']
})
export class VrmCasualReportComponent
{
    @Input() metadata: any;
    @Input() rawData: any;
    @Input() reportTitle: string;
    @Input() reportOptions: ReportOption;
    @Input() otherOptions?: OtherMiniReportOptions;

    @Output() showDialog: EventEmitter<any> = new EventEmitter();

    agMobileColumns: ColDef[] = [];

    columnsToDisplay: ColDef[];
    agGridPcColumns: ColDef[];

    doubleClickedRowData: any;
  
    initialLoad: boolean = true;
    initialView: string;

    frameworkComponents: any;
    statusBar: any;
    gridOptions: any;
    isRtlLanguage: boolean;

    @ViewChild('agGridCasualReport', { static: true }) agGrid: AgGridAngular;

    constructor(
        private cService: CommonService,
        private rptService: ReportsService,
        private crService: CasualReportService
    ) {
        // TODO: Looks like isRtl needs to be watched. Use RxJs!
        this.isRtlLanguage = cService.selectedLanguage.isRtl;
        setTimeout(() => { 
            if (this.otherOptions.hasHeader) {
                this.cService.setPageHeader(this.reportTitle, true); 
            }
        });

        this.initializeAgGridProperties();
        this.initialView = (cService.isPcViewport) ? 'pc' : 'mobile';
    }

    /* AG-GRID LIFECYCLE HOOKS
    ------------------------ */
    initializeAgGridProperties() {
        // Used to specify Ag-Grid specific sub components
        this.frameworkComponents = {
            titleLinkButtonCasualReportComponent: TitleLinkButtonCasualReportComponent,
            fileExtensionAsIconComponent: FileExtensionAsIconComponent,
            actionOptionButtonCasualReportComponent: ActionOptionButtonCasualReportComponent
        };

        this.statusBar = {
            statusPanels: this.crService.agGridConfig.statusPanels
        };

        // Should come last mostly
        this.gridOptions = {
            defaultColDef: this.crService.agGridConfig.defaultColumnConfig,
            suppressRowClickSelection: false,
            statusBar: this.statusBar,
            rowSelection: 'single',
            ...this.rptService.agGridConfig.common
        };
    }

    agGridReady(event: AgGridAngular) {
        this.displayColumnsByViewportSize();

        setTimeout(() => {
        this.initialLoad = false;
        }, 500);
    }


    /*  EVENTS
    -------------- */
    cellDoubleClicked(event: { data: any; colDef: ColDef; }) {
        this.doubleClickedRowData = event.data;
        this.activateDialog();

        console.log('%c Cell double clicked', 'color: limegreen', this.doubleClickedRowData);
    }

    getRowSelection(event: AgGridAngular) {
        this.crService.selectedRows = event.api.getSelectedRows();
        console.log('%c Selected Casual Report Row', 'color: salmon', this.crService.selectedRows);
    }


    /* METHODS
    ----------- */
    private displayColumnsByViewportSize() 
    {
        if (this.agGrid.columnApi.isPivotMode) {
            this.agGrid.columnApi.setPivotMode(false);
        }

        if (this.metadata.structure) {
            this.agGridPcColumns = this.crService.convertToAgColumnDefinition(this.metadata.structure);
        }
        else {
            this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.formatNotSupported);
            console.warn('VRM WARNING: Invalid Casual Report Structure');
        }
  
        this.columnsToDisplay = this.agGridPcColumns;
        this.commonGridConfig();
    }

    private commonGridConfig() {    
        // Run Async - Ensure grid is attached to the DOM
        setTimeout(() => {
            if (this.columnsToDisplay.length < 10 && !this.cService.isMobileViewport && !this.metadata.groupStructure) {
                this.agGrid.api.sizeColumnsToFit();
            }
            else {
                this.agGrid.columnApi.autoSizeAllColumns();
            }
        });
    }

    activateDialog() {
        if (this.doubleClickedRowData) {
            this.showDialog.emit({
                maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
                maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
                height: '100%',
                width: (this.cService.isMobileViewport) ? '100%' : '95%',

                data: {
                    selectedRow: this.doubleClickedRowData,
                    casualDialogType: CasualDialogTypesEnum.guideViewer
                },

                disableClose: true
            });
        }
        else {
            this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.failedToProcessData);
            console.error('VRM ERROR: Cannot drilldown without valid data selection');
        }
    }
}