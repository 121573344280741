import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformColNames'
})
export class TransformColNames implements PipeTransform {
    transform(columnName: string): { colName: string, tooltip: string | null }
    {
        const column = { colName: '', tooltip: null },
        explicitCols = [
            { name: 'pos', label: 'No.' },
            { name: 'privacy', label: 'Pri.' }
        ];

        // Separate camel-cased words using regex
        const colNameTransformed = columnName.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();

        const explicitValue = explicitCols.find(col => col.name.toLowerCase() === columnName.toLowerCase());
        if (explicitValue) {
            column.colName = explicitValue['label'].toUpperCase();
            column.tooltip = colNameTransformed;
        }
        else {
            column.colName = colNameTransformed;
        }

        return column;
    }
}