import { Component, Input, ViewChild, OnInit, OnDestroy, EventEmitter, Output, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonService } from '../../services/common.service';
import { ReportsService, ReportMeta, ColumnSortModel, ReportNameEnum } from '../../services/reports.service';

import { SendActivationMailComponent } from './send-activation.component';

import { ReportOption } from '../../model/reports';
import { AgGridAngular } from 'ag-grid-angular';
import { NumberToPercentageComponent } from '../ag-grid-cell-renderers/number-to-percentage.component';
import { NumberToDecimalPlaceComponent } from '../ag-grid-cell-renderers/number-to-decimal-place.component';
import { CustomDrivingRatingFilterComponent } from '../ag-grid-custom-filters/custom-driving-rating-filter.component';
import { CustomCompanyRiskFilterComponent } from '../ag-grid-custom-filters/custom-company-risk-filter.component';
import { CustomStandardHighMedLowRatingComponent } from '../ag-grid-custom-filters/custom-standard-high-med-low-rating-filter.component';
import { CustomCompleteIncompleteNotstartedRatingFilterComponent } from '../ag-grid-custom-filters/custom-complete-incomplete-notstarted-rating-filter.component';
import { PrefixPlusSignToPositiveNumComponent } from '../ag-grid-cell-renderers/prefix-plus-sign-to-positive-num.component';
import { MaskStandardHighMedLowRatingsComponent } from '../ag-grid-cell-renderers/mask-standard-high-med-low-ratings.component';
import { MaskPoorAverageGoodRatingComponent } from '../ag-grid-cell-renderers/mask-poor-average-good-rating.component';
import { MaskCompleteIncompleteNotstartedRatingComponent } from '../ag-grid-cell-renderers/mask-complete-incomplete-notstarted-rating.component';
import { MaskCompanyRiskLevelPointsComponent } from '../ag-grid-cell-renderers/mask-company-risk-level-points.component';
import { FileExtToIconAndLinkButtonComponent } from '../ag-grid-cell-renderers/file-ext-to-icon-and-link-button.component';
import { CustomSettingsToolPanelComponent } from '../ag-grid-components/settings-tool-pane/custom-settings-tool-panel.component';
import { CustomDetailsToolPaneComponent } from '../ag-grid-components/details-tool-pane/custom-details-tool-pane.component';
import { GridOptions, SideBarDef, ColDef, Column } from 'ag-grid-community';
import { StationDspFilterComponent } from '../ag-grid-components/station-dsp-filter/station-dsp-filter.component';
import { GridIndexingCoachLinkButtonComponent } from '../ag-grid-cell-renderers/grid-indexing-coach-link-button.component';
import { GridSeeMvrLinkButtonComponent } from '../ag-grid-cell-renderers/grid-see-mvr-link-button.component';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { Subscription } from 'rxjs';
import { PinnedRowStyleComponent } from '../ag-grid-cell-renderers/pinned-row-style.component';

// TODO: Plug this in properly later: import { en } from './grid-internationalization';

@Component({
  selector: 'vrm-mentor-reports',
  templateUrl: './vrm-mentor-reports.component.html',
  styleUrls: ['./vrm-mentor-reports.component.scss']
})
export class VrmMentorReportsComponent implements OnInit, OnDestroy {
  @Input() metadata: ReportMeta;
  @Input() rawData: Array<any> = [];
  @Input() pinnedTopRowData: Array<any> = [];
  @Input() pinnedBottomRowData: Array<any> = [];
  @Input() reportTitle: string;
  @Input() reportOptions: ReportOption;
  @Input() multiRowSelection: boolean = false;
  @Input() primaryKey: string;
  @Input() canAddElement: boolean = false;
  @Input() canUploadElements: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() csvOption: any;
  @Output() addNewElement: EventEmitter<any> = new EventEmitter();
  // Available only if canUpload is true
  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Output() makeRowEditable: EventEmitter<any> = new EventEmitter();
  @Output() deleteElement: EventEmitter<any> = new EventEmitter();

  agMobileColumns: ColDef[] = [];

  columnsToDisplay: ColDef[];
  agGridDefaultColumnConfig: ColDef;

  initialLoad: boolean = true;
  initialView: string;
  reportInformation: string = '';
  gridToolPaneShowing: boolean = true;

  currentFilters: any = {};
  originalKeyHasUnderscore: boolean = false;

  frameworkComponents: any;
  sideBar: SideBarDef;
  statusBar: any;
  gridOptions: GridOptions;
  private icons: any;

  commonReportPageChangedSubscription: Subscription;
  newlySelectedRowAddedSubscription: Subscription;
  headerSearchToggledSubscription: Subscription;
  headerSearchInputChangedSubscription: Subscription;
  reportDownloadRequestSubscription: Subscription;

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  constructor(
    public cService: CommonService,
    public rptService: ReportsService,
    private translate: TranslateService
  ) {
    this.commonReportPageChangedSubscription = rptService.commonReportPageChanged$.subscribe({
      next: () => {
        rptService.selectedRows = [];

        if (!this.initialLoad) {
          setTimeout(() => {
            this.displayColumnsByViewportSize();
          });
        }

        setTimeout(() => {
          this.cService.setPageHeader(this.reportTitle, true, true);
        });
        
      }
    });

    this.initialView = (cService.isPcViewport) ? 'pc' : 'mobile';
  }

  ngOnInit() {
    this.newlySelectedRowAddedSubscription = this.rptService.newlySelectedRowAdded$.subscribe({
      next: (respData) => {
        if (respData) {
          this.multiRowSelection ? this.rptService.selectedRows.push(respData) : this.rptService.selectedRows = [respData];

          if (!this.rptService.gridSearch.text && respData.pin) {
            const selectedRowIndex: number = this.rawData.findIndex(data => data.pin == respData.pin);

            // This would fail if unable to find items to tick due to existing search.
            // Hence why this functionality is only available when search is not in use.
            this.agGrid.api.selectIndex(selectedRowIndex, true, false);
          }

          const driver = this.rawData.find(row => row[this.primaryKey] === respData[this.primaryKey]);
          if (driver) { driver.selected = true; }
        }
      }
    });

    /* ====================================
    Shared Page-header events subscriptions
    ====================================== */
    this.headerSearchToggledSubscription = this.cService.headerSearchToggled$.subscribe({
      next: (closed: boolean) => {
        if (!this.initialLoad) {
          this.rptService.gridSearch.text = '';
          this.clearSearch();
        }
      }
    });

    this.headerSearchInputChangedSubscription = this.cService.headerSearchInputChanged$.subscribe({
      next: (searchText: string) => {
        if (!this.initialLoad) {
          console.log('%c Search Text', 'color: lime', this.rptService.gridSearch.text);
          this.searchTextChanged(searchText);
        }
      }
    });

    this.reportDownloadRequestSubscription = this.cService.downloadReportTriggered$.subscribe({
      next: () => {
        if (!this.initialLoad) {
          this.exportReportAsExcel();
        }
      }
    });
    // -- End

    this.initializeAgGridProperties();
  }

  /* AG-GRID LIFECYCLE HOOKS
  ------------------------ */
  initializeAgGridProperties() {
    // Used to specify Ag-Grid specific sub components
    this.frameworkComponents = {
      numberToPercentageComponent: NumberToPercentageComponent,
      numberToDecimalPlaceComponent: NumberToDecimalPlaceComponent,
      customDrivingRatingFilterComponent: CustomDrivingRatingFilterComponent,
      customCompanyRiskFilterComponent: CustomCompanyRiskFilterComponent,
      customStandardHighMedLowRatingComponent: CustomStandardHighMedLowRatingComponent,
      customCompleteIncompleteNotstartedRatingFilterComponent: CustomCompleteIncompleteNotstartedRatingFilterComponent,
      prefixPlusSignToPositiveNumComponent: PrefixPlusSignToPositiveNumComponent,
      maskPoorAverageGoodRatingComponent: MaskPoorAverageGoodRatingComponent,
      maskCompleteIncompleteNotstartedRatingComponent: MaskCompleteIncompleteNotstartedRatingComponent,
      maskStandardHighMedLowRatingsComponent: MaskStandardHighMedLowRatingsComponent,
      maskCompanyRiskLevelPointsComponent: MaskCompanyRiskLevelPointsComponent,
      fileExtToIconAndLinkButtonComponent: FileExtToIconAndLinkButtonComponent,
      customSettingsToolPanelComponent: CustomSettingsToolPanelComponent,
      customDetailsToolPaneComponent: CustomDetailsToolPaneComponent,
      sendActivationMail: SendActivationMailComponent,
      stationDspFilterComponent: StationDspFilterComponent,
      gridIndexingCoachLinkButtonComponent: GridIndexingCoachLinkButtonComponent,
      gridSeeMvrLinkButtonComponent: GridSeeMvrLinkButtonComponent,
      pinnedRowStyleComponent: PinnedRowStyleComponent
    };

    this.icons = {
      'settings': '<span class="ag-icon ag-icon-settings"></span>',
      'details': '<span class="ag-icon ag-icon-details"></span>'
    };

    if (this.reportOptions && this.reportOptions.sideBar) {
      this.sideBar = this.reportOptions.sideBar;
    }

    this.statusBar = {
      statusPanels: this.rptService.agGridConfig.statusPanels
    };

    // Should come last mostly
    this.gridOptions = {
      defaultColDef: this.rptService.agGridConfig.defaultColumnConfig,
      rowGroupPanelShow: 'always',
      rowSelection: this.multiRowSelection ? 'multiple' : 'single',
      suppressRowClickSelection: false,
      statusBar: this.statusBar,
      excelStyles: this.rptService.agGridConfig.excelStyles,
      icons: this.icons,
      rowClassRules: this.rptService.agGridConfig.rowClassRules,
      ...this.rptService.agGridConfig.common
    };
  }

  agGridReady(event: AgGridAngular) {
    this.displayColumnsByViewportSize();

    setTimeout(() => {
      this.initialLoad = false;
    }, 500);
  }

  /* EVENTS
  ----------- */
  toggleGridToolPane() {
    setTimeout(() => {
      if (this.agGrid.api.isSideBarVisible()) {
        this.agGrid.api.setSideBarVisible(false);
        this.gridToolPaneShowing = false;
      }
      else {
        this.agGrid.api.setSideBarVisible(true);
        this.agGrid.api.openToolPanel('customDetails');
        this.gridToolPaneShowing = true;
      }
    });
  }

  clearRowSelections() {
    this.agGrid.api.deselectAll();
    this.rptService.selectedRows = [];
  }
  getRowSelection(event: AgGridAngular) {
    const currentSelection = event.api.getSelectedRows();
    if (currentSelection && currentSelection.length > 0) {
      // NOTE: getSelectedRow returns no row when search is active.
      // As a result, it overrides any selectedRow during double-click.
      // For this reason, it is only allowed when it returns a row at minimum
      this.rptService.selectedRows = event.api.getSelectedRows();
    }

    // NOTE: This prevents the user from mistakenly unselecting
    // all existing multiple selections due to an accidental single click.
    if (this.rptService.selectedRows.length > 1) {
      this.gridOptions.suppressRowClickSelection = true;
    }
    else {
      this.gridOptions.suppressRowClickSelection = false;
    }
  }
  cellDoubleClicked(event: { data: any }) {
    if (event.data && event.data.pin) {
      // Allow current selection drilldown on double-click. 
      // I.E. Don't clear existing selection - Ecolab request!
      // this.clearRowSelections();

      if (!this.cService.userProfile.manages || this.cService.userProfile.manages.length === 0) {
        console.log('%cCell double-click event', 'color: limegreen', event.data);
        
        const driverAlreadyAdded = this.rptService.selectedRows.find(row => row.pin === event.data.pin);

        if (!driverAlreadyAdded) {
          this.rptService.selectedRows.push(event.data);

          this.rptService.selectedRows.forEach((driverData: any) => {
            const selectedRowIndex: number = this.rawData.findIndex(data => data.pin == driverData.pin);
            this.agGrid.api.selectIndex(selectedRowIndex, true, false);
          });
        }
        
      }
      else {
        this.rptService.autoPopulateManagedDriversForManagement(this.rawData, this.agGrid.api);
      }
      
      this.makeSelectedRowEditableViaDialog(event.data);
    }
  }
  searchTextChanged(searchText: string) {
    setTimeout(() => {
      this.agGrid.api.setQuickFilter(this.rptService.gridSearch.text);
    });
  }
  clearAllFilters() {
    if (this.agGrid.api.isAnyFilterPresent()) {
      setTimeout(() => {
        this.rptService.gridState.filterModel = this.rptService.defaultFilters;
        this.agGrid.api.setFilterModel(this.rptService.gridState.filterModel);
        this.rptService.persistedStateChanged = true;
      });
    }
  }
  clearSearch() {
    if (this.agGrid.api.isQuickFilterPresent()) {
      this.agGrid.api.setQuickFilter('');
    }
  }

  gridSortChanged() {
    if (!this.initialLoad) {
      this.rptService.gridState.sortModel = this.agGrid.api.getSortModel();
      this.rptService.gridState.sortModel.map(
        (model: ColumnSortModel) => { 
          model.colId = model.colId.replace(this.cService.regEx.underscoreAndCharsAfter, '');
        });
      
      this.rptService.persistedStateChanged = true;
      console.log('%c Sort Model', 'color: orange', this.rptService.gridState.sortModel);

      // Show or hide back-end's default sort indicator
      setTimeout(() => {
        const dummySortIndicator = document.getElementById('dummySortIndicator');

        if (dummySortIndicator) {
          if (this.rptService.gridState.sortModel && this.rptService.gridState.sortModel.length > 0) {
            dummySortIndicator.style.display = 'none';
          }
          else {
            dummySortIndicator.style.display = '';
          }
        }
        
      });
    }
  }

  gridColumnFilterModified() {
    setTimeout(() => {
      this.currentFilters = this.agGrid.api.getFilterModel();
      this.rptService.gridState.filterModel = this.currentFilters;

      const currentFilterKeys = Object.keys(this.currentFilters);

      const lastCurrentFilterItem = currentFilterKeys.length - 1;
      this.originalKeyHasUnderscore = 
        currentFilterKeys.length > 0 && currentFilterKeys[lastCurrentFilterItem].includes('_');
    });

    setTimeout(() => {
      Object.assign(this.rptService.gridState.filterModel, this.currentFilters);

      const defaultFilterKeys = Object.keys(this.rptService.defaultFilters);
      defaultFilterKeys.forEach((defaultFilterKey: string) => {
        if (this.currentFilters[defaultFilterKey]) {
          this.rptService.gridState.filterModel[defaultFilterKey] = this.currentFilters[defaultFilterKey];
        }
        else {
          if (!this.rptService.gridState.filterModel[defaultFilterKey]) {
            // Back to default
            this.rptService.gridState.filterModel[defaultFilterKey] =
              this.rptService.defaultFilters[defaultFilterKey];
          }
        }
      });

      // Handle agGrid's underscore suffix to ensure persistence 
      // on identical column names for newly loaded reports.
      const modelKeys = Object.keys(this.rptService.gridState.filterModel);

      if (this.originalKeyHasUnderscore) {
        // Create non-underscored properties
        modelKeys.forEach((originalKey: string) => {
          const key = originalKey.replace(this.cService.regEx.underscoreAndCharsAfter, '');
          this.rptService.gridState.filterModel[key] = this.rptService.gridState.filterModel[originalKey];
        });
      }
      else {
        // Create underscored properties
        modelKeys.forEach((originalKey: string) => {
          const _key = originalKey.replace(this.cService.regEx.underscoreAndCharsAfter, '') + '_1';
          this.rptService.gridState.filterModel[_key] = this.rptService.gridState.filterModel[originalKey];
        });
      }

      this.rptService.persistedStateChanged = true;
      console.log('%c - :: - Filter Model Changed', 'color: limegreen', this.rptService.gridState.filterModel);
    }, 150);
  }

  gridVisibleColumnsToggled() {
    setTimeout(() => {
      const columnState = this.agGrid.columnApi.getColumnState();
  
      // Add / Remove columns from hidden-column list
      columnState.forEach((column: ColumnState) => 
      {
        // NOTE: agGrid would suffix columnIds with _1 whenever new report is loaded and
        // similar column names to the previous report are found. We then remove the suffix
        // as we are able to process column persistence via the report service and before
        // agGrid gets to apply the suffix
        const columnId = column.colId.replace(this.cService.regEx.underscoreAndCharsAfter, '');
        
        const existsInHiddenList = this.rptService.gridState.hiddenColumns.find(col => col === columnId);
  
        if (column.hide) {
          if (!existsInHiddenList) {
            this.rptService.gridState.hiddenColumns.push(columnId);
          }
        }
        else {
          if (existsInHiddenList) {
            this.rptService.gridState.hiddenColumns
             = this.rptService.gridState.hiddenColumns.filter(col => col !== columnId);
          }
        }
      });

      this.rptService.persistedStateChanged = true;
  
      console.log('%c Hidden Columns', 'color: pink; font-size: 16px',
        this.rptService.gridState.hiddenColumns);
    });
  }

  saveOrPersistCurrentStateAsDefault() {
    console.log('%c SAVE Report State', 'color: limegreen', 
      this.rptService.gridState);

    this.rptService.saveReportState().subscribe({
      next: () => { this.rptService.persistedStateChanged = false; }
    });
  }

  gridColumnResized() {
    this.agGrid.api.setHeaderHeight(this.rptService.agGridConfig.common.headerHeight);
    const columns =  this.agGrid.columnApi.getAllColumns();

    // Calculate need for column-heading text to span 3 rows
    for (let i = 0; i < columns.length; i++) {
      const column: Column = columns[i];
      const columnHeaderName = column.getColDef().headerName;

      if (columnHeaderName) {
        const columnHeaderWords = columnHeaderName.split(' ');
        const hasOverTwoWords: boolean = columnHeaderWords.length > 2;
        const columnActualWidth: number = column.getActualWidth();
        const pixelPerChar: number = 7.1;
        const langCode = this.cService.userProfile.languageCode.toLowerCase();
  
        if (hasOverTwoWords) {
          const initialHeight: number = 50;
          let colHeaderHeight: number = initialHeight;
  
          // Check if first word pair fit column size
          const firstWordPair = columnHeaderWords[0] + ' ' + columnHeaderWords[1];
          let remainingWords = '';
          for (let x = 2; x < columnHeaderWords.length; x++) {
            remainingWords += ' ' + columnHeaderWords[x];
          }
  
          // Assume 1 char needs 7.1px of space
          const firstPairLength = firstWordPair.split('').length;
          const remainingWordsLength = remainingWords.split('').length;
          const maxCharPossibleInActualWidth = (columnActualWidth - 20) / pixelPerChar;
  
          if (remainingWordsLength > maxCharPossibleInActualWidth) {
            colHeaderHeight = 85;
            this.setColumnHeaderHeight(colHeaderHeight);
            break;
          }
          else if (firstPairLength > maxCharPossibleInActualWidth) {
            colHeaderHeight = 65;
          }
          else if (firstPairLength >= (maxCharPossibleInActualWidth - 2.5)) {
            colHeaderHeight = 60;
          }
  
          if (colHeaderHeight > initialHeight) {
            this.setColumnHeaderHeight(colHeaderHeight);
          }
        }
        else {
          if (langCode === 'jp' || langCode === 'kr' || langCode === 'ko') {
            this.setColumnHeaderHeight(60);
          }
        }
      }
    }

  }
  setColumnHeaderHeight(colHeaderHeight: number) {
    setTimeout(() => { this.agGrid.api.setHeaderHeight(colHeaderHeight); });
  }

  // Event emitter
  /**
   * Emit upload elements to the parent component
   */
  onUploadElements() {
    if (this.canUploadElements) {
      this.upload.emit({
        maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
        maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
        // height: '100%',
        width: (this.cService.isMobileViewport) ? '100%' : '95%',
        disableClose: true
      });

    } else {
      this.cService.showToast(
        this.cService.readyTranslations.messagesAndWarnings.functionalityNotAvailable);
    }
  }
  /**
   *  Emit add element event to the parent component
   */
  onAddElement() {
    if (this.canAddElement) {
      this.addNewElement.emit({
        maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
        maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
        height: '100%',
        width: (this.cService.isMobileViewport) ? '100%' : '95%',

        data: {
          selectedRow: {},
          reportEndpointName: this.reportOptions.reportEndpointName
        },

        disableClose: true
      });
    } else {
      this.cService.showToast(
        this.cService.readyTranslations.messagesAndWarnings.functionalityNotAvailable);
    }
  }
  /**
   * Emit delete element event to the parent component
   */
  onDeleteElement() {
    const defaultSelection = this.rptService.selectedRows[0];
    if (this.canDelete && defaultSelection) {
      this.deleteElement.emit(defaultSelection);
    } else {
      this.cService.showToast(
        this.cService.readyTranslations.messagesAndWarnings.functionalityNotAvailable);
    }
  }

  exportReportAsExcel() {
    this.agGrid.api.exportDataAsExcel(
      this.rptService.getExportDataCustomization(this.metadata.structure));
  }

  makeSelectedRowEditableViaDialog(suggestedDefaultSelection?: any) {
    const defaultSelection = (suggestedDefaultSelection) 
      ? suggestedDefaultSelection : this.rptService.selectedRows[0];

    this.rptService.currentReportData = this.rawData;

    if (defaultSelection) {
      this.makeRowEditable.emit({
        maxHeight: (this.cService.isMobileViewport) ? '100%' : '',
        maxWidth: (this.cService.isMobileViewport) ? '100%' : '',
        height: '100%',
        width: (this.cService.isMobileViewport) ? '100%' : '95%',

        data: {
          selectedRow: defaultSelection,
          reportEndpointName: this.reportOptions.reportEndpointName
        },

        disableClose: true
      });
    } else {
      console.error('VRM ERROR: Cannot drilldown without valid data selection');
    }
  }

  /* METHODS
  ----------- */

  private displayColumnsByViewportSize() {
    if (this.cService.isMobileViewport) {
      this.agGrid.api.closeToolPanel();
    }
    
    if (this.agGrid.columnApi.isPivotMode) {
      this.agGrid.columnApi.setPivotMode(false);
    }

    this.rptService.currentReportOptions = this.reportOptions;

    this.rptService.dataStructure = this.metadata.structure;
    this.columnsToDisplay = this.rptService.convertToAgColumnDefinition(this.metadata.structure, this.metadata.name);

    this.translate.get('REPORTINFORMATION').subscribe({
      next: (translation) => {
        if (this.metadata.information && this.metadata.information.notification) {
          if (translation[this.metadata.information.notification]) {
            this.reportInformation = translation[this.metadata.information.notification];
          }
          else {
            console.warn('VRM WARNING: Translation not found, hence report info not available');
          }
        }
      }
    });

    if (this.rawData.length === 0) {
      this.pinnedTopRowData = [];
      this.pinnedBottomRowData = [];
    }

    setTimeout(() => {
      this.rptService.currentReportData = this.rawData;
      
      if (!this.initialLoad) {
        this.agGrid.columnDefs = this.columnsToDisplay;
      }

      this.restoreStateAcrossGrids();
      this.gridToolPaneShowing = this.agGrid.api.isSideBarVisible();

      // Set pinned row where applicable - includes workaround to stop duplication of pinned row
      // or displaying of previously pinned row when no data is returned
      // if (this.metadata.name === ReportNameEnum.station) {
      //   this.agGrid.api.setPinnedTopRowData([]);

      //   if (this.rawData.length > 0) {
      //     const pinnedRows = [this.rawData[0]];
      //     this.agGrid.api.setPinnedTopRowData(pinnedRows);

      //     this.rawData = this.rawData.filter(data => data.station != pinnedRows['station']);
      //   }
      // }
    });
  }

  private attemptToFitGridColumns() {
    const visibleColumns = this.columnsToDisplay.filter(col => !col.hide);

    // Run Async - Ensure grid is attached to the DOM
    setTimeout(() => {
      visibleColumns.forEach((columnDef: ColDef) => {
        if (!columnDef.width) {
          this.agGrid.columnApi.autoSizeColumn(columnDef.colId);
        }
      });

      // NOTE: Called manually in case autoSizeColumn() wasn't triggered
      this.gridColumnResized();
    });

    // NOTE: Sizing columns to fit creates inconsistent sizes when 
    // moving from report to report. Allocated sizes are even ignored.

    // setTimeout(() => {
    //   if (this.cService.isPcViewport && visibleColumns.length < 18) {
    //     this.agGrid.api.sizeColumnsToFit();
    //   }
    // }, 250);
  }

  private restoreStateAcrossGrids() {
    if (!this.initialLoad) {
      // NOTE: When a new report is loaded, columns missing in the previous
      // report in view are assumed to come at later index in the column ordering.
      // This solution forces agGrid to use the order returned 
      // from the API after report switch.
      setTimeout(() => {
        const columnKeys = Object.keys(this.metadata.structure);
        this.agGrid.columnApi.moveColumns(columnKeys, 0);
      });
    }

    // Search / QuickFilter
    if (this.rptService.gridSearch.text) {
      this.searchTextChanged(this.rptService.gridSearch.text);
    }

    // Filter / Column Filter
    if (this.rptService.gridState.filterModel) {
      setTimeout(() => {
        // If it's not possible to set this from the reports-service, then agGrid's _1 suffix
        // would need to be taken into account for all filterModel column names.
        this.agGrid.api.setFilterModel(this.rptService.gridState.filterModel);
      });
    }

    this.attemptToFitGridColumns();
  }

  ngOnDestroy(): void {
    if (this.headerSearchToggledSubscription) { this.headerSearchToggledSubscription.unsubscribe(); }
    if (this.newlySelectedRowAddedSubscription) { this.newlySelectedRowAddedSubscription.unsubscribe(); }
    if (this.commonReportPageChangedSubscription) { this.commonReportPageChangedSubscription.unsubscribe(); }
    if (this.headerSearchInputChangedSubscription) { this.headerSearchInputChangedSubscription.unsubscribe(); }
    if (this.reportDownloadRequestSubscription) { this.reportDownloadRequestSubscription.unsubscribe(); }
  }
}
