import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommonService } from '../services/common.service';
import { Config } from '../config';

interface DynamicFormMeta {
    formId: string;
    name: string;
    pageType: string;
    title: string;
    titleTranslationTag?: string;
}
export interface DynamicFormsStruct {
    formId: string;
    metadata: DynamicFormMeta;
    page: Array<any>;
}

// Coach Intervention
export enum TabsEnum {
    insight = 0,
    history
}
export interface PccCrash {
    crashId?: string;
    date?: string;
    description?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DrilldownFormsService
{
    // Coach Intervention
    selectedDriver: any;
    showFormPane: boolean = false;
    widenForm: boolean = false;
    dynamicFormIsVisible: boolean = false;
    refreshHistoryOnTabSwitch: boolean = false;
    launchedIntervention: string;
    pccReviewInterventionValue: string = 'PccReview';
    driverImprovementInterventionValue = 'DriverImprovementReview';
    selectedPccReviewCrash: PccCrash;
    currentFormSubmissionId: string = '';
    currentFormStruct: DynamicFormsStruct;
    selectedDynamicFormData: any;
    formIsReadonly: boolean = false;
    drilldownTabs = { insight: TabsEnum.insight, history: TabsEnum.history };
    currentTabIndex: number = TabsEnum.insight;

    constructor(
        private http: HttpClient,
        private cService: CommonService
    ) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.cService.userProfile.token,
            'ed_locale': "en_US'",
        });
        this.options = { headers: this.headers };
    }
    headers: HttpHeaders;
    options: any;
    // Coach Intervention Events / Methods
    closeFormPane() {
        this.showFormPane = false;
        this.dynamicFormIsVisible = false;
        this.widenForm = false;
        this.refreshHistoryOnTabSwitch = false;
        this.launchedIntervention = '';
        this.currentFormSubmissionId = '';
        this.currentFormStruct = null;
        this.selectedDynamicFormData = null;
        this.formIsReadonly = false;
    }

    launchSelectedIntervention(selectedHistory: any, 
        selectedIntervention: string = '', formHistoryId: string = '', pccReviewCrash: PccCrash = {}) {

        this.selectedPccReviewCrash = pccReviewCrash;

        setTimeout(() => {
            this.launchedIntervention = (selectedHistory) ? selectedHistory.interventionValue : selectedIntervention;
            
            if (this.launchedIntervention) {
                
                this.formIsReadonly = (selectedHistory) ? selectedHistory.readOnly : false;
                formHistoryId = (selectedHistory) ? selectedHistory.submissionId : '';

                this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.working);

                this.currentFormSubmissionId = '';
                if (formHistoryId) {
                    this.currentFormSubmissionId = formHistoryId;
                }

                if (selectedIntervention) {
                    // Assign crash to PCC Review and apply "new" status
                    this.createNewIntervention(selectedIntervention).subscribe({
                        next: (response: { submissionId: string }) => {
                            this.currentFormSubmissionId = response.submissionId;
                            this.getLaunchedDynamicFormStructure(response.submissionId);
                            this.refreshHistoryOnTabSwitch = true;
                        }
                    });
                }
                else {
                    this.getLaunchedDynamicFormStructure(formHistoryId);
                }
            }
            else {
                this.closeFormPane();
                this.cService.showToast(`:: ${this.cService.readyTranslations.messagesAndWarnings.requestedAssetNotAvailable}`);
            }
        }, 100);
    }
    private getLaunchedDynamicFormStructure(formHistoryId: string) {
        this.getDynamicFormsStructAndDataForkJoined(
        this.launchedIntervention, this.selectedDriver.pin, formHistoryId)
        .subscribe({
            next: (response: Array<any>) => {
                this.selectedDynamicFormData = response[0].formData;
                if (response[1].form_structure && response[1].form_structure.metadata) {
                    response[1].form_structure.metadata.pageType = (response[1].form_structure.pages.length > 1) ? 'accordion' : '';
                    this.currentFormStruct = response[1].form_structure;
                }

                this.showFormPane = true;
                this.currentTabIndex = TabsEnum.insight;
                (!this.cService.isPcViewport) ? this.widenForm = true : this.widenForm = false;

                this.dynamicFormIsVisible = true;
            },

            error: (error) => {
                this.cService.showToast(this.cService.readyTranslations.messagesAndWarnings.failedToProcessRequest);
                console.error(`Dynamic Form - ${this.launchedIntervention} - ERROR:`, error);
            }
        });
    }

    // API
    private getActiveEventSummary(pin?: string): Observable<any> {
        return this.commonGet(`driverindex/active_event_summary/${pin}`);
    }
    private getHistoricEventSummary(pin?: string): Observable<any> {
        return this.commonGet(`driverindex/historic_event_summary/${pin}`);
    }
    getEventSummaryForkJoined(pin: string): Observable<any> {
        return forkJoin([
            this.getActiveEventSummary(pin),
            this.getHistoricEventSummary(pin)
        ]);
    }

    private getDriverIndexSummary(pin: string): Observable<any> {
        return this.commonGet(`charts/driverindex/summary/${pin}`);
    }
    private getDriverIndexCoachSummary(pin: string): Observable<any> {
        return this.commonGet(`driverindex/coach_summary/${pin}`);
    }
    private getMentorCharts(pin: string): Observable<any> {
        return this.commonGet(`charts/driverindex/mentor/${pin}`);
    }
    getCoachSummariesForkJoined(pin: string): Observable<any> {
        return forkJoin([
            this.getDriverIndexSummary(pin),
            this.getDriverIndexCoachSummary(pin),
            this.getMentorCharts(pin)
        ]);
    }

    // TODO: Remove once sure ECOLAB has settled into Dynamic Forms
    getDriverIndexCoachForm(pin: string, coachId: string): Observable<any> {
        return this.commonGet(`driverindex/coach/${pin}/${coachId}`);
    }
    //force mat-chip mat-primary mat-standard-chip green-background mat-chip-disabled ng-star-inserted

    private getDynamicFormStructure(formName: string): Observable<any> {

        return this.commonGet(`api/v1/forms/structure`);
        return this.commonGet(`api/v1/forms/structure?tag=${formName}`);
    }
    private getExistingDynamicFormsData(
        formName: string, employeePin: string, formHistoryId?: string): Observable<any> {
        return this.commonGet(
            `api/v1/forms/data///`);
        return this.commonGet(
            `/api/v1/forms/data/${formName}/${employeePin}${formHistoryId ? '/' + formHistoryId : ''}`);
    }
    getDynamicFormsStructAndDataForkJoined(
        formName: string, employeePin: string, formHistoryId?: string): Observable<any> {
        return forkJoin([
            this.getExistingDynamicFormsData(formName, employeePin, formHistoryId),
            this.getDynamicFormStructure(formName)
        ]);
    }

    private createNewIntervention(interventionType: string): Observable<any> {
        const createData = {
            interventionType: interventionType,
            employeeNumber: this.selectedDriver.pin,
            crashId: (this.selectedPccReviewCrash && this.selectedPccReviewCrash.crashId) 
                ? this.selectedPccReviewCrash.crashId : ''
        };

        return this.commonPost(`coaching_interventions/create`, createData);
    }

    saveDynamicForm(formName: string, employeePin: string, formData: any): Observable<any> {
        return this.commonPost(`forms/data/${formName}/${employeePin}`, formData);
    }

    // TODO: Remove once sure ECOLAB has settled into Dynamic Forms
    saveDriverIndexCoachForm(pin: string, coachId: string, formData: any): Observable<any> {
        return this.commonPost(`driverindex/coach/${pin}/${coachId}`, formData);
    }

    // TODO: Remove once sure ECOLAB has settled into Dynamic Forms
    savePccReview(pin: string, submissionName: string, formData: any): Observable<any> {
        return this.commonPost(`form_service/submission/PccReview/${pin}/${submissionName}`, formData);
    }

    getCoachingHistoryTabDataForkJoined(employeePin: any): Observable<any> {
        const historyPostData = {
            employeeNumber: employeePin
        };

        return forkJoin([
            this.commonGet(`coaching_interventions/list/${employeePin}`),
            this.commonGet(`coaching_interventions/unassigned_crashes/${employeePin}`),

            this.commonGet('coaching_interventions/history/meta'),
            this.commonPost('coaching_interventions/history', historyPostData)
        ]);
    }

    getNewlyAuthenticatedTableauCharts(): Observable<any> {
        return this.commonGet(`tableau/trusted`);
    }

    fetchOutstandingInterventionsPerDriver(pins: Array<any>): Observable<any> {
        return this.commonPost(`coaching_interventions/outstanding`, { employeeNumbers: pins });
    }

    // Common requests
    // Common requests
    private commonGet(secondaryPath: string): Observable<any> {
        // alert(`${Config.apiRoot.uri + secondaryPath}`)
        return this.http.get(`${Config.apiRoot.uri + secondaryPath}`, this.options)
            .pipe(
                map(response => {
                    console.log(`%c GET Endpoint: ${secondaryPath}`, 'color: lightblue', response);
                    return response;
                })
            );
    }
    private commonPost(secondaryPath: string, postData?: any): Observable<any> {

        return this.http.post(`${Config.apiRoot.uri + secondaryPath}`, postData, this.options)
            .pipe(
                map(response => {
                    console.log(`%c POST Endpoint: ${secondaryPath}`, 'color: green', response);
                    return response;
                })
            );
    }
  
}
