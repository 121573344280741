import { Pipe, PipeTransform } from '@angular/core';

import { CommonService } from '../services/common.service';

@Pipe({
    name: 'namesToInitial'
})
export class NamesToInitial implements PipeTransform {
    constructor(private cService: CommonService) {}

    transform(names: string) {
        return this.cService.getInitialFromNames(names);
    }
}