import { Component } from '@angular/core';
import { Angular2CsvComponent } from 'angular2-csv';

// NOTE
// What use is Angular2CsvComponent serving? If not needed, please uninstall it.
// Secondly, why make this a component just to create a div?
// What's the use of onDownload() event?

@Component({
  selector: 'app-angularcsv',
  template: '<div (click)=\"onDownload()\"><ng-content></ng-content></div>',
  styles: []
})
export class AngularCsvComponent extends Angular2CsvComponent {}