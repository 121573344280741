import { Component, ViewChild, ElementRef } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { ReportsService, EndpointFilterValue } from '../../services/reports.service';

@Component({
  selector: 'vrm-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent
{
  searchTooltip: string = 'Search';
  searchBorderColor?: string;

  @ViewChild('pageHeaderSearchInput', { static: false }) pageHeaderSearchInput: ElementRef<HTMLInputElement>;

  constructor(
      public rptService: ReportsService,
      public cService: CommonService
  ) {
    this.toggleSearchBorderColor(true);
  }

  private toggleSearchBorderColor(applyColor: boolean) {
    if (applyColor && this.cService.userProfile && this.cService.userProfile.HqColour) {
      this.searchBorderColor = '#' + this.cService.userProfile.HqColour;
    }
    else {
      this.searchBorderColor = '';
    }
  }

  toggleSearchActivation() {
    this.rptService.gridSearch.activated = !this.rptService.gridSearch.activated;

    if (this.rptService.gridSearch.activated) {
      setTimeout(() => {
        this.pageHeaderSearchInput.nativeElement.focus();
      });

      this.searchTooltip = 'Exit search';
    }
    else {
      this.rptService.gridSearch.text = '';
      this.searchTooltip = 'Search';
    }

    this.cService.triggerHeaderSearchToggledAsObservable(this.rptService.gridSearch.activated);
  }

  searchInputChanged() {
    this.cService.triggerHeaderSearchInputChangedAsObservable(this.rptService.gridSearch.text);
  }

  applySingleReportFilter(filterValue: EndpointFilterValue) {
    this.rptService.endpointFilterValue = filterValue;
    this.rptService.endpointFilterValue.filterName = this.rptService.singleEndpointFilters.name;

    this.rptService.triggerReportEndpointFilterChangedObservable(filterValue);
  }

  emitDownloadEvent() {
    this.cService.triggerReportDownloadRequestAsObservable();
  }
}
