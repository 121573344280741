import { Component, OnInit, Input } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'vrm-account-info',
    template: `
    <div class="margin-10" [ngClass]="useMini ? 'margin-t-10' : 'margin-t-30'" style="opacity: 0.7" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center" *ngIf="!useMini">
            <div class="text-align-c card-avatar" 
                [style.borderColor]="vrmPrimaryColor" 
                [style.background]="vrmPrimaryColor">
                <div class="padding-t-15 bold">{{ userInitials }}</div>
            </div>
        </div>

        <div class="text-align-c" [class.padding-t-30]="!useMini" [style.fontSize]="(cService.isPcViewport) ? fontSizeInVw : fixedFontSize">
            <div><span *ngIf="!useMini">Hi, &nbsp;</span> <span>{{ fullName }}</span></div>
            <div *ngIf="!useMini">Last seen: {{ lastSeen | momentIeCompatibleDate: 'relativeTime' }}</div>
            <div [class.margin-t-10]="!useMini">{{ cService.userProfile.email }}</div>

            <div class="margin-t-10" [matTooltip]="canSwitchCompany ? 'Switch Company' : ''" fxLayout="column">
                <small class="red-text">{{ 'SECURITY.companyInUse' | translate | uppercase }}</small>
                <mat-divider></mat-divider>
                <button mat-button [matMenuTriggerFor]="companySwitchList" class="sharp-corners" fxFlex [disabled]="!canSwitchCompany">
                    <span [style.color]="vrmPrimaryColor" class="bold">{{ cService.userProfile.company }}</span> 
                    <mat-icon *ngIf="canSwitchCompany"
                        [style.color]="vrmPrimaryColor" style="position: relative" 
                        [style.top]="dropDownIconPosInPx">arrow_drop_down</mat-icon>

                    <mat-menu #companySwitchList="matMenu" [overlapTrigger]="false">
                        <button mat-menu-item *ngFor="let distComp of cService.userProfile.distributorCompanies"
                            (click)="authService.switchToSelectedCompany(distComp)">
                            <span [class.bold]="cService.userProfile.company.toLowerCase() === distComp.toLowerCase()"
                                [style.color]="cService.userProfile && cService.userProfile.HqColour && cService.userProfile.company.toLowerCase() === distComp.toLowerCase() ? 
                                '#' + cService.userProfile.HqColour : ''">
                                {{ distComp }}
                            </span>
                        </button>
                    </mat-menu>
                </button>
            </div>
        </div>

        <div class="margin-t-15 margin-b-30 bold" fxLayout="row" fxLayoutAlign="center" *ngIf="showSecurityPageButton">
            <button mat-stroked-button color="primary" class="sharp-corners" [style.fontSize]="(cService.isPcViewport) ? fontSizeInVw : fixedFontSize"
                (click)="cService.navigateToPage('security')">
                Account & Security
            </button>
        </div>
    </div>
    `,
    styles: [`
    .card-avatar {
        border: 1px solid;
        color: white;

        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    `]
  })
  export class AccountInfoBadgeComponent implements OnInit
  {
    @Input() fontSizeInVw?: string = '0.75';
    @Input() showSecurityPageButton?: boolean = true;
    @Input() dropDownIconPosInPx?: string = '0px';
    @Input() useMini?: boolean = false;
    @Input() canSwitchCompany: boolean = true;

    fixedFontSize: string = '14px';
    userInitials: string;
    fullName: string;
    lastSeen: string; 
    vrmPrimaryColor: string;
    
    constructor(public cService: CommonService, public authService: AuthService) { }

    ngOnInit() {
        this.lastSeen = this.cService.userProfile.lastLoginTime;
        this.vrmPrimaryColor = (this.cService.userProfile && this.cService.userProfile.HqColour) ? 
                                '#' + this.cService.userProfile.HqColour : '';
        this.dropDownIconPosInPx = (this.dropDownIconPosInPx.toString().trim().endsWith('px')) ? 
                                    this.dropDownIconPosInPx : this.dropDownIconPosInPx + 'px';

        if (this.fontSizeInVw && !this.fontSizeInVw.trim().toLowerCase().endsWith('vw')) {
            this.fontSizeInVw += 'vw';
        }

        if (!this.cService.userProfile.distributorCompanies || this.cService.userProfile.distributorCompanies.length <= 0) {
            this.canSwitchCompany = false;
        }

        const firstName = this.cService.userProfile.firstName;
        const lastName = this.cService.userProfile.lastName;
        this.fullName = firstName + ' ' + lastName;

        const firstInitial = (firstName) ? firstName.substring(0, 1).toUpperCase() : '';
        const secondInitial = (lastName) ? lastName.substring(0, 1).toUpperCase() : '';
        this.userInitials = firstInitial + secondInitial;
    }
  }