import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'grid-pinned-row-style',
    template: `
    <span style="font-weight: bold">{{ value }}</span>
    `,
    styles: []
})
export class PinnedRowStyleComponent implements ICellRendererAngularComp
{
    value: any;

    agInit(params: { value: any }) {
        this.value = params.value;
    }

    refresh(): boolean { return false; }
}