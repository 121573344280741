import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// Local imports
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import { Credential } from 'src/app/model/credential';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide: boolean = true;
  loginInProgress: boolean = false;
  passwordRestInProgress: boolean = false;
  liveCountdownValue: number;
  returnUrl: any;

  constructor(
    public cService: CommonService, public authService: AuthService,
    private router: Router, private route: ActivatedRoute,
    private dialog: MatDialog, private changeDetection: ChangeDetectorRef,
    private gaService: GoogleAnalyticsService
  ) {
    if (this.authService.doFullRefreshOnLogout) {
      location.reload();
    }
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      pin: new FormControl(null, Validators.required)
    });

    this.cService.userProfile = JSON.parse(localStorage.getItem(this.cService.localStorageKeys.userProfile));
    if(!this.cService.userProfile){
      var userLang = navigator.language.split('-')[0] ;

   

      console.log(userLang) 
      this.cService.userProfile={
        languageCode: userLang 
      };
    }
    this.cService.applyUserProfile();

    // Process namespace login
    this.route.params.subscribe({
      next: (response: { namespace: string }) => {
        if (response.namespace) {
          this.cService.userProfile.loginNamespace = response.namespace;
          this.authService.clearUserProfileButPreserveSettings();
        }
        else {
          if (this.cService.userProfile) {
            this.cService.userProfile.loginNamespace = '';
          }
          this.authService.clearUserProfileButPreserveSettings();
        }
      }
    });

    this.dialog.closeAll();

    console.log('Profile when logged out', this.cService.userProfile);

    if (this.loginForm) {
      this.loginForm.valueChanges.subscribe({
        next: () => {
          if (!this.authService.messageHasCountdown) {
            this.authService.loginErrorMessage = '';
            this.authService.loginSuccessMessage = '';
          }
        }
      });
    }
  }


  public clearLoginForm(callback?: () => void): void {
    this.loginForm.reset();
    this.authService.loginErrorMessage = '';

    if (callback) { callback(); }
  }
  public logUserIn(): void {
    if (this.passwordRestInProgress) {
      this.requestPasswordResetLink();
    }
    else {
      this.loginInProgress = true;
      this.passwordRestInProgress = false;


      const credentials: Credential = {
        firstName: this.loginForm.get('firstname').value,
        lastName: this.loginForm.get('lastname').value,
        companyCode: this.loginForm.get('company').value,
        pin: this.loginForm.get('pin').value,
        username: '',
        password: ''
      };

      this.authService.loginToDriverHq(credentials).subscribe({
        next: (response) => {
          this.loginInProgress = false;
          this.route.queryParams
            .subscribe(params => {
              this.returnUrl = params['return'];
              if (!this.returnUrl) {
                this.returnUrl = this.cService.appSettings.defaultPage;
              }
              this.router.navigateByUrl(this.returnUrl).then(() => {
                this.clearLoginForm();
              });
            }
            );
         
        },

        error: (error) => {
          this.loginInProgress = false;
          this.authService.logout(true, true);

          this.authService.loginErrorMessage = this.cService.readyTranslations.messagesAndWarnings.signInFailed;
        }
      });
    }
  }
  requestPasswordResetLink() {
    console.log('Password reset data', this.loginForm.value);
    this.authService.loginErrorMessage = '';

    this.authService.requestPasswordRestLink(this.loginForm.value).subscribe({
      next: (responseForTestOnlyRemoveOnceDone: any) => {
        this.clearLoginForm(() => {
          this.authService.loginSuccessMessage = this.cService.readyTranslations.messagesAndWarnings.passwordResetSuccessfullyRequested;
        });

        console.log('Password Reset Token', responseForTestOnlyRemoveOnceDone);
      },
      error: () => {
        this.authService.loginErrorMessage =
          `
          ${this.cService.readyTranslations.messagesAndWarnings.passwordResetRequestFailed} . 
          ${this.cService.readyTranslations.messagesAndWarnings.ifProblemPersistsContact}: 
          ${this.cService.appSettings.supportEmailAddress}
          `;
      }
    });
  }

  getLiveCountdown(liveCount: number) {
    this.liveCountdownValue = liveCount;

    if (!this.liveCountdownValue || this.liveCountdownValue === 0) {
      this.authService.messageHasCountdown = false;
      this.authService.loginErrorMessage = '';
    }
  }

  showLoginForm() {
    const showLoginForm = !this.authService.isAuthenticated() && !this.authService.doFullRefreshOnLogout;
    return showLoginForm;
  }

  commencePasswordReset() {
    this.passwordRestInProgress = !this.passwordRestInProgress;
    this.loginInProgress = false;
    this.authService.loginErrorMessage = '';
  }
}


// export class LoginComponent implements OnInit {
//   loginForm: FormGroup;
//   hide: boolean = true;
//   loginInProgress: boolean = false;
//   passwordRestInProgress: boolean = false;
//   liveCountdownValue: number;

//   constructor(
//     public cService: CommonService, public authService: AuthService,
//     private router: Router, private route: ActivatedRoute,
//     private dialog: MatDialog, private changeDetection: ChangeDetectorRef,
//     private gaService: GoogleAnalyticsService
//   ) {
//     if (this.authService.doFullRefreshOnLogout) {
//       location.reload();
//     }
//   }

//   ngOnInit() {
//     localStorage.clear();
//     this.loginForm = new FormGroup({
//       firstname: new FormControl(null, Validators.required),
//       lastname: new FormControl(null, Validators.required),
//       company: new FormControl(null, Validators.required),
//       pin: new FormControl(null, Validators.required)
//     });

//     this.cService.userProfile = JSON.parse(localStorage.getItem(this.cService.localStorageKeys.userProfile));
//     this.cService.applyUserProfile();

//     // Process namespace login
//     this.route.params.subscribe({
//       next: (response: { namespace: string }) => {
//         if (response.namespace) {
//           this.cService.userProfile.loginNamespace = response.namespace;
//           this.authService.clearUserProfileButPreserveSettings();
//         }
//         else {
//           if (this.cService.userProfile) {
//             this.cService.userProfile.loginNamespace = '';
//           }
//           this.authService.clearUserProfileButPreserveSettings();
//         }
//       }
//     });

//     this.dialog.closeAll();

//     console.log('Profile when logged out', this.cService.userProfile);

//     if (this.loginForm) {
//       this.loginForm.valueChanges.subscribe({
//         next: () => {
//           if (!this.authService.messageHasCountdown) {
//             this.authService.loginErrorMessage = '';
//             this.authService.loginSuccessMessage = '';
//           }
//         }
//       });
//     }
//   }


//   public clearLoginForm(callback?: () => void): void {
//     this.loginForm.reset();
//     this.authService.loginErrorMessage = '';

//     if (callback) { callback(); }
//   }
//   public logUserIn(): void {
//     if (this.passwordRestInProgress) {
//       this.requestPasswordResetLink();
//     }
//     else {
//       this.loginInProgress = true;
//       this.passwordRestInProgress = false;

//       const credentials: Credential = {
//         firstName: this.loginForm.get('firstname').value,
//         lastName: this.loginForm.get('lastname').value,
//         companyCode: this.loginForm.get('company').value,
//         pin: this.loginForm.get('pin').value,
//         username: '',
//         password: ''
//       };

//       this.authService.loginToDriverHq(credentials).subscribe({
//         next: (response) => {
//           this.loginInProgress = false;

//           this.router.navigate([this.cService.appSettings.defaultPage]).then(() => {
//             this.clearLoginForm();
//           });
//         },

//         error: (error) => {
//           this.loginInProgress = false;
//           this.authService.logout(true, true);

//           this.authService.loginErrorMessage = this.cService.readyTranslations.messagesAndWarnings.signInFailed;
//         }
//       });
//     }
//   }
//   requestPasswordResetLink() {
//     console.log('Password reset data', this.loginForm.value);
//     this.authService.loginErrorMessage = '';

//     this.authService.requestPasswordRestLink(this.loginForm.value).subscribe({
//       next: (responseForTestOnlyRemoveOnceDone: any) => {
//         this.clearLoginForm(() => {
//           this.authService.loginSuccessMessage = this.cService.readyTranslations.messagesAndWarnings.passwordResetSuccessfullyRequested;
//         });

//         console.log('Password Reset Token', responseForTestOnlyRemoveOnceDone);
//       },
//       error: () => {
//         this.authService.loginErrorMessage =
//           `
//           ${this.cService.readyTranslations.messagesAndWarnings.passwordResetRequestFailed} . 
//           ${this.cService.readyTranslations.messagesAndWarnings.ifProblemPersistsContact}: 
//           ${this.cService.appSettings.supportEmailAddress}
//           `;
//       }
//     });
//   }

//   getLiveCountdown(liveCount: number) {
//     this.liveCountdownValue = liveCount;

//     if (!this.liveCountdownValue || this.liveCountdownValue === 0) {
//       this.authService.messageHasCountdown = false;
//       this.authService.loginErrorMessage = '';
//     }
//   }

//   showLoginForm() {
//     const showLoginForm = !this.authService.isAuthenticated() && !this.authService.doFullRefreshOnLogout;
//     return showLoginForm;
//   }

//   commencePasswordReset() {
//     this.passwordRestInProgress = !this.passwordRestInProgress;
//     this.loginInProgress = false;
//     this.authService.loginErrorMessage = '';
//   }
// }




