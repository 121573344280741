import { Pipe, PipeTransform } from '@angular/core';

import { CommonService } from '../services/common.service';

@Pipe({
    name: 'hexToRgba'
})
export class HexToRgba implements PipeTransform 
{
    constructor(private cService: CommonService) {}

    transform(hexColor: string, transparency: number) {
        const rgbaValue: string = this.cService.convertHexColorToRgba(transparency, hexColor);
        return rgbaValue;
    }
}