import { Component, Input, EventEmitter, Output } from '@angular/core';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'vrm-dialog-header',
    template: `
    <mat-progress-bar mode="indeterminate" class="fixed extreme-tl" color="accent" *ngIf="showProgress"></mat-progress-bar>

    <div fxLayout="column" [style.paddingTop]="'2px'" [style.background-color]="'#' + cService.userProfile?.HqColour"
      style="padding-bottom: 7.2px; padding-left: 15px; padding-right: 15px; color: white">
      <div fxLayout="row" fxLayoutAlign="space-between" [style.height]="titleDescription ? '30px' : '45px'">
        <div mat-dialog-title class="bold margin-none force" style="opacity: 0.8">{{ dialogTitle }}</div>

        <span fxFlex></span>

        <button *ngIf="!hideClose" mat-icon-button mat-dialog-close style="top: 3px" 
          (click)="dialogClosed()">
            <mat-icon style="color: white">close</mat-icon>
        </button>
      </div>

      <small *ngIf="titleDescription" style="color: white; font-weight: bold">{{ titleDescription }}</small>
    </div>

    <mat-divider *ngIf="!hideDivider"></mat-divider>
    `
  })
  export class DialogHeaderComponent 
  {
    @Input() dialogTitle: string;
    @Input() titleDescription?: string;
    @Input() showProgress?: boolean = false;
    @Input() hideClose?: boolean = false;
    @Input() hideDivider?: boolean = false;

    // NOTE: Use only when the X sign is the only means to close the dialog
    // else, other means would have to be handled.
    @Output() dialogClosedViaRedX = new EventEmitter();

    constructor(public cService: CommonService) {}

    dialogClosed() {
      this.dialogClosedViaRedX.emit({ closed: true });
    }
  }