import { Component, OnInit } from '@angular/core';

import { DriverHqService } from 'src/app/services/driver-hq.service';
import { DatePipe } from '@angular/common';

import { CommonService } from '../../services/common.service';
import { animate, state, style, transition, trigger } from '@angular/animations';


// Local imports




@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TrainingComponent implements OnInit {
  data: any[];


  constructor(
    public cService: CommonService,
    private driverHqService: DriverHqService, private datePipe: DatePipe
  ) {

    this.data = [];

    setTimeout(() => {
      const pageTitle = 'Training'
      this.cService.setPageHeader(pageTitle, false, false, false);
    });



  }
  async getData() {
    let list = await this.driverHqService.getTrainingList().toPromise();
    if (list) {
      this.data = list;
    }
  }

  async openModule(event, module) {
    let url = await this.driverHqService.getModuleUrl(module.productCode, window.location.href).toPromise();
    console.log(url.url)
    window.location.href = url.url;
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.getData();
    }, 150);
   
    //this.cService.carouselDriver(this.charts1.length);
  }



}
