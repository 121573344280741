import { Config } from '../config';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { CommonService } from './common.service';
import { ProvisioningService } from './provisioning.service';
import * as _ from 'lodash';
import { ApiResponse, GroupsApi, Dvcr, DvcrListItem} from '../model/provisioning';
import * as moment from 'moment';
import { constant } from '../data/constant';

interface MappedGroups {
  [propName: string]: GroupsApi;
}

interface DateFilter {
  startDate?: number;
  endDate?: number;
}

@Injectable({ providedIn: 'root' })
export class FleetReportDvcrService {


  selectedDate: DateFilter = {
    startDate: moment().startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
  };

  private mappedPost: Array<Dvcr>;

  constructor(
    private http: HttpClient,
    private cService: CommonService,
    private prService: ProvisioningService,
  ) { 

    }

   setSelectedDate(date: any) {
    this.selectedDate.startDate = moment(date, Config.momentDateFormatUs).startOf('day').valueOf();
    this.selectedDate.endDate = moment(date, Config.momentDateFormatUs).endOf('day').valueOf();
  }   

  getTrips(headers = null): Observable<any> {
    return this.http.get(`${Config.apiRoot.uri}dvir/summaries/company/${this.cService.userProfile.fpId}`, {
      params: {
        endTime: `${this.selectedDate.endDate}`,
        startTime: `${this.selectedDate.startDate}`,
        fetchReportDetail: 'TRUE',
      }, headers  
    })
      .pipe(
        map(({bodyResponse, statusCode, headerLine  }: ApiResponse) => {
          console.log('Trips - API', bodyResponse);
          return bodyResponse;
        })
      );
  }

  getZip(zipfilePath): Observable<any> {
    return this.http.get(`${Config.apiRoot.uri}dvir/${zipfilePath}/zip` )
      .pipe(
        map(({bodyResponse, statusCode, headerLine  }: ApiResponse) => {
          return bodyResponse.imageBinary;
        })
      );
  }
  
    setPassword(password , token) {      
      return this.http.post(`${Config.apiRoot.uri}users/activate`, {
          password: password.value,
          token: token     
      }).pipe(
          map((response) => {
            return response;
          })
        );
    }

  _mapTrips(headers = null): Observable<any> {
    return this.getTrips(headers)
      .pipe(
        map((trips: Dvcr[]): Dvcr[] => {
          this.mappedPost = _.keyBy(trips.filter((trip) => {
            return (trip.report_type_is_post === 1);
          }), 'pre_id');
          return trips;
        })
      );
  }

  getTripsData(headers = null): Observable<any> {
    return forkJoin({
      trips: this._mapTrips(headers),
      groupsApi: this.prService.mapGroups(headers),
      users: this.prService.mapUsers(headers)
    }).pipe(
      map(
        ({ trips, groupsApi, users, }): Array<DvcrListItem> => {
          const ret = [];
          trips.map((trip: Dvcr): void => {
            if (trip.report_type_is_post != 1) {
              // Excludes Dvcr records posted by users that left company:
              // TODO: Change as necessary in phase2
              if (users[trip.user_id] && users[trip.user_id].user) {
              ret.push({
                id: trip.id,
                firstName:  `${users[trip.user_id].user.FirstName}`,
                lastName: `${users[trip.user_id].user.LastName}`,
                vin: trip.vin,
                driverId: trip.user_id,
                station: (groupsApi[trip.group_id]) ? `${groupsApi[trip.group_id].name}` : '',
                preTrip: moment.unix(trip.report_time / 1000).format('h:mm a'),
                s1Pre: trip.severity1_count,
                s2Pre: trip.severity2_count,
                same: (this.mappedPost[trip.id]) ? (this.mappedPost[trip.id].report.sameCondition) ? 'Y' : 'N' : '',
                postTrip: (this.mappedPost[trip.id]) ? moment.unix(this.mappedPost[trip.id].report_time / 1000).format('h:mm a') : null,
                s1Post: (this.mappedPost[trip.id]) ? this.mappedPost[trip.id].severity1_count : null,
                s2Post: (this.mappedPost[trip.id]) ? this.mappedPost[trip.id].severity2_count : null,
                moreDetails: {
                  zipCode: trip.zipfilePath,
                  moreDetailsData: trip.report.categories.filter((category) => category.hasDamage).map((category) => ({
                    title: constant.CATEGORY_NAME_20190312[category.name],
                    tit: category.name,    
                    content: (category.predefinedComments === 'OTHER') ? category.comment : constant.PREDEFINED_COMMENTS_TEXT[category.predefinedComments],
                    style: (category.severity == 1) ? { color: '#df2828' } :
                           (category.severity == 2) ? { color: '#f1c410' } : null
                  })),
                }
             });
            }
           }
          });
          return ret;
        }
      ));
  }


  loadNotifications(): Observable<any> {
    return this.http.get(`${Config.apiRoot.uri}dvir/stat`, {
      params: {
        companyId: `${this.cService.userProfile.fpId}`,
        endTime: `${moment().endOf('day').valueOf()}`,
        startTime: `${moment().startOf('day').valueOf()}`
      }
    })
      .pipe(
        map(({bodyResponse, statusCode, headerLine  }: ApiResponse) => {
          return bodyResponse;
        })
      );
  }

}
