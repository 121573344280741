import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

// TODO: This component needs to be renamed as it's for timeoutCountdown

@Component({
  selector: 'snackbar-from-component',
  template: `
    <div>
      <mat-icon class="margin-t-10" style="color: orange">warning</mat-icon> 
      <span style="color: orange">
        &nbsp; {{ 'SECURITY.timeout' | translate | uppercase}} {{ 'SECURITY.warning' | translate | uppercase }} 
      </span>
      <span> - {{ 'SECURITY.youWillBeSignedOutIn' | translate }}</span> 
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="margin-t-10">
      <countdown [counter]="countFrom" [interval]="1000" (value)="value = $event">
        <h3>{{ value }} {{ 'UNITS.seconds' | translate }}</h3>
      </countdown>

      <button mat-button (click)="snackBarRef.dismiss()">{{ 'GENERIC.abort' | translate | uppercase }}</button>
    </div>
  `,
  styles: []
})
export class SnackBarFromComponentComponent implements OnInit  
{
  countFrom: number;
  value: number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<any>) { }

  ngOnInit() {
    this.countFrom = this.data.countdownFrom;
  }
}
