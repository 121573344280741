import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ 
    name: 'trustAsSafeUrl'
})
 @Injectable({
    providedIn: 'root'
})
 export class TrustAsSafeUrl implements PipeTransform
 {
    constructor(private sanitizer: DomSanitizer) {}

    transform(untrustedUrl: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(untrustedUrl);
    }
 }