import { Directive, OnInit, Renderer2, RendererStyleFlags2, ElementRef } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';

@Directive({
    selector: '[vrm-color]'
})
export class VrmColorDirective implements OnInit
{
    constructor(
        private cService: CommonService,
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        if (this.cService.userProfile) {
            const companyColor = (this.cService.userProfile && this.cService.userProfile.HqColour) ? 
                '#' + this.cService.userProfile.HqColour : '';

            this.renderer.setStyle(
                this.elementRef.nativeElement, 'color', `${ companyColor }`, RendererStyleFlags2.Important
            );
        }
    }
}