import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { CommonService, NumericRatingToTextEnum } from '../../services/common.service';

@Component({
    selector: 'mask-vrm-rating',
    template: `
    <span>{{ value }}</span>
    `,
    styles: []
})
export class MaskStandardHighMedLowRatingsComponent implements ICellRendererAngularComp
{
    value: string;

    constructor(private cService: CommonService) { }

    agInit(params: { value: any }) {
        this.value = this.cService.mapNumericRatingToText(
            NumericRatingToTextEnum.highMediumLowNa, params.value);
    }

    refresh() { return false; }
}