import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, IDoesFilterPassParams, RowNode } from 'ag-grid-community';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'custom-company-risk-filter',
    template: `
    <div class="padding-10" fxLayout="column" fxFlex>
        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="highSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.high' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="mediumSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.medium' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="lowSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.low' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="safeDriverSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.safeDriver' | translate }} / {{ 'REPORTDATA.na' | translate }}
        </mat-checkbox>
    </div>
    `,
    styles: [`
        :host ::ng-deep label.mat-checkbox-layout {
            width: 100%;
        }
        :host ::ng-deep label .mat-checkbox-inner-container {
            margin-left: 0px;
        }
    `]
})
export class CustomCompanyRiskFilterComponent implements IFilterAngularComp
{
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    highSelected: boolean = true;
    mediumSelected: boolean = true;
    lowSelected: boolean = true;
    safeDriverSelected: boolean = true;

    constructor(private cService: CommonService) { }

    agInit(params: IFilterParams) {
        this.params = params;
        this.valueGetter = params.valueGetter;
    }

    isFilterActive(): boolean {
        return !this.highSelected || !this.mediumSelected || !this.lowSelected
            || !this.safeDriverSelected;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        let filterExpression: boolean = false;

        const companyRiskLevelValues = this.cService.userProfile.companyRiskLevelValues;

        // TODO: Test where there's enough data representing each category

        const showLow = (this.valueGetter(params.node) >= companyRiskLevelValues.low
            && this.valueGetter(params.node) < companyRiskLevelValues.medium);

        const showMedium = (this.valueGetter(params.node) >= companyRiskLevelValues.medium
            && this.valueGetter(params.node) < companyRiskLevelValues.high);

        const showHigh = this.valueGetter(params.node) >= companyRiskLevelValues.high;

        const showSafeAndNotApplicable = this.valueGetter(params.node) < companyRiskLevelValues.low;

        // Single de-selections
        if (!this.highSelected) {
            filterExpression = this.valueGetter(params.node) < companyRiskLevelValues.high;
        }

        if (!this.mediumSelected) {
            filterExpression = this.valueGetter(params.node) < companyRiskLevelValues.medium
                || showHigh;
        }

        if (!this.lowSelected) {
            filterExpression = (this.valueGetter(params.node) > companyRiskLevelValues.low
                && this.valueGetter(params.node) >= companyRiskLevelValues.medium)
                || showSafeAndNotApplicable;
        }

        if (!this.safeDriverSelected) { // <-- Represents Safe Driver & NA deselection (< low)
            filterExpression = this.valueGetter(params.node) >= companyRiskLevelValues.low;
        }

        // Double de-selections
        if (!this.highSelected && !this.mediumSelected) {
            filterExpression = this.valueGetter(params.node) < companyRiskLevelValues.medium;
        }

        if (!this.highSelected && !this.lowSelected) {
            filterExpression = showMedium || showSafeAndNotApplicable;
        }

        // <-- safeDriverSelected represents bot Safe Driver & NA deselection (< low)
        if (!this.highSelected && !this.safeDriverSelected) {
            filterExpression = showMedium || showLow;
        }

        if (!this.mediumSelected && !this.lowSelected) {
            filterExpression = showHigh || showSafeAndNotApplicable;
        }

        if (!this.mediumSelected && !this.safeDriverSelected) {
            filterExpression = showHigh || showLow;
        }

        if (!this.lowSelected && !this.safeDriverSelected) {
            filterExpression = showHigh || showMedium;
        }

        // Triple de-selection
        if (!this.mediumSelected && !this.lowSelected && !this.safeDriverSelected) {
            filterExpression = showHigh;
        }

        if (!this.highSelected && !this.lowSelected && !this.safeDriverSelected) {
            filterExpression = showMedium;
        }

        if (!this.highSelected && !this.mediumSelected && !this.safeDriverSelected) {
            filterExpression = showLow;
        }

        if (!this.highSelected && !this.mediumSelected && !this.lowSelected) {
            filterExpression = showSafeAndNotApplicable;
        }

        // Catch all - nothing selected
        if (!this.highSelected && !this.mediumSelected && !this.lowSelected 
            && !this.safeDriverSelected) {
            filterExpression = this.valueGetter(params.node) == null;
        }

        return filterExpression;
    }

    getModel(): any {
        return;
    }

    setModel(model: any) {
        
    }

    // ----------------------

    selectionChanged() {
        this.params.filterChangedCallback();
    }
}