import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { Config } from './config';

// RayGun
import { RaygunErrorHandler } from './error-handlers/app.raygun.setup';

// SW
import { ServiceWorkerModule } from '@angular/service-worker';

// JWT
import { JwtModule } from '@auth0/angular-jwt';
export function tokenGetter() {
  return localStorage.getItem('mis.vrm.eDriving.access_token');
}
// NG-IDLE & Keep-alive
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

// I18n - Translations
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { VrmLangTranslationLoader } from './interceptors-and-loaders/language.loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new VrmLangTranslationLoader(httpClient);
}

// App core
import { AppComponent } from './app.component';
import { LoginComponent } from './pages_driverhq/login/login.component';
import { SingleSignOnComponent } from './pages/sso/single-sign-on.component';
import { SecurityComponent } from './pages/security/security.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';

// Dashboards
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// GUIDE / FAQ / Other pages
import { CompanyGuideComponent } from './pages/company-guide/company-guide.component';
import { VrmGuideComponent } from './pages/vrm-guide/vrm-guide.component';

// Reports
import { CommonReportsComponent } from './pages/common-reports/common-reports.component';
import { VrmCompareComponent } from './pages/vrm-compare/vrm-compare.component';

// DVCR
import { FleetReportDvcrComponent } from './pages/fleet-report-dvcr/fleet-report-dvcr.component';

// Provisioning
import { SelfProvisioningComponent } from './pages/self-provisioning/self-provisioning.component';
import { VrmUploadUsersFormComponent } from './components/vrm-upload-users-form/vrm-upload-users-form.component';

// Activation
import { FleetActivationComponent } from './pages/fleet-activation/fleet-activation.component';

// Angular Material - Custom module
import { MaterialLibModule } from './material-lib.module';

// VRM FORMS
import {
  VrmDynamicFormsModule,
  VrmFormDriverIndexCoachModule,
  VrmFormMentorEvaluationModule,
  VrmFormCrashFreeCultureOneToOneModule,
  VrmFormPccReviewModule
} from 'vrm-forms';

// Plug-Ins | Components | Interceptors | Pipes
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/chartsModule'; // NOTE: Do not import if not in use . Charts is in Beta
import { NumberToPercentageComponent } from './components/ag-grid-cell-renderers/number-to-percentage.component';
import { NumberToDecimalPlaceComponent } from './components/ag-grid-cell-renderers/number-to-decimal-place.component';
import { CustomDrivingRatingFilterComponent } from './components/ag-grid-custom-filters/custom-driving-rating-filter.component';
import { CustomCompanyRiskFilterComponent } from './components/ag-grid-custom-filters/custom-company-risk-filter.component';
import { CustomStandardHighMedLowRatingComponent } from './components/ag-grid-custom-filters/custom-standard-high-med-low-rating-filter.component';
import { CustomCompleteIncompleteNotstartedRatingFilterComponent } from './components/ag-grid-custom-filters/custom-complete-incomplete-notstarted-rating-filter.component';
import { PrefixPlusSignToPositiveNumComponent } from './components/ag-grid-cell-renderers/prefix-plus-sign-to-positive-num.component';
import { MaskPoorAverageGoodRatingComponent } from './components/ag-grid-cell-renderers/mask-poor-average-good-rating.component';
import { MaskStandardHighMedLowRatingsComponent } from './components/ag-grid-cell-renderers/mask-standard-high-med-low-ratings.component';
import { MaskCompleteIncompleteNotstartedRatingComponent } from './components/ag-grid-cell-renderers/mask-complete-incomplete-notstarted-rating.component';
import { MaskCompanyRiskLevelPointsComponent } from './components/ag-grid-cell-renderers/mask-company-risk-level-points.component';
import { FileExtToIconAndLinkButtonComponent } from './components/ag-grid-cell-renderers/file-ext-to-icon-and-link-button.component';
import { CustomSettingsToolPanelComponent } from './components/ag-grid-components/settings-tool-pane/custom-settings-tool-panel.component';
import { CustomDetailsToolPaneComponent } from './components/ag-grid-components/details-tool-pane/custom-details-tool-pane.component';
import { StationDspFilterComponent } from './components/ag-grid-components/station-dsp-filter/station-dsp-filter.component';
import { GridIndexingCoachLinkButtonComponent } from './components/ag-grid-cell-renderers/grid-indexing-coach-link-button.component';
import { TitleLinkButtonCasualReportComponent } from './components/ag-grid-cell-renderers/title-link-button-casual-report.component';
import { ActionOptionButtonCasualReportComponent } from './components/ag-grid-cell-renderers/action-option-button-casual-report.component';
import { FileExtensionAsIconComponent } from './components/ag-grid-cell-renderers/file-extension-as-icon.component';
import { GridSeeMvrLinkButtonComponent } from './components/ag-grid-cell-renderers/grid-see-mvr-link-button.component';
import { PinnedRowStyleComponent } from './components/ag-grid-cell-renderers/pinned-row-style.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TruncateModule } from 'ng2-truncate';
import { HttpRequestInterceptorProvider } from './interceptors-and-loaders/http.interceptor';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { VrmPageContainerComponent } from './components/vrm-page-container/vrm-page-container.component';
import { VrmMatPaginatorComponent } from './components/vrm-mat-paginator/vrm-mat-paginator.component';
import { VrmCardComponent } from './components/vrm-card/vrm-card.component';
import { VrmChartJsComponent } from './components/vrm-chart-js/vrm-chart-js.component';
import { VrmExpansionPanelComponent } from './components/vrm-expansion-panel/vrm-expansion-panel.component';
import { VrmMentorReportsComponent } from './components/vrm-mentor-reports/vrm-mentor-reports.component';
import { VrmMiniReportComponent } from './components/vrm-mini-reports/vrm-mini-reports.component';
import { VrmCasualReportComponent } from './components/vrm-casual-report/vrm-casual-report.component';
import { VrmAgChartComponent } from './components/vrm-ag-chart/vrm-ag-chart.component';
import { VrmMultiSelectComponent } from './components/vrm-multiselect/vrm-multi-select.component';
import { VrmSummaryCardComponent } from './components/vrm-summary-card/vrm-summary-card.component';
import { VrmSummaryItemComponent } from './components/vrm-summary-card/vrm-summary-item.component';

import { VrmUserFormComponent } from './components/vrm-user-form/vrm-user-form.component';

import { MultiDatesHighlightCalendarComponent } from './components/calendar/multi-dates-highlight-calendar.component';
import { SecurityFooterComponent } from './components/security-footer/security-footer.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AccountInfoBadgeComponent } from './components/account-information/account-info-badge.component';
import { VrmPercentageArcComponent } from './components/vrm-percentage-arc/vrm-percentage-arc.component';
import { MentorDashboardTabComponent } from './components/mentor-dashboard-tab/mentor-dashboard-tab.component';

import { CheckUpdatesButtonComponent } from './components/xs-components/check-updates-button.component';
import { DarkThemeToggleComponent } from './components/xs-components/dark-theme-toggle.component';
import { SettingsButtonComponent } from './components/xs-components/settings-button.component';
import { LogoutButtonComponent } from './components/xs-components/logout-button.component';
import { CalendarHeaderComponent } from './components/xs-components/calendar-header.component';
import { PasswordPolicyComponent } from './components/xs-components/password-policy.component';
import { SimpleClientPaginatorComponent } from './components/xs-components/simple-client-paginator.component';
import { SeverityDetailsComponent } from './pages/fleet-report-dvcr/severity-details.component';

import { ColumnNamesTransform } from './pipes/get-column-names-from-meta.pipe';
import { TransformColNames } from './pipes/transform-col-names';
import { TransformReportCell } from './pipes/transform-report-cell';
import { DetermineElementProps } from './pipes/determine-element-props.pipe';
import { DetermineReportCellColor } from './pipes/determine-report-cell-color';
import { ClassBasedOnValue } from './pipes/classBasedOnValue';
import { HexToRgba } from './pipes/hex-to-rgba.pipe';
import { NumberToDecimalPlacesPipe } from './pipes/number-to-decimal-places.pipe';
import { MomentDatePipe } from './pipes/moment-date-format.pipe';
import { TranslateDateMonthPipe } from './pipes/translate-date-month.pipe';

import { SnackBarFromComponentComponent } from './components/snack-bar-from-component/snack-bar-from-component.component';
import { CountdownDirective } from './directives/countdown.directive';

import { RenderComponentDirective } from './directives/render-component.directive';

import { VrmRaisedButtonDirective } from './directives/buttons/vrm-raised-button.directive';
import { VrmButtonDirective } from './directives/buttons/vrm-button.directive';
import { VrmColorDirective } from './directives/colors/vrm-color.directive';
import { VrmBackgroundColorDirective } from './directives/colors/vrm-bg-color.directive';
import { VrmAccentDirective } from './directives/colors/vrm-accent.directive';
import { VrmBackgroundAccentDirective } from './directives/colors/vrm-bg-accent.directive';

import { ConcatenateText } from './pipes/concatenate.pipe';
import { NamesToInitial } from './pipes/names-to-initial.pipe';
import { IeCompatibleDate } from './pipes/ie-compatible-date.pipe';
import { TrustAsSafeUrl } from './pipes/trust-as-safe-url.pipe';
import { GetFormErrorMessage } from './pipes/get-form-error-message';
import { DriverIndexComponent } from './dialogs/driver-index/driver-index.component';
import { DriverIndexClassicComponent } from './dialogs/driver-index-classic/driver-index-classic.component';
import { CounterBadgeComponent } from './components/xs-components/counter-badge.component';
import { GuideViewerComponent } from './dialogs/guide-viewer/guide-viewer.component';
import { GuideViewerSidepaneComponent } from './dialogs/guide-viewer-sidepane/guide-viewer-sidepane.component';
import { UserIndexComponent } from './dialogs/user-index/user-index.component';
import { DialogSidepaneComponent } from './dialogs/sidepane/dialog-sidepane.component';
import { DialogSidepaneClassicComponent } from './dialogs/sidepane-classic/dialog-sidepane-classic.component';
import { UploadOutcomeComponent } from './dialogs/upload-user-outcome/upload-outcome.component';

import { AngularCsvComponent } from './components/angular-csv-component/angular-csv.component';
import { SendActivationMailComponent } from './components/vrm-mentor-reports/send-activation.component';

import { VrmFilterPanelComponent } from './components/vrm-filter-panel/vrm-filter-panel.component';
import { DialogHeaderComponent } from './dialogs/dialog-header/dialog-header.component';
import { GoodAveragePoorCardComponent } from './components/good-average-poor-card/good-average-poor-card.component';
import { environment } from '../environments/environment';
import { DvcrNotificationBadgeComponent } from './components/dvcr-notification-badge/dvcr-notification-badge';
import { UploadUsersComponent } from './dialogs/upload-users/upload-users.component';
import { TrainingComponent } from './pages_driverhq/training/training.component';
import { CoachingComponent } from './pages_driverhq/coaching/coaching.component';
import { EventsComponent } from './pages_driverhq/events/events.component';
import { DriverPerformanceComponent } from './pages_driverhq/driver-performance/driver-performance.component';
import { DriverPerformanceMentorComponent } from './pages_driverhq/driver-performance-mentor/driver-performance-mentor.component';
import { DriverhqGuideComponent } from './pages_driverhq/driverhq-guide/driverhq-guide.component';
import { DriverHqGuideViewerComponent } from './dialogs/driver-hq-guide-viewer/driver-hq-guide-viewer.component';
import { DriverHqSsoComponent } from './pages_driverhq/driver-hq-sso/driver-hq-sso.component';
import { BillingComponent } from './pages_driverhq/billing/billing.component';
import { PromptWithActionDialogComponent } from './dialogs/prompt-with-action/prompt-with-action.component';
import { TrustAsSafeHtmlPipe } from './pipes/trust-as-safe-html.pipe';
import { ResourcesComponent } from './pages_driverhq/documents/resources.component';
import { DriverHqDocumentViewerComponent } from './components/driver-hq-document-viewer/driver-hq-document-viewer.component';


const productionOnlyProviders = (environment.production) ? [
  { provide: ErrorHandler, useClass: RaygunErrorHandler }
] : [];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SingleSignOnComponent,
    DashboardComponent,
    CompanyGuideComponent,
    VrmGuideComponent,
    SecurityComponent,
    PasswordResetComponent,
    VrmChartJsComponent,
    VrmCardComponent,
    VrmPageContainerComponent,
    VrmMatPaginatorComponent,
    VrmExpansionPanelComponent,
    FleetReportDvcrComponent,
    SelfProvisioningComponent,
    FleetActivationComponent,
    UploadUsersComponent,
    VrmUploadUsersFormComponent,

    CommonReportsComponent,
    VrmCompareComponent,

    VrmMentorReportsComponent,
    VrmMiniReportComponent,
    VrmCasualReportComponent,
    VrmAgChartComponent,
    VrmMultiSelectComponent,
    VrmFilterPanelComponent,
    VrmSummaryCardComponent,
    VrmSummaryItemComponent,

    VrmUserFormComponent,

    SnackBarFromComponentComponent,
    DialogHeaderComponent,
    AccountInfoBadgeComponent,
    VrmPercentageArcComponent,
    GoodAveragePoorCardComponent,

    CheckUpdatesButtonComponent,
    DarkThemeToggleComponent,
    SettingsButtonComponent,
    LogoutButtonComponent,
    CalendarHeaderComponent,
    PasswordPolicyComponent,
    DvcrNotificationBadgeComponent,
    SimpleClientPaginatorComponent,

    SendActivationMailComponent,
    SeverityDetailsComponent,

    ColumnNamesTransform,
    TransformColNames,
    TransformReportCell,
    DetermineElementProps,
    DetermineReportCellColor,
    ClassBasedOnValue,
    MultiDatesHighlightCalendarComponent,
    SecurityFooterComponent,
    CountdownDirective,
    VrmRaisedButtonDirective,
    VrmButtonDirective,
    VrmColorDirective,
    VrmBackgroundColorDirective,
    VrmAccentDirective,
    VrmBackgroundAccentDirective,
    ConcatenateText,
    TrustAsSafeUrl,
    NamesToInitial,
    IeCompatibleDate,
    GetFormErrorMessage,
    DriverIndexComponent,
    DriverIndexClassicComponent,
    CounterBadgeComponent,
    GuideViewerComponent,
    GuideViewerSidepaneComponent,

    RenderComponentDirective,

    UserIndexComponent,
    UploadOutcomeComponent,

    DialogSidepaneComponent,
    DialogSidepaneClassicComponent,
    PromptWithActionDialogComponent,
    PageHeaderComponent,
    HexToRgba,
    NumberToDecimalPlacesPipe,
    MomentDatePipe,
    TranslateDateMonthPipe,
    TrustAsSafeHtmlPipe,
    MentorDashboardTabComponent,

    AngularCsvComponent,

    // Ag-Grid Cell Renderers / sub components
    NumberToPercentageComponent,
    NumberToDecimalPlaceComponent,
    CustomDrivingRatingFilterComponent,
    CustomCompanyRiskFilterComponent,
    CustomStandardHighMedLowRatingComponent,
    CustomCompleteIncompleteNotstartedRatingFilterComponent,
    PrefixPlusSignToPositiveNumComponent,
    MaskPoorAverageGoodRatingComponent,
    MaskStandardHighMedLowRatingsComponent,
    MaskCompleteIncompleteNotstartedRatingComponent,
    MaskCompanyRiskLevelPointsComponent,
    FileExtToIconAndLinkButtonComponent,
    CustomSettingsToolPanelComponent,
    CustomDetailsToolPaneComponent,
    StationDspFilterComponent,
    GridIndexingCoachLinkButtonComponent,
    TitleLinkButtonCasualReportComponent,
    ActionOptionButtonCasualReportComponent,
    FileExtensionAsIconComponent,
    GridSeeMvrLinkButtonComponent,
    PinnedRowStyleComponent,
    TrainingComponent,
    CoachingComponent,
    EventsComponent,
    DriverPerformanceComponent,
    DriverPerformanceMentorComponent,
    DriverhqGuideComponent,
    DriverHqGuideViewerComponent,
    DriverHqSsoComponent,
    BillingComponent,
    ResourcesComponent,
    DriverHqDocumentViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),

    // Angular Material
    MaterialLibModule,

    // VRM Forms
    VrmDynamicFormsModule,
    VrmFormDriverIndexCoachModule,
    VrmFormMentorEvaluationModule,
    VrmFormCrashFreeCultureOneToOneModule,
    VrmFormPccReviewModule,

    // JWT
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [Config.apiRoot.uri],
        blacklistedRoutes: [`${Config.apiRoot.uri}/api/login`]
      }
    }),
    // NG-IDLE & Keepalive
    NgIdleKeepaliveModule.forRoot(),

    // Plug-Ins
    AgGridModule.withComponents([
      NumberToPercentageComponent,
      NumberToDecimalPlaceComponent,
      CustomDrivingRatingFilterComponent,
      CustomCompanyRiskFilterComponent,
      CustomStandardHighMedLowRatingComponent,
      CustomCompleteIncompleteNotstartedRatingFilterComponent,
      PrefixPlusSignToPositiveNumComponent,
      MaskPoorAverageGoodRatingComponent,
      MaskStandardHighMedLowRatingsComponent,
      MaskCompleteIncompleteNotstartedRatingComponent,
      MaskCompanyRiskLevelPointsComponent,
      FileExtToIconAndLinkButtonComponent,
      CustomSettingsToolPanelComponent,
      CustomDetailsToolPaneComponent,
      GridIndexingCoachLinkButtonComponent,
      TitleLinkButtonCasualReportComponent,
      ActionOptionButtonCasualReportComponent,
      FileExtensionAsIconComponent,
      GridSeeMvrLinkButtonComponent,
      PinnedRowStyleComponent,
      StationDspFilterComponent
    ]),

    FlexLayoutModule,
    TruncateModule,
    PasswordStrengthBarModule,
    ImgFallbackModule,
    PdfViewerModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    HttpRequestInterceptorProvider,
    ...productionOnlyProviders,
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SeverityDetailsComponent,
    SnackBarFromComponentComponent,
    DriverIndexComponent,
    DriverIndexClassicComponent,
    GuideViewerComponent,
    UserIndexComponent,
    UploadUsersComponent,
    DialogSidepaneComponent,
    DialogSidepaneClassicComponent,
    UploadOutcomeComponent,
    SendActivationMailComponent,
    CalendarHeaderComponent,
    DriverHqGuideViewerComponent,
    PromptWithActionDialogComponent
  ],
  exports: []
})
export class AppModule { }
