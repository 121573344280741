import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { CommonService } from '../services/common.service';

@Pipe({
    name: 'getFormErrorMessage'
})
export class GetFormErrorMessage implements PipeTransform 
{
    constructor(private cService: CommonService) {}

    transform(errorPrefix: string, 
    controlLabel: string, formControl: AbstractControl, ...validations: string[]): string 
    {
        console.log('PIPE');
        let error = '';
        errorPrefix = (errorPrefix) ? errorPrefix : '';

        const validation = {
            required: 'required',
            email: 'email',
            mustMatch: 'mustMatch',
            invalid: 'pattern'
        };
      
        const warning = {
            blank: this.cService.readyTranslations.form.required,
            invalidEmail: this.cService.readyTranslations.form.invalidEmail,
            mustMatch: this.cService.readyTranslations.form.mustMatch,
            invalid: this.cService.readyTranslations.form.invalid,
        };

        
        for (let i = 0; i < validations.length; i++) {
            const errorCheck = validations[i];
            if (errorCheck.toLowerCase() === validation.required) {
                error = formControl.hasError(validation.required) ? warning.blank : '';
                break;
            }

            if (errorCheck.toLowerCase() === validation.email) {
                error = formControl.hasError(validation.email) ? warning.invalidEmail : '';
                break;
            }

            if (errorCheck.toLowerCase() === validation.mustMatch.toLowerCase()) {
                error = formControl.hasError(validation.mustMatch) ? `${controlLabel} ${warning.mustMatch}` : '';
                break;
            }

            if (errorCheck.toLowerCase() === validation.invalid) {
                error = formControl.hasError(validation.invalid) ? `${controlLabel} ${warning.invalid}` : '';
                break;
            }
        }

        error = (error) ? `${errorPrefix} ${error}` : '';

        return error;
    }
}