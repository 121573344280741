import { Component, ViewChild } from '@angular/core';
import { MatDrawerToggleResult, MatDrawer } from '@angular/material/sidenav';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'guide-viewer-sidepane',
    templateUrl: './guide-viewer-sidepane.component.html',
    styleUrls: ['./guide-viewer-sidepane.component.scss']
})
export class GuideViewerSidepaneComponent
{
    @ViewChild('drawer', { static: false }) sidepaneDrawer: MatDrawer;

    showSidepane: boolean = true;
    paneOpened: boolean = true;
    
    constructor(public cService: CommonService) { }


    // EVENTS
    toggleDrawer() {
        this.sidepaneDrawer.toggle().then((paneState: MatDrawerToggleResult) => {
            this.paneOpened = (paneState == 'open') ? true : false;
            console.log('Pane opened', this.paneOpened);
        });
    }
}