import { Component } from '@angular/core';
import { IFilterParams, IDoesFilterPassParams, RowNode } from 'ag-grid-community';

import { CommonService } from '../../services/common.service';

@Component({
    selector: 'custom-vrm-rating-filter',
    template: `
    <div class="padding-10" fxLayout="column" fxFlex>
        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="highSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.high' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="mediumSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.medium' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="lowSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.low' | translate }}
        </mat-checkbox>

        <mat-checkbox color="primary" class="padding-5" 
            [(ngModel)]="notApplicableSelected" (change)="selectionChanged()">
            {{ 'REPORTDATA.na' | translate }}
        </mat-checkbox>
    </div>
    `,
    styles: [`
        :host ::ng-deep label.mat-checkbox-layout {
            width: 100%;
        }
        :host ::ng-deep label .mat-checkbox-inner-container {
            margin-left: 0px;
        }
    `]
})
export class CustomStandardHighMedLowRatingComponent
{
    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    highSelected: boolean = true;
    mediumSelected: boolean = true;
    lowSelected: boolean = true;
    notApplicableSelected: boolean = true;

    constructor(private cService: CommonService) { }

    agInit(params: IFilterParams) {
        this.params = params;
        this.valueGetter = params.valueGetter;
    }

    isFilterActive(): boolean {
        return !this.highSelected || !this.mediumSelected || !this.lowSelected
            || !this.notApplicableSelected;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        let filterExpression: boolean;
        const nodeValue = this.valueGetter(params.node);
        const rating = this.cService.highMediumLowRatingValues;

        // Single de-selections
        if (!this.highSelected) {
            filterExpression = nodeValue < rating.high;
        }

        if (!this.mediumSelected) {
            filterExpression = nodeValue != rating.medium;
        }

        if (!this.lowSelected) {
            filterExpression = nodeValue != rating.low;
        }

        if (!this.notApplicableSelected) {
            filterExpression = nodeValue >= rating.low;
        }

        // Double de-selections
        if (!this.highSelected && !this.mediumSelected) {
            filterExpression = nodeValue <= rating.low;
        }

        if (!this.highSelected && !this.lowSelected) {
            filterExpression = nodeValue == rating.medium
                || nodeValue < rating.low; // <-- Captures NA
        }

        if (!this.highSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue == rating.medium
                || nodeValue == rating.low;
        }

        if (!this.mediumSelected && !this.lowSelected) {
            filterExpression = nodeValue == rating.high
                || nodeValue < rating.low; // <-- Less than low = NA
        }

        if (!this.mediumSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue == rating.high
                || nodeValue == rating.low;
        }

        if (!this.lowSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue >= rating.medium;
        }

        // Triple de-selections / single selection
        if (!this.highSelected && !this.mediumSelected && !this.lowSelected) {
            // Not applicable selected
            filterExpression = nodeValue < rating.low;
        }

        if (!this.highSelected && !this.mediumSelected && !this.notApplicableSelected) {
            // Low selected
            filterExpression = nodeValue == rating.low;
        }

        if (!this.highSelected && !this.lowSelected && !this.notApplicableSelected) {
            // Medium selected
            filterExpression = nodeValue == rating.medium;
        }

        if (!this.mediumSelected && !this.lowSelected && !this.notApplicableSelected) {
            // High selected
            filterExpression = nodeValue == rating.high;
        }

        // Catch all - nothing selected
        if (!this.highSelected && !this.mediumSelected && !this.lowSelected && !this.notApplicableSelected) {
            filterExpression = nodeValue == null;
        }

        return filterExpression;
    }

    getModel(): any {
        return;
    }

    setModel(model: any) {
        
    }

    // ----------------------

    selectionChanged() {
        this.params.filterChangedCallback();
    }
}