// Angular Core
import { Injectable } from '@angular/core';
// Angular Common
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Services
import { CommonService } from './common.service';
import { Config } from '../config';
// Model
import { ApiResponse, Provisioning, GroupsApi, FirstLoginApi, DriverBody, DriversListItem, UserBody } from '../model/provisioning';
// Third part
import * as moment from 'moment';
import * as _ from 'lodash';
import { UserForm } from '../model/UserForm';

interface MappedGroups {
  [propName: string]: GroupsApi;
}

interface MappedDrivers {
  [propName: string]: Provisioning;
}

/**
 * @TODO HANDLE ERROR...
 */
@Injectable({ providedIn: 'root' })
export class ProvisioningService {

  provisioningOptions = {
    mobileColumns: [ 'name', 'email']
  };

  private _provisioningDataChange = new BehaviorSubject(null);
  provisioningDataChange$ = this._provisioningDataChange.asObservable();


  groups: GroupsApi[];
  drivers: Provisioning[];
  driversFirstLogin: FirstLoginApi;
  selectedRows: Array<any> = [];

  constructor( 
    private http: HttpClient,
    private cService: CommonService
  ) {}

  getDrivers(headers = null): Observable<Provisioning[]> {
    return this.http.get(`${Config.apiRoot.uri}companies/${this.cService.userProfile.fpId}/users?excludes=cdbUserProduct`, {
      headers
    })
      .pipe(
        map(({bodyResponse, statusCode, headerLine  }: ApiResponse) => {
          console.log('User - API', bodyResponse);
          this.drivers = bodyResponse;
          return bodyResponse;
        })
      );
  }

  getDriversFirstLogin(headers = null): Observable<FirstLoginApi>{ 
    return this.http.get(`${Config.apiRoot.uri}companies/${this.cService.userProfile.fpId}/loginStat`, {
      headers
    })
    .pipe(
      map(({ bodyResponse, statusCode, headerLine }: ApiResponse) => {
        console.log('Driver first login - API', bodyResponse);
        this.driversFirstLogin = bodyResponse;
        return bodyResponse;
      })
    ); 
  }

  getGroups(headers = null): Observable<GroupsApi[]> {
    return this.http.get(`${Config.apiRoot.uri}companies/${this.cService.userProfile.fpId}/groups`, {
        headers
    })
    .pipe(
      map(({ bodyResponse, statusCode, headerLine }: ApiResponse) => {
        console.log('Groups - API', bodyResponse);
        this.groups = bodyResponse;
        return bodyResponse;
      })
    );
  }

  /**
   * @TODO better error handler
   */
  saveUser(body: DriverBody, headers = null): Observable<ApiResponse> {
    return this.http.post(`${Config.apiRoot.uri}companies/${this.cService.userProfile.fpId}/users`, body, {
      headers
    })
    .pipe(
      map(({ bodyResponse, statusCode, headerLine }: ApiResponse) => {
        return { statusCode, bodyResponse };
      }));
  }

  /**
   * @TODO better error handler
   */
  _updateUserData({ idUser, FirstName, MiddleName, LastName, PhoneNumber, Email }: UserBody, headers = null): Observable<ApiResponse> {
    return this.http.patch(`${Config.apiRoot.uri}users/${idUser}/update`, {
      FirstName,
      MiddleName,
      LastName,
      PhoneNumber,
      Email
    }, {
      headers
    }).pipe( 
      map(({ bodyResponse, statusCode, headerLine }: ApiResponse) => {
        return { statusCode, bodyResponse };
      }));
  }

  /**
   * @TODO better error handler
   */
  _updateUserGroups({ id, isManager, isDriver, driverGroupId, managerGroupIds }: DriverBody, headers = null): Observable<ApiResponse> {
    return this.http.patch(`${Config.apiRoot.uri}companies/${this.cService.userProfile.fpId}/users/${id}`, {
      isManager,
      managerGroupIds,
      isDriver,
      driverGroupId 
    }, {
      headers
    }).pipe( 
      map(({ bodyResponse, statusCode, headerLine }: ApiResponse) => {
        return { statusCode, bodyResponse };
      }));
  }

  updateUser(body: DriverBody, headers = null): Observable<{user: ApiResponse, groups: ApiResponse}> {
    return forkJoin({
      user: this._updateUserData(body.user, headers),
      groups: this._updateUserGroups(body, headers)
    });
  }

  deleteUser(idUser: number, idGroups: number, headers = null): Observable<ApiResponse> {
    return this.http.delete(`${Config.apiRoot.uri}companies/${this.cService.userProfile.fpId}/users/${idUser}`, {
      headers
    }).pipe(
      map(({ bodyResponse, statusCode, headerLine }: ApiResponse) => {
        return { statusCode, bodyResponse };
    }));
  }

  mapGroups(headers = null): Observable<MappedGroups> {
    return this.getGroups(headers)
    .pipe(
      map((groups: GroupsApi[]): MappedGroups => {
        return _.keyBy(groups, 'id');
      })
    );
  }

  mapUsers(headers = null): Observable<MappedDrivers> {
    return this.getDrivers(headers)
      .pipe(
        map((users: Provisioning[]): MappedDrivers => {
          return _.keyBy(users, 'user.UserId');
        })
      );
  }
  
  _populateManagers(managerGroupIds: number[], groups: MappedGroups): string[] {
    return managerGroupIds.map((groupId: number): string => (groups[`${groupId}`].name));
  }

  /**
   * Send activation mail
   * @todo Error handler
   * @param email: string the mail who send mail 
   */
  sendActivationMail(userId: number): Observable<ApiResponse> {
    return this.http.get(`${Config.apiRoot.uri}users/send_activation/${userId}`)
      .pipe(
        map(({ bodyResponse, statusCode }: ApiResponse) => {
          return { statusCode, bodyResponse } ;
        })
      );
  }

  allowedDomains(): Observable<ApiResponse> {
    return this.http.get(`${Config.apiRoot.uri}companies/allowed_domains`)
      .pipe(
        map(({ bodyResponse, statusCode }: ApiResponse) => {
          return { statusCode, bodyResponse } ;
        })
      );
  }

  getDriversData(headers = null): Observable<DriversListItem[]> {
    return forkJoin({
      drivers: this.getDrivers(headers),
      firstLogin: this.getDriversFirstLogin(headers),
      groups: this.mapGroups(headers)
    }).pipe(
      map(
        ({ drivers, firstLogin, groups }): Array<DriversListItem> => {
          console.log('Mapped Groups -', groups);
          return drivers.map((driver: Provisioning): DriversListItem => {
            return {
              idProvisioning: driver.id,
              id: driver.user.UserId,
              firstName: driver.user.FirstName || null,
              middleName: driver.user.MiddleName || null,
              lastName: driver.user.LastName || null,
              email: driver.user.Email,
              phoneNumber: (driver.user) ? driver.user.PhoneNumber : null,
              station: (driver.isDriver) ? (driver.driverGroupId) ? groups[`${driver.driverGroupId}`].name : 'UNASSIGNED' : null,
              manages: (driver.isManager) ? driver.managerGroupIds.length ? this._populateManagers(driver.managerGroupIds, groups).join(' - ') : 'UNASSIGNED' : null,
              isDriver: driver.isDriver,
              isManager: driver.isManager,
              driverGroupId: (driver.isDriver) ? `${driver.driverGroupId}` : null,
              managerGroupIds: (driver.isManager) ? driver.managerGroupIds : [],
              firstLogin: (firstLogin[driver.user.UserId]) ? moment(firstLogin[driver.user.UserId].firstLogin).format(Config.momentDateFormatUs) : null
            };
          });
        }
      ));
  }

  /**
   * Create an array of Observable and perform a fork Join of api calls.
   * @TODO : A bulk process api should be required to make the upload o users
   * @param userForms UserForm Array
   */
  forkCreateUserCalls(userForms: UserForm[], headers = null): Observable<ApiResponse[]> {
    const fork: Observable<ApiResponse>[] = [];
    for (let i = 0; i < userForms.length; i++) {
      if (userForms[i].getUserForm.valid) {
        const { value } = userForms[i].getUserForm;
        fork.push(this.saveUser({
          isDriver: value.isDriver,
          isManager: value.isManager,
          driverGroupId: value.driverGroupId,
          managerGroupIds: value.managerGroupIds,
          user: {
            FirstName: value.firstName,
            MiddleName: value.middleName,
            LastName: value.lastName,
            Email: value.email,
            UserName: value.email,
            PhoneNumber: value.phoneNumber,
            Products: [
              { ProductCode: 'MENTOR_AMZL' },
              { ProductCode: 'VRM-OMS-US-EN-0001' }
            ]
          }
        }, headers));
      }
    }
    return forkJoin(fork);
  }

  // rxjs Event
  /**
   * Trigger the fwtch of data
   */
  triggerBehaviourprovisioningDataChange() {
    this._provisioningDataChange.next(null);
  }
  
}