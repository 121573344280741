export const groupStructure = [
    {
        groupName: 'Details', 
        structure: {
            location: { name: "Location", type: 'text', chartDataType: 'category', pinned: true },
            number_drivers: { name: "# Driver", type: 'number', field: "number_drivers", chartDataType: 'series' },
            active_events_driver: { name: "# Active events per driver", type: 'number', chartDataType: 'series' },
            average_index_score: { name: "Average DriverINDEX Score", type: 'number', chartDataType: 'series' },
            index_score_trend: { name: "Trend", type: 'number', chartDataType: 'series' }
        }
    },
    {
        groupName: "Event Type: Collision",
        structure: {
            collision_active_events_driver: { name: "# Active Events per driver", type: 'number', chartDataType: 'series'},
            collision_index_sub_score: { name: "Indexing Sub Score", type: 'number', chartDataType: 'series'},
            collision_perc_index_score: { name: "% of Index Score", type: 'percentage', chartDataType: 'series' }
        }
    },
    {
        groupName: "Event Type: Company",
        structure: {
            company_active_events_driver: { name: "# Active Events per driver", type: 'number', chartDataType: 'series'},
            company_index_sub_score: { name: "Indexing Sub Score", type: 'number', chartDataType: 'series'},
            company_perc_index_score: { name: "% of Index Score", type: 'percentage', chartDataType: 'series' }
        }
    },
    {
        groupName: "Event Type: Incident",
        structure: {
            incident_active_events_driver: { name: "# Active Events per driver", type: 'number', chartDataType: 'series'},
            incident_index_sub_score: { name: "Indexing Sub Score", type: 'number', chartDataType: 'series'},
            incident_perc_index_score: { name: "% of Index Score", type: 'percentage', chartDataType: 'series'}
        }
    },
    {
        groupName: "Event Type: Licence",
        structure: {
            licence_active_events_driver: { name: "# Active Events per driver", type: 'number', chartDataType: 'series'},
            licence_index_sub_score: { name: "Indexing Sub Score", type: 'number', chartDataType: 'series'},
            licence_perc_index_score: { name: "% of Index Score", type: 'percentage', chartDataType: 'series'}
        }
    },
    {
        groupName: "Event Type: VRM",
        structure: {
            vrm_active_events_driver: { name: "# Active Events per driver", type: 'number', chartDataType: 'series'},
            vrm_index_sub_score: { name: "Indexing Sub Score", type: 'number', chartDataType: 'series'},
            vrm_perc_index_score: { name: "% of Index Score", type: 'percentage', chartDataType: 'series'}
        }
    },
    {
        groupName: "Events per 100 miles",
        structure: {
            acceleration: { name: "Acceleration", type: 'number', chartDataType: 'series'},
            braking: { name: "Braking", type: 'number', chartDataType: 'series'},
            cornering: { name: "Cornering", type: 'number', chartDataType: 'series'},
            speeding: { name: "Speeding", type: 'number', chartDataType: 'series'},
            distraction: { name: "Distraction", type: 'number', chartDataType: 'series'}
        }
    }
];


export const driverIndexCompareData = [
    {
        "location": "Cape May",
        "number_drivers": 30,
        "active_events_driver": 5,
        "average_index_score": 77,
        "index_score_trend": "Down",
        "collision_active_events_driver": 5,
        "collision_index_sub_score": 1.2,
        "collision_perc_index_score": 20,
        "company_active_events_driver": 8,
        "company_index_sub_score": 1,
        "company_perc_index_score": 60,
        "incident_active_events_driver": 5,
        "incident_index_sub_score": 1.2,
        "incident_perc_index_score": 20,
        "licence_active_events_driver": 8,
        "licence_index_sub_score": 1,
        "licence_perc_index_score": 60,
        "vrm_active_events_driver": 8,
        "vrm_index_sub_score": 1,
        "vrm_perc_index_score": 60,
        "acceleration": 10,
        "braking": 0,
        "cornering": 10,
        "speeding": 0,
        "distraction": 10
    },
    {
        "location": "Huddersfield",
        "number_drivers": 20,
        "active_events_driver": 7,
        "average_index_score": 33,
        "index_score_trend": "Down",
        "collision_active_events_driver": 3,
        "collision_index_sub_score": 0.9,
        "collision_perc_index_score": 10,
        "company_active_events_driver": 2,
        "company_index_sub_score": 1.2,
        "company_perc_index_score": 20,
        "incident_active_events_driver": 3,
        "incident_index_sub_score": 0.9,
        "incident_perc_index_score": 10,
        "licence_active_events_driver": 2,
        "licence_index_sub_score": 1.2,
        "licence_perc_index_score": 20,
        "vrm_active_events_driver": 2,
        "vrm_index_sub_score": 1.2,
        "vrm_perc_index_score": 20,
        "acceleration": 15,
        "braking": 2,
        "cornering": 15,
        "speeding": 2,
        "distraction": 15
    },
    {
        "location": "Boston",
        "number_drivers": 10,
        "active_events_driver": 3,
        "average_index_score": 55,
        "index_score_trend": "Static",
        "collision_active_events_driver": 6,
        "collision_index_sub_score": 1.5,
        "collision_perc_index_score": 70,
        "company_active_events_driver": 5,
        "company_index_sub_score": 2.1,
        "company_perc_index_score": 15,
        "incident_active_events_driver": 6,
        "incident_index_sub_score": 1.5,
        "incident_perc_index_score": 70,
        "licence_active_events_driver": 5,
        "licence_index_sub_score": 2.1,
        "licence_perc_index_score": 15,
        "vrm_active_events_driver": 5,
        "vrm_index_sub_score": 2.1,
        "vrm_perc_index_score": 15,
        "acceleration": 6,
        "braking": 5,
        "cornering": 6,
        "speeding": 5,
        "distraction": 6
    },
    {
        "location": "New York",
        "number_drivers": 10,
        "active_events_driver": 2,
        "average_index_score": 35,
        "index_score_trend": "Up",
        "collision_active_events_driver": 8,
        "collision_index_sub_score": 3,
        "collision_perc_index_score": 30,
        "company_active_events_driver": 8,
        "company_index_sub_score": 1.2,
        "company_perc_index_score": 30,
        "incident_active_events_driver": 8,
        "incident_index_sub_score": 3,
        "incident_perc_index_score": 30,
        "licence_active_events_driver": 8,
        "licence_index_sub_score": 1.2,
        "licence_perc_index_score": 30,
        "vrm_active_events_driver": 8,
        "vrm_index_sub_score": 1.2,
        "vrm_perc_index_score": 30,
        "acceleration": 22,
        "braking": 10,
        "cornering": 22,
        "speeding": 10,
        "distraction": 22
    },
    {
        "location": "San Jose",
        "number_drivers": 20,
        "active_events_driver": 6,
        "average_index_score": 67,
        "index_score_trend": "Up",
        "collision_active_events_driver": 2,
        "collision_index_sub_score": 1.8,
        "collision_perc_index_score": 15,
        "company_active_events_driver": 9,
        "company_index_sub_score": 4,
        "company_perc_index_score": 25,
        "incident_active_events_driver": 2,
        "incident_index_sub_score": 1.8,
        "incident_perc_index_score": 15,
        "licence_active_events_driver": 9,
        "licence_index_sub_score": 4,
        "licence_perc_index_score": 25,
        "vrm_active_events_driver": 9,
        "vrm_index_sub_score": 4,
        "vrm_perc_index_score": 25,
        "acceleration": 27,
        "braking": 25,
        "cornering": 27,
        "speeding": 25,
        "distraction": 27
    },
    {
        "location": "Rome",
        "number_drivers": 15,
        "active_events_driver": 9,
        "average_index_score": 98,
        "index_score_trend": "Static",
        "collision_active_events_driver": 9,
        "collision_index_sub_score": 2.1,
        "collision_perc_index_score": 10,
        "company_active_events_driver": 2,
        "company_index_sub_score": 3,
        "company_perc_index_score": 33,
        "incident_active_events_driver": 9,
        "incident_index_sub_score": 2.1,
        "incident_perc_index_score": 10,
        "licence_active_events_driver": 2,
        "licence_index_sub_score": 3,
        "licence_perc_index_score": 33,
        "vrm_active_events_driver": 2,
        "vrm_index_sub_score": 3,
        "vrm_perc_index_score": 33,
        "acceleration": 40,
        "braking": 10,
        "cornering": 40,
        "speeding": 10,
        "distraction": 40
    },
    {
        "location": "BENCHMARK",
        "number_drivers": 18,
        "active_events_driver": 5,
        "average_index_score": 61,
        "index_score_trend": "Static",
        "collision_active_events_driver": 6,
        "collision_index_sub_score": 2,
        "collision_perc_index_score": 26,
        "company_active_events_driver": 6,
        "company_index_sub_score": 2,
        "company_perc_index_score": 31,
        "incident_active_events_driver": 6,
        "incident_index_sub_score": 2,
        "incident_perc_index_score": 26,
        "licence_active_events_driver": 6,
        "licence_index_sub_score": 2,
        "licence_perc_index_score": 31,
        "vrm_active_events_driver": 6,
        "vrm_index_sub_score": 2,
        "vrm_perc_index_score": 31,
        "acceleration": 20,
        "braking": 9,
        "cornering": 20,
        "speeding": 9,
        "distraction": 20
    }
];