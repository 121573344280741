import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  headers: HttpHeaders;
  options: any;
  constructor(private common: CommonService, private http: HttpClient, public jwtHelper: JwtHelperService) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.common.userProfile.token,
      'ed_locale': 'en_US',
    });
    this.options = { headers: this.headers };
  }
  public getBillingSetup(userId): Observable<HttpEvent<any>> {
    const url = Config.apiRoot.uri + `/api/v1/billing/user/${userId}`;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, options);
    return this.http.request(req);
  }
  public getChargeHistory(userId): Observable<HttpEvent<any>> {
    const url = Config.apiRoot.uri + `/api/v1/billing/user/${userId}/charges`;
    const options = { headers: this.headers };
    const req = new HttpRequest('GET', url, options);
    return this.http.request(req);
  }
  public createBillingSession(userId: number, body: any): Observable<HttpEvent<any>> {
    const url = Config.apiRoot.uri + `/api/v1/billing/user/${userId}/setupSessions`;
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, body, options);
    return this.http.request(req);
  }
  public detachPaymentMethod(userId: number,  paymentMethodId: string): Observable<HttpEvent<any>> {
    const url = Config.apiRoot.uri + `/api/v1/billing/user/${userId}/paymentMethods/${paymentMethodId}/detach`;
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, {}, options);
    return this.http.request(req);
  }
  public completeBillingSession(userId: number, sessionId: string): Observable<HttpEvent<any>> {
    const url = Config.apiRoot.uri + `/api/v1/billing/user/${userId}/setupSessions/${sessionId}/complete`;
    const options = { headers: this.headers };
    const req = new HttpRequest('POST', url, {}, options);
    return this.http.request(req);
  }
  
}
