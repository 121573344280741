/**
 * The following translations must be added to en.json.
 * Find the top-level object for each and add newly created ones.
 * 
 * Then ensure the parent object is subscribed to in the CommonService.
 */

export const readyTranslations = {
  messagesAndWarnings: {
    noResultsFound: 'No Results Found',
    failedToFetchData: 'Failed to fetch data',
    failedToProcessData: 'Failed to process data',
    failedToProcessRequest: 'Failed to process request',
    keepAliveFailed: 'Keep-alive Failed',
    deletionFailed: 'Failed to Delete',
    refreshAndTryAgain: 'Refresh and try again',
    passwordSuccessfullyChanged: 'Password successfully changed',
    invalidPassword: 'Invalid new password',
    signInFailed: 'Sign-In Failed . Check credentials',
    passwordResetRequestFailed: 'Password reset request failed . Refresh and try again',
    ifProblemPersistsContact: 'If problem persists contact',
    passwordResetSuccessfullyRequested: 'Password reset successfully requested . Check your email to complete the process',
    unableToValidateSecurityRequest: 'Unable to validate security request . Try the process again.',
    uploadCsvFile: 'Please import valid .csv file',
    functionalityNotAvailable: 'Functionality not available',
    insufficientPermissionToViewRequestedPage: 'You have insufficient permission to view the requested page',
    working: 'Working...',
    genericError: 'Something went wrong',
    activationMailSuccess: 'Activation email sent.',
    activationMailFailed: 'Failed to send.',
    activationBulkMailSuccess: 'Bulk activation email sent successfully.',
    activationBulkMailFailed: 'Bulk email failed to send.',
    unauthorized: 'Access denied',
    checkCredentials: 'Please ensure you have entered existing credentials correctly',
    tryAgainIn: 'Try again in: ',
    formatNotSupported: 'The format in use is not supported',
    failedToSwitchToTheSelectedCompany: 'Failed to switch to the selected company',
    fileMayNotWorkCorrectly: 'This file may not download or work correctly',
    requestedAssetNotAvailable: 'Requested asset not available',
    landingPageNotFound: 'Landing page not found',
    mvrOrdered: 'MVR ORDER REQUEST SENT',
    hasMvrInformationSuggestion: 'Sufficient information to order an MVR',
    noValidDriverLicenseSuggestion: 'Valid driver license information',
    pendingMvrOrderSuggestion: 'No pending MVR order',
    invalidUserInfo: 'Invalid user information.',
    invalidStations: 'Station(s) not found.',
    invalidCsvRow: 'Invalid row entry in the CSV.',
    uploadErrorGeneral: 'Errors found while uploading.Please check error below then click on \'Continue\' button to try again.'
  },

  generic: {
    search: 'SEARCH',
    refresh: 'Refresh',
    reloading: 'Reloading',
    ok: 'OK',
    close: 'close',
    cancel: 'Cancel',
    bookmark: 'Bookmark',
    reportState: 'Report state',
    createNewOneToOne: 'Create New One To One Coaching Session',
    createNewPccReview: 'Create New Post Collision Coaching Session',
    createNewDriverIndexCoach: 'Create New DriverINDEX Coaching Session',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sept: 'Sept',
    sep: 'Sept',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
    readonlyView: 'Read-only view',
    print: 'Print',
    orderMvr: 'Order MVR',
    driver: 'Driver',
    license: 'License',
    note: 'Note',
    created: 'Created',
    description: 'Description',
    acknowledgement: 'Acknowledgement',
    update: 'Update',
    details: 'Details',
    mentorPlaylist: 'Mentor Playlist',
    assigned: 'Assigned',
    noMentorPlaylistModules: 'No Modules Found',
    up: 'Up',
    down: 'Down',
    notApplicable: 'N/A'
  },

  form: {
    required: 'Required',
    invalidEmail: 'Enter a valid email',
    mustMatch: 'must match',
    invalid: 'is invalid',
    failedToSaveFormData: 'Failed to save Form data',
    formSaved: 'Form Saved',
    driverPerformanceRecord: 'Driver Performance Record',
    oneToOnePlus: 'OneToOne+',
    postCollisionCrash: 'Post Collision Crash',
    driverIndexCoachReview: 'DriverINDEX Coach Review',
    saveCurrentProgress: 'Save Current Progress',
    cancel: 'Cancel',
    markSessionAsComplete: 'Mark Session as Complete?',
    completeAllRequiredFields: 'Please complete all required fields',
    coachingSessionWillNoMoreBeEditable: 'This coaching session will no more be editable once confirmed as complete',
    pressCompleteToConfirm: 'Press the Complete button to confirm',
    coachingCanOnlyBeMarkedCompleteIfRequiredFilled: 'Coaching can only be marked as complete if all required fields have been completed',
    retry: 'Retry',
    yes: 'Yes',
    no: 'No',
    emailAddress: 'Email Address'
  },
  user: {
    firstNameLabel: 'First Name',
    middleNameLabel: 'Middle Name',
    lastNameLabel: 'Last Name',
    phoneNumberLabel: 'Phone Number',
    eMailLabel: 'Email Address',
    driver: 'Driver',
    manager: 'Manager',
    driverSelect: 'Select station to drive',
    managerSelect: 'Select station to manage',
    successMessage: 'User successfully created',
    successUpdateMessage: 'User successfully saved',
    confirmDeleteMessage: 'Deleting user cannot be undone. Do you really want to delete the user?',
    successDeleteMessage: 'User successfully deleted',
    retryDeleteMessage: 'Please try again',
    failedDeleteMessage: 'User deletion failed',
    uploadUsersButton: 'Choose your csv file for upload',
    downloadTemplateButton: 'Download csv template',
    uploadInvalidRow: 'Invalid',
    uploadValidRow: 'Valid',
    uploadLosedRow: 'Lost',
    uploadSubmit: 'Upload valid users',
    userExisist: 'User already exists',
    GroupDataUpdateFailed: 'Groups data failed to save',
    UserDataUpdateFailed: 'User data failed to save',
    uploadContinue: 'Continue',
    formatToCsvTemplate: 'In Order to upload users, you must format the data using our CSV template.',
    downloadOnRight: 'You can download it on the right.',
    saveAndUploadFile: 'Once you have entered your data into this template,save it and upload the file below.',
    verifyAfterUpload: 'After the data uploads, you will have an opportunity to verify and edit it before adding the new user accounts.',
    uploadingStatus: 'Uploading',
    uploadingStatusTo: 'to',
    uploadingStatusOutOf: 'out of ',
    uploadingStatusRecords: 'records.',
    uploadResultSubject: 'User/s',
    uploadResultTitle: 'Upload users result',
    updateUser: 'Update User',
    createUser: 'Create User',
    deleteUser: 'Delete User',
    sendBatchActivationEmail: 'SEND BATCH ACTIVATION EMAILS',
    usernameOrEmailExists: 'User name or email exists',
    usernameExists: 'User name exists',
    emailExists: 'Email exists',
    unknownServerError: 'Server Error. Please retry.',
    invalidEntry: 'Invalid Data Entry.',
    invalidEmail: 'Invalid Email'
  },

  reportData: {
    safeDriver: 'Safe Driver',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    veryHigh: 'Very High',

    active: 'Active',
    inactive: 'Inactive',
    archive: 'Archive',
    other: 'Other',
    preHire: 'Pre Hire',

    agree: 'Agree',
    disagree: 'Disagree',
    hold: 'Hold',
    testAccount: 'Test Account',

    required: 'Required',
    optional: 'Optional',
    current: 'Current',

    complete: 'Complete',
    incomplete: 'Incomplete',
    inProgress: 'In progress',
    notStarted: 'Not Started',
    partial: 'Partial',
    na: 'NA',

    great: 'Great',
    good: 'Good',
    average: 'Average',
    poor: 'Poor',
    risky: 'Risky',

    veryLowRisk: 'Very Low Risk',
    lowRisk: 'Low Risk',
    mediumRisk: 'Medium Risk',
    highRisk: 'High Risk',
    veryHighRisk: 'Very High Risk',
    noData: 'No Data',

    yes: 'Yes',
    no: 'No',
    valid: 'Valid',
    notValid: 'Not Valid',
    seeMvr: 'See MVR',

    playlistItem: 'Playlist Item',
    dateAssigned: 'Date Assigned',
    dateCompleted: 'Date Completed',
    status: 'Status',
    benchmark: 'BENCHMARK',
    allStations: 'All Stations'
  },
  guideFaq: {
    managerGuides: 'Manager Guides FAQ',
    companyGuides: 'Company Guides FAQ',
    vrmGuides: 'VRM Guides FAQ'
  },
  mainSidenav: {
    contactUs: 'Contact Us'
  },
  orderMvr: {
    pleaseVerifyDriverHas: 'Please verify the driver has',
    sendAlert: 'Yes, send me alerts.',
    dontSendAlert: 'No, do not send me alerts.',
    emailAlert: 'Email Alert',
    mvrAlertNote: 'if you said yes for an email alert, you will get an alert when the order is placed and another when the MVR is returned',
    mvrSubmitNote: 'By clicking the button above, an MVR will be ordered using the above details and a charge will be made. The MVR will be logged into the system as ordered by',
    emailConfirmation: 'Email Confirmation',
    orderMvr: 'Order Mvr',
    orderMvrNow: 'Order MVR now',
    missingMvrLicense: 'No License Number'
  },

  alerts: {
    resolveAlert: 'Resolve Alert',
    resolveDate: 'Alert Resolved Date',
    resolveBy: 'Alert Resolved By',
    acknowledgement: 'To complete this mandatory action, select the \'alert resolved\' tickbox and enter details of the action taken and then click \'update\'.',
    alertResolved: 'Alert Resolved',
    actionsTaken: 'Actions Taken',
    alertDetail: 'Alert Detail',
    actionsTakenError: 'Actions Taken comments must be min:1 and max:250 characters',
    actionsTakenEmpty: 'Actions Taken input field cannot be empty'
  },
  userCsv: {
    firstName: 'First Name*',
    lastName: 'Last Name*',
    middleName: 'Middle Name',
    email: 'Email*',
    phoneNumber: 'Phone Number (Optional)',
    driverGroupId: 'Station Assignment* (only 1 per driver)',
    managerGroupIds: 'Managed Station(s) If user is a manager - enter stations they manage separated by a space'
  }
};